import React from 'react'
import { connect } from 'react-redux'
import { default as SurveyAnswer } from './survey_answer.jsx'
import { default as ResponseLastEditedBy } from './response_last_edited_by.jsx'
import RmgProgramLogo from '../helpers/rmg_program_logo.jsx'
import IconWithTooltip from 'shared/components/icon_with_tooltip.jsx'

const SectionQuestion = ({
                             questionCode,
                             question,
                             answer,
                             status,
                             id,
                             helperText,
                             earliestAllowedDateForYes
                         }) => {

    let questionClasses = [
        'col-sm-12 question',
        `status-${ status }`,
        `${ question.requireYesNoAnswer && !answer.answer && 'unanswered' }`
    ].join(' ')

    return (
        <div className={ questionClasses }>
            <div className="row">
                { question.program &&
                    <RmgProgramLogo imagePath={ question.program.imagePath } />
                }
                <div className="col-sm-11">
                    <p className="question-content">
                        { questionCode && `${questionCode}. ` }
                        <span dangerouslySetInnerHTML={{ __html: question.questionContent }} />
                    </p>
                </div>
                <div className="col-sm-1">
                    <IconWithTooltip
                        icon="fa-question-circle"
                        content={ question.helpContent }
                    />
                    <ResponseLastEditedBy
                        sectionQuestionId={ id }
                    />
                </div>
            </div>

            {/* { question.resetAnswerAnnually &&
        <div className="row">
          <div className="col-sm-12">
            <em className='annual-reset-notice'>This question resets and must be answered again annually.</em>
          </div>
        </div>
      } */}

            <div className="row">
                <SurveyAnswer question={ id }
                              allowNotApplicableAnswer={ question.allowNotApplicableAnswer }
                              requireYesNoAnswer={ question.requireYesNoAnswer }
                              requireDateWhenAnsweringYes={ question.requireDateWhenAnsweringYes }
                              earliestAllowedDateForYes={ earliestAllowedDateForYes }
                              status={ status } />
            </div>
        </div>
    )
}

export default connect(
    (state, props) => ({
        answer: state.responses.find((response) => response.sectionQuestionId == props.id) || {},
    })
)(SectionQuestion)
