import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import ConfirmationModal from 'shared/components/confirmation_modal.jsx'
import ConfirmAndSaveModalFooter from 'shared/components/confirm_and_save_modal_footer.jsx'

import { apiEndpoint } from '../../ducks/rails.js'
import { policyYear } from 'apps/cyber/shared/ducks/survey.js'
import { submitSectionToRails } from '../../ducks/survey.js'

class ImportQuestionTable extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            saveAttemptStatus: "unattempted", // ["unattempted", "success", "fail"]
            errorMessageFromSaveAttempt: '',
            confirmingImport: false
        }

        this.handleImport = this.handleImport.bind(this)
    }

    componentDidMount() {
        new App.CyberQuestionTable(this.importTable, this.props.coverageTypeId, this.props.policyYear).render()
    }

    handleImport({ confirmed = false }) {
        if(!confirmed && this.props.surveyStatus === 'active') {
            this.setState({ confirmingImport: true })

        } else {
            this.setState({ confirmingImport: false })

            let section = { cyber_section_questions_attributes: {} }
            for (let [index, value] of App.CyberQuestionTable.questionsToImport(this.importTable).entries()) {
                section.cyber_section_questions_attributes[index] = JSON.parse(value)
            }

            let { apiEndpoint: { path, method }, mode, dispatch } = this.props
            path = path.replace(/:id/g, this.props.surveySectionId)

            submitSectionToRails(section, path, method, mode, dispatch).
            then(() => {
                this.setState({ saveAttemptStatus: "success" })
            }).
            then(() => {
                App.CyberQuestionTable.removeRowsThatWereJustImported(this.importTable)
            }).
            catch((json) => {
                this.setState({
                    saveAttemptStatus: "fail",
                    errorMessageFromSaveAttempt: json.error || "Problem importing questions"
                })
            })
        }
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <table ref={ (table) => { this.importTable = table } } className="table table-striped">
                            <thead>
                            <tr>
                                <th></th>
                                <th>Question Title</th>
                                <th>Client Tier</th>
                                <th>Last Edited Date</th>
                            </tr>
                            </thead>
                            <tfoot>
                            <tr className='search'>
                                <th></th>
                                <th className='select-filter' id='filter-question-content'></th>
                                <th></th>
                                <th></th>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12">
                        {
                            this.state.saveAttemptStatus === "success" &&
                            <div className="pull-left">
                                <em>
                                    <i className="fa fa-check-circle text-success" aria-hidden="true">
                                    </i> Questions imported successfully
                                </em>
                            </div>
                        }

                        {
                            this.state.saveAttemptStatus === "fail" &&
                            <div className="pull-left">
                                <em>
                                    <i className="fa fa-times-circle text-danger" aria-hidden="true">
                                    </i> { this.state.errorMessageFromSaveAttempt }
                                </em>
                            </div>
                        }

                        {
                            this.state.saveAttemptStatus !== "success" &&
                            <div>
                                <br />
                                { !this.state.confirmingImport ? (
                                    <button className="pull-right btn btn-sm btn-muted-blue" onClick={ this.handleImport }>
                                        { this.state.saveAttemptStatus === "unattempted" ? 'Import Questions' : 'Retry' }
                                    </button>
                                ) : (
                                    <div>
                                        <div className='confirm-text'>
                                            <i className="fa fa-exclamation-circle"></i>
                                            Are you sure you want to import to the active survey?
                                        </div>
                                        <div className="pull-right">
                                            <button
                                                type="button"
                                                className="btn btn-link"
                                                onClick={ () => { this.setState({ confirmingImport: false }) } }
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="btn btn-danger"
                                                onClick={ () => { this.handleImport({ confirmed: true }) } }
                                            >
                                                Confirm
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

ImportQuestionTable.propTypes = {
    surveySectionId: PropTypes.number.isRequired,
    mode:            PropTypes.string.isRequired,
    apiEndpoint:     PropTypes.object.isRequired,
    policyYear:      PropTypes.number.isRequired,

    coverageTypeId:  PropTypes.number /* || null if this is for the 'General Guidelines' section */
}

ImportQuestionTable = connect(
    (state, props) => {
        return {
            surveyStatus: state.survey.status,
            apiEndpoint:  apiEndpoint(state.rails, { resource: "surveySections", mode: "update" }),
            policyYear:   policyYear(state.survey),
            mode:         "update"
        }
    }
)(ImportQuestionTable)

export default ImportQuestionTable
