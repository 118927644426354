import React from 'react'
import { Provider } from 'react-redux'

import { default as Survey } from './components/survey.jsx'

import { default as store, loadResponses, loadScores, loadLink, loadExitLink } from './store.js'

import { load as loadSurvey }      from 'apps/cyber/shared/ducks/survey.js'
import { loadWithReviewAndSubmit } from 'apps/cyber/shared/ducks/coverage_types.js'
import { load as loadLookup }      from 'apps/cyber/shared/ducks/lookups.js'


class CyberSurveyFrontend extends React.Component {
    constructor(props) {
        super(props)

        store.dispatch(loadSurvey(this.props))
        store.dispatch(loadWithReviewAndSubmit(this.props))
        store.dispatch(loadResponses(this.props.responses))
        store.dispatch(loadScores(this.props.scores))
        store.dispatch(loadLookup('tier', this.props.meta.tier))
        store.dispatch(loadExitLink(this.props.meta.saveAndExitLink))
        store.dispatch(loadLink(this.props.meta.cyberLink))
    }

    render() {
        return (
            <Provider store={ store }>
                <Survey />
            </Provider>
        )
    }
}

export default CyberSurveyFrontend
