import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { closeMobileMenu } from '../ducks/wizard.js'

class PanelSidebar extends React.Component {
  onLinkClick = (event) => {
    event.target.blur()

    if (this.props.mobileMenuOpen) { this.props.closeMobileMenu() }

    if (this.props.onLinkClick) {
      this.props.onLinkClick(event)
    }
  }

  badgeFor = (countKey) => {
    if (this.props[countKey] && this.props[countKey] > 0) {
      return <span className="badge">{ this.props[countKey] }</span>
    }

    return null
  }

  render() {
    return (
      <ul className={ `panel-body-left nav nav-stacked nav-sidebar ${this.props.mobileMenuOpen ? "-is-open" : ""}` }>
        <li><NavLink to="/general_settings" onClick={ this.onLinkClick }>General Settings</NavLink></li>
        <li><NavLink to="/event_questions" onClick={ this.onLinkClick }>Event Questions</NavLink></li>
        <li><NavLink to="/email_notifications" onClick={ this.onLinkClick }>Email Notifications</NavLink></li>
        { this.props.attendeeCount > 0 ? <li><NavLink to="/email_attendees" onClick={ this.onLinkClick }>{`Send Email to (${this.props.attendeeCount}) ${this.props.attendeeCount > 1 ? 'Attendees' : 'Attendee'}`}</NavLink></li> : null }
      </ul>
    )
  }
}

PanelSidebar = connect(
  (state, props) => ({
    mobileMenuOpen:   state.wizard.mobileMenuOpen,
    attendeeCount: state.event.attendeeCount
  }),
  (dispatch, props) => ({
    closeMobileMenu: () => { dispatch(closeMobileMenu()) }
  })
)(PanelSidebar)

export default PanelSidebar
