import React from "react"
import { connect } from 'react-redux'

import { reorderable } from 'apps/cyber/shared/components/reorderable.jsx'
import EditableSectionQuestion from './editable_section_question.jsx'

import { reorderSectionQuestion } from '../ducks/survey.js'

let DraggableSectionQuestion = reorderable(EditableSectionQuestion, 'DraggableSectionQuestion', {
    moveHandlerName:    '_reorderSectionQuestion',
    endDragHandlerName: 'handleEndDrag',

    validDropTarget: (targetProps, sourceProps) => {
        return targetProps.surveySectionId === sourceProps.surveySectionId
    }
})

DraggableSectionQuestion = connect(
    null,
    (dispatch, props) => ({
        _reorderSectionQuestion: (source, target) => {
            dispatch(reorderSectionQuestion(source, target))
        }
    })
)(DraggableSectionQuestion)

export default React.forwardRef((props, ref) => {
    return <DraggableSectionQuestion { ...props } forwardedRef={ ref } />
});
