import React from 'react'
import { connect } from 'react-redux'
import FlipMove from 'react-flip-move';

import Card from 'shared/components/card.jsx'
import ControlButton from 'shared/components/control_button.jsx'
import { sortSectionQuestionsFromSection } from 'apps/rmg/shared/ducks/survey.js'

import { canDestroySections, canEditSections, canEditQuestions, submitSectionToRails } from '../ducks/survey.js'
import { apiEndpoint } from '../ducks/rails.js'

import SurveySectionFormContainer from './survey_section/survey_section_form_container.jsx'
import DeleteSurveySectionContainer from './survey_section/delete_survey_section_container.jsx'
import EditableSectionQuestion from './editable_section_question.jsx'
import DraggableSectionQuestion from './draggable_section_question.jsx'
import DragPreviewSectionQuestion from './drag_preview_section_question.jsx'
import AddOrImportQuestionButton from './add_or_import/add_or_import_question_button.jsx'

class EditableSurveySection extends React.Component {
  constructor(props) {
    super(props)

    this.state = { editing: false, confirmingDelete: false }

    this.handleEdit       = this.handleEdit.bind(this)
    this.handleHide       = this.handleHide.bind(this)
    this.handleDelete     = this.handleDelete.bind(this)
    this.handleDeleteHide = this.handleDeleteHide.bind(this)

    this.shouldHideSectionDetails        = this.shouldHideSectionDetails.bind(this)
    this.handleSectionQuestionReordering = this.handleSectionQuestionReordering.bind(this)
  }

  // hmm, deletion modals kinda suck, we unmount this whole component before the modals
  // handleHide runs, so we're trying to change this state post-unmount 😔
  componentDidMount() { this._isMounted = true; }
  componentWillUnmount() { this._isMounted = false; }

  handleEdit(event) {
    this.setState({ editing: true })
  }

  handleHide() {
    this.setState({ editing: false })
  }

  handleDelete(event) {
    this.setState({ confirmingDelete: true })
  }

  handleDeleteHide() {
    if (this._isMounted) { this.setState({ confirmingDelete: false }) }
  }

  handleSectionQuestionReordering() {
    let { apiEndpoint: { path, method }, mode, dispatch } = this.props
    path = path.replace(/:id/g, this.props.section.id)

    submitSectionToRails(this.props.section, path, method, mode, dispatch)
  }

  shouldHideSectionDetails() {
    return this.props.typeOfThingDragging === 'DraggableSurveySection' ? { display: 'none' } : {}
  }

  render() {
    const {
      id,
      coverageTypeId,
      header,
      sectionQuestionsAttributes: questions
    } = this.props.section

    const controls = []

    if (this.props.canEditSections) {
      controls.push(<ControlButton handler={ this.handleEdit } iconClass='glyphicon glyphicon-pencil' />)
    }

    if (this.props.canDestroySections) {
      controls.push(<ControlButton handler={ this.handleDelete } iconClass='glyphicon glyphicon-trash' />)
    }
    else {
      controls.push(<ControlButton iconClass='glyphicon glyphicon-lock' />)
    }

    if (this.props.canEditSections) {

      if (!this.props.beingUsedAsADragPreview && this.props.connectDragSource) {
        controls.push(
          this.props.connectDragSource(
            <div><ControlButton iconClass="glyphicon glyphicon-menu-hamburger" /></div>
          )
        )
      }
      else {
        controls.push(<ControlButton iconClass="glyphicon glyphicon-menu-hamburger" />)
      }
    }

    return (
      <div ref={ this.props.forwardedRef }>
        <Card fill={ false }
              controls={ controls }
              title={ header }
              body={
                <div style={ this.shouldHideSectionDetails() }>
                  {
                    !this.props.beingUsedAsADragPreview &&
                      <FlipMove easing="ease" duration={ 500 } staggerDurationBy={ 15 } staggerDelayBy={ 20 }>
                        {
                          sortSectionQuestionsFromSection(this.props.section).map((sectionQuestion) => {
                            return this.props.canEditQuestions ?
                              <DraggableSectionQuestion
                                key={ sectionQuestion.id }
                                id={ sectionQuestion.id }
                                question={ sectionQuestion.questionAttributes }
                                { ...sectionQuestion }
                                handleEndDrag={ this.handleSectionQuestionReordering } />
                            :
                              <EditableSectionQuestion
                                key={ sectionQuestion.id }
                                id={ sectionQuestion.id }
                                question={ sectionQuestion.questionAttributes }
                                { ...sectionQuestion } />
                          })
                        }
                      </FlipMove>
                  }

                  { this.props.canEditQuestions && <DragPreviewSectionQuestion key="__preview"/> }

                  { this.props.canEditQuestions && !this.props.beingUsedAsADragPreview &&
                      <AddOrImportQuestionButton surveySectionId={ id } coverageTypeId={ coverageTypeId } />
                  }
                </div>
              }
        />

        {
          this.state.editing &&
            <SurveySectionFormContainer handleHide={ this.handleHide } section={ this.props.section } />
        }

        {
          this.state.confirmingDelete &&
            <DeleteSurveySectionContainer handleHide={ this.handleDeleteHide }
                                          sectionId={ this.props.section.id } />
        }
      </div>
    )
  }
}

EditableSurveySection = connect(
  (state, props) => ({
    canDestroySections: canDestroySections(state.survey),
    canEditSections: canEditSections(state.survey),
    canEditQuestions: canEditQuestions(state.survey),
    apiEndpoint: apiEndpoint(state.rails, { resource: "surveySections", mode: "update" }),
    mode: "update"
  }),
  null
)(EditableSurveySection)

export default React.forwardRef((props, ref) => {
  return <EditableSurveySection { ...props } forwardedRef={ ref } />
});
