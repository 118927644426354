import React from 'react'
import PropTypes from 'prop-types';

import { policyYearRange } from 'shared/utils/policy_year.js'

let Form = ({ helperText, policyYear, handlePolicyYearChange }) => (
  <div>
    <div className="row">
      <div className="col-xs-12">
        <p>{ helperText }</p>
      </div>
    </div>

    <div className="row">
      <br /> {/* some space would be nice */}
    </div>

    <div className="row">
      <div className="col-xs-6">
        <div className="form-group is-required">
          <label>Effective Policy Year<span></span></label>

          <input type="text" className="form-control"
            value={ policyYear }
            onChange={ handlePolicyYearChange }
          />

          {
            policyYear && Number(policyYear) === Number(policyYear) &&
              <p className="help-block">
                As in policy year { policyYearRange(Number(policyYear)) }
              </p>
          }
        </div>
      </div>
    </div>
  </div>
)

Form.propTypes = {
  helperText: PropTypes.string.isRequired,
  handlePolicyYearChange: PropTypes.func.isRequired,

  policyYear: PropTypes.string
}

export default Form
