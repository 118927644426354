const lengthValidator = (length, message = "") => function(value, state) {

  // old api: we just consider it to be the max allowed
  if (typeof length === "number") {
    if (value && value.length > length) {
      return message || `Too many characters (${value.length}/${length})`;
    }

    return null;
  }

  const { eq } = length

  if (eq) {
    if (value && value.length !== eq) {
      return message || `Must be ${exactly} characters or digits`
    }
  }

  // examples of what this api could support, but we don't need them today
  //
  // if (lt) {
  //   if (value && value.length >= lt) {
  //     return message || `Must be less than ${exactly} characters or digits`
  //   }
  // }
  //
  // if (gt) {
  //   if (value && value.length <= gt) {
  //     return message || `Must be greater than ${exactly} characters or digits`
  //   }
  // }

  return null;
};

export default lengthValidator;
