import React from 'react'

import { formatPhoneNumber } from 'shared/utils/phone_numbers.js'

import Label from './label.jsx'
import Response from './response.jsx'

const Phone = ({ label, value, missingText = "Unspecified" }) => (
  <div className={ !value ? "unanswered" : "" }>
    <Label>{ label }</Label>
    <Response>{ value !== undefined && value !== null && value !== "" ? formatPhoneNumber(value) : missingText }</Response>
  </div>
)

export default Phone;
