// we refactored the bootstrap_modal component for the incident_report_form refactorings we did, but
// we have older efforts still using this. we should move them off it at somepoint.
// future efforts should use the shared/components/bootstrap_modal.jsx or adjust it for their needs.

import React from 'react'
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom'

class BootstrapModal extends React.Component {
  constructor(props) {
    super(props);

    this.uuid = this.uuid.bind(this);
  }

  componentDidMount() {
    $(ReactDOM.findDOMNode(this)).modal();

    if (this.props.handleHide) {
      $(ReactDOM.findDOMNode(this)).on('hidden.bs.modal', this.props.handleHide);
    }
  }

  componentWillUnmount() {
    // make sure this gets cleaned up
    $(ReactDOM.findDOMNode(this)).modal('hide');
  }

  render() {
    const uuid = this.uuid(); // overkill? should allow for more than one of these on a page though

    return (
      <div className="modal fade" id={ `${uuid}_modal` } tabIndex="-1" role="dialog"
                          aria-labelledby={ `${uuid}_modal_label` }
                          data-backdrop="static"
                          data-keyboard="false">

        <div className="modal-dialog" role="document">

          <div className="modal-content">
            <div className="modal-header">

              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title" id={ `${uuid}_modal_label` }>{ this.props.title }</h4>
            </div>

            <div className="modal-body">
              { this.props.body }
            </div>

            <div className="modal-footer">
              { this.props.footer ||
                <button type="button" className="btn btn-link" data-dismiss="modal">Close</button> }
            </div>
          </div>
        </div>
      </div>
    )
  }

  uuid() {
    // from: http://stackoverflow.com/a/2117523/1947079
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r&0x3 | 0x8);
      return v.toString(16);
    });
  }
}

BootstrapModal.propTypes = {
  body:            PropTypes.element.isRequired,
  title:           PropTypes.string.isRequired,

  footer:          PropTypes.element,
  handleHide:      PropTypes.func
}

export default BootstrapModal;
