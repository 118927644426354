import React from 'react'
import PropTypes from 'prop-types';

import BootstrapModal from 'shared/components/move_off_of_eventually_bootstrap_modal.jsx'

class ConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <BootstrapModal title={ this.props.title }
        body={ this.props.body }
        footer={
          this.props.footer ||
            <div>
              <button type="button"
                      className="btn btn-link"
                      data-dismiss="modal">
                { this.props.cancelText || 'Cancel' }
              </button>

              <button type="button"
                      className="btn btn-default btn-danger"
                      data-dismiss="modal"
                      tabIndex="2"
                      onClick={ this.props.handleConfirm }>
                { this.props.confirmText || 'Delete' }
              </button>
            </div>
        }
        handleHide={ this.props.handleHide }
      />
    )
  }
}

ConfirmationModal.propTypes = {
  body:            PropTypes.element.isRequired,
  footer:          PropTypes.element,
  title:           PropTypes.string.isRequired,
  confirmText:     PropTypes.string,
  cancelText:      PropTypes.string,

  handleConfirm:   PropTypes.func,
  handleHide:      PropTypes.func
}

export default ConfirmationModal;
