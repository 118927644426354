import React from 'react'
import { Switch, Route, Link, Redirect } from 'react-router-dom'

import PropertiesIndex from './properties_index.jsx'
import PropertiesNew from './properties_new.jsx'
import PropertiesEdit from './properties_edit.jsx'

let PropertiesRouter = () => (
  <Switch>
    <Route exact path="/properties"          component={ PropertiesIndex } />
    <Route exact path="/properties/new"      component={ PropertiesNew } />
    <Route       path="/properties/:id/edit" component={ PropertiesEdit } />
  </Switch>
)

export default PropertiesRouter
