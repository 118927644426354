import React from 'react'
import { connect } from 'react-redux'

import BaseForm from 'shared/components/base_form.jsx'

import {
  FormField,
  TextField
} from 'shared/components/fields'

import {
  commaSeparatedEmailsValidator,
  requiredValidator,
} from 'shared/utils/validators'

import RailsApi from 'shared/utils/rails_api.js'

import {
  extractDateFromRailsDateTime,
  extractTimeFromRailsDateTime
} from 'shared/utils';

import EmailEditor from '../email_notifications/email_editor';

import { apiEndpoint } from '../ducks/rails'
import { resetWizardState, updateStarted, updateSucceeded, setSuccessFlash, updateFailed } from '../ducks/wizard'
import { resetErrorsForEvent } from '../ducks/submission_errors'
import { Panel, PanelSidebar, PanelRightColumn, PanelBody, PanelControls } from '../panel'

class EmailAttendeesForm extends BaseForm {
  constructor(props) {
    super(props)

    const errors = { ...(this.props.errors || {}) }

    this.state = {
      email: {
        cc:      new FormField(props.event.organizerEmails, commaSeparatedEmailsValidator()),
        subject: new FormField('', requiredValidator()),
        message: new FormField('', requiredValidator()),
      }
    };

    // add any errors we found when attempting to submit
    Object.keys(this.state.email).map((key) => {
      this.state.email[key].errorMessage = (errors[key] || {}).message
    })
  }

  handleChangeFor = (name) => {
    return ( this.handleFieldChange("email", name) )
  }

  prepareData = () => {
    let copy = { email: { ...this.state.email } }

    // turn FormField objects in to just the raw value
    Object.keys(copy.email).map((key) => {
      copy.email[key] = copy.email[key].value
    })

    return { email: copy }
  }

  handleSend = (event) => {
    if (!this.validateFields("email")) { return null; }
    event.preventDefault();
    let { dispatch, apiEndpoint } = this.props

    this.props.dispatch(updateStarted())
    return RailsApi.patch(`${apiEndpoint.path}`, { body: this.prepareData() }).
      then((json) => {
        dispatch({
          type: 'RAILS_UPDATE_SUCCEEDED',
          actions: [
            updateSucceeded(),
            setSuccessFlash('Email Sent')
          ]
        })
      }).
      catch((json) => {
        dispatch({
          type: 'RAILS_UPDATE_FAILED',
          actions: [
            updateFailed(),
            setErrorFlash(json.error.message, { details: json.error.details })
          ]
        })
      })
  }


  render() {
    return (
      <Panel>
        <PanelSidebar />

        <PanelRightColumn>
          <PanelBody>
            <div className="row">
              <div className="col-xs-12">
                <h4>{`Send Email to (${this.props.event.attendeeCount}) ${this.props.event.attendeeCount > 1 ? 'Attendees' : 'Attendee'}`}</h4>
                <p>{`There ${this.props.event.attendeeCount > 1 ? 'are' : 'is'} currently (${this.props.event.attendeeCount}) `}
                    <a href={`/event_attendees?event_id=${this.props.event.id}`}>{`registered ${this.props.event.attendeeCount > 1 ? 'attendees' : 'attendee'} `}</a>
                    for this event. Use this form to send a custom email message from VRSA.</p>

                <TextField type="text"
                            name="subject"
                            onChange={ this.handleChangeFor('subject') }
                            label="Subject"
                            value={ this.state.email.subject.value }
                            errorMessage={ this.state.email.subject.errorMessage }
                            tabIndex="1"
                            autoFocus={ false } />

                <TextField type="text"
                            name="cc"
                            onChange={ this.handleChangeFor('cc') }
                            label="CC"
                            value={ this.state.email.cc.value }
                            errorMessage={ this.state.email.cc.errorMessage }
                            tabIndex="2"
                            autoFocus={ false } />

                <p>Message</p>
                <EmailEditor
                  defaultMessage={this.state.email.message.value}
                  tabIndex={3}
                  onChange={(value) => this.handleChangeFor('message')(value)}
                />
              </div>
            </div>
          </PanelBody>

          <PanelControls actionMessage="Sending...">
            <button type="button"
                    onClick={ this.handleSend }
                    className={ `btn btn-success ${this.props.updating ? "disabled" : ""}` }>

              Send Email
            </button>
          </PanelControls>
        </PanelRightColumn>
      </Panel>
    )
  }
}

EmailAttendeesForm = connect(
  (state, props) => ({
    event:            state.event,
    email:            state.eventEmail,
    errors:           state.submissionErrors.event,
    updateSucceeded:  state.wizard.updateSucceeded,
    updating:         state.wizard.updating,
    apiEndpoint:      apiEndpoint(state.rails, "emailAttendees"),
    flash:            state.wizard.flash
  }),
  (dispatch, props) => ({
    dispatch,
    resetWizardState: () => { dispatch(resetWizardState()) },
    resetSubmissionErrors: () => { dispatch(resetErrorsForEvent()) }
  })
)(EmailAttendeesForm);

export default EmailAttendeesForm
