import RailsApi from 'shared/utils/rails_api.js'

import { refreshMetaData } from './rails.js'
import { setErrorFlash, setSuccessFlash, updateSucceeded, updateFailed, updateStarted } from './wizard.js'
import { updateIncidentReport } from './incident_report.js'

const LOAD_PROPERTIES   = "incident_report_form/properties/LOAD_PROPERTIES"
const UPDATE_PROPERTIES = "incident_report_form/properties/UPDATE_PROPERTIES"

export default function properties(state = {}, action) {
  switch (action.type) {
    case LOAD_PROPERTIES: {
      return { ...action.properties }
    }

    case UPDATE_PROPERTIES: {
      return { ...action.properties }
    }

    default: {
      return state
    }
  }
}

export const loadProperties = (properties) => {
  return { type: LOAD_PROPERTIES, properties: properties }
}

export const updateProperties = (properties) => {
  return { type: UPDATE_PROPERTIES, properties: properties }
}

export const getPropertiesCount = (state) => ( Object.keys(state).length )

export const getProperty = (state, id) => {
  return Object.values(state).find((property) => (
    property.id && property.id.toString() === id.toString()
  ))
}

export const asyncUpdateProperties = (url, data) => (dispatch) => {
  dispatch(updateStarted())
  
  return RailsApi.patch(url, { body: data }).
    then((json) => {
      dispatch({
        type: 'RAILS_UPDATE_SUCCEEDED',
        actions: [
          refreshMetaData({ ...json.meta }),
          updateIncidentReport(json.incidentReport),
          updateProperties(json.incidentReport.damagedPropertiesAttributes),
          updateSucceeded(),
          setSuccessFlash("Properties updated", { keep: true })
        ]
      })
    }).
    catch((json) => {
      console.log(json)

      dispatch({
        type: 'RAILS_UPDATE_FAILED',
        actions: [
          updateFailed(),
          setErrorFlash(json.error.message, { details: json.error.details })
        ]
      })
    })
}

export const asyncDeleteProperty = (url, id, propertiesCount) => (dispatch) => {
  let data = {
    incidentReport: {
      damagedPropertiesAttributes: {
        [id]: { id: id, "_destroy": true }
      }
    }
  }

  if (propertiesCount === 1) { // we're about to delete the last property 😱
    data.incidentReport.wereAnyPropertiesDamaged = false
  }

  dispatch(updateStarted())

  return RailsApi.patch(url, { body: data }).
    then((json) => {
      dispatch({
        type: 'RAILS_UPDATE_SUCCEEDED',
        actions: [
          refreshMetaData({ ...json.meta }),
          updateIncidentReport(json.incidentReport),
          updateProperties(json.incidentReport.damagedPropertiesAttributes),
          updateSucceeded(),
          setSuccessFlash("Property deleted", { keep: propertiesCount === 1 }) // we redirect when we delete the last property
        ]
      })
    }).
    catch((json) => {
      console.log(json)

      dispatch({
        type: 'RAILS_UPDATE_FAILED',
        actions: [
          updateFailed(),
          setErrorFlash(json.error.message, { details: json.error.details })
        ]
      })
    })
}
