import React from "react";
import { connect } from "react-redux";

let CyberLinkWidget = ({ cyberLink }) => {
    console.log(cyberLink);
    if (!cyberLink) {
        return <div>Loading...</div>;
    }

    return (
        <div className="card card-rmg">
            <div className="card-header"> </div>
            <div className="card-body">
                <a href={cyberLink.link}>{cyberLink.displayName}</a>
            </div>
        </div>
    );
};

CyberLinkWidget = connect((state,props) => ({
    cyberLink: state.cyberLink
}))(CyberLinkWidget);

export default CyberLinkWidget;
