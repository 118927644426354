// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// -------- from rails --------
// require("@rails/ujs").start() // we're still using jquery_ujs
require("turbolinks").start()
// require("@rails/activestorage").start() // we're not using this
// require("channels") // not using this

// -------- polyfills --------
import 'babel-polyfill'
import 'whatwg-fetch'

// -------- our sprinkles (order matters) --------
require(`script-loader!sprinkles/init.js`);
require(`script-loader!sprinkles/label_floater.js`);
require(`script-loader!sprinkles/data_table.js`);

// -------- our sprinkles (order doesn't matters) --------
require(`script-loader!sprinkles/admin_table.js`);
require(`script-loader!sprinkles/app.scroll_out.js`);
require(`script-loader!sprinkles/automagic_form_submission.js`);
require(`script-loader!sprinkles/business_continuity_documents_table.js`);
require(`script-loader!sprinkles/documents_table.js`);
require(`script-loader!sprinkles/elasticubes_table.js`);
require(`script-loader!sprinkles/event_attendee_table.js`);
require(`script-loader!sprinkles/event_table.js`);
require(`script-loader!sprinkles/grant_requests_table.js`);
require(`script-loader!sprinkles/incident_reports_table.js`);
require(`script-loader!sprinkles/member_nav.js`);
require(`script-loader!sprinkles/non_react_phone_number_inputs.js`);
require(`script-loader!sprinkles/osha_incidents_table.js`);
require(`script-loader!sprinkles/question_table.js`);
require(`script-loader!sprinkles/survey_table.js`);
require('script-loader!sprinkles/cyber_survey_table.js')
require('script-loader!sprinkles/cyber_question_table.js')
require(`script-loader!sprinkles/sync_organizations.js`);
require(`script-loader!sprinkles/user_table.js`);

// -------- react_rails and react_ujs --------
var componentRequireContext = require.context("apps", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// -------- mount a component on click --------
// TODO - might be nice to ditch this. kinda weird to have to require react / react-dom outselves
//        in the handler.
$(document).on("click", "[data-behavior~=mount-react-component-on-click]", function(event) {
  event.preventDefault();

  let props = $(this).data("props")
  props.mount = document.getElementById($(this).data("mount"))

  require("react-dom").render(
    require("react").createElement(ReactRailsUJS.getConstructor($(this).data("component")), props, null),
    props.mount
  );
});
