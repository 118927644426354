const mimeTypeValidator = (allowableMimeTypes, message = "") => function(value, state) {
  if (value) {
    if (!allowableMimeTypes.includes(value)) {
      if (state.filename.value &&
          ['.dmg', '.exe', '.app'].some((ext) => ( state.filename.value.endsWith(ext) ))) {

        return 'File type not allowed (application/executable)'
      }
      else {
        return `File type not allowed (${value})`
      }
    }
  }

  return null;
};

export default mimeTypeValidator;
