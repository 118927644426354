import React from 'react'

class SameAsCheckbox extends React.Component {
  constructor(props) {
    super(props)

    // because we're not persisting this "same as" checkbox, we'll just take our best guess
    // when the component mounts, so if all the source and target values match, someone _probably_
    // clicked "same as" before
    this.state = { checked: this.shouldBeChecked(props.fields) }
  }

  // this should clear the checkbox if a user updates the value after clicking it. and techinically,
  // it'll check the checkbox if they make the values match, but i think that's ok, too.
  componentWillReceiveProps(nextProps) {
    let _shouldBeChecked = this.shouldBeChecked(nextProps.fields)

    if (_shouldBeChecked !== this.state.checked) {
      this.setState({ checked: _shouldBeChecked })
    }
  }

  shouldBeChecked = (fields) => {
    return fields.every((field) => {
      return field.source === field.target
    })
  }

  handleSameAsCheck = (event) => {
    let checked = event.target.checked

    this.setState((prevState) => ( { checked: checked } ), () => {
      if (this.state.checked) {
        this.props.fields.forEach((field) => { field.handleChange(field.source) })
      }
    })
  }

  render() {
    return (
      <div>
        <div className={ this.props.wrapperClass }> {/* TODO - stolen from how .form-group styles */}
          <div className="checkbox" >
            <label>
              <input type="checkbox"
                     checked={ this.state.checked }
                     onChange={ this.handleSameAsCheck } />
              { this.props.label }
            </label>
          </div>
        </div>

        { !this.state.checked && this.props.children }
      </div>
    )
  }
}

export default SameAsCheckbox
