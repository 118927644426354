import React from 'react'
import { connect } from 'react-redux'
import { Link, Redirect, Prompt } from 'react-router-dom'

import BaseForm from 'shared/components/base_form.jsx'
import { withSynchronousCheckForUpdateSucceeded } from 'shared/components/hocs/with_synchronous_check_for_update_succeeded.jsx'
import SelectWithAsyncOptionsFromRails from 'shared/components/select_with_async_options_from_rails'

import {
  FormField,
  TextField,
  PhoneNumberField,
  DateAndTimeField,
  AddressField,
  ToggleSwitchField
} from 'shared/components/fields'

import {
  requiredValidator,
  emailValidator,
  notInFutureValidator,
  lengthValidator,
  phoneNumberValidator
} from 'shared/utils/validators'

import { asyncUpdateIncidentReport } from '../ducks/incident_report.js'
import { apiEndpoint } from '../ducks/rails.js'
import { resetWizardState } from '../ducks/wizard.js'
import { resetErrorsForIncidentReport } from '../ducks/submission_errors.js'
import { Panel, PanelSidebar, PanelRightColumn, PanelBody, PanelControls } from '../panel'

import MemberInfo from './member_info.jsx'
import SelectMemberLocation from './select_member_location.jsx'

const requireFirstReportToEmailOrPhone = (value, state) => {
  if (!state.firstReportedToEmail.value && !state.firstReportedToPrimaryPhone.value) {
    return requiredValidator('Email or Phone Required')(value)
  }
}

class OverviewForm extends BaseForm {
  constructor(props) {
    super(props)

    const incidentReport = { ...(this.props.incidentReport || {}) }
    const errors = { ...(this.props.errors || {}) }

    this.state = {
      incidentReport: {
        descriptionOfHowIncidentOccurred:
          new FormField(incidentReport.descriptionOfHowIncidentOccurred, [requiredValidator(), lengthValidator(255)]),

        thirdPartyAtFault:                    new FormField(incidentReport.thirdPartyAtFault),

        thirdPartyFirstOrCompanyName:         new FormField(incidentReport.thirdPartyFirstOrCompanyName),
        thirdPartyLastName:                   new FormField(incidentReport.thirdPartyLastName),
        thirdPartyStreetAddress:              new FormField(incidentReport.thirdPartyStreetAddress),
        thirdPartyOptionalLine:               new FormField(incidentReport.thirdPartyOptionalLine),
        thirdPartyCity:                       new FormField(incidentReport.thirdPartyCity),
        thirdPartyState:                      new FormField(incidentReport.thirdPartyState),
        thirdPartyZip:                        new FormField(incidentReport.thirdPartyZip),
        thirdPartyPhone:                      new FormField(incidentReport.thirdPartyPhone, phoneNumberValidator()),
        thirdPartyEmail:                      new FormField(incidentReport.thirdPartyEmail, emailValidator()),
        thirdPartyAtFaultAdditionalComments:  new FormField(incidentReport.thirdPartyAtFaultAdditionalComments),

        hasInsuranceInfoForThirdPartyAtFault: new FormField(incidentReport.hasInsuranceInfoForThirdPartyAtFault),

        thirdPartyInsuranceProviderName:      new FormField(incidentReport.thirdPartyInsuranceProviderName),
        thirdPartyInsurancePolicyNumber:      new FormField(incidentReport.thirdPartyInsurancePolicyNumber),
        thirdPartyInsuranceAdjuster:          new FormField(incidentReport.thirdPartyInsuranceAdjuster),
        thirdPartyInsurancePolicyClaimNumber: new FormField(incidentReport.thirdPartyInsurancePolicyClaimNumber),
        thirdPartyInsuranceStreetAddress:     new FormField(incidentReport.thirdPartyInsuranceStreetAddress),
        thirdPartyInsuranceOptionalLine:      new FormField(incidentReport.thirdPartyInsuranceOptionalLine),
        thirdPartyInsuranceCity:              new FormField(incidentReport.thirdPartyInsuranceCity),
        thirdPartyInsuranceState:             new FormField(incidentReport.thirdPartyInsuranceState),
        thirdPartyInsuranceZip:               new FormField(incidentReport.thirdPartyInsuranceZip),
        thirdPartyInsurancePhone:             new FormField(incidentReport.thirdPartyInsurancePhone, phoneNumberValidator()),
        thirdPartyInsuranceEmail:             new FormField(incidentReport.thirdPartyInsuranceEmail, emailValidator()),

        incidentHappenedAt:
          new FormField(incidentReport.incidentHappenedAt, [requiredValidator(), notInFutureValidator()]),

        memberLocationCode:        new FormField(incidentReport.memberLocationCode, requiredValidator()),
        memberLocationDescription: new FormField(incidentReport.memberLocationDescription),

        department: new FormField(incidentReport.department, requiredValidator()),

        incidentStreetAddress: new FormField(incidentReport.incidentStreetAddress, requiredValidator()),
        incidentOptionalLine:  new FormField(incidentReport.incidentOptionalLine),
        incidentCity:          new FormField(incidentReport.incidentCity, requiredValidator()),
        incidentState:         new FormField(incidentReport.incidentState, requiredValidator()),
        incidentZip:           new FormField(incidentReport.incidentZip, requiredValidator()),

        firstReportedToName:           new FormField(incidentReport.firstReportedToName, requiredValidator()),
        firstReportedToPrimaryPhone:   new FormField(incidentReport.firstReportedToPrimaryPhone, [phoneNumberValidator(), requireFirstReportToEmailOrPhone]),
        firstReportedToAlternatePhone: new FormField(incidentReport.firstReportedToAlternatePhone),
        firstReportedToEmail:          new FormField(incidentReport.firstReportedToEmail, [emailValidator(), requireFirstReportToEmailOrPhone]),

        firstReportedOn: new FormField(incidentReport.firstReportedOn, [requiredValidator(), notInFutureValidator()])
      }
    }

    // add any errors we found when attempting to submit
    Object.keys(this.state.incidentReport).map((key) => {
      this.state.incidentReport[key].errorMessage = (errors[key] || {}).message
    })
  }

  handleChangeFor = (name) => ( this.handleFieldChange("incidentReport", name) )

  prepareData = () => {
    let copy = { incidentReport: { ...this.state.incidentReport } }

    // turn FormField objects in to just the raw value
    Object.keys(copy.incidentReport).map((key) => {
      copy.incidentReport[key] = copy.incidentReport[key].value
    })

    return copy
  }

  handleSave = (event) => {
    event.preventDefault()

    if (event.target && event.target.href) { // looks like we clicked a link in the sidebar
      // since we hijack clicking on the sidebar and attempt to save this form, forcing you
      // to at least deal with this first step before bouncing to anywhere in the form,
      // we need to remember what you clicked so we can send you on your way if everything checked
      // out
      this.setState({ redirect: event.target.href.split("/edit").slice(1).join('/edit') })
    }
    else {
      // otherwise, we need to forget if you had clicked the sidebar before then clicked
      // "Save and Continue" 🤷‍
      this.setState({ redirect: '' })
    }

    if (this.validateFields("incidentReport")) {
      this.props.asyncUpdateIncidentReport(this.prepareData())
    }
  }

  render() {
    if (this.props.updateSucceededAndWizardReset) {
      return <Redirect to={ this.state.redirect || "/contact_details" } />
    }

    return (
      <Panel>
        <PanelSidebar onLinkClick={ this.handleSave } />

        <PanelRightColumn>
          <PanelBody>
            <MemberInfo />

            <hr />

            <div className="row">
              <div className="col-xs-12">
                <h4>Occurrence Description</h4>

                <TextField type="textarea"
                           name="descriptionOfHowIncidentOccurred"
                           onChange={ this.handleChangeFor("descriptionOfHowIncidentOccurred") }
                           label="Incident Description"
                           value={ this.state.incidentReport.descriptionOfHowIncidentOccurred.value }
                           errorMessage={ this.state.incidentReport.descriptionOfHowIncidentOccurred.errorMessage }
                           required={ true }
                           tabIndex="1"
                           autoFocus={ true }
                           warn={ (value) => ( value && `Character limit: ${value.length}/255` ) } />
              </div>

              <div className="col-xs-12">
                <p>{ 'On what date and at approximately what time did this incident, injury, or illness occur?' }</p>

                <DateAndTimeField name="incidentHappenedAt"
                                  onChange={ this.handleChangeFor("incidentHappenedAt") }
                                  label={{ date: "Date of Occurrence", time: "Time of Occurrence" }}
                                  value={ this.state.incidentReport.incidentHappenedAt.value }
                                  errorMessage={ this.state.incidentReport.incidentHappenedAt.errorMessage }
                                  required={ true }
                                  tabIndex="2" />

                <p>Select a member-specific location/department.</p>

                <SelectMemberLocation label="Member Location..."
                                      value={ this.state.incidentReport.memberLocationCode.value }
                                      errorMessage={ this.state.incidentReport.memberLocationCode.errorMessage }
                                      onChange={ (code, description) => {
                                        this.handleChangeFor("memberLocationCode")(code)
                                        this.handleChangeFor("memberLocationDescription")(description)
                                      }}
                                      required={ true }
                                      tabIndex="3" />

                <p>Select a department type.</p>

                <SelectWithAsyncOptionsFromRails loadPath={ this.props.generalDepartmentsPath }
                                                 label="Department Type..."
                                                 value={ this.state.incidentReport.department.value }
                                                 errorMessage={ this.state.incidentReport.department.errorMessage }
                                                 onChange={ this.handleChangeFor("department") }
                                                 required={ true }
                                                 tabIndex="4" />

                <p>{ 'Where did this incident, injury, or illness occur?' }</p>

                <AddressField required={ true }
                              tabIndex="5"
                              fields={{
                                streetAddress: {
                                  name: "incidentStreetAddress",
                                  value: this.state.incidentReport.incidentStreetAddress.value,
                                  errorMessage: this.state.incidentReport.incidentStreetAddress.errorMessage,
                                  onChange: this.handleChangeFor("incidentStreetAddress")
                                },
                                optionalLine: {
                                  name: "incidentOptionalLine",
                                  value: this.state.incidentReport.incidentOptionalLine.value,
                                  errorMessage: this.state.incidentReport.incidentOptionalLine.errorMessage,
                                  onChange: this.handleChangeFor("incidentOptionalLine")
                                },
                                city: {
                                  name: "incidentCity",
                                  value: this.state.incidentReport.incidentCity.value,
                                  errorMessage: this.state.incidentReport.incidentCity.errorMessage,
                                  onChange: this.handleChangeFor("incidentCity")
                                },
                                state: {
                                  name: "incidentState",
                                  value: this.state.incidentReport.incidentState.value,
                                  errorMessage: this.state.incidentReport.incidentState.errorMessage,
                                  onChange: this.handleChangeFor("incidentState")
                                },
                                zip: {
                                  name: "incidentZip",
                                  value: this.state.incidentReport.incidentZip.value,
                                  errorMessage: this.state.incidentReport.incidentZip.errorMessage,
                                  onChange: this.handleChangeFor("incidentZip")
                                }
                              }} />

              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <p>{ 'To whom was this incident, injury, illness, property or vehicle damage first reported?' }</p>

                <TextField type="text"
                           name="firstReportedToName"
                           onChange={ this.handleChangeFor("firstReportedToName") }
                           label="Reported To Name"
                           value={ this.state.incidentReport.firstReportedToName.value }
                           errorMessage={ this.state.incidentReport.firstReportedToName.errorMessage }
                           required={ true }
                           tabIndex="6" />

                <p>{ 'How can we contact this individual?' }</p>
              </div>

              <div className="col-xs-12 cols-sm-6">
                <PhoneNumberField name="firstReportedToPrimaryPhone"
                                  onChange={ this.handleChangeFor("firstReportedToPrimaryPhone") }
                                  label="Primary Phone"
                                  value={ this.state.incidentReport.firstReportedToPrimaryPhone.value }
                                  errorMessage={ this.state.incidentReport.firstReportedToPrimaryPhone.errorMessage }
                                  required={ true }
                                  tabIndex="7" />
              </div>

              <div className="col-xs-12 col-sm-6">
                <PhoneNumberField name="firstReportedToAlternatePhone"
                                  onChange={ this.handleChangeFor("firstReportedToAlternatePhone") }
                                  label="Alternate Phone"
                                  value={ this.state.incidentReport.firstReportedToAlternatePhone.value }
                                  errorMessage={ this.state.incidentReport.firstReportedToAlternatePhone.errorMessage }
                                  tabIndex="8" />
              </div>

              <div className="col-xs-12 col-sm-6">
                <TextField type="text"
                           name="firstReportedToEmail"
                           onChange={ this.handleChangeFor("firstReportedToEmail") }
                           label="Email Address"
                           value={ this.state.incidentReport.firstReportedToEmail.value }
                           errorMessage={ this.state.incidentReport.firstReportedToEmail.errorMessage }
                           required={ true }
                           tabIndex="9" />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <p>{ 'When was this incident first reported?' }</p>

                <DateAndTimeField name="firstReportedOn"
                                  includeTime={ false }
                                  onChange={ this.handleChangeFor("firstReportedOn") }
                                  label={{ date: "Date First Reported" }}
                                  value={ this.state.incidentReport.firstReportedOn.value }
                                  errorMessage={ this.state.incidentReport.firstReportedOn.errorMessage }
                                  required={ true }
                                  tabIndex="10" />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <p>Is a party outside of your organization at fault?</p>

                <ToggleSwitchField name="thirdPartyAtFault"
                                   onChange={ this.handleChangeFor('thirdPartyAtFault') }
                                   value={ this.state.incidentReport.thirdPartyAtFault.value }
                                   errorMessage={ this.state.incidentReport.thirdPartyAtFault.errorMessage }
                                   tabIndex="11" />
              </div>

              {
                this.state.incidentReport.thirdPartyAtFault.value &&
                  <div className="col-xs-12">
                    <p>Please provide any information you have about the responsible party.</p>

                    <div className="row">
                      <div className="col-xs-6">
                        <TextField type="text"
                                   name="thirdPartyFirstOrCompanyName"
                                   onChange={ this.handleChangeFor("thirdPartyFirstOrCompanyName") }
                                   label="First or Company Name"
                                   value={ this.state.incidentReport.thirdPartyFirstOrCompanyName.value }
                                   errorMessage={ this.state.incidentReport.thirdPartyFirstOrCompanyName.errorMessage } />
                      </div>

                      <div className="col-xs-6">
                        <TextField type="text"
                                   name="thirdPartyLastName"
                                   onChange={ this.handleChangeFor("thirdPartyLastName") }
                                   label="Last Name"
                                   value={ this.state.incidentReport.thirdPartyLastName.value }
                                   errorMessage={ this.state.incidentReport.thirdPartyLastName.errorMessage } />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-12">
                        <AddressField fields={{
                                        streetAddress: {
                                          name: "thirdPartyStreetAddress",
                                          value: this.state.incidentReport.thirdPartyStreetAddress.value,
                                          errorMessage: this.state.incidentReport.thirdPartyStreetAddress.errorMessage,
                                          onChange: this.handleChangeFor("thirdPartyStreetAddress")
                                        },
                                        optionalLine: {
                                          name: "thirdPartyOptionalLine",
                                          value: this.state.incidentReport.thirdPartyOptionalLine.value,
                                          errorMessage: this.state.incidentReport.thirdPartyOptionalLine.errorMessage,
                                          onChange: this.handleChangeFor("thirdPartyOptionalLine")
                                        },
                                        city: {
                                          name: "thirdPartyCity",
                                          value: this.state.incidentReport.thirdPartyCity.value,
                                          errorMessage: this.state.incidentReport.thirdPartyCity.errorMessage,
                                          onChange: this.handleChangeFor("thirdPartyCity")
                                        },
                                        state: {
                                          name: "thirdPartyState",
                                          value: this.state.incidentReport.thirdPartyState.value,
                                          errorMessage: this.state.incidentReport.thirdPartyState.errorMessage,
                                          onChange: this.handleChangeFor("thirdPartyState")
                                        },
                                        zip: {
                                          name: "thirdPartyZip",
                                          value: this.state.incidentReport.thirdPartyZip.value,
                                          errorMessage: this.state.incidentReport.thirdPartyZip.errorMessage,
                                          onChange: this.handleChangeFor("thirdPartyZip")
                                        }
                                      }} />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-6">
                        <PhoneNumberField name="thirdPartyPhone"
                                          onChange={ this.handleChangeFor("thirdPartyPhone") }
                                          label="Phone"
                                          value={ this.state.incidentReport.thirdPartyPhone.value }
                                          errorMessage={ this.state.incidentReport.thirdPartyPhone.errorMessage } />
                      </div>

                      <div className="col-xs-6">
                        <TextField name="thirdPartyEmail"
                                   onChange={ this.handleChangeFor("thirdPartyEmail") }
                                   label="Email Address"
                                   value={ this.state.incidentReport.thirdPartyEmail.value }
                                   errorMessage={ this.state.incidentReport.thirdPartyEmail.errorMessage } />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-12">
                        <TextField type="textarea"
                                   name="thirdPartyAtFaultAdditionalComments"
                                   onChange={ this.handleChangeFor("thirdPartyAtFaultAdditionalComments") }
                                   label="Additional Comments"
                                   value={ this.state.incidentReport.thirdPartyAtFaultAdditionalComments.value }
                                   errorMessage={ this.state.incidentReport.thirdPartyAtFaultAdditionalComments.errorMessage } />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-12">
                        <p>Do you have the responsible party's insurance information?</p>

                        <ToggleSwitchField name="hasInsuranceInfoForThirdPartyAtFault"
                                           onChange={ this.handleChangeFor('hasInsuranceInfoForThirdPartyAtFault') }
                                           value={ this.state.incidentReport.hasInsuranceInfoForThirdPartyAtFault.value }
                                           errorMessage={ this.state.incidentReport.hasInsuranceInfoForThirdPartyAtFault.errorMessage } />
                      </div>
                    </div>

                    {
                      this.state.incidentReport.hasInsuranceInfoForThirdPartyAtFault.value &&
                        <div className="row">
                          <div className="col-xs-12">
                            <div className="row">
                              <div className="col-xs-6">
                                <TextField type="text"
                                           name="thirdPartyInsuranceProviderName"
                                           onChange={ this.handleChangeFor("thirdPartyInsuranceProviderName") }
                                           label="Insurance Provider Name"
                                           value={ this.state.incidentReport.thirdPartyInsuranceProviderName.value }
                                           errorMessage={ this.state.incidentReport.thirdPartyInsuranceProviderName.errorMessage } />
                              </div>

                              <div className="col-xs-6">
                                <TextField type="text"
                                           name="thirdPartyInsurancePolicyNumber"
                                           onChange={ this.handleChangeFor("thirdPartyInsurancePolicyNumber") }
                                           label="Policy Number"
                                           value={ this.state.incidentReport.thirdPartyInsurancePolicyNumber.value }
                                           errorMessage={ this.state.incidentReport.thirdPartyInsurancePolicyNumber.errorMessage } />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-xs-6">
                                <TextField type="text"
                                           name="thirdPartyInsuranceAdjuster"
                                           onChange={ this.handleChangeFor("thirdPartyInsuranceAdjuster") }
                                           label="Adjuster Name"
                                           value={ this.state.incidentReport.thirdPartyInsuranceAdjuster.value }
                                           errorMessage={ this.state.incidentReport.thirdPartyInsuranceAdjuster.errorMessage } />
                              </div>

                              <div className="col-xs-6">
                                <TextField type="text"
                                           name="thirdPartyInsurancePolicyClaimNumber"
                                           onChange={ this.handleChangeFor("thirdPartyInsurancePolicyClaimNumber") }
                                           label="Claim Number"
                                           value={ this.state.incidentReport.thirdPartyInsurancePolicyClaimNumber.value }
                                           errorMessage={ this.state.incidentReport.thirdPartyInsurancePolicyClaimNumber.errorMessage } />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-xs-12">
                                <AddressField fields={{
                                                streetAddress: {
                                                  name: "thirdPartyInsuranceStreetAddress",
                                                  value: this.state.incidentReport.thirdPartyInsuranceStreetAddress.value,
                                                  errorMessage: this.state.incidentReport.thirdPartyInsuranceStreetAddress.errorMessage,
                                                  onChange: this.handleChangeFor("thirdPartyInsuranceStreetAddress")
                                                },
                                                optionalLine: {
                                                  name: "thirdPartyInsuranceOptionalLine",
                                                  value: this.state.incidentReport.thirdPartyInsuranceOptionalLine.value,
                                                  errorMessage: this.state.incidentReport.thirdPartyInsuranceOptionalLine.errorMessage,
                                                  onChange: this.handleChangeFor("thirdPartyInsuranceOptionalLine")
                                                },
                                                city: {
                                                  name: "thirdPartyInsuranceCity",
                                                  value: this.state.incidentReport.thirdPartyInsuranceCity.value,
                                                  errorMessage: this.state.incidentReport.thirdPartyInsuranceCity.errorMessage,
                                                  onChange: this.handleChangeFor("thirdPartyInsuranceCity")
                                                },
                                                state: {
                                                  name: "thirdPartyInsuranceState",
                                                  value: this.state.incidentReport.thirdPartyInsuranceState.value,
                                                  errorMessage: this.state.incidentReport.thirdPartyInsuranceState.errorMessage,
                                                  onChange: this.handleChangeFor("thirdPartyInsuranceState")
                                                },
                                                zip: {
                                                  name: "thirdPartyInsuranceZip",
                                                  value: this.state.incidentReport.thirdPartyInsuranceZip.value,
                                                  errorMessage: this.state.incidentReport.thirdPartyInsuranceZip.errorMessage,
                                                  onChange: this.handleChangeFor("thirdPartyInsuranceZip")
                                                }
                                              }} />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-xs-6">
                                <PhoneNumberField name="thirdPartyInsurancePhone"
                                                  onChange={ this.handleChangeFor("thirdPartyInsurancePhone") }
                                                  label="Phone"
                                                  value={ this.state.incidentReport.thirdPartyInsurancePhone.value }
                                                  errorMessage={ this.state.incidentReport.thirdPartyInsurancePhone.errorMessage } />
                              </div>

                              <div className="col-xs-6">
                                <TextField name="thirdPartyInsuranceEmail"
                                           onChange={ this.handleChangeFor("thirdPartyInsuranceEmail") }
                                           label="Email Address"
                                           value={ this.state.incidentReport.thirdPartyInsuranceEmail.value }
                                           errorMessage={ this.state.incidentReport.thirdPartyInsuranceEmail.errorMessage } />
                              </div>
                            </div>
                          </div>
                        </div>
                    }
                  </div>
              }
            </div>
          </PanelBody>

          <PanelControls>
            <Prompt when={ this.anyChangesMade("incidentReport", this.props.incidentReport) }
                    message="Are you sure you want to move away? Any unsaved changes
                             made to this section will be lost." />

            <Link to="/contact_details" onClick={ this.handleSave }>
              <button type="button"
                      className={ `btn btn-success ${this.props.updating ? "disabled" : ""}` }>

                Save & Continue
              </button>
            </Link>
          </PanelControls>
        </PanelRightColumn>
      </Panel>
    )
  }
}

OverviewForm = connect(
  (state, props) => ({
    incidentReport:         { ...state.incidentReport }, // TODO - that was lazy 😴
    errors:                 state.submissionErrors.incidentReport,
    updateSucceeded:        state.wizard.updateSucceeded,
    updating:               state.wizard.updating,
    generalDepartmentsPath: state.rails.asyncHelpers.generalDepartments,
    apiEndpoint:            apiEndpoint(state.rails, "incident_report")
  }),
  null,
  ({ apiEndpoint, ...stateProps }, { dispatch }, props) => ({
    ...stateProps,
    ...props,

    asyncUpdateIncidentReport: (data) => {
      return asyncUpdateIncidentReport(apiEndpoint.path, data)(dispatch)
    },

    resetWizardState: () => { dispatch(resetWizardState()) },
    resetSubmissionErrors: () => { dispatch(resetErrorsForIncidentReport()) }
  })
)(withSynchronousCheckForUpdateSucceeded(OverviewForm));

export default OverviewForm
