import React from "react"
import { connect } from 'react-redux'

import AffixWhenScrolledPast from 'shared/components/affix_when_scrolled_past.jsx'

import SurveyHeader from './survey_header.jsx'
import SurveyTabList from './survey_tab_list.jsx'
import SurveyTab from './survey_tab.jsx'
import RmgHeader from './rmg_header.jsx'
import NavAndScore from './nav_and_score.jsx'

let Survey = () => (
  <div>
    <AffixWhenScrolledPast id='rmg-header' inheritWidth={ true } hideUntilFixed={ true }>
      <NavAndScore />
    </AffixWhenScrolledPast>

    <div>
      <RmgHeader />
    </div>

    <div id='rmg-panel' className="panel panel-default panel-incident-form">
      <div className="panel-heading">
        <SurveyHeader />
      </div>

      <div className="panel-body">
        <div className="panel-columns">

          <SurveyTabList />
          <SurveyTab />

        </div>
      </div>
    </div>

  </div>
)


export default Survey
