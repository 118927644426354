const LOAD_SUBMISSION_ERRORS  = "incident_report_form/submissionErrors/LOAD_SUBMISSION_ERRORS"
const RESET_SUBMISSION_ERRORS = "incident_report_form/submissionErrors/RESET_SUBMISSION_ERRORS"
const RESET_ERRORS_FOR_PERSON = "incident_report_form/submissionErrors/RESET_ERRORS_FOR_PERSON"
const RESET_ERRORS_FOR_INCIDENT_REPORT = "incident_report_form/submissionErrors/RESET_ERRORS_FOR_INCIDENT_REPORT"

// example state:
// {
//   incidentReport: {
//     [field]: { path: "", message: "" }
//   },
//   persons: {
//     [id]: {
//       path: ""
//       displayName: 'Gray Kemmey'
//       [field]: "social_security_number can't be blank"
//     }
//   }
// }
//
const initialState = {
  incidentReport: {},
  persons: {},
  // TODO - add other claimant error object when / if needed
}

export default function submissionErrors(state = initialState, action) {
  switch (action.type) {
    case LOAD_SUBMISSION_ERRORS: {
      return { ...initialState, ...action.errors }
    }

    case RESET_SUBMISSION_ERRORS: {
      return { ...initialState }
    }

    case RESET_ERRORS_FOR_INCIDENT_REPORT: {
      return {
        ...state,
        incidentReport: {}
      }
    }

    case RESET_ERRORS_FOR_PERSON: {
      // oh javascript 😒 destructuring numerical ids doesn't actually work like you'd expect
      // check it: https://stackoverflow.com/a/42418793/1947079
      //
      let { [action.id.toString()]: person_to_remove, ...others } = state.persons

      return {
        ...state,
        persons: {
          ...others
        }
      }
    }

    default: {
      return state
    }
  }
}

export const loadSubmissionErrors = (errors) => {
  return { type: LOAD_SUBMISSION_ERRORS, errors: errors }
}

export const resetSubmissionErrors = () => {
  return { type: RESET_SUBMISSION_ERRORS }
}

export const resetErrorsForIncidentReport = () => {
  return { type: RESET_ERRORS_FOR_INCIDENT_REPORT }
}

export const resetErrorsForPerson = (id) => {
  return { type: RESET_ERRORS_FOR_PERSON, id: id }
}
