import React from 'react'

import { filterDestroyedRecords } from 'shared/utils/rails_params.js'
import { formatDollarValue } from 'shared/utils/dollar_values.js'

import Section from 'shared/components/summary/section.jsx'
import Row from 'shared/components/summary/row.jsx'
import BasicColumn from 'shared/components/summary/basic_column.jsx'
import MultiSelect from 'shared/components/summary/multi_select.jsx'
import Phone from 'shared/components/summary/phone.jsx'
import DateAndTime from 'shared/components/summary/date_and_time.jsx'
import Address from 'shared/components/summary/address.jsx'

import AttachmentCard from './attachment_card.jsx'

const App = ({ grantRequest, meta, coverageOptions }) => (
  <div>
    <Section title="Member Info">
      <Row columnSize="xs-6">
        <BasicColumn label="Member Name" value={ meta.organizationName } />
        <BasicColumn label="Member Number" value={ `#${meta.threeCharacterMemberNumber}` } />
      </Row>
    </Section>

  <Section title='Applicant Info'>
      <Row>
        <BasicColumn label='Department requesting funds' value={ grantRequest.department } />
      </Row>
      <Row columnSize="xs-6">
        <BasicColumn label='Applicant name' value={ grantRequest.applicantName } />
        <BasicColumn label='Applicant title' value={ grantRequest.applicantTitle } />
        <BasicColumn label='Applicant email' value={ grantRequest.applicantEmail } />
        <Phone label='Applicant phone' value={ grantRequest.applicantPhone } />
        <Address
          label='Mailing address'
          streetAddress={ grantRequest.mailingStreetAddress }
          optionalLine={ grantRequest.mailingOptionalLine }
          city={ grantRequest.mailingCity }
          state={ grantRequest.mailingState }
          zip={ grantRequest.mailingZip }
        />
      </Row>
    </Section>

    <Section title="Request Details">
      <Row columnSize="xs-12">
        <BasicColumn label='Provide a brief description of this grant request.' value={ grantRequest.description } />
        <BasicColumn label='How will this grant be used to help reduce the probability of claims and enhance your risk management effort?' value={ grantRequest.howWillThisBeUsed } />
        <MultiSelect label='Coverages' options={ coverageOptions } selectedValues={ grantRequest.coverageIds } />

        <table className='table table-striped no-bottom-border'>
          <thead>
            <tr>
              <th>Description</th>
              <th>Estimated cost</th>
            </tr>
          </thead>
          <tbody>
            {
              Object
                .values(grantRequest.lineItemsAttributes)
                .map(item =>
                  <tr key={ item.id }>
                    <td>{ item.description }</td>
                    <td>{ formatDollarValue(item.estimatedCost) }</td>
                  </tr>
              )
            }
          </tbody>
          <tfoot className='highlighted'>
            <tr>
              <td></td>
              <td>
                TOTAL COST: { formatDollarValue(
                                Object
                                  .values(grantRequest.lineItemsAttributes)
                                  .reduce((t, l) => {
                                    return t + (Number(l.estimatedCost) || 0)
                                  }, 0)
                              )
                            }
              </td>
            </tr>
          </tfoot>
        </table>
      </Row>
    </Section>

    <Section title="Supporting Documentation" divider={ false }>
      <Row>
        {
          Object.entries(filterDestroyedRecords(grantRequest.attachmentsAttributes)).length === 0 ?
            <p>No entries added.</p>
          :
            Object.entries(filterDestroyedRecords(grantRequest.attachmentsAttributes)).
              map(([id, attachment]) => (
                <AttachmentCard
                  key={ id }
                  attachment={ attachment }
                />
              ))
        }
      </Row>
    </Section>

    <Section title='Finalize' divider={ false }>
      <Row columnSize="xs-6">
        <BasicColumn label="CAO Name" value={ grantRequest.caoName } />
        <BasicColumn label="CAO Email" value={ grantRequest.caoEmail } />
        <Phone label="CAO Phone" value={ grantRequest.caoPhone } />
        <BasicColumn label="CAO Title" value={ grantRequest.caoTitle } />
      </Row>
      <Row columnSize="xs-6">
        <BasicColumn label="Signature" value={ grantRequest.signature } />
        <BasicColumn
          label="Additional Emails to Notify"
          missingText="None"
          value={ Object
                    .values(grantRequest.emailsToNotifyAttributes)
                    .map(item => item.email).join(', ')
                } />
      </Row>
    </Section>
  </div>
)

export default App
