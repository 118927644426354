// when nesting a combination of DragSources / DropTargets / DragPreviews things can get weird
// and you may start to see a warning that looks like:
//
//     "Warning: setState(...): Cannot update during an existing state transition..."
//
// in that case it's probably due to re-rendering nested Drag/Drop objects. it's react-dnd calling
// setState in this case. here's the rules of thumb that seemed to work:
//
//   1. the DragPreview should never render a DragSource or DropTarget. this can happen when you're
//      reusing components for both. below, the `reorderablePreview` hoc injects a
//      beingUsedAsADragPreview prop for this purpose
//
//   2. the actual DragSource / DropTargets should not conditionally render nested Dragbables.
//      instead, favor always rendering them and hiding by wrapping in <div style="display: none;">
//
// for more info, peoples problems here proved immensely useful: https://www.bountysource.com/issues/32677234-warning-with-nested-dragsource

import React from 'react'
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget, DragLayer } from 'react-dnd';

const reorderable = (Component, type, options = {}) => {
    const { moveHandlerName, endDragHandlerName, validDropTarget } = options

    const dragSourceSpec = {
        beginDrag: (props) => {
            return { ...props, startingOrder: props.order, itemType: type }
        },
        isDragging: (props, monitor) => {
            return props.id === monitor.getItem().id
        },
        endDrag: (props, monitor, component) => {
            const item = monitor.getItem();

            if (type === item.itemType && item.startingOrder !== item.order) {
                props[endDragHandlerName]()
            }
        }
    }

    const dragSourceConnect = (connect, monitor) => ({
        connectDragSource:  connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging:         monitor.isDragging(),

        somethingIsDragging: !!monitor.getItem(), // this'll return null if nothing is dragging
        typeOfThingDragging: monitor.getItem() && monitor.getItem().itemType
    })

    const dropTargetSpec = {
        canDrop: (props, monitor) => {
            let base = true

            if (validDropTarget) {
                base = base && validDropTarget(props, monitor.getItem())
            }

            return base && type === monitor.getItem().itemType
        },
        hover: (props, monitor, component) => {
            if (props.id === monitor.getItem().id) { return } // can't switch with yourself
            if (!monitor.canDrop()) { return } // if you can't drop here, you can get switched on hover either

            // stolen codes: https://github.com/react-dnd/react-dnd/blob/master/examples/04%20Sortable/Simple/Card.js#L34-L58
            //
            // determine rectangle on screen
            const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

            // get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // determine mouse position
            const clientOffset = monitor.getClientOffset();

            // get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            // only perform the move when the mouse has crossed half of the items height:
            // when dragging downwards, only move when the cursor is below 50%
            // when dragging upwards, only move when the cursor is above 50%

            // dragging downwards
            if (monitor.getItem().order < props.order && hoverClientY < hoverMiddleY) {
                return;
            }

            // dragging upwards
            if (monitor.getItem().order > props.order && hoverClientY > hoverMiddleY) {
                return;
            }

            monitor.getItem()[moveHandlerName](monitor.getItem(), props)

            // once we update the store, we need to update our monitor as well or we'll get odd results
            // if they say move an item down one, up one, then down one without dropping
            monitor.getItem().order = props.order
        }
    }

    const dropTargetConnect = (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        canDrop:           monitor.canDrop()
    })

    const Reorderable = class extends React.Component {
        render() {
            const { forwardedRef, ...props } = this.props

            return this.props.connectDropTarget(
                <div ref={ forwardedRef }>
                    {/* we wrap this in a top div because react-flip-move is going to play with the opacity
              and we don't want it to see that we're actually hiding our component while it's dragging.
              making js libraries play nice, still a blast in 2017 😬 */}
                    <div style={ { opacity: this.props.isDragging ? 0 : 1 } }>
                        <Component { ...props } />
                    </div>
                </div>
            )
        }
    }

    return _.flow(
        DragSource(type, dragSourceSpec, dragSourceConnect),
        DropTarget(type, dropTargetSpec, dropTargetConnect)
    )(Reorderable)
}

const reorderablePreview = (Component, type) => {
    const ReorderablePreview = class extends React.Component {
        constructor(props) {
            super(props)

            this.previewStyles = this.previewStyles.bind(this)
        }

        // 👮 stolen codes: https://github.com/yahoo/react-dnd-touch-backend/blob/master/examples/js/ItemPreview.jsx
        //                  https://github.com/react-dnd/react-dnd/blob/master/examples/02%20Drag%20Around/Custom%20Drag%20Layer/CustomDragLayer.js
        //
        previewStyles(initialSourceOffset, currentCursorOffset, cursorOffsetDifference) {
            if (!initialSourceOffset || !currentCursorOffset || !cursorOffsetDifference) {
                return {
                    display: 'none'
                };
            }

            // pretty kludgy, just guess and checking that subtracting 40 makes things line up better.
            // hardly thoroughly tested...
            let previewWidth = this.preview ? this.preview.clientWidth - 40 : 0;

            // http://www.paulirish.com/2012/why-moving-elements-with-translate-is-better-than-posabs-topleft/
            //
            let x = initialSourceOffset.x + cursorOffsetDifference.x - previewWidth;
            let y = currentCursorOffset.y - 20; // ...ditto for subtracting 20 here

            let transform = `translate(${x}px, ${y}px)`;

            return {
                position: 'absolute',
                opacity: 0.5,
                pointerEvents: 'none',
                transform: transform,
                WebkitTransform: transform,

                // TODO - these styles are a hack, how can we do this better??
                width: '60%',
                maxWidth: '600px',

                // uncomment these to debug:
                // borderStyle: 'solid',
                // boderWidth: '15px',
                // borderColor: 'green'
            };
        }

        render() {
            if (!this.props.isDragging) { return null }

            // if we're not dragging something this component should be responsible for previewing,
            // then don't render anything
            if (this.props.itemType !== type) { return null }

            const layerStyles = {
                position:      'fixed',
                pointerEvents: 'none',
                zIndex:        100,
                left:          0,
                top:           0,
                width:         '100%',
                height:        '100%',

                // uncomment these to debug:
                // borderStyle: 'solid',
                // boderWidth: '15px',
                // borderColor: 'red'
            }

            const { initialSourceOffset, currentCursorOffset, cursorOffsetDifference, ...props } = this.props

            return (
                <div style={ layerStyles }>
                    <div style={ this.previewStyles(initialSourceOffset, currentCursorOffset, cursorOffsetDifference) }
                         ref={ (preview) => { this.preview = preview } }>
                        <Component { ...props } />
                    </div>
                </div>
            )
        }
    }

    return DragLayer(
        (monitor) => ({
            ...monitor.getItem(),
            initialSourceOffset:    monitor.getInitialSourceClientOffset(),
            currentCursorOffset:    monitor.getSourceClientOffset(),
            cursorOffsetDifference: monitor.getDifferenceFromInitialOffset(),
            isDragging:             monitor.isDragging(),

            beingUsedAsADragPreview: true
        })
    )(ReorderablePreview)
}

export { reorderable, reorderablePreview}
