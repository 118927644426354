const LOAD_EMAILS_TO_NOTIFY   = "grant_request_form/emails_to_notify/LOAD_EMAILS_TO_NOTIFY"
const UPDATE_EMAILS_TO_NOTIFY = "grant_request_form/emails_to_notify/UPDATE_EMAILS_TO_NOTIFY"

export default function emailsToNotify(state = {}, action) {
  switch (action.type) {
    case LOAD_EMAILS_TO_NOTIFY: {
      return { ...action.emailsToNotify }
    }

    case UPDATE_EMAILS_TO_NOTIFY: {
      return { ...action.emailsToNotify }
    }

    default: {
      return state
    }
  }
}
//action creator, returns action
export const loadEmailsToNotify = (emailsToNotify) => {
  return { type: LOAD_EMAILS_TO_NOTIFY, emailsToNotify: emailsToNotify }
}

export const updateEmailsToNotify = (emailsToNotify) => {
  return { type: UPDATE_EMAILS_TO_NOTIFY, emailsToNotify: emailsToNotify }
}

export const getEmailsToNotifyCount = (state) => ( Object.keys(state).length )

export const getEmailToNotify = (state, id) => {
  return Object.values(state).find((emailToNotify) => (
      emailToNotify.id && emailToNotify.id.toString() === id.toString()
  ))
}

// ☝️ for now emailsToNotify are saved as a part of saving the grantRequest, so we'll dispatch an

// update from grant_request.js's `asyncUpdateGrantRequest`

