import { createStore, combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

const RESET_STORE = 'file_share/document_upload/RESET_STORE';
const CHANGE_SUBMISSION_STATUS = 'file_share/document_upload/CHANGE_SUBMISSION_STATUS';

const submissionStatus = (state = "unattempted", action) => {
  switch (action.type) {
    case CHANGE_SUBMISSION_STATUS: {
      return action.status
    }

    case RESET_STORE: {
      return "unattempted"
    }

    default: {
      return state;
    }
  }
}

export const changeToSuccessStatus = () => {
  return { type: CHANGE_SUBMISSION_STATUS, status: "success" }
}

export const changeToFailStatus = () => {
  return { type: CHANGE_SUBMISSION_STATUS, status: "failed" }
}

export const resetStore = () => {
  return { type: RESET_STORE }
}

export const QueryStore = {
  currentlyInSuccessStatus: (state) => {
    return state.submissionStatus == "success"
  },

  currentlyInFailStatus: (state) => {
    return state.submissionStatus == "failed"
  }
}

const store = createStore(
  combineReducers({
    submissionStatus: submissionStatus,
    form:             reduxFormReducer
  }),

  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
