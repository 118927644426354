import React from 'react'

import Section from 'shared/components/summary/section.jsx'
import Row from 'shared/components/summary/row.jsx'
import BasicColumn from 'shared/components/summary/basic_column.jsx'
import Phone from 'shared/components/summary/phone.jsx'
import DateAndTime from 'shared/components/summary/date_and_time.jsx'
import Address from 'shared/components/summary/address.jsx'
import BooleanColumn from 'shared/components/summary/boolean_column.jsx'
import RailsEnum from 'shared/components/summary/rails_enum.jsx'

let PropertyCard = ({ property }) => {
  return (
    <div className="card">
      <header className="card-header">
        <h5 className="card-title">{ property.propertyDescription }</h5>
      </header>

      <div className="card-body">
        <Row columnSize="xs-6" >
          <BooleanColumn label="Member-owned?"
                         value={ property.memberOwned } />

          {
            property.memberOwned &&
              <RailsEnum label="Property Type" value={ property.propertyType } />
          }
        </Row>

        <Row columnSize="xs-12">
          <Address label="Address"
                   streetAddress={ property.streetAddress }
                   optionalLine={ property.optionalLine }
                   city={ property.city }
                   state={ property.state }
                   zip={ property.zip } />
        </Row>

        {
          !property.memberOwned &&
            <div>
              <Row columnSize="xs-6" >
                <BasicColumn label="Owner First Name" value={ property.ownerFirstName } />
                <BasicColumn label="Owner Last Name" value={ property.ownerLastName } />
              </Row>
              <Row columnSize="xs-6" >
                <Phone label="Owner Primary Phone" value={ property.ownerPrimaryPhone } />
                <Phone label="Owner Alternate Phone" value={ property.ownerAlternatePhone } />
                <BasicColumn label="Owner Email Address" value={ property.ownerEmail } />
              </Row>

              <Row columnSize="xs-12">
                <Address label="Address"
                         streetAddress={ property.ownerStreetAddress }
                         optionalLine={ property.ownerOptionalLine }
                         city={ property.ownerCity }
                         state={ property.ownerState }
                         zip={ property.ownerZip } />
              </Row>

              <Row columnSize="xs-12">
                <BasicColumn label="Third-party insurance provider name"
                             value={ property.insuranceProviderName } />
              </Row>

              <Row columnSize="xs-6">
                <BasicColumn label="Third-party insurance policy number"
                             value={ property.policyNumber } />

                <Phone label="Third-party insurance phone number"
                       value={ property.insuranceProviderPhone } />
              </Row>

              <Row columnSize="xs-12">
                <Address label="Third-party insurance provider address"
                         streetAddress={ property.insuranceProviderStreetAddress }
                         optionalLine={ property.insuranceProviderOptionalLine }
                         city={ property.insuranceProviderCity }
                         state={ property.insuranceProviderState }
                         zip={ property.insuranceProviderZip } />
              </Row>
            </div>
        }

        <Row columnSize="xs-12">
          <BooleanColumn label="Is a claim being made for damage to this property" value={ property.claimBeingMade } />
          <BasicColumn label="Description of damage" value={ property.descriptionOfDamage } />

          <BasicColumn label="What is the estimated loss value for this property"
                       value={ property.estimatedTotalDamage } />
        </Row>

        <Row columnSize="xs-12">
          <BasicColumn label="Additional Comments" value={ property.additionalComments } />
        </Row>
      </div>

      <footer className="card-footer"></footer>
    </div>
  )
}

export default PropertyCard;
