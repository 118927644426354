import RailsApi from 'shared/utils/rails_api.js'

import { refreshMetaData } from './rails.js'
import { setErrorFlash, setSuccessFlash, updateSucceeded, updateFailed, updateStarted } from './wizard.js'
import { updateGrantRequest } from './grant_request.js'

const LOAD_ATTACHMENTS   = "grant_request_form/attachments/LOAD_ATTACHMENTS"
const UPDATE_ATTACHMENTS = "grant_request_form/attachments/UPDATE_ATTACHMENTS"

export default function attachments(state = {}, action) {
  switch (action.type) {
    case LOAD_ATTACHMENTS: {
      return { ...action.attachments }
    }

    case UPDATE_ATTACHMENTS: {
      return { ...action.attachments }
    }

    default: {
      return state
    }
  }
}

export const loadAttachments = (attachments) => {
  return { type: LOAD_ATTACHMENTS, attachments: attachments }
}

export const updateAttachments = (attachments) => {
  return { type: UPDATE_ATTACHMENTS, attachments: attachments }
}

export const getAttachmentsCount = (state) => ( Object.keys(state).length )

export const getAttachment = (state, id) => {
  return Object.values(state).find((attachment) => (
    attachment.id && attachment.id.toString() === id.toString()
  ))
}

export const asyncUpdateAttachments = (url, data, options = {}) => (dispatch) => {
  dispatch(updateStarted())

  // default to noop functions if not given
  const {
    onSuccess  = () => {},
    onProgress = () => {},
    onError    = () => {}
  } = options

  return RailsApi.uploadWithXhr(url, 'PATCH', {
    body: data,

    onSuccess: (json) => {
      dispatch({
        type: 'RAILS_UPDATE_SUCCEEDED',
        actions: [
          refreshMetaData({ ...json.meta }),
          updateGrantRequest(json.grantRequest),
          updateAttachments(json.grantRequest.attachmentsAttributes)
        ]
      })

      onSuccess(json)
    },

    onError: (error) => {
      onError(error)
    },

    onProgress: (progress) => {
      onProgress(progress)
    }
  })
}

export const asyncDeleteAttachment = (url, id, attachmentsCount) => (dispatch) => {
  let data = {
    grantRequest: {
      attachmentsAttributes: {
        [id]: { id: id, "_destroy": true }
      }
    }
  }

  dispatch(updateStarted())

  return RailsApi.patch(url, { body: data }).
    then((json) => {
      dispatch({
        type: 'RAILS_UPDATE_SUCCEEDED',
        actions: [
          refreshMetaData({ ...json.meta }),
          updateGrantRequest(json.grantRequest),
          updateAttachments(json.grantRequest.attachmentsAttributes),
          updateSucceeded(),
          setSuccessFlash("Attachment deleted", { keep: attachmentsCount === 1 })
        ]
      })
    }).
    catch((json) => {
      dispatch({
        type: 'RAILS_UPDATE_FAILED',
        actions: [
          updateFailed(),
          setErrorFlash(json.error.message, json.error.details)
        ]
      })
    })
}
