import React from 'react'
import PropTypes from 'prop-types';

let Footer = ({ submitText, submitToRails, errorMessageFromSaveAttempt, policyYear, policyYearError }) => (
    <div>
        { (errorMessageFromSaveAttempt || policyYearError) &&
            <div className="pull-left">
                <em>
                    <i className="fa fa-times-circle text-danger" aria-hidden="true">
                    </i> { errorMessageFromSaveAttempt || policyYearError }
                </em>
            </div>
        }

        <div className="pull-right">
            <button
                type="button"
                className="btn btn-link"
                data-dismiss="modal">

                Cancel
            </button>

            <button
                type="button"
                className="btn btn-default btn-success"
                disabled={ !!policyYearError || !policyYear }
                onClick={ submitToRails }>

                { submitText }
            </button>
        </div>
    </div>
)

Footer.propTypes = {
    submitText: PropTypes.string.isRequired,
    submitToRails: PropTypes.func.isRequired,

    errorMessageFromSaveAttempt: PropTypes.string
}

export default Footer
