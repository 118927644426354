// this should allow each step in the form to really control the functionality of the panel,
// while abstracting away the common markup. usage example:
//
//   <Panel>
//     <PanelSidebar />
//     <PanelRightColumn>
//       <PanelBody>
//         { the_form_step_specifies_the_body /* usually form inputs */ }
//       </PanelBody>
//
//       <PanelControls>
//         { the_form_step_specifies_the_controls }
//       </PanelControls>
//     </PanelRightColumn>
//   </Panel>
//
// we'll repeat the use of those components a lot (hence the index.js listing), but _should_ be
// clean usage 🤷‍

// should you wanna copy the import everything statement:
// import { Panel, PanelSidebar, PanelRightColumn, PanelBody, PanelControls } from '../panel'

export { default as Panel } from './panel.jsx'
export { default as PanelSidebar } from './panel_sidebar.jsx'
export { default as PanelRightColumn } from './panel_right_column.jsx'
export { default as PanelBody } from './panel_body.jsx'
export { default as PanelControls } from './panel_controls.jsx'
