import React from "react"

const ProgressBar = ({ percentage, hideBeforeStart = false, hideWhenComplete = false }) => {
  return ((percentage == 0 || percentage == null) && hideBeforeStart) ||
          percentage == 100 && hideWhenComplete ? null : (
    <div className="progress">
      <div
        className='progress-bar progress-bar-striped active'
        role="progressbar"
        aria-valuenow={ percentage }
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ width: percentage + '%' }}
      >
        { percentage ? percentage + '%' : '' }
      </div>
    </div>
  )
}

export default ProgressBar
