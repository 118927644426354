import React from 'react'
import { connect } from 'react-redux'

import { SelectField } from 'shared/components/fields'

import { persistedClaimants } from '../store.js'

class RelatedToSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = { value: props.selected }

    this.textForPerson   = this.textForPerson.bind(this);
    this.textForProperty = this.textForProperty.bind(this);
    this.textForVehicle  = this.textForVehicle.bind(this);
    this.onChange        = this.onChange.bind(this);
  }

  textForPerson(person) {
    return `${person.firstName} ${person.lastName}`
  }

  textForProperty(property) {
    return `Damaged Property: ${property.id}` // TODO
  }

  textForVehicle(vehicle) {
    return `${vehicle.year} ${vehicle.makeAndModel} (${vehicle.vehicleIdentificationNumber})`
  }

  onChange(value) {
    let [attachableType, attachableId] = value.split("|");

    this.setState({ value: value });

    // if event.target.value === "|", we want to clear the association
    if (!attachableType || !attachableId) {
      this.props.onChange(null, null)
    }
    else {
      this.props.onChange(attachableType, parseInt(attachableId, 10))
    }
  }

  render() {
    const { claimants: { injuredPersons, damagedProperties, involvedVehicles } } = this.props;

    const people = Object.entries(injuredPersons).map(([key, person]) => {
              // important to know here that rails polymorphic associations on STI models use the
              // sti_base_class. that seems super non-intuitive, but whatevas...
              const value = `InjuredPerson::Base|${person.id}`;
              return { value: value, displayText: this.textForPerson(person) }
            })

    const properties = Object.entries(damagedProperties).map(([key, property]) => {
              const value = `DamagedProperty|${property.id}`;
              return { value: value, displayText: this.textForProperty(property) }
            })

    const vehicles = Object.entries(involvedVehicles).map(([key, vehicle]) => {
                const value = `InvolvedVehicle|${vehicle.id}`;
                return { value: value, displayText: this.textForVehicle(vehicle) }
              })

    return (
      <SelectField name="relatedTo"
                   label="Related To"
                   value={ this.props.selected }
                   options={[ { value: '|', displayText: 'The Report' },
                              ...people,
                              ...properties,
                              ...vehicles
                            ]}
                   onChange={ this.onChange }
                   className="form-control"
                   bootstrapWrapperClassName={ this.props.bootstrapWrapperClassName }
                   />
    )
  }
}

RelatedToSelect = connect(
  (state, props) => {
    const attachableType = props.attachment.attachableType
    const attachableId = props.attachment.attachableId

    return {
      claimants: {
        injuredPersons:    state.persons,
        damagedProperties: state.properties,
        involvedVehicles:  state.vehicles
      },

      // just to note, the format of this value--'type|id'--is important throughout the component
      selected: `${attachableType || ""}|${attachableId || ""}`
    }
  }
)(RelatedToSelect)

export default RelatedToSelect;
