const RailsApi = {
  get: function(url, options = {}) {
    return this.submit(url, "GET", options)
  },

  post: function(url, options = {}) {
    return this.submit(url, "POST", options)
  },

  patch: function(url, options = {}) {
    return this.submit(url, "PATCH", options)
  },

  delete: function(url, options = {}) {
    return this.submit(url, "DELETE", options)
  },

  talk: function(url, method, options = {}) {
    return fetch(url, {
      method: method,
      body: this.formatBodyObject(options.body),
      headers: {
        ...this.defaultHeaders(method, options.body),
        ...options.headers
      },
      credentials: 'same-origin'
    });
  },

  // legacy implementation: assumes you're getting json back and handles the call to `response.json()`
  submit: function(url, method, options = {}) {
    return this.talk(url, method, options).
             then((response) => {
               return response.json().then((json) => {
                 return response.ok ? json : Promise.reject(json)
               })
             })
  },

  // XHR upload function
  // - Support file uploads with progress events
  uploadWithXhr: function(url, method = 'PATCH', options = {}) {
    let formData = this.formatBodyObject(options.body)
    let xhr = new XMLHttpRequest()

    // default to noop functions if not given
    const {
      onSuccess  = () => {},
      onProgress = () => {},
      onError    = () => {}
    } = options

    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        let percentComplete = ((e.loaded / e.total) * 100).toFixed(0)
        onProgress(percentComplete)
      }
    }

    xhr.open(method, url, true)

    Object.entries(this.defaultHeaders(method, options.body)).forEach(([key, value]) => {
      xhr.setRequestHeader(key, value)
    })

    xhr.onerror = (e) => {
      onError({ error: { message: 'Error uploading file' } })
    }

    xhr.onload = (e) => {
      if (e.target.status == 200 || e.target.status == 201) {
        let resp = JSON.parse(e.target.response)
        onSuccess(resp)
      }
      else {
        let resp = {}

        try {
          resp = JSON.parse(e.target.response)
        }
        catch (jsonParseError) {
          // the error key here looks repititve, but matches our server which sends something like:
          //
          //   render status: 422, json: { error: errors_for_react(report) }
          //
          resp = { error: { message: 'Error uploading file (status ' + e.target.status + ')' } }
        }

        onError(resp)
      }
    }

    xhr.send(formData)
  },

  defaultHeaders: function(method, body) {
    let defaultHeaders = {
      "X-Requested-With": "XMLHttpRequest",
      "Accept":           "application/json; charset=UTF-8"
    }

    if (body) {
      try {
        JSON.parse(body)
        defaultHeaders["Content-Type"] = "application/json; charset=UTF-8"
      }
      catch (e) {} // ok, it wasn't json
    }

    if (method !== "GET" || method !== "get") {
      // this won't exist in test env so we check for it
      let meta_csrf = document.querySelector("meta[name=csrf-token]")
      if (meta_csrf) { defaultHeaders["X-CSRF-Token"] = meta_csrf.content }
    }

    return defaultHeaders
  },

  formatBodyObject: function(body) {
    if (body instanceof FormData)  { return body }
    if (!(body instanceof Object)) { return body }

    return this.turnSimpleObjectIntoFormDataObject(body)
  },

  turnSimpleObjectIntoFormDataObject: function(object, parent = "", data = new FormData()) {
    Object.entries(object).map(([key, value]) => {
      let appendKey = parent ? `${parent}[${key}]` : key

      if (value instanceof Array) {
        // Rails will parse key[]=1&key[]=2 into key: [1, 2]
        appendKey += '[]'

        value.map((v) => {
          if (v !== null && v !== undefined) { data.append(appendKey, v) }
        })
      }
      else if (value instanceof File) {
        data.append(appendKey, value)
      }
      else if (value instanceof Object) {
        this.turnSimpleObjectIntoFormDataObject(value, appendKey, data)
      }

      else {
        if (value !== null && value !== undefined) { data.append(appendKey, value) }
      }
    });

    return data
  }
}

export default RailsApi
