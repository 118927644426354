import React from 'react'
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom'

import BootstrapModal from 'shared/components/move_off_of_eventually_bootstrap_modal.jsx'

import Form from './components/form.jsx'
import Footer from './components/footer.jsx'

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = { policyYear: "", errorMessageFromSaveAttempt: "" }

    this.unmountNewSurveyComponent = this.unmountNewSurveyComponent.bind(this)
    this.handlePolicyYearChange    = this.handlePolicyYearChange.bind(this)
    this.submitToRails             = this.submitToRails.bind(this)
    this.policyYearError           = this.policyYearError.bind(this)
  }

  unmountNewSurveyComponent() {
    ReactDOM.unmountComponentAtNode(this.props.mount);
  }

  handlePolicyYearChange(event) {
    let value = (event.target.value || "").replace(/[\D]/g, '').slice(0, 4)

    this.setState({ policyYear: value })
  }

  policyYearError() {
    if (!this.state.policyYear) { return false }

    let _policyYear = Number(this.state.policyYear)
    if (_policyYear === _policyYear) {
      if (_policyYear < new Date().getFullYear()) {
        return "Policy Year can't be in the past"
      }

      if (this.props.yearsWeHaveASurveyFor.includes(_policyYear)) {
        return "Policy Year has already been taken"
      }
    }
    else {
      return "Policy Year isn't a valid year"
    }

    return false
  }

  submitToRails() {
    const { [this.props.mode]: { path, method } } = this.props.apiEndpoints

    let data = new FormData();
    if (this.state.policyYear)            { data.append("survey[policy_year]", this.state.policyYear) }
    if (this.props.idOfSurveyToDuplicate) { data.append("survey[duplicate_id]", this.props.idOfSurveyToDuplicate) }

    fetch(path, {
      headers:     {
        "Accept":       "application/json; charset=UTF-8",
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
      },
      credentials: 'same-origin',
      method:      method,
      body:        data
    }).
    then((response) => {
      if (response.ok) {
        return response.json().then((json) => { window.location = json.redirect_to_survey_edit_page })
      }
      else {
        response.json().then((json) => { this.setState({ errorMessageFromSaveAttempt: json.error }) })
      }
    })
  }

  render() {
    const title = this.props.mode === "create" ? "Create New RMG Form" :
                                                 `Duplicate RMG Form ${this.props.yearOfSurveyToDuplicate}`

    const helperText = this.props.mode === "create" ?
      "Specify the effective policy year to begin creating your new RMG Form."
    :
      "Specify the effective policy year for this RMG Form."

    const submitText = this.props.mode === "create" ? "Create" : "Duplicate"

    return (
      <BootstrapModal title={ title }
        body={
          <Form helperText={ helperText }
                handlePolicyYearChange={ this.handlePolicyYearChange }
                policyYear={ this.state.policyYear }
          />
        }
        footer={
          <Footer submitText={ submitText }
                  submitToRails={ this.submitToRails }
                  errorMessageFromSaveAttempt={ this.state.errorMessageFromSaveAttempt }
                  policyYear={ this.state.policyYear }
                  policyYearError={ this.policyYearError() }
          />
        }
        handleHide={ this.unmountNewSurveyComponent }
      />
    )
  }
}

App.propTypes = {
  mount:                   PropTypes.object.isRequired, // techincaly it's a DOM element
  mode:                    PropTypes.string.isRequired, // ["create", "duplicate"]
  apiEndpoints:            PropTypes.object.isRequired,

  idOfSurveyToDuplicate:   PropTypes.number,
  yearOfSurveyToDuplicate: PropTypes.number
}

export default App
