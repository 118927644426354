// having an issue where dates from rails actually go back a day in the datepicker. you can
// experience this magic 🎩 for yourself:
//
//   new Date("2017-03-24").toDateString()
//   "Thu Mar 23 2017"
//
// good job, javascript 👏 anyway, looks like Date() is assuming that's UTC time so the 24th at
// midnight in UTC, and converting that to the EST which is the 23rd at 8pm 😑 so, we'll check
// if we have a full ISO 8601 string or just a simple date string and handle the differences using
// this veritable genius's answer here: http://stackoverflow.com/a/11847980/1947079
// i have no idea why this is necessary / works 😖
//
const convertToDate = (iso) => {
  if (moment(iso, "YYYY-MM-DD", true).isValid()) { // we know it's in 2017-03-24 format not ISO 8601
    let date_javascript_screws_up = new Date(iso);
    let date_i_meant = new Date(
      date_javascript_screws_up.getUTCFullYear(),
      date_javascript_screws_up.getUTCMonth(),
      date_javascript_screws_up.getUTCDate()
    );

    return date_i_meant;
  }
  else {
    return new Date(iso);
  }
}

// we define some functions for separating our rails datetime columns into two inputs, and then
// back again. for now, moment is pulled in globally by sprockets, so we should be fine using it
// here, though it's a little gross 😷
//
const extractDateFromRailsDateTime = (iso) => {
  // it just so happens, with no involvement from our end, rails will send a DateTime object
  // as an ISO 8601 string to react
  if (iso === null || iso === undefined) {
    return "";
  }

  return moment(convertToDate(iso)).format("MM/DD/YYYY");
}

const extractTimeFromRailsDateTime = (iso) => {
  if(iso === null || iso === undefined) {
    return "";
  }

  return moment(convertToDate(iso)).format("hh:mm A");
}

const constructRailsDateTime = (date, time) => {
  // .format defaults to moment.defaultFormat which out-the-box is YYYY-MM-DDTHH:mm:ssZ or ISO 8601
  //
  // this has the hidden side-affect (i think it's an advantage) of auto-magically grabbing the local
  // time on the client side. could be weird if you're filing a report in a timezone not where
  // the incident happened. that seems unlikely so long as we're only dealing with virginia

  if((date === null || date === undefined || date === "")) {
    // without a date we can't do much be set it to right now. if we set it to "" we might
    // overwrite things as they're trying to make changes for the first time
    return moment().format();
  }

  return moment(`${date} ${time}`, "MM/DD/YYYY hh:mm: A").format();
}

export { extractDateFromRailsDateTime, extractTimeFromRailsDateTime, constructRailsDateTime, convertToDate };
