import React from 'react'

import { FileField } from 'shared/components/fields'

const UploadButton = ({ handleChooseFiles, className, label }) => (
  <label className={ className || 'btn btn-brand-yellow' }>
    <FileField
      name="documents"
      onChange={ handleChooseFiles }
      multiple={ true }
      withoutBootstrapMarkup={ true }
      className="form-control hidden" />
    { label || "Upload Documents" }
  </label>
)

export default UploadButton
