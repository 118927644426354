const LOAD_LINE_ITEMS   = "grant_request_form/line_itmes/LOAD_LINE_ITEMS"
const UPDATE_LINE_ITEMS = "grant_request_form/line_itmes/UPDATE_LINE_ITEMS"

export default function lineItems(state = {}, action) {
  switch (action.type) {
    case LOAD_LINE_ITEMS: {
      return { ...action.lineItems }
    }

    case UPDATE_LINE_ITEMS: {
      return { ...action.lineItems }
    }

    default: {
      return state
    }
  }
}

export const loadLineItems = (lineItems) => {
  return { type: LOAD_LINE_ITEMS, lineItems: lineItems }
}

export const updateLineItems = (lineItems) => {
  return { type: UPDATE_LINE_ITEMS, lineItems: lineItems }
}

export const getLineItemsCount = (state) => ( Object.keys(state).length )

export const getLineItem = (state, id) => {
  return Object.values(state).find((lineItem) => (
    lineItem.id && lineItem.id.toString() === id.toString()
  ))
}

// ☝️ for now lineItems are saved as a part of saving the grantRequest, so we'll dispatch an
// update from grant_request.js's `asyncUpdateGrantRequest`
