import React from "react";
import { connect } from "react-redux";
import RmgScoreWidget from "../helpers/rmg_score_widget.jsx";

import { policyYear } from "apps/rmg/shared/ducks/survey.js";
import { policyYearRange } from "shared/utils/policy_year.js";

let RmgHeader = ({ renderAsReadOnly, organization, policyYear }) => (
  <div>
    <p data-behavior="printed-at-timestamp" style={{ display: "none" }}></p>

    <div className="page-header">
      <h1>Risk Management Assessment</h1>
    </div>

    {renderAsReadOnly && organization && (
      <div>
        <hr />
        <div className="alert alert-info clearfix">
          <h3 className="pull-left no-margin-bottom no-margin-top">
            {organization.name}
          </h3>
          <span className="label label-sm label-right label-danger">
            Read-only
          </span>
          <strong className="pull-right">{policyYearRange(policyYear)}</strong>
        </div>
      </div>
    )}

    <div className="row">
      <div className="rmg-instructional-text col-sm-9">
        <p>
          Since losses are shared by all members, VRSA promotes and encourages
          any actions or inactions members can take to reduce the frequency
          and/or severity of losses. The Risk Management Assessment (RMA)
          focuses on specific areas of risk management best practices. Members
          who demonstrate 100% commitment to risk management may be eligible for
          a policy premium discount. It is important the top manager of your
          organization reviews the completed RMA to ensure awareness of risks
          and best practices at the highest level.
        </p>

        <p>
          <span className="small-text">
            Questions are based on the coverage(s) your organization has with
            VRSA. All training and policies recommended in this document are
            offered either through VRSA staff or the Online University.
          </span>
        </p>
      </div>

      <RmgScoreWidget />
    </div>
  </div>
);

export default connect((state, props) => ({
  policyYear: policyYear(state.survey),
  renderAsReadOnly: state.lookups.renderAsReadOnly,
  organization: state.lookups.organization,
}))(RmgHeader);
