import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { default as IncidentReportSummary } from 'apps/incident_report_summary/app.jsx'
import RailsApi from 'shared/utils/rails_api.js'

import { Panel, PanelSidebar, PanelRightColumn, PanelBody, PanelControls } from '../panel'
import SubmissionValidator from '../submission_validator.js'
import BootstrapModal from 'shared/components/bootstrap_modal.jsx'

import { loadSubmissionErrors } from '../ducks/submission_errors.js'
import { apiEndpoint } from '../ducks/rails.js'
import { updateStarted, updateSucceeded, updateFailed, setErrorFlash, resetWizardState } from '../ducks/wizard.js'


class ReviewRouter extends React.Component { // this doesn't have any nested routes to worry about
  constructor(props) {
    super(props)

    this.state = { confirmingSubmission: false }

    // figure out the errors, and save the to the store. this does kinda rely on knowing that we're
    // not gonna be re-rendering this component, we'd unmount it if / when we navigate away, but
    // that's it
    this.validator = new SubmissionValidator(props)
    this.validator.isValid() // call this to populate errors
    props.loadSubmissionErrors(this.validator.errors)
  }

  componentWillUnmount() {
    this.props.resetWizardState()
  }

  handleSubmitClicked = (event) => {
    this.setState({ confirmingSubmission: true })
  }

  handleModalClosed = () => {
    this.setState({ confirmingSubmission: false })
  }

  handleSubmit = () => {
    let {
      incidentReport,
      persons,
      vehicles,
      properties,
      witnesses,
      attachments,
      dispatch,
      apiEndpoint
    } = this.props

    let data = { incidentReport: {
      ...incidentReport,
      injuredPersonsAttributes:    persons,
      damagedPropertiesAttributes: properties,
      involvedVehiclesAttributes:  vehicles,
      witnessesAttributes:         witnesses,
      attachmentsAttributes:       attachments
    }}

    dispatch(updateStarted())

    return RailsApi.patch(`${apiEndpoint.path}/submit`, { body: data }).
      then((json) => {
        window.location = json.redirect
      }).
      catch((json) => {
        console.log(json)

        dispatch({
          type: 'RAILS_UPDATE_FAILED',
          actions: [
            updateFailed(),
            setErrorFlash(json.error.message, { details: json.error.details })
          ]
        })
      })
  }

  render() {
    return (
      <Panel>
        <PanelSidebar />

        <PanelRightColumn>
          <PanelBody>
            {
              !this.validator.isValid() &&
                <div className="row">
                  <div className="col-xs-12">
                    <div className="flash alert alert-danger">
                      <p>Please address the following before submitting this incident report:</p>
                      <br />

                      {
                        this.validator.incidentReportErrors().length !== 0 &&
                          <ul>
                            {
                              this.validator.incidentReportErrors().map(({ path, message }, index) => (
                                <li key={ index }>{ message } (Click <Link to={ path }>here</Link> to fix)</li>
                              ))
                            }
                          </ul>
                      }

                      {
                        this.validator.personsErrors().length !== 0 &&
                          this.validator.personsErrors().map(({ displayName, path, errors }, index) => (
                            <div key={ index }>
                              <br />
                              <h4>Person: { displayName } (<Link to={ path }>click here to fix</Link>)</h4>

                              <ul className="list-unstyled">
                                {
                                  errors.map((error, index) => (
                                    <li key={ index }>{ error }</li>
                                  ))
                                }
                              </ul>
                            </div>
                          ))
                      }
                    </div>
                  </div>
                </div>
            }

            <IncidentReportSummary incidentReport={{
                                     ...this.props.incidentReport,
                                     injuredPersonsAttributes:    this.props.persons,
                                     damagedPropertiesAttributes: this.props.properties,
                                     involvedVehiclesAttributes:  this.props.vehicles,
                                     witnessesAttributes:         this.props.witnesses,
                                     attachmentsAttributes:       this.props.attachments
                                   }}
                                   meta={ this.props.meta }
                                   stackCards={ true } />

            {
              this.state.confirmingSubmission &&
                <BootstrapModal title="Confirm"
                                handleHide={ this.handleModalClosed }
                                buttons={[
                                  { className: "btn btn-link", dismiss: true, name: "Cancel" },
                                  {
                                    className: "btn btn-default btn-success",
                                    onClick: this.handleSubmit,
                                    loading: this.props.submitting,
                                    name: "Submit"
                                  }
                                ]}>

                  <p>{ 'Are you sure you want to submit this incident report?' }</p>
                </BootstrapModal>
            }
          </PanelBody>

          <PanelControls>
            <Link to="/attachments">
              <button type="button"
                      className="btn btn-link">
                Back
              </button>
            </Link>


            {
              this.validator.isValid() ?
                <button type="button"
                        className="btn btn-success"
                        onClick={ this.handleSubmitClicked }>

                  Submit
                </button>
              :
                <button type="button"
                        className="disabled btn btn-success">

                  Submit
                </button>
            }
          </PanelControls>
        </PanelRightColumn>
      </Panel>
    )
  }
}

ReviewRouter = connect(
  (state, props) => ({
    incidentReport:      state.incidentReport,
    persons:             state.persons,
    vehicles:            state.vehicles,
    properties:          state.properties,
    witnesses:           state.witnesses,
    attachments:         state.attachments,
    submitting:          state.wizard.updating,
    submissionSucceeded: state.wizard.updateSucceeded,
    meta:                state.rails,
    apiEndpoint:         apiEndpoint(state.rails, "incident_report")
  }),
  (dispatch, props) => ({
    dispatch,
    resetWizardState: () => { dispatch(resetWizardState()) },
    loadSubmissionErrors: (errors) => { dispatch(loadSubmissionErrors(errors)) }
  })
)(ReviewRouter)

export default ReviewRouter
