import React from 'react'

import { filterDestroyedRecords, recordWithId } from 'shared/utils/rails_params.js'

import Section from 'shared/components/summary/section.jsx'
import Row from 'shared/components/summary/row.jsx'
import BasicColumn from 'shared/components/summary/basic_column.jsx'
import Phone from 'shared/components/summary/phone.jsx'
import DateAndTime from 'shared/components/summary/date_and_time.jsx'
import Address from 'shared/components/summary/address.jsx'
import BooleanColumn from 'shared/components/summary/boolean_column.jsx'

import PersonCard from './components/person_card.jsx'
import PropertyCard from './components/property_card.jsx'
import VehicleCard from './components/vehicle_card.jsx'
import WitnessCard from './components/witness_card.jsx'
import AttachmentCard from './components/attachment_card.jsx'

const App = ({ incidentReport, meta, stackCards }) => (
  <div>
    <Section title="Member Info">
      <Row columnSize="xs-6">
        <BasicColumn label="Member Name" value={ meta.organizationName } />
        <BasicColumn label="Member Number" value={ `#${meta.threeCharacterMemberNumber}` } />
      </Row>
    </Section>

    {
      incidentReport.type == "IncidentReport::Addendum" ?
        <Section title="Addendum">
          <Row columnSize="xs-12">
            <BasicColumn value={ `This report updates a previously submitted report ` +
                                 `with id ${incidentReport.primaryIncidentReportId}` } />
          </Row>
        </Section>
      :
        <div>
          <Section title="Occurrence Description">
            <Row columnSize="xs-12">
              <BasicColumn label="Briefly describe the incident occurrence being reported"
                           value={ incidentReport.descriptionOfHowIncidentOccurred } />
            </Row>

            <Row columnSize="xs-12">
              <DateAndTime label="On what date and at approximately what time did this incident,
                                  injury, or illness occur?"
                           value={ incidentReport.incidentHappenedAt }
                           type="BOTH" />
            </Row>

            <Row columnSize="xs-6" >
              <BasicColumn label="Member Location" value={ incidentReport.memberLocationDescription } />
              <BasicColumn label="Department Type" value={ incidentReport.department } />
            </Row>

            <Row columnSize="xs-12">
              <Address label="Where did this incident, injury, or illness occur?"
                       streetAddress={ incidentReport.incidentStreetAddress }
                       optionalLine={ incidentReport.incidentOptionalLine }
                       city={ incidentReport.incidentCity }
                       state={ incidentReport.incidentState }
                       zip={ incidentReport.incidentZip } />
            </Row>
          </Section>

          <Section title="First Reported To">
            <Row columnSize="xs-6">
              <BasicColumn label="Name" value={ incidentReport.firstReportedToName } />
              <BasicColumn label="Email" value={ incidentReport.firstReportedToEmail } />
            </Row>

            <Row columnSize="xs-6">
              <Phone label="Primary Phone" value={ incidentReport.firstReportedToPrimaryPhone } />
              <Phone label="Alternate Phone" value={ incidentReport.firstReportedToAlternatePhone } />
              <DateAndTime label="When was this incident first reported?"
                           value={ incidentReport.firstReportedOn } />
            </Row>
          </Section>

          <Section title="Third-Party at Fault">
            <Row columnSize="xs-12">
              <BooleanColumn label="Is a party outside of your organization at fault?"
                             value={ incidentReport.thirdPartyAtFault } />
            </Row>

            {
              incidentReport.thirdPartyAtFault &&
                <div>
                  <Row columnSize="xs-6">
                    <BasicColumn label="First or Company Name" value={ incidentReport.thirdPartyFirstOrCompanyName } />
                    <BasicColumn label="Last Name" value={ incidentReport.thirdPartyLastName } />
                  </Row>

                  <Row columnSize="xs-12">
                    <Address label="Third Party at Fault Address"
                             streetAddress={ incidentReport.thirdPartyStreetAddress }
                             optionalLine={ incidentReport.thirdPartyOptionalLine }
                             city={ incidentReport.thirdPartyCity }
                             state={ incidentReport.thirdPartyState }
                             zip={ incidentReport.thirdPartyZip } />
                  </Row>

                  <Row columnSize="xs-6">
                    <Phone label="Phone" value={ incidentReport.thirdPartyPhone } />
                    <BasicColumn label="Email Address" value={ incidentReport.thirdPartyEmail } />
                  </Row>

                  <Row columnSize="xs-12">
                    <BasicColumn label="Additional Comments"
                                 value={ incidentReport.thirdPartyAtFaultAdditionalComments } />
                  </Row>

                  <Row columnSize="xs-12">
                    <BooleanColumn label="Do you have the responsible party's insurance information?"
                                   value={ incidentReport.hasInsuranceInfoForThirdPartyAtFault } />
                  </Row>

                  {
                    incidentReport.hasInsuranceInfoForThirdPartyAtFault &&
                      <div>
                        <Row columnSize="xs-6">
                          <BasicColumn label="Insurance Provider Name" value={ incidentReport.thirdPartyInsuranceProviderName } />
                          <BasicColumn label="Policy Number" value={ incidentReport.thirdPartyInsurancePolicyNumber } />
                        </Row>

                        <Row columnSize="xs-6">
                          <BasicColumn label="Adjuster Name" value={ incidentReport.thirdPartyInsuranceAdjuster } />
                          <BasicColumn label="Claim Number" value={ incidentReport.thirdPartyInsurancePolicyClaimNumber } />
                        </Row>

                        <Row columnSize="xs-12">
                          <Address label="Third Party at Insurance Address"
                                   streetAddress={ incidentReport.thirdPartyInsuranceStreetAddress }
                                   optionalLine={ incidentReport.thirdPartyInsuranceOptionalLine }
                                   city={ incidentReport.thirdPartyInsuranceCity }
                                   state={ incidentReport.thirdPartyInsuranceState }
                                   zip={ incidentReport.thirdPartyInsuranceZip } />
                        </Row>

                        <Row columnSize="xs-6">
                          <Phone label="Phone" value={ incidentReport.thirdPartyInsurancePhone } />
                          <BasicColumn label="Email Address" value={ incidentReport.thirdPartyInsuranceEmail } />
                        </Row>
                      </div>
                  }
                </div>
            }
          </Section>
        </div>
    }

    <Section title="Contact Details">
      <Row columnSize="xs-6" >
        <BasicColumn label="Contact Name" value={ incidentReport.claimContactName } />
        <BasicColumn label="Contact Job Title" value={ incidentReport.claimContactJobTitle } />
      </Row>
      <Row columnSize="xs-6" >
        <Phone label="Contact Phone" value={ incidentReport.claimContactPhone } />
        <BasicColumn label="Contact Email Address" value={ incidentReport.claimContactEmail } />
      </Row>

      <Row columnSize="xs-6">
        <BasicColumn label="Preferred Contact Method" value={ incidentReport.claimContactBestMethod } />
        <BasicColumn label="Best Time to Contact" value={ incidentReport.claimContactBestTime } />
      </Row>
    </Section>

    <Section title="Persons (BI / WC)">
      <Row>
        {
          Object.entries(filterDestroyedRecords(incidentReport.injuredPersonsAttributes)).length === 0 ?
            <p>No entries added.</p>
          :
            Object.entries(filterDestroyedRecords(incidentReport.injuredPersonsAttributes)).
              map(([id, person]) => (
                <PersonCard key={ id } person={ person } />
              ))
        }
      </Row>
    </Section>

    <Section title="Property and Loss">
      <Row>
        {
          Object.entries(filterDestroyedRecords(incidentReport.damagedPropertiesAttributes)).length === 0 ?
            <p>No entries added.</p>
          :
            Object.entries(filterDestroyedRecords(incidentReport.damagedPropertiesAttributes)).
              map(([id, property]) => (
                <PropertyCard key={ id } property={ property } />
              ))
        }
      </Row>
    </Section>

    <Section title="Involved Vehicles">
      <Row>
        {
          Object.entries(filterDestroyedRecords(incidentReport.involvedVehiclesAttributes)).length === 0 ?
            <p>No entries added.</p>
          :
            Object.entries(filterDestroyedRecords(incidentReport.involvedVehiclesAttributes)).
              map(([id, vehicle]) => (
                <VehicleCard key={ id } vehicle={ vehicle } />
              ))
        }
      </Row>
    </Section>

    <Section title="Witnesses">
      <Row>
        {
          Object.entries(filterDestroyedRecords(incidentReport.witnessesAttributes)).length === 0 ?
            <p>No entries added.</p>
          :
            Object.entries(filterDestroyedRecords(incidentReport.witnessesAttributes)).
              map(([id, witness]) => (
                <WitnessCard key={ id } witness={ witness } />
              ))
        }
      </Row>
    </Section>

    <Section title="Attachments" divider={ false }>
      <Row>
        {
          Object.entries(filterDestroyedRecords(incidentReport.attachmentsAttributes)).length === 0 ?
            <p>No entries added.</p>
          :
            Object.entries(filterDestroyedRecords(incidentReport.attachmentsAttributes)).
              map(([id, attachment]) => (
                <AttachmentCard
                  key={ id }
                  recordWithId={ recordWithId }
                  injuredPersons={ filterDestroyedRecords(incidentReport.injuredPersonsAttributes) }
                  damagedProperties={ filterDestroyedRecords(incidentReport.damagedPropertiesAttributes) }
                  involvedVehicles={ filterDestroyedRecords(incidentReport.involvedVehiclesAttributes) }
                  attachment={ attachment }
                />
              ))
        }
      </Row>
    </Section>
  </div>
)

export default App;
