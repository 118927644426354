import React from "react"

let ToggleSwitchGroup = ({ label, field, disabled, onChange, checked }) => (
    <div className={ `form-group permission-toggle toggle-switch-group ${ disabled && "-is-disabled" }` }>
        <span className="pull-left power-name">{ label }</span>
        <div className="toggle-switch-container pull-right">
            <label className="toggle-switch" htmlFor={ field }>
                <input
                    type='checkbox'
                    name={ field }
                    id={ field }
                    onChange={ (e) => onChange(e, field) }
                    checked={ checked }
                />
                <div className="toggle-slider -round"></div>
            </label>
        </div>
    </div>
)

export default ToggleSwitchGroup
