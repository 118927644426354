import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { getPersonsCount } from '../ducks/persons.js'
import { getVehiclesCount } from '../ducks/vehicles.js'
import { getPropertiesCount } from '../ducks/properties.js'
import { getWitnessesCount } from '../ducks/witnesses.js'
import { getAttachmentsCount } from '../ducks/attachments.js'
import { closeMobileMenu } from '../ducks/wizard.js'

class PanelSidebar extends React.Component {
  onLinkClick = (event) => {
    event.target.blur()

    if (this.props.mobileMenuOpen) { this.props.closeMobileMenu() }

    if (this.props.onLinkClick) {
      this.props.onLinkClick(event)
    }
  }

  badgeFor = (countKey) => {
    if (this.props[countKey] && this.props[countKey] > 0) {
      return <span className="badge">{ this.props[countKey] }</span>
    }

    return null
  }

  render() {
    return (
      <ul className={ `panel-body-left nav nav-stacked nav-sidebar ${this.props.mobileMenuOpen ? "-is-open" : ""}` }>
        <li><NavLink to="/overview" onClick={ this.onLinkClick }>Report Overview</NavLink></li>

        <li><NavLink to="/contact_details" onClick={ this.onLinkClick }>Contact Details</NavLink></li>

        <li>
          <NavLink to="/persons" onClick={ this.onLinkClick }>
            { 'Persons (BI / WC)' }
            { this.badgeFor("personsCount") }
          </NavLink>
        </li>

        <li>
          <NavLink to="/vehicles" onClick={ this.onLinkClick }>
            Involved Vehicles
            { this.badgeFor("vehiclesCount") }
          </NavLink>
        </li>

        <li>
          <NavLink to="/properties" onClick={ this.onLinkClick }>
            Property and Loss
            { this.badgeFor("propertiesCount") }
          </NavLink>
        </li>

        <li>
          <NavLink to="/witnesses" onClick={ this.onLinkClick }>
            Witnesses
            { this.badgeFor("witnessesCount") }
          </NavLink>
        </li>

        <li>
          <NavLink to="/attachments" onClick={ this.onLinkClick }>
            Attachments
            { this.badgeFor("attachmentsCount") }
          </NavLink>
        </li>

        <li><NavLink to="/review" onClick={ this.onLinkClick }>Review and Submit</NavLink></li>
      </ul>
    )
  }
}

PanelSidebar = connect(
  (state, props) => ({
    personsCount:     getPersonsCount(state.persons),
    vehiclesCount:    getVehiclesCount(state.vehicles),
    propertiesCount:  getPropertiesCount(state.properties),
    witnessesCount:   getWitnessesCount(state.witnesses),
    attachmentsCount: getAttachmentsCount(state.attachments),
    mobileMenuOpen:   state.wizard.mobileMenuOpen
  }),
  (dispatch, props) => ({
    closeMobileMenu: () => { dispatch(closeMobileMenu()) }
  })
)(PanelSidebar)

export default PanelSidebar
