import React from 'react'
import { Provider, connect } from 'react-redux'

import { dispatchCustomEvent } from 'shared/utils/events.js'

import store from './store.js'
import { resetWizardState } from './ducks/wizard.js'
import { loadMetaData, apiEndpoint } from './ducks/rails.js'

import DocumentUploadContainer from './components/document_upload_container.jsx'
import UploadButton from './components/upload_button.jsx'

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentlyUploadingFiles: false,
      unpersistedFiles: []
    }

    store.dispatch({
      type: 'INITIAL_LOAD_OF_DATA_FROM_RAILS',
      actions: [
        loadMetaData(props.meta),
      ]
    })
  }

  finishUploadingFiles = () => {
    this.setState(
      { currentlyUploadingFiles: false },
      () => {
        this.notifyOnUploadSuccess()
        store.dispatch(resetWizardState())
      }
    );
  }

  cancelUploadingFiles = () => {
    this.setState({ currentlyUploadingFiles: false })
  }

  handleChooseFiles = (files) => {
    let filesArray = Array.from(files)

    this.setState({
      currentlyUploadingFiles: true,
      unpersistedFiles: filesArray
    })
  }

  notifyOnUploadSuccess = () => {
    dispatchCustomEvent(this, 'business_continuity:uploaded')
  }

  render() {
    return (
      <Provider store={ store }>
        <div>
          <UploadButton
            handleChooseFiles={ this.handleChooseFiles } />

          {
            this.state.currentlyUploadingFiles &&
              <DocumentUploadContainer
                files={ [...this.state.unpersistedFiles] }
                finishUploadingFiles={ this.finishUploadingFiles }
                cancelUploadingFiles={ this.cancelUploadingFiles }
              />
          }
        </div>
      </Provider>
    )
  }
}

export default App;
