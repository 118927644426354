import { createStore, combineReducers } from 'redux'

import { default as tabReducer }    from 'apps/rmg/shared/ducks/tabs.js'
import { default as lookups }       from 'apps/rmg/shared/ducks/lookups.js'
import { default as coverageTypes } from './ducks/coverage_types.js'
import { default as survey }        from './ducks/survey.js'
import { default as rails }         from './ducks/rails.js'

const rmgAdmin = combineReducers({
  tab:           tabReducer,
  survey:        survey,
  rails:         rails,
  lookups:       lookups,
  coverageTypes: coverageTypes
})

const store = createStore(
  rmgAdmin,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() // TODO - strip this in production
)

export default store
