import RailsApi from 'shared/utils/rails_api.js'

import { refreshMetaData } from './rails.js'
import { setErrorFlash, setSuccessFlash, updateSucceeded, updateFailed, updateStarted } from './wizard.js'
import { updateIncidentReport } from './incident_report.js'

const LOAD_PERSONS   = "incident_report_form/persons/LOAD_PERSONS"
const UPDATE_PERSONS = "incident_report_form/persons/UPDATE_PERSONS"

export default function persons(state = {}, action) {
  switch (action.type) {
    case LOAD_PERSONS: {
      return { ...action.persons }
    }

    case UPDATE_PERSONS: {
      return { ...action.persons }
    }

    default: {
      return state
    }
  }
}

export const loadPersons = (persons) => {
  return { type: LOAD_PERSONS, persons: persons }
}

export const updatePersons = (persons) => {
  return { type: UPDATE_PERSONS, persons: persons }
}

export const getPersonsCount = (state) => ( Object.keys(state).length )

export const getPerson = (state, id) => {
  return Object.values(state).find((person) => (
    person.id && person.id.toString() === id.toString()
  ))
}

export const asyncUpdatePersons = (url, data) => (dispatch) => {
  dispatch(updateStarted())

  return RailsApi.patch(url, { body: data }).
    then((json) => {
      dispatch({
        type: 'RAILS_UPDATE_SUCCEEDED',
        actions: [
          refreshMetaData({ ...json.meta }),
          updateIncidentReport(json.incidentReport),
          updatePersons(json.incidentReport.injuredPersonsAttributes),
          updateSucceeded(),
          setSuccessFlash("Persons updated", { keep: true })
        ]
      })
    }).
    catch((json) => {
      console.log(json)

      dispatch({
        type: 'RAILS_UPDATE_FAILED',
        actions: [
          updateFailed(),
          setErrorFlash(json.error.message, { details: json.error.details })
        ]
      })
    })
}

export const asyncDeletePerson = (url, id, personsCount) => (dispatch) => {
  let data = {
    incidentReport: {
      injuredPersonsAttributes: {
        [id]: { id: id, "_destroy": true }
      }
    }
  }

  if (personsCount === 1) { // we're about to delete the last person 😱
    data.incidentReport.wereAnyPeopleInjured = false
  }

  dispatch(updateStarted())

  return RailsApi.patch(url, { body: data }).
    then((json) => {
      dispatch({
        type: 'RAILS_UPDATE_SUCCEEDED',
        actions: [
          refreshMetaData({ ...json.meta }),
          updateIncidentReport(json.incidentReport),
          updatePersons(json.incidentReport.injuredPersonsAttributes),
          updateSucceeded(),
          setSuccessFlash("Person deleted", { keep: personsCount === 1 }) // we redirect when we delete the last person
        ]
      })
    }).
    catch((json) => {
      console.log(json)

      dispatch({
        type: 'RAILS_UPDATE_FAILED',
        actions: [
          updateFailed(),
          setErrorFlash(json.error.message, { details: json.error.details })
        ]
      })
    })
}
