import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Link, Redirect, Prompt } from 'react-router-dom'

import BaseForm from 'shared/components/base_form.jsx'

import {
  FormField,
  FileField
} from 'shared/components/fields'

import {
  getAttachmentsCount,
  asyncDeleteAttachment,
  asyncUpdateAttachments
} from '../ducks/attachments.js'

import {
  Panel,
  PanelSidebar,
  PanelRightColumn,
  PanelBody,
  PanelControls
} from '../panel'

import { apiEndpoint } from '../ducks/rails.js'

import AttachmentsTable from './attachments_table.jsx'

class AttachmentsForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      confirmingDelete: false,
      savingAttachments: false,
      deletingId: null,
      attachments: this.props.attachments || []
    }
  }

  componentWillReceiveProps = (nextProps) => {
    // because we're updating in place on the index screen (i.e. i can change a comment, click
    // "save" and we don't move pages), we've gotta update the state when rails re-hydrates the
    // attachments in the redux store (i.e. AttachmentsIndex gets new attachments props and passes
    // it down).
    //
    // this isn't a concern on AttachmentsNew (or uploading) because it doesn't receive an
    // attachments prop, and instead just adds to the state when uploading, and pops off when
    // uploads to rails succeed
    //
    if(!this.props.addingNewAttachments) {
      this.setState({
        attachments: nextProps.attachments
      })
    }
  }

  handleCommentChange = (attachment) => (comment) => {
    this.setState((prevState) => ({
      attachments: prevState.attachments.map((att) => {
        return this.attachmentIdentifier(att) != this.attachmentIdentifier(attachment) ? att : {
          ...att,
          comment: new FormField(comment),
          unsavedChanges: true
        }
      })
    }))
  }

  handleRelatedToChange = (attachment) => (attachableType, attachableId) => {
    this.setState((prevState) => ({
      attachments: prevState.attachments.map((att) => {
        return this.attachmentIdentifier(att) != this.attachmentIdentifier(attachment) ? att : {
          ...att,
          attachableType: attachableType,
          attachableId: attachableId,
          unsavedChanges: true
        }
      })
    }))
  }

  handleChooseAttachments = (files) => {
    let newAttachments = []

    for(let i = 0; i < files.length; i++) {
      newAttachments.push({
        document:         new FormField(files[i]),
        comment:          new FormField(''),
        documentFileName: files[i].name,
        documentFileSize: files[i].size,
        _tempId:          Date.now() + i,
        unsavedChanges:   true
      })
    }

    this.setState((prevState) => ({
      attachments: prevState.attachments.concat(newAttachments)
    }))
  }

  prepareData = (attachment) => {
    let key = attachment.id ? attachment.id : Date.now()

    // the structure of what we wanna send back to rails for updating
    let data = {
      incidentReport: {
        attachmentsAttributes: {
          [key]: {}
        } // new object to copy attachment values into
      }
    }

    // collect our form values
    Object.keys(attachment).map((field) => {
      data.incidentReport.attachmentsAttributes[key][field] = (attachment[field] instanceof FormField) ?
                                                                attachment[field].value : attachment[field]
    })

    return data
  }

  attachmentIdentifier = (attachment) => {
    return attachment.id || attachment._tempId
  }

  handleSave = (event) => {
    event.preventDefault()

    this.setState({
      savingAttachments: true
    })

    this.state.attachments.forEach((attachment) => {
      if(attachment.unsavedChanges) {
        this.props.asyncUpdateAttachments(
          this.prepareData(attachment),
          this.successCallback(attachment),
          this.errorCallback(attachment),
          this.progressCallback(attachment)
        )
      }
    })
  }

  handleDelete = (attachment) => {
    if(attachment.id) {
      this.props.asyncDeleteAttachment(attachment.id)
    }

    this.setState((prevState) => ({
      attachments: prevState.attachments.filter((att) => {
        return this.attachmentIdentifier(attachment) != this.attachmentIdentifier(att)
      })
    }))
  }

  successCallback = (attachment) => (json) => {
    const attachmentWasJustUploaded = attachment.id == null

    if(attachmentWasJustUploaded) {
      // remove from the Pending Uploads list
      this.handleDelete(attachment)
    }

    if(!this.filesCurrentlyUploading()) {
      this.setState({ savingAttachments: false })
    }
  }

  errorCallback = (attachment) => (data) => {
    const attachmentId = this.attachmentIdentifier(attachment)

    this.setState((prevState) => ({
      attachments: prevState.attachments.map((att) => {
        return attachmentId &&
          this.attachmentIdentifier(att) != attachmentId ? att :
            {
              ...att,
              uploadStatus: {
                status: 'error',
                message: data.error.message,
                details: data.error.details
              }
            }
      })
    }))

    if(!this.filesCurrentlyUploading()) {
      this.setState({ savingAttachments: false })
    }
  }

  progressCallback = (attachment) => (progress) => {
    const attachmentId = this.attachmentIdentifier(attachment)
    progress = Math.round(progress)

    this.setState((prevState) => ({
      attachments: prevState.attachments.map((att) => {
        return this.attachmentIdentifier(att) != attachmentId ? att : {
          ...att,
          uploadStatus: {
            ...att.uploadStatus || {},
            percentage: progress
          }
        }
      })
    }))
  }

  anyChangesMade = () => {
    return this.state.attachments.some((attachment) => attachment.unsavedChanges)
  }

  filesCurrentlyUploading = () => {
    return this.state.attachments.some((attachment) => attachment.uploadStatus && attachment.uploadStatus.status != 'error')
  }

  renderControlGroup() {
      return (
        <div>
          { this.props.addingNewAttachments &&
              <Link to="/attachments">
                <button type="button"
                        className="btn btn-link"
                        onClick={ (e) => {
                          if (this.anyChangesMade() && !confirm("Are you sure you want to move away? Any unsaved changes " +
                                      "made to this section will be lost.")) {
                            e.preventDefault()
                          }
                        }}>
                  Cancel
                </button>
              </Link>
          }

          { !this.props.addingNewAttachments &&
              <Link to="/witnesses">
                <button type="button"
                        className="btn btn-link">

                  Back
                </button>
              </Link>
          }

          { (this.anyChangesMade() || this.state.savingAttachments) &&
            <button type="button"
                    onClick={ this.handleSave }
                    disabled={ this.state.savingAttachments }
                    className={ `btn btn-success ${this.state.savingAttachments ? "disabled" : ""}` }>

              { this.props.addingNewAttachments ? 'Save to Claim' : 'Save Changes' }
            </button>
          }

          { !this.anyChangesMade() && !this.state.savingAttachments &&
              <Link to="/review">
                <button type="button"
                        className="btn btn-success">

                  Next
                </button>
              </Link>
          }
        </div>
      )
  }

  render() {
    return (
      <Panel>
        <PanelSidebar />
        <PanelRightColumn>
          <PanelBody>
            { this.state.savingAttachments && this.state.attachments.length == 0 &&
                <Redirect to='/attachments' />
            }

            { this.props.addingNewAttachments ?
                <div>
                  <h4>Upload Attachments</h4>
                  <FileField
                    name="documents"
                    onChange={ this.handleChooseAttachments }
                    multiple={ true }
                    className="form-control" />
                </div>
              :
                <div>
                  <h4>Attachments</h4>
                  <Link to="/attachments/new">
                    <button
                      type="button"
                      className="btn btn-success btn-with-icon plus">

                      Add New
                    </button>
                  </Link>
                </div>
            }

            <AttachmentsTable
              attachments={ this.state.attachments }
              handleRelatedToChange={ this.handleRelatedToChange }
              handleCommentChange={ this.handleCommentChange }
              handleDelete={ this.handleDelete }
           />
          </PanelBody>
          <PanelControls>
            <Prompt when={ this.anyChangesMade() && !this.props.addingNewAttachments }
                    message="Are you sure you want to move away? Any unsaved changes
                             made to this section will be lost." />
            { this.renderControlGroup() }
          </PanelControls>
        </PanelRightColumn>
      </Panel>
    )
  }
}

AttachmentsForm.propTypes = {
  tableComponent: PropTypes.object
}

AttachmentsForm = connect(
  (state, props) => ({
    updating:        state.wizard.updating,
    attachmentsCount: getAttachmentsCount(state.attachments),
    apiEndpoint:      apiEndpoint(state.rails, "incident_report")
  }),
  null,
  ({ apiEndpoint, ...stateProps }, { dispatch }, props) => ({
    ...stateProps,
    ...props,

    asyncUpdateAttachments: (data, ...callbacks) => {
      return asyncUpdateAttachments(apiEndpoint.path, data, ...callbacks)(dispatch)
    },
    asyncDeleteAttachment: (id, attachmentsCount) => {
      return asyncDeleteAttachment(apiEndpoint.path, id, attachmentsCount)(dispatch)
    }
  })
)(AttachmentsForm)

export default AttachmentsForm;
