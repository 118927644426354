import RailsApi from 'shared/utils/rails_api.js'

// we extend this reducer by just calling it in the default action handler. basically, if we
// define an action here use it, otherwise punt to the baseSurveyReducer
import { default as baseSurveyReducer, load } from 'apps/rmg/shared/ducks/survey.js'

const CREATE_SECTION           = 'rmg/admin/ducks/survey/CREATE_SECTION'
const UPDATE_SECTION           = 'rmg/admin/ducks/survey/UPDATE_SECTION'
const DESTROY_SECTION          = 'rmg/admin/ducks/survey/DESTROY_SECTION'
const REORDER_SECTION          = 'rmg/admin/ducks/survey/REORDER_SECTION'
const CREATE_QUESTION          = 'rmg/admin/ducks/survey/CREATE_QUESTION'
const UPDATE_QUESTION          = 'rmg/admin/ducks/survey/UPDATE_QUESTION'
const DESTROY_SECTION_QUESTION = 'rmg/admin/ducks/survey/DESTROY_SECTION_QUESTION'
const REORDER_SECTION_QUESTION = 'rmg/admin/ducks/survey/REORDER_SECTION_QUESTION'

export default (state = {}, action) => {
  switch (action.type) {
    case CREATE_SECTION: {
      return {
        ...state,
        surveySectionsAttributes: {
          ...state.surveySectionsAttributes,
          [action.key]: { ...action.section }
        }
      }
    }

    case UPDATE_SECTION: {
      let key = Object.keys(state.surveySectionsAttributes).find((_key) => (
        state.surveySectionsAttributes[_key].id === action.section.id
      ))

      if (!key) { return state }

      return {
        ...state,
        surveySectionsAttributes: {
          ...state.surveySectionsAttributes,
          [key]: { ...action.section }
        }
      }
    }

    case DESTROY_SECTION: {
      let key = Object.keys(state.surveySectionsAttributes).find((_key) => (
        state.surveySectionsAttributes[_key].id === action.sectionId
      ))

      if (!key) { return state }

      const { [key]: { bye_bye }, ...otherSections } = state.surveySectionsAttributes
      return {
        ...state,
        surveySectionsAttributes: {
          ...otherSections,
        }
      }
    }

    case REORDER_SECTION: {
      return {
        ...state,

        surveySectionsAttributes: (function(state, action) {
          let { ...copy } = state

          for (let [_key, section] of Object.entries(copy)) {
            if (action.source.id === section.id) {
              copy[_key].order = action.target.order
            }
            else if (action.source.order > section.order && section.order >= action.target.order) {
              copy[_key].order = section.order + 1
            }
            else if (action.source.order < section.order && section.order <= action.target.order) {
              copy[_key].order = section.order - 1
            }
          }

          return { ...copy }
        })(state.surveySectionsAttributes, action)
      }
    }

    case REORDER_SECTION_QUESTION: {
      let key = Object.keys(state.surveySectionsAttributes).find((_key) => (
        state.surveySectionsAttributes[_key].id === action.source.surveySectionId
      ))

      if (!key) { return state }

      return {
        ...state,

        surveySectionsAttributes: {
          ...state.surveySectionsAttributes,

          [key]: (function(state, action) {
            let { ...copy } = state.sectionQuestionsAttributes

            for (let [_key, section_question] of Object.entries(copy)) {
              if (action.source.id === section_question.id) {
                copy[_key].order = action.target.order
              }
              else if (action.source.order > section_question.order && section_question.order >= action.target.order) {
                copy[_key].order = section_question.order + 1
              }
              else if (action.source.order < section_question.order && section_question.order <= action.target.order) {
                copy[_key].order = section_question.order - 1
              }
            }

            return {
              ...state,

              sectionQuestionsAttributes: copy
            }
          })(state.surveySectionsAttributes[key], action)
        }
      }
    }

    case CREATE_QUESTION:
    case UPDATE_QUESTION:
    case DESTROY_SECTION_QUESTION: {
      return {
        ...state,
        surveySectionsAttributes: surveySections(state.surveySectionsAttributes, action)
      }
    }

    default: return baseSurveyReducer(state, action);
  }
}

const surveySections = (state, action) => {
  switch (action.type) {
    case CREATE_QUESTION:
    case UPDATE_QUESTION:
    case DESTROY_SECTION_QUESTION: {
      let key = Object.keys(state).find((_key) => (
        state[_key].id.toString() === action.sectionQuestion.surveySectionId.toString()
      ))

      if (!key) { return state }

      return {
        ...state,
        [key]: surveySection(state[key], action)
      }
    }
  }
}

const surveySection = (state, action) => {
  switch (action.type) {
    case CREATE_QUESTION: {
      // Question doesn't go in this section
      if(action.sectionQuestion.surveySectionId != state.id) {
        return state
      }

      return {
        ...state,
        sectionQuestionsAttributes: {
          ...state.sectionQuestionsAttributes,
          [action.key]: { ...action.sectionQuestion }
        }
      }
    }

    case UPDATE_QUESTION: {
      let key = Object.keys(state.sectionQuestionsAttributes).find((_key) => (
        state.sectionQuestionsAttributes[_key].id.toString() === action.sectionQuestion.id.toString()
      ))

      if (!key) { return state }

      return {
        ...state,
        sectionQuestionsAttributes: {
          ...state.sectionQuestionsAttributes,
          [key]: { ...action.sectionQuestion }
        }
      }
    }

    case DESTROY_SECTION_QUESTION: {
      let key = Object.keys(state.sectionQuestionsAttributes).find((_key) => (
        state.sectionQuestionsAttributes[_key].id === action.sectionQuestion.id
      ))

      if (!key) { return state }

      const { [key]: { bye_bye }, ...otherQuestions } = state.sectionQuestionsAttributes
      return {
        ...state,
        sectionQuestionsAttributes: {
          ...otherQuestions,
        }
      }
    }
  }
}

export const createQuestion = (sectionQuestion) => {
  return { type: CREATE_QUESTION, key: Date.now().toString(), sectionQuestion: { ...sectionQuestion } }
}

export const updateQuestion = (sectionQuestion) => {
  return { type: UPDATE_QUESTION, sectionQuestion: { ...sectionQuestion } }
}

const destroySectionQuestion = (sectionQuestion) => {
  return { type: DESTROY_SECTION_QUESTION, sectionQuestion: sectionQuestion }
}

export const submitQuestionToRails = (sectionQuestion, url, method, mode, dispatch) => {
  return RailsApi.submit(url, method, { body: { section_question: { ...sectionQuestion } } }).
    then((json) => {
      mode === "create" ? dispatch(createQuestion(json.section_question)) :
                          dispatch(updateQuestion(json.section_question))
    })
}

export const canDestroyQuestions = (state) => {
  return state.status === 'pending'
}

export const canEditQuestions = (state) => {
  return state.status !== 'archived'
}

export const canLockOrHideQuestion = (state) => {
  return state.status === 'active'
}

export const removeSectionQuestionFromRails = (sectionQuestion, url, method, mode, dispatch) => {
  return RailsApi.submit(url, method).
    then((json) => { dispatch(destroySectionQuestion(sectionQuestion)) })
}

const createSection = (section) => {
  return { type: CREATE_SECTION, key: Date.now().toString(), section: { ...section } }
}

const updateSection = (section) => {
  return { type: UPDATE_SECTION, section: { ...section } }
}

const destroySection = (sectionId) => {
  return { type: DESTROY_SECTION, sectionId: sectionId }
}

export const submitSectionToRails = (section, url, method, mode, dispatch) => {
  return RailsApi.submit(url, method, { body: { survey_section: { ...section } } }).
    then((json) => {
      mode === "create" ? dispatch(createSection(json.survey_section)) :
                          dispatch(updateSection(json.survey_section))
    })
}

export const removeSectionFromRails = (sectionId, url, method, dispatch) => {
  return RailsApi.submit(url, method).
    then((json) => { dispatch(destroySection(sectionId)) })
}

export const canDestroySections = (state) => {
  return state.status === 'pending'
}

export const canCreateSections = (state) => {
  return state.status !== 'archived'
}

export const canEditSections = (state) => {
  return state.status !== 'archived'
}

export const reorderSectionQuestion = (source, target) => {
  return { type: REORDER_SECTION_QUESTION, source: source, target: target }
}

export const reorderSection = (source, target) => {
  return { type: REORDER_SECTION, source: source, target: target }
}

export const submitSurveyToRails = (survey, url, method, dispatch) => {
  return RailsApi.submit(url, method, { body: { survey: { ...survey } } }).
    then((json) => {
      dispatch(load({ survey: json.survey }))
    })
}
