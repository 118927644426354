import React from 'react'
import BasicColumn from 'shared/components/summary/basic_column.jsx'

const MultiSelect = ({ label, selectedValues, options }) => {
  let selectedItemLabels =
        options.filter(item => selectedValues.indexOf(item.value) >= 0 )
               .map(item => item.displayText)
               .join(", ")

  return (
    <BasicColumn label={ label } value={ selectedItemLabels } />
  )
}

export default MultiSelect
