import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import ConfirmationModal from 'shared/components/confirmation_modal.jsx'
import { apiEndpoint } from '../ducks/rails.js'

class ActionWithConfirmation extends React.Component {
  constructor(props) {
    super(props)

    this.onConfirm = this.onConfirm.bind(this)
  }

  onConfirm() {
    let confirmReturn

    if(this.props.apiEndpoint && this.props.apiEndpoint.path) {
      let { apiMode, apiEndpoint: { path, method }, record, dispatch } = this.props
      path = path.replace(/:id/g, record.id)

      confirmReturn = this.props.onConfirm(record, path, method, apiMode, dispatch)
    } else {
      confirmReturn = this.props.onConfirm()
    }

    Promise.resolve(confirmReturn).then(() => {
      this.props.handleHide()
    })
  }

  render() {
    const { record, title, body, confirmText, apiResource, apiMode, onConfirm } = this.props

    return (
      <ConfirmationModal title={ title }
        body={ <p>{ body }</p> }
        confirmText={ confirmText }
        handleConfirm={ this.onConfirm }
        handleHide={ this.props.handleHide }
      />
    )
  }
}

ActionWithConfirmation.propTypes = {
  record: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  apiResource: PropTypes.string.isRequired,
  apiMode: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

ActionWithConfirmation = connect(
  (state, props) => {
    return {
      apiEndpoint: apiEndpoint(state.rails, { resource: props.apiResource, mode: props.apiMode }),
    }
  }
)(ActionWithConfirmation)

export default ActionWithConfirmation
