const dollarValueValidator = (options = {}) => function(value, state) {
  if (value) {
    let as_a_number = Number(value)
    let pattern = /^\d+(\.\d{1,2})?$/

    if (as_a_number !== as_a_number) {
      return "Not a valid number"
    }

    if(!pattern.test(value)) {
      return "Value must contain only two decimals"
    }

    if (options.max && value > options.max) {
      return `Exceeded maximum dollar value limit $(${options.max})`
    }
  }

  return null;
};

export default dollarValueValidator;
