import React from 'react'
import { connect } from 'react-redux'

import { policyYear } from 'apps/cyber/shared/ducks/survey.js'
import { policyYearRange } from 'shared/utils/policy_year.js'

import { default as SurveyActionButtons } from './action_buttons.jsx'

let EditableSurveyHeader = ({ policyYear }) => (
    <div>
        <h1 className='panel-title'>
            Cybersecurity Assessment: { policyYearRange(policyYear) }
            <span className='pull-right'>
        <SurveyActionButtons />
      </span>
        </h1>
    </div>
)

EditableSurveyHeader = connect(
    (state, props) => ({
        policyYear: policyYear(state.survey)
    })
)(EditableSurveyHeader)

export default EditableSurveyHeader