const numberValidator = ({ lte }, message = "") => function(value, state) {

  if (value) {
    let _value = Number(value)

    if (lte) {
      if (_value > lte) {
        return message || `Must be less than or equal to ${lte}`
      }
    }
  }

  return null
}

export default numberValidator;
