import RailsApi from 'shared/utils/rails_api.js'

// we extend this reducer by just calling it in the default action handler. basically, if we
// define an action here use it, otherwise punt to the baseSurveyReducer
import { default as baseSurveyReducer, load } from 'apps/cyber/shared/ducks/survey.js'

const CREATE_SECTION           = 'cyber/admin/ducks/survey/CREATE_SECTION'
const UPDATE_SECTION           = 'cyber/admin/ducks/survey/UPDATE_SECTION'
const DESTROY_SECTION          = 'cyber/admin/ducks/survey/DESTROY_SECTION'
const REORDER_SECTION          = 'cyber/admin/ducks/survey/REORDER_SECTION'
const CREATE_QUESTION          = 'cyber/admin/ducks/survey/CREATE_QUESTION'
const UPDATE_QUESTION          = 'cyber/admin/ducks/survey/UPDATE_QUESTION'
const DESTROY_SECTION_QUESTION = 'cyber/admin/ducks/survey/DESTROY_SECTION_QUESTION'
const REORDER_SECTION_QUESTION = 'cyber/admin/ducks/survey/REORDER_SECTION_QUESTION'

export default (state = {}, action) => {
    switch (action.type) {
        case CREATE_SECTION: {
            return {
                ...state,
                cyberSurveySectionsAttributes: {
                    ...state.cyberSurveySectionsAttributes,
                    [action.key]: { ...action.section }
                }
            }
        }

        case UPDATE_SECTION: {
            let key = Object.keys(state.cyberSurveySectionsAttributes).find((_key) => (
                state.cyberSurveySectionsAttributes[_key].id === action.section.id
            ))

            if (!key) { return state }

            return {
                ...state,
                cyberSurveySectionsAttributes: {
                    ...state.cyberSurveySectionsAttributes,
                    [key]: { ...action.section }
                }
            }
        }

        case DESTROY_SECTION: {
            let key = Object.keys(state.cyberSurveySectionsAttributes).find((_key) => (
                state.cyberSurveySectionsAttributes[_key].id === action.sectionId
            ))

            if (!key) { return state }

            const { [key]: { bye_bye }, ...otherSections } = state.cyberSurveySectionsAttributes
            return {
                ...state,
                cyberSurveySectionsAttributes: {
                    ...otherSections,
                }
            }
        }

        case REORDER_SECTION: {
            return {
                ...state,

                cyberSurveySectionsAttributes: (function(state, action) {
                    let { ...copy } = state

                    for (let [_key, section] of Object.entries(copy)) {
                        if (action.source.id === section.id) {
                            copy[_key].order = action.target.order
                        }
                        else if (action.source.order > section.order && section.order >= action.target.order) {
                            copy[_key].order = section.order + 1
                        }
                        else if (action.source.order < section.order && section.order <= action.target.order) {
                            copy[_key].order = section.order - 1
                        }
                    }

                    return { ...copy }
                })(state.cyberSurveySectionsAttributes, action)
            }
        }

        case REORDER_SECTION_QUESTION: {
            let key = Object.keys(state.cyberSurveySectionsAttributes).find((_key) => (
                state.cyberSurveySectionsAttributes[_key].id === action.source.surveySectionId
            ))

            if (!key) { return state }

            return {
                ...state,

                cyberSurveySectionsAttributes: {
                    ...state.cyberSurveySectionsAttributes,

                    [key]: (function(state, action) {
                        let { ...copy } = state.cyberSectionQuestionsAttributes

                        for (let [_key, cyber_section_question] of Object.entries(copy)) {
                            if (action.source.id === cyber_section_question.id) {
                                copy[_key].order = action.target.order
                            }
                            else if (action.source.order > cyber_section_question.order && cyber_section_question.order >= action.target.order) {
                                copy[_key].order = cyber_section_question.order + 1
                            }
                            else if (action.source.order < cyber_section_question.order && cyber_section_question.order <= action.target.order) {
                                copy[_key].order = cyber_section_question.order - 1
                            }
                        }

                        return {
                            ...state,

                            cyberSectionQuestionsAttributes: copy
                        }
                    })(state.cyberSurveySectionsAttributes[key], action)
                }
            }
        }

        case CREATE_QUESTION:
        case UPDATE_QUESTION:
        case DESTROY_SECTION_QUESTION: {
            return {
                ...state,
                cyberSurveySectionsAttributes: surveySections(state.cyberSurveySectionsAttributes, action)
            }
        }

        default: return baseSurveyReducer(state, action);
    }
}

const surveySections = (state, action) => {

    switch (action.type) {
        case CREATE_QUESTION:
        case UPDATE_QUESTION:
        case DESTROY_SECTION_QUESTION: {
            let key = Object.keys(state).find((_key) => (
                state[_key].id.toString() === action.cyberSectionQuestion.surveySectionId.toString()
            ))

            if (!key) { return state }

            return {
                ...state,
                [key]: surveySection(state[key], action)
            }
        }
    }
}

const surveySection = (state, action) => {
    switch (action.type) {
        case CREATE_QUESTION: {
            // Question doesn't go in this section
            if(action.cyberSectionQuestion.surveySectionId != state.id) {
                return state
            }

            return {
                ...state,
                cyberSectionQuestionsAttributes: {
                    ...state.cyberSectionQuestionsAttributes,
                    [action.key]: { ...action.cyberSectionQuestion }
                }
            }
        }

        case UPDATE_QUESTION: {
            let key = Object.keys(state.cyberSectionQuestionsAttributes).find((_key) => (
                state.cyberSectionQuestionsAttributes[_key].id.toString() === action.cyberSectionQuestion.id.toString()
            ))

            if (!key) { return state }

            return {
                ...state,
                cyberSectionQuestionsAttributes: {
                    ...state.cyberSectionQuestionsAttributes,
                    [key]: { ...action.cyberSectionQuestion }
                }
            }
        }

        case DESTROY_SECTION_QUESTION: {
            let key = Object.keys(state.cyberSectionQuestionsAttributes).find((_key) => (
                state.cyberSectionQuestionsAttributes[_key].id === action.cyberSectionQuestion.id
            ))

            if (!key) { return state }

            const { [key]: { bye_bye }, ...otherQuestions } = state.cyberSectionQuestionsAttributes
            return {
                ...state,
                cyberSectionQuestionsAttributes: {
                    ...otherQuestions,
                }
            }
        }
    }
}

export const createQuestion = (cyberSectionQuestion) => {

    return { type: CREATE_QUESTION, key: Date.now().toString(), cyberSectionQuestion: { ...cyberSectionQuestion } }
}

export const updateQuestion = (cyberSectionQuestion) => {

    return { type: UPDATE_QUESTION, cyberSectionQuestion: { ...cyberSectionQuestion } }
}

const destroySectionQuestion = (cyberSectionQuestion) => {
    return { type: DESTROY_SECTION_QUESTION, cyberSectionQuestion: cyberSectionQuestion }
}

export const submitQuestionToRails = (cyberSectionQuestion, url, method, mode, dispatch) => {
    return RailsApi.submit(url, method, { body: { cyberSectionQuestion: { ...cyberSectionQuestion } } }).
    then((json) => {
        mode === "create" ? dispatch(createQuestion(json.cyber_section_question)) :
            dispatch(updateQuestion(json.cyber_section_question))
    })
}

export const canDestroyQuestions = (state) => {
    return state.status === 'pending'
}

export const canEditQuestions = (state) => {
    return state.status !== 'archived'
}

export const canLockOrHideQuestion = (state) => {
    return state.status === 'active'
}

export const removeSectionQuestionFromRails = (cyberSectionQuestion, url, method, mode, dispatch) => {
    return RailsApi.submit(url, method).
    then((json) => { dispatch(destroySectionQuestion(cyberSectionQuestion)) })
}

const createSection = (section) => {
    return { type: CREATE_SECTION, key: Date.now().toString(), section: { ...section } }
}

const updateSection = (section) => {
    return { type: UPDATE_SECTION, section: { ...section } }
}

const destroySection = (sectionId) => {
    return { type: DESTROY_SECTION, sectionId: sectionId }
}

export const submitSectionToRails = (section, url, method, mode, dispatch) => {
    return RailsApi.submit(url, method, { body: { cyber_survey_section: { ...section } } }).
    then((json) => {
        mode === "create" ? dispatch(createSection(json.survey_section)) :
            dispatch(updateSection(json.survey_section))
    })
}

export const removeSectionFromRails = (sectionId, url, method, dispatch) => {
    return RailsApi.submit(url, method).
    then((json) => { dispatch(destroySection(sectionId)) })
}

export const canDestroySections = (state) => {
    return state.status === 'pending'
}

export const canCreateSections = (state) => {
    return state.status !== 'archived'
}

export const canEditSections = (state) => {
    return state.status !== 'archived'
}

export const reorderSectionQuestion = (source, target) => {
    return { type: REORDER_SECTION_QUESTION, source: source, target: target }
}

export const reorderSection = (source, target) => {
    return { type: REORDER_SECTION, source: source, target: target }
}

export const submitSurveyToRails = (survey, url, method, dispatch) => {
    return RailsApi.submit(url, method, { body: { survey: { ...survey } } }).
    then((json) => {
        dispatch(load({ survey: json.survey }))
    })
}
