import React from 'react'
import PropTypes from 'prop-types';

import FloatingLabelInputs from 'shared/components/floating_label_inputs.jsx'

let SurveySectionForm = ({ handleFormFieldChange, section }) => (
  <div>
    <div className="row">
      <div className="col-xs-12">
        <div className="form-group is-required">

          <FloatingLabelInputs.Input
            label="Section Title"
            isRequired={ true }
            name="header" type="text" className="form-control"
            value={ section.header || '' }
            onChange={ handleFormFieldChange }
          />

        </div>
      </div>
    </div>

    <div className="row">
      <div className="col-xs-12">
        <div className="form-group">

          <FloatingLabelInputs.Input
            label="Section Text"
            name="content" type="text" className="form-control"
            value={ section.content || '' }
            onChange={ handleFormFieldChange }
          />

        </div>
      </div>
    </div>
  </div>
)

SurveySectionForm.propTypes = {
  handleFormFieldChange: PropTypes.func.isRequired,
  section: PropTypes.object.isRequired
}

export default SurveySectionForm
