import React from 'react'

import ProgressBar from 'shared/components/progress_bar.jsx'
import { TextField } from 'shared/components/fields'
import ImagePreview from 'shared/components/image_preview.jsx'

class AttachmentsRow extends React.Component {
  render() {
    const { attachment } = this.props

    return (
      <tr>
        <td className="preview">
          <ImagePreview url={ attachment.url.value } contentType={ attachment.contentType.value } />

          <div className='filename'>
            { attachment.changed.value && <small>*</small> }
            { attachment.filename.value }
          </div>

          <ProgressBar percentage={ attachment.updating.value ? attachment.percentComplete.value : null }
                       hideWhenComplete={ true }
                       hideBeforeStart={ true } />
        </td>

        <td>
          <ErrorMessage attachment={ attachment } />

          <TextField name="comment"
                     onChange={ this.props.handleCommentChange }
                     type='textarea'
                     label="Type comment..."
                     value={ attachment.comment.value }
                     errorMessage={ attachment.comment.errorMessage }
                     bootstrapWrapperClassName='no-margin-bottom'
                     rows={ 3 } />
        </td>
        <td>
          <a className='remove-attachment' onClick={ this.props.deleteAttachment }>
            <i className='fa fa-trash-o'></i>
          </a>
        </td>
      </tr>
    )
  }
}

const ErrorMessage = ({ attachment }) => {
  let errors = [
    attachment.document && attachment.document.errorMessage,
    attachment.contentType.errorMessage,
    attachment.size.errorMessage,
    attachment.error.value
  ].filter((n) => n)

  if (errors.length === 0) { return null }

  return (
    <div className="alert alert-danger alert-condensed" role="alert">
      {
        errors.map((error, index) => (
          <p key={ index }>{ error }</p>
        ))
      }
    </div>
  )
}

export default AttachmentsRow
