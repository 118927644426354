import React from 'react'
import PropTypes from 'prop-types';

class ImagePreview extends React.Component {
  constructor(props) {
    super(props);
    this.icon = this.icon.bind(this);
  }

  render() {
    return (
      this.props.contentType.includes("image") ?
        <img className="img-responsive -incident-report-attachment-thumbnail" src={ this.props.url } />
      :
        <i className={ this.icon() } aria-hidden="true"></i>
    )
  }

  icon() {
    const { contentType } = this.props;

    if (contentType.includes("audio") || contentType === "application/ogg") {
      return "fa fa-file-audio-o fa-5x";
    }
    else if (contentType.includes("video")) {
      return "fa fa-file-video-o fa-5x";
    }
    else if (contentType.includes("text")) {
      return "fa fa-file-text-o fa-5x";
    }
    else if (contentType === "application/vnd.mspowerpoint") {
      return "fa fa-file-powerpoint-o fa-5x";
    }
    else if (contentType === "application/pdf") {
      return "fa fa-file-pdf-o fa-5x";
    }
    else {
      return "fa fa-file-o fa-5x";
    }
  }
}

ImagePreview.propTypes = {
  url:         PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired
}

export default ImagePreview;
