import React from "react"

let ErrorSpan = ({ meta: { touched, error } }) => (
  touched && error ? <span className="field-level-error">{ error }</span> : false
);

let WarningSpan = ({ meta: { active, warning } }) => (
  active && warning ? <span className="field-level-warning">{ warning }</span> : false
);

function decorateInput(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.floatLabel = this.floatLabel.bind(this);
    }

    floatLabel() {
      let label_floater = new App.LabelFloater({
        $label: $(this.label),
        $input: $(this.wrapped.inputElement()) }
      )

      label_floater.initialize();
    }

    componentDidUpdate() {
      // opt-in to floating the label on update so we only have to call this code on inputs
      // that are populated by dispatching a change action. otherwise, it'll happen on every
      // keypress, blur, click, etc. on every input 😳
      if (this.props.shouldFloatLabelOnUpdate) {
        this.floatLabel();
      }
    }

    componentDidMount() {
      this.floatLabel();
    }

    render() {
      const {
        wrapInFormGroup = true,
        wrapperClass = "",
        meta,
        requiredInput = false,
        name,
        labelText,
        hasInstructions = false,
        instructions,
        input,
        inputClass = "form-control",
        shouldFloatLabelOnUpdate,
        ...otherProps
      } = this.props

      let cssClasses = [
        wrapInFormGroup ? "form-group" : "",
        meta.touched && meta.error ? "has-error" : "",
        (meta.active && meta.warning && !meta.error) ? "has-warning" : "",
        wrapperClass,
        requiredInput ? "is-required" : ""
      ].filter((n) => n); // removes the empty strings

      return (
        <div className={ cssClasses.join(" ") }>
          <label ref={ (label) => { this.label = label } } htmlFor={ name }>
            { labelText }{ requiredInput ? <span></span> : "" }
          </label>

          <WrappedComponent ref={ (wrapped) => { this.wrapped = wrapped } }
            { ...input } { ...otherProps } id={ name } className={ inputClass } />

          {
            meta.error ?
              <ErrorSpan meta={ meta } />
            :
              <WarningSpan meta={ meta } />
          }

          { hasInstructions ? <p>{ instructions }</p> : ""}
        </div>
      )
    }
  }
}

class BootstrapInputField extends React.Component {
  inputElement() {
    return this.input;
  }

  render() {
    return (
      <input ref={ (input) => { this.input = input } } { ...this.props } />
    )
  }
}
BootstrapInputField = decorateInput(BootstrapInputField);

class BootstrapTextArea extends React.Component {
  inputElement() {
    return this.input;
  }

  render() {
    return (
      <textarea ref={ (input) => { this.input = input } } { ...this.props } />
    )
  }
}
BootstrapTextArea = decorateInput(BootstrapTextArea);

class BootstrapSelect extends React.Component {
  inputElement() {
    return this.input;
  }

  render() {
    const { children, ...otherProps } = this.props;

    return (
      <select ref={ (input) => { this.input = input } } { ...otherProps }>
        { children }
      </select>
    )
  }
}
BootstrapSelect = decorateInput(BootstrapSelect);

let ErrorDivAndSpanWrapper = ({
  children, wrapInFormGroup = true, wrapperClass = "", meta, requiredInput = false
}) => {

  let cssClasses = [
    wrapInFormGroup ? "form-group" : "",
    meta.touched && meta.error ? "has-error" : "",
    wrapperClass,
    requiredInput ? "is-required" : ""
  ].filter((n) => n); // removes the empty strings

  return (
    <div className={ cssClasses.join(" ") }>
      { children }
      <ErrorSpan meta={ meta } />
    </div>
  )
}

export { BootstrapInputField, BootstrapTextArea, BootstrapSelect, ErrorSpan, ErrorDivAndSpanWrapper };
