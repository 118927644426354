import React from "react"
import { connect } from 'react-redux'

import { getOverallScore } from 'apps/cyber/shared/ducks/survey.js'
import { activeCoverage } from 'apps/cyber/shared/ducks/coverage_types.js'
import { changeTab } from 'apps/cyber/shared/ducks/tabs.js'

let ScoreBar = ({ score, style }) => (
    <span className='score' style={ style }>
    <span className='bar' style={{width: score + '%'}}></span>
  </span>
)

let NavAndScore = ({ score, coverageTypes, activeTab, renderAsPreview, goToTab }) => (
    <div id='nav_and_score'>
        <div>Cybersecurity Assessment</div>

        {/*<div className="dropdown dark">*/}
        {/*    <a id="dLabel" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
        {/*        { activeTab.name }*/}
        {/*        { ' ' }*/}
        {/*        <span className="caret"></span>*/}
        {/*    </a>*/}
        {/*    <ul className="dropdown-menu condensed" aria-labelledby="dLabel">*/}
        {/*        { coverageTypes.map((coverageType, ix) =>*/}
        {/*            <li key={ coverageType.id }>*/}
        {/*                <a onClick={ goToTab(ix) } className={ activeTab.id == coverageType.id ? 'current' : '' }>{ coverageType.name }</a>*/}
        {/*            </li>*/}
        {/*        )}*/}
        {/*    </ul>*/}
        {/*</div>*/}


    </div>
)

NavAndScore = connect(
    (state, props) => ({
        score: getOverallScore(state),
        coverageTypes: state.coverageTypes,
        activeTab: activeCoverage(state.coverageTypes, state.tab),
        renderAsPreview: state.lookups.renderAsPreview
    }),
    (dispatch, props) => ({
        goToTab: (index) => () => {
            dispatch(changeTab(index))
            let topOfRmgPanel = document.querySelector('#rmg-panel').getBoundingClientRect().top + window.pageYOffset - 150
            window.scrollTo(0, topOfRmgPanel);
        }
    })
)(NavAndScore)

export default NavAndScore
