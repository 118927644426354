import React from "react";
import PropTypes from "prop-types";

export default class SelectField extends React.Component {
  onChange = (event) => {
    this.props.onChange(event.target.value);
  };

  floatLabel = () => {
    if (this.label) {
      let label_floater = new App.LabelFloater({
        $label: $(this.label),
        $input: $(this.input),
      });

      label_floater.initialize();
    }
  };

  componentDidUpdate() {
    // opt-in to floating the label on update so we only have to call this code on inputs
    // that are populated by dispatching a change action. otherwise, it'll happen on every
    // keypress, blur, click, etc. on every input 😳
    if (this.props.shouldFloatLabelOnUpdate) {
      this.floatLabel();
    }
  }

  componentDidMount() {
    this.floatLabel();
  }

  render() {
    const {
      name,
      required,
      withoutBootstrapMarkup,
      options,
      bootstrapWrapperClassName,
      className,
      errorMessage,
      label,
      placeholder,
      readOnly,
      value,
      instructions,
      tabIndex,
      onChange,
    } = this.props;

    let wrapperClass = [withoutBootstrapMarkup ? "" : "form-group"]
      .concat(bootstrapWrapperClassName || [])
      .concat(errorMessage ? "has-error" : "")
      .concat(required && required.warn ? "is-required-but-warn-about-it" : "")
      .concat(
        required && (required.warn === null || required.warn === undefined)
          ? "is-required"
          : ""
      )
      .filter((n) => n)
      .join(" ");

    let inputClass = [withoutBootstrapMarkup ? "" : "form-control"]
      .concat(className || [])
      .filter((n) => n)
      .join(" ");

    return (
      <div className={wrapperClass}>
        {label && (
          <label
            ref={(label) => {
              this.label = label;
            }}
            htmlFor={name}
          >
            {label}
            {required ? <span></span> : ""}
          </label>
        )}

        <select
          ref={(input) => {
            this.input = input;
          }}
          className={inputClass}
          id={name}
          name={name}
          placeholder={placeholder}
          readOnly={readOnly}
          value={value}
          tabIndex={tabIndex}
          onChange={this.onChange}
        >
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          {options.map((option, index) => (
            <option
              key={index}
              value={option.value}
              id={`${name}_${option.value}`}
            >
              {option.text
                ? option.text === "Ring central"
                  ? "RingCentral"
                  : option.text
                : option.displayText}
            </option>
          ))}
        </select>

        <span className="Select-arrow"></span>

        {errorMessage && (
          <span className="field-level-error">{errorMessage}</span>
        )}

        {instructions && typeof instructions == "string" ? (
          <p>{instructions}</p>
        ) : (
          instructions
        )}
      </div>
    );
  }
}

SelectField.defaultProps = {
  required: false,
  withoutBootstrapMarkup: false,
  shouldFloatLabelOnUpdate: false,
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  withoutBootstrapMarkup: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,

  bootstrapWrapperClassName: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  className: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),

  errorMessage: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  instructions: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  onChange: (props) => {
    if (!props.readOnly) {
      if (typeof props.onChange !== "function") {
        return new Error(
          "If SelectField is not ReadOnly, then onChange must be defined"
        );
      }
    }
  },
};
