import React from 'react'
import { connect } from 'react-redux'

import RailsApi from 'shared/utils/rails_api.js'
import BootstrapModal from 'shared/components/bootstrap_modal.jsx'

import BaseForm from 'shared/components/base_form.jsx'
import {
  FormField,
  TextField,
  MultiSelectField
} from 'shared/components/fields'

import {
  requiredValidator,
} from 'shared/utils/validators'

import { apiEndpoint } from './ducks/rails.js'
import { setSuccessFlash } from './ducks/wizard.js'


class AskForHelp extends BaseForm {
  constructor(props) {
    super(props)

    this.initState()
  }

  initState = () => {
    this.state = {
      modalShowing:        false,
      submitting:          false,
      submissionSucceeded: null,

      helpRequest: {
        recipients: new FormField([], requiredValidator()),
        message:    new FormField('')
      }
    }
  }
  resetState = () => ( this.initState() )

  handleChangeFor = (name) => ( this.handleFieldChange("helpRequest", name) )

  showModal = (event) => {
    this.setState({ modalShowing: true })
  }

  closeModal = () => {
    this.setState({ modalShowing: false }, this.resetState)
  }

  prepareData = () => {
    let copy = { helpRequest: { ...this.state.helpRequest } }

    Object.keys(copy.helpRequest).map((key) => {
      copy.helpRequest[key] = copy.helpRequest[key].value
    })

    return copy
  }

  handleSubmit = () => {
    if (this.validateFields("helpRequest")) {
      this.setState({ submitting: true })

      RailsApi.post(this.props.apiEndpoint.path, { body: this.prepareData() }).
        then((json) => {
          this.props.dispatch(setSuccessFlash("Help request sent"))
          this.setState({ submissionSucceeded: true, submitting: false }, this.closeModal)
        }).
        catch((json) => {
          this.setState({ submissionSucceeded: false, submitting: false })
        })
    }
  }

  render() {
    return (
      <span>
        <a onClick={ this.showModal }>
          <button className="cancel-btn btn btn-link">{ 'Send Link' }</button>
        </a>

        {
          this.state.modalShowing &&
            <div className="text-left" style={{ display: 'inline' }}>
              {/* ☝️ turn off some styles we inherited from the PanelHeader */}
              <BootstrapModal title="Send a link"
                              handleHide={ this.closeModal }
                              buttons={[
                                { className: "btn btn-link", dismiss: true, name: "Cancel", style: { color: '#000E19' } },
                                {
                                  className: "btn btn-default btn-success",
                                  hideAfterClicking: false,
                                  onClick: this.handleSubmit,
                                  loading: this.state.submitting,
                                  name: "Send email"
                                }
                              ]}>

                <div className="row">
                  <div className="col-xs-12">
                    <MultiSelectField label="Who should receive a link?"
                                      type="checkbox"
                                      name="recipients"
                                      value={ this.state.helpRequest.recipients.value }
                                      errorMessage={ this.state.helpRequest.recipients.errorMessage }
                                      onChange={ this.handleChangeFor("recipients") }
                                      tabIndex="1"
                                      required={ true }
                                      options={ this.props.users } />
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12">
                    <p>{ 'Message (optional):' }</p>

                    <TextField type="textarea"
                               name="message"
                               onChange={ this.handleChangeFor("message") }
                               label="Type..."
                               value={ this.state.helpRequest.message.value }
                               errorMessage={ this.state.helpRequest.message.errorMessage }
                               tabIndex="2" />
                  </div>
                </div>
              </BootstrapModal>
            </div>
        }
      </span>
    )
  }
}

AskForHelp = connect(
  (state, props) => ({
    apiEndpoint: apiEndpoint(state.rails, "help_requests")
  }),
  (dispatch, props) => ({
    dispatch
  })
)(AskForHelp)

export default AskForHelp
