import React from "react"
import { connect } from 'react-redux'

import { filterSectionsToCoverage } from 'apps/cyber/shared/ducks/survey.js'
import SurveyReviewSection from './survey_review_section.jsx'

const SurveyReview = ({ survey, coverages }) => (
    <div className='survey-section row'>
        <div className="col-xs-12">
            {
                Object.values(coverages).map((coverage) => {
                        let sections = filterSectionsToCoverage(survey, coverage)
                        return sections.length == 0 ? ( null ) : (
                            <div key={ coverage.id } className="row review-coverage-row">
                                <div className="col-xs-12">
                                    <h2 className="review-coverage-title">{ coverage.alias || coverage.name }</h2>
                                    { Object.values(sections).map((section, index) =>
                                        Object.values(section.cyberSectionQuestionsAttributes).length > 0 &&
                                        <SurveyReviewSection  key={ section.id }
                                                              header={ section.header }
                                                              questions={ section.cyberSectionQuestionsAttributes } />)
                                    }

                                    <hr />
                                </div>
                            </div>
                        )
                    }
                )
            }
        </div>
    </div>
)

export default connect(
    (state, props) => ({
        survey: state.survey,
        coverages: state.coverageTypes
    })
)(SurveyReview)
