import React from 'react'
import PropTypes from 'prop-types';

export default class RadioField extends React.Component {
  onChange = (event) => {
    let value = event.target.value

    if (value === "true" || value === "false") {
      this.props.onChange(value === "true")
    }
    else {
      this.props.onChange(value)
    }
  }

  render() {
    const {
      name,
      required,
      withoutBootstrapMarkup,
      options,
      bootstrapWrapperClassName,
      className,
      errorMessage,
      label,
      value,
      tabIndex,
      onChange
    } = this.props

    let wrapperClass = [].concat(bootstrapWrapperClassName || []).
      concat(errorMessage ? "has-error" : "").
      concat(required && required.warn ? "is-required-but-warn-about-it" : "").
      concat(required && (required.warn === null || required.warn === undefined) ? "is-required" : "").
      filter((n) => n).
      join(' ')

    let inputClass = [].concat(className || []).
      filter((n) => n).
      join(' ')

    return (
      <div className={ wrapperClass } style={{ marginBottom: '2.5rem' }}> {/* TODO - stolen from how .form-group styles */}
        { label && <label>{ label }{ required ? <span></span> : "" }</label> }

        {
          options.map((option, index) => (
            <label className="radio-inline" key={ index }>
              <input type="radio"
                     className={ inputClass }
                     id={ `${name}_${option.value}` }
                     name={ `${name}_${option.value}` }
                     value={ option.value }
                     checked={ value === option.value }
                     tabIndex={ tabIndex }
                     onChange={ this.onChange } /> { option.displayText }
            </label>
          ))
        }

        { errorMessage && <span className="field-level-error">{ errorMessage }</span> }
      </div>
    )
  }
}

RadioField.defaultProps = {
  required: false
}

RadioField.propTypes = {
  name:                      PropTypes.string.isRequired,
  required:                  PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  options:                   PropTypes.array.isRequired,

  bootstrapWrapperClassName: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  className:                 PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),

  errorMessage:              PropTypes.string,
  label:                     PropTypes.string,
  value:                     PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  instructions:              PropTypes.string,
  tabIndex:                  PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  onChange: (props) => {
    if (!props.readOnly) {
      if (typeof props.onChange !== 'function') {
        return new Error('If RadioField is not ReadOnly, then onChange must be defined');
      }
    }
  }
}
