import React from 'react'
import Label from './label.jsx'
import Response from './response.jsx'

const BooleanColumn = ({ label, value, missingText = "Unspecified" }) => {
  let formattedValue = value;

  if (value === true || value === "true") {
    formattedValue = "Yes";
  }
  else if (value === false || value === "false") {
    formattedValue = "No";
  }

  return (
    <div className={ !formattedValue ? "unanswered" : "" }>
      <Label>{ label }</Label>
      <Response>{ formattedValue ? String(formattedValue) : missingText }</Response>
    </div>
  )
}

export default BooleanColumn;
