import React from 'react'

let VehiclesHeader = () => (
  <div className="row">
    <div className="col-xs-12">
      <h4>Involved Vehicles Report</h4>

      <p>
        This section records any vehicles involved in this report.
      </p>
    </div>
  </div>
)

export default VehiclesHeader
