import React from 'react'
import { Switch, Route, Link, Redirect } from 'react-router-dom'

import PersonsIndex from './persons_index.jsx'
import PersonsNew from './persons_new.jsx'
import PersonsEdit from './persons_edit.jsx'

let PersonsRouter = () => (
  <Switch>
    <Route exact path="/persons"          component={ PersonsIndex } />
    <Route exact path="/persons/new"      component={ PersonsNew } />
    <Route       path="/persons/:id/edit" component={ PersonsEdit } />
  </Switch>
)

export default PersonsRouter
