import React from 'react'
import { Switch, Route } from 'react-router-dom'

import AttachmentsIndex from './attachments_index.jsx'
import AttachmentsNew from './attachments_new.jsx'

let AttachmentsRouter = () => (
  <Switch>
    <Route exact path="/attachments"          component={ AttachmentsIndex } />
    <Route exact path="/attachments/new"      component={ AttachmentsNew } />
  </Switch>
)

export default AttachmentsRouter