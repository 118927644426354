import { createStore, combineReducers } from 'redux'

import { default as lookups } 			   from 'apps/rmg/shared/ducks/lookups.js'
import { default as tabReducer }           from 'apps/rmg/shared/ducks/tabs.js'
import { default as surveyReducer }        from 'apps/rmg/shared/ducks/survey.js'
import { default as coverageTypesReducer } from 'apps/rmg/shared/ducks/coverage_types.js'

// Action types
export const UPDATE_RESPONSE = 'rmg/frontend/UPDATE_RESPONSE'
export const ADD_RESPONSE    = 'rmg/frontend/ADD_RESPONSE'
export const LOAD_RESPONSES  = 'rmg/frontend/LOAD_RESPONSES'

export const LOAD_SCORES = 'rmg/frontend/LOAD_SCORES'

// Action creators
export function loadResponses(responses) {
  return { type: LOAD_RESPONSES, responses: responses }
}

export function addResponse(response) {
  return { type: ADD_RESPONSE, ...response }
}

export function updateResponse(response) {
  return { type: UPDATE_RESPONSE, ...response }
}

export function loadScores(scores){
  return { type: LOAD_SCORES, scores: scores }
}

function sendToRails(url, data, dispatch, addCallback, updateCallback) {
  // Prevent you a CSRF for great good
  let token     = document.querySelector('meta[name=csrf-token]').content
  let newRecord = data.id == null
  let callback  = newRecord ? addCallback : updateCallback

  dispatch(callback({ ...data, status: 'saving' }))

  return fetch(url, {
            method: newRecord ? 'POST' : 'PATCH',
            body: JSON.stringify({
              id: data.id,
              response: data
            }),
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'X-CSRF-Token': token,
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            credentials: 'same-origin'
          })
          .then((result) => {
            if(result.ok) { return result.json(); }
            throw Error(response.statusText);
          })
          .then((newData) => {
            dispatch(callback({ ...newData.response, status: 'success' }))
            dispatch(loadScores(newData.scores))
          })
          .catch((err) => {
            dispatch(callback({ ...data, status: 'error', error: err }))
          })
}

export function startUpdateResponse(dispatch, newResponse) {
  return sendToRails('/rmg/frontend/responses', newResponse, dispatch, addResponse, updateResponse)
}

export function pretendToSendResponseToRails(dispatch, newResponse) {

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
  let callback = newResponse.id == null ? addResponse : updateResponse

  dispatch(callback({ ...newResponse, status: 'saving' }))

  sleep(100).then(() => {
    let fakedResponseFromRails = { id: newResponse.id }

    // first things first, we gotta give it an id
    if (!fakedResponseFromRails.id) {
      fakedResponseFromRails.id = Date.now()
    }

    // then we gotta camelize the props like rails would have
    fakedResponseFromRails.answer            = newResponse.answer
    fakedResponseFromRails.comment           = newResponse.comment
    fakedResponseFromRails.notApplicable     = newResponse.not_applicable
    fakedResponseFromRails.sectionQuestionId = newResponse.section_question_id
    fakedResponseFromRails.yesDate           = newResponse.yes_date

    fakedResponseFromRails.createdAt         = new Date().toISOString()
    fakedResponseFromRails.updatedAt         = new Date().toISOString()

    dispatch(callback({ ...fakedResponseFromRails, status: 'success' }))
  })
}

// Reducers
const response = (state, action) => {
  switch (action.type) {
    case UPDATE_RESPONSE:
      if(state.id != action.id) {
        return state
      }

      if(action.status && action.status == 'error') {
        console.log("ERROR", action)
        return state
      }

      if(action.status && action.status == 'success') {
        return {
          ...state,
          ...action
        }
      }

      // in-progress request.
      return {
        ...state,
        status: 'saving'
      }
  }
}

const responses = (state = [], action) => {
  switch (action.type) {
    case LOAD_RESPONSES:
      return action.responses || []
    case ADD_RESPONSE:
      if(action.status && action.status == 'success') {
        return state.concat(action)
      }
      return state
    case UPDATE_RESPONSE:
      return state.map(r =>
        response(r, action)
      )
    default:
      return state
  }
}

const scores = (state = [], action) => {
  switch(action.type) {
    case LOAD_SCORES:
      return action.scores || []
    default:
      return state
  }
}

const rmgFrontend = combineReducers({
  lookups:       lookups,
  responses:     responses,
  tab:           tabReducer,
  survey:        surveyReducer,
  coverageTypes: coverageTypesReducer,
  scores:        scores
})

const store = createStore(
  rmgFrontend,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() // TODO - strip this in production
)

export default store
