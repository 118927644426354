export const CHANGE_TAB = 'rmg/shared/ducks/tabs/CHANGE_TAB'

export default (state = 0, action) => {
  switch (action.type) {
    case CHANGE_TAB:
      return action.index
    default:
      return state
  }
}

export function changeTab(index) {
  return { type: CHANGE_TAB, index }
}
