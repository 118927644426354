import React from 'react'
import { connect } from 'react-redux'

import BaseFormWithAttachments from 'shared/components/base_form_with_attachments.jsx'

import DocumentUploadModal from './document_upload_modal.jsx'

import { formFieldsFromFileList } from '../ducks/business_continuity_document.js'
import { apiEndpoint } from '../ducks/rails.js'
import {
  getAttachmentsCount,
  asyncDeleteAttachment,
  asyncUpdateAttachments
} from '../ducks/attachments.js'
import { updateSucceeded } from '../ducks/wizard.js'

class DocumentUploadContainer extends BaseFormWithAttachments {
  constructor(props) {
    super(props)

    this.state = {
      submitting: false,
      documents: []
    }
  }

  componentDidMount() {
    super.componentDidMount()

    // Initialize document list here so we can call `determineUrlAndContentType`
    // on the pending docs we get from the file selector.
    // Can't do it in constructor since `determineUrlAndContentType` calls `setState`,
    // so we get this error: "Can only update a mounted or mounting component".
    this.setState(
      { documents: formFieldsFromFileList(this.props.files, this.props.allowableMimeTypes) },
      this.determineUrlAndContentType
    )
  }

  handleAddFiles = (files) => {
    let filesArray = Array.from(files)

    this.setState(prevState => (
      {
        documents: [
          ...prevState.documents,
          ...formFieldsFromFileList(filesArray, this.props.allowableMimeTypes)
        ]
      }),
      this.determineUrlAndContentType
    )
  }

  handleTitleChangeFor = (attachment) => {
    return this.handleFieldChangeInList("documents", attachment.id || attachment._id, "title", () => {
      this.handleFieldChangeInList("documents", attachment.id || attachment._id, "changed")(true)
    })
  }

  handleHideModal = () => {
    this.props.cancelUploadingFiles()
  }

  render() {
    return (
      <DocumentUploadModal
        documents={ this.state.documents }
        handleDeleteFor={ this.handleDeleteFor }
        handleSubmit={ this.handleSave }
        handleAddFiles={ this.handleAddFiles }
        handleTitleChangeFor={ this.handleTitleChangeFor }
        handleHideModal={ this.handleHideModal }
        submitting={ this.state.submitting } />
    )
  }
}

DocumentUploadContainer.defaultProps = {
  baseKey: 'businessContinuityDocument',
  listKey: 'documents',
  attachmentAttributes: ["_destroy", "id", "document", "title"]
}

DocumentUploadContainer = connect(
  (state, props) => ({
    updating:         state.wizard.updating,
    updateSucceeded:  state.wizard.updateSucceeded,

    allowableMimeTypes: state.rails.allowableMimeTypes,
    apiEndpoint:        apiEndpoint(state.rails, "business_continuity_document")
  }),
  null,
  ({ apiEndpoint, ...stateProps }, { dispatch }, props) => ({
    ...stateProps,
    ...props,

    asyncUpdateAttachments: (data, options) => {
      return asyncUpdateAttachments(apiEndpoint.path, data, options)(dispatch)
    },

    asyncDeleteAttachment: (id, documentsCount) => {
      return asyncDeleteAttachment(apiEndpoint.path, id, documentsCount)(dispatch)
    },

    dispatchUpdateSucceeded: (attachment) => {
      props.finishUploadingFiles()
      return dispatch(updateSucceeded()) // even though some attachments could fail, we'll handle that case-by-case
    }
  })
)(DocumentUploadContainer)

export default DocumentUploadContainer
