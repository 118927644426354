import React from 'react'
import { connect } from 'react-redux'

let PanelControls = ({ actionMessage, updating, children }) => (
  <div className="panel-controls">
    <div className="row">

      <div className="col-xs-6">
        <div className="pull-left">
          {
            updating &&
              <em>
                <i className="fa fa-spinner fa-spin fa-fw text-primary" aria-hidden="true">
                </i> { actionMessage || "Saving..." }
              </em>
          }
        </div>
      </div>

      <div className="col-xs-6">
        <div className="pull-right">
          { children }
        </div>
      </div>
    </div>
  </div>
)

PanelControls = connect(
  (state, props) => ({
    updating: state.wizard.updating
  })
)(PanelControls)

export default PanelControls
