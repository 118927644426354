import React from 'react'
import { connect } from 'react-redux'

let PanelBody = ({ flash, children }) => (
  <div className="panel-body-right-inner-padding">

    {
      flash &&
        <div className="row">
          <div className="col-xs-12">

            {/* we've got some jquery in init.js that cleans up success flash messages, the `keep`
                class opts us out of that */}
            <div className={ `flash alert alert-${flash.type} keep` }>
              <p>{ flash.message }</p>

              { flash.details && flash.details.length > 0 && <br /> }
              {
                flash.details && flash.details.length > 0 &&
                  <ul className="list-unstyled">
                    {
                      flash.details.map((error, index) => (
                        <li key={ index }>{ error }</li>
                      ))
                    }
                  </ul>
              }
            </div>
          </div>
        </div>
    }

    { children }
  </div>
)

PanelBody = connect(
  (state, props) => ({
    flash: state.wizard.flash
  })
)(PanelBody);

export default PanelBody
