import React from 'react'
import AttachmentsForm from './attachments_form.jsx'

class AttachmentsNew extends React.Component {
  render() {
    return (
      <AttachmentsForm addingNewAttachments={ true } attachments={ [] } />
    )
  }
}

export default AttachmentsNew