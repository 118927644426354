import React from "react";

import RailsApi from "shared/utils/rails_api.js";
import BaseForm from "shared/components/base_form.jsx";
import {
  FormField,
  TextField,
  RadioField,
  SelectField,
} from "shared/components/fields";
import OneAsyncSelectToRuleThemAll from "shared/components/one_async_select_to_rule_them_all.jsx";
import { requiredValidator } from "shared/utils/validators";
import BootstrapModal from "shared/components/bootstrap_modal.jsx";

import { apiEndpoint } from "../shared/ducks/rails.js";

const requiredIfForm300A = (value, state) => {
  if (state.oshaForm.value === "300A") {
    return requiredValidator()(value);
  }
};

class App extends BaseForm {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = () => {
    return {
      export: {
        year: new FormField(this.props.export.year, [requiredValidator()]),

        oshaForm: new FormField(
          this.props.export.oshaForm,
          this.isForPdf() ? [requiredValidator()] : []
        ),
        oshaLocationId: new FormField(
          this.props.export.oshaLocationId,
          this.isForPdf() ? [requiredValidator()] : []
        ),

        averageNumberOfEmployees: new FormField(
          this.props.export.averageNumberOfEmployees,
          this.isForPdf() ? [requiredIfForm300A] : []
        ),
        totalHoursWorkedByEmployees: new FormField(
          this.props.export.totalHoursWorkedByEmployees,
          this.isForPdf() ? [requiredIfForm300A] : []
        ),
      },

      open: false,
      updating: false,
      flash: null,
      incompleteIncidents: [],
    };
  };

  isForCsv = () => this.props.type === "csv";
  isForPdf = () => this.props.type === "pdf";

  handleModalClose = () => {
    this.setState(this.initialState());
  };

  handleChangeFor = (name) => this.handleFieldChange("export", name);

  prepareData = (ignoreAnyIncidentsIncompleteWarning) => {
    let copy = { export: { ...this.state.export } };

    // turn FormField objects in to just the raw value
    Object.keys(copy.export).map((key) => {
      copy.export[key] = copy.export[key].value;
    });

    copy.export.type = this.props.type;
    copy.export.ignoreAnyIncidentsIncompleteWarning =
      ignoreAnyIncidentsIncompleteWarning;

    return copy;
  };

  handleSubmit =
    ({ ignoreAnyIncidentsIncompleteWarning } = {}) =>
    (event) => {
      event.preventDefault();
      event.target.blur();

      this.setState({ updating: true }, () => {
        if (this.validateFields("export")) {
          const { path, method } = apiEndpoint(this.props.meta, "export");
          const data = this.prepareData(ignoreAnyIncidentsIncompleteWarning);

          RailsApi.talk(path, method, { body: data })
            .then((response) => {
              const contentType = response.headers.get("content-type");

              if (contentType && contentType.includes("application/json")) {
                return response.json().then((json) => {
                  return response.ok ? json : Promise.reject(json);
                });
              }

              // we know rails will always give us back json if it fails, so don't even bother to
              // check ok here. hence why our catch assumes it'll get json data.
              return Promise.all([response.arrayBuffer(), response]);
            })
            .then(([buffer, response]) => {
              const contentType = response.headers.get("content-type");

              const filename = ((contentDisposition) => {
                // should look something like `attachment; filename="osha_300.csv"` so look for
                // filename in quotes. we expect rails to set it.
                return /"([^"]*)"/.exec(contentDisposition)[1];
              })(response.headers.get("content-disposition"));

              const blob = new Blob([buffer], { type: contentType });

              if (navigator.msSaveOrOpenBlob) {
                navigator.msSaveOrOpenBlob(blob, filename);
              } else {
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }

              this.setState({ open: false, updating: false });
            })
            .catch((json) => {
              if (json.warning && json.warning.incomplete_incidents) {
                this.setState({
                  incompleteIncidents: json.warning.incomplete_incidents,
                  updating: false,
                });
              }

              this.setState({
                flash: { type: "danger", ...json.error },
                updating: false,
              });
            });
        } else {
          this.setState({ updating: false });
        }
      });
    };

  render() {
    return (
      <div>
        <a
          className="btn btn-sm btn-brand-yellow btn-with-icon external-link"
          onClick={() => {
            this.setState({ open: true });
          }}
        >
          {this.isForCsv() ? "Export CSV" : "Export PDF"}
        </a>

        {this.state.open && (
          <BootstrapModal title="New Export" handleHide={this.handleModalClose}>
            {this.renderForm()}
          </BootstrapModal>
        )}
      </div>
    );
  }

  renderForm() {
    if (this.state.incompleteIncidents.length > 0) {
      return this.warnAboutIncompleteIncidentsBeforeExporting();
    }

    return (
      <div>
        {this.state.flash && (
          <div className="row">
            <div className="col-xs-12">
              {/* we've got some jquery in init.js that cleans up success flash messages, the `keep`
                    class opts us out of that */}
              <div
                className={`flash alert alert-${this.state.flash.type} keep`}
              >
                <p>{this.state.flash.message}</p>

                {this.state.flash.details &&
                  this.state.flash.details.length > 0 && <br />}
                {this.state.flash.details &&
                  this.state.flash.details.length > 0 && (
                    <ul className="list-unstyled">
                      {this.state.flash.details.map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  )}
              </div>
            </div>
          </div>
        )}

        {this.isForCsv() && (
          <div className="row" style={{ marginBottom: "2.5rem" }}>
            <div className="col-xs-12">
              <p>
                Note: This format does not meet the formatting requirements for
                uploading to OSHA.
              </p>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-xs-12">
            <SelectField
              name="year"
              className="form-control-state"
              onChange={this.handleChangeFor("year")}
              label="Year"
              placeholder="Select..."
              value={this.state.export.year.value}
              errorMessage={this.state.export.year.errorMessage}
              options={this.props.meta.yearOptions.map((y) => ({
                displayText: y,
                value: y,
              }))}
              required={true}
            />
          </div>
        </div>

        {this.isForPdf() && (
          <div>
            <div className="row">
              <div className="col-xs-12">
                <RadioField
                  name="oshaForm"
                  onChange={this.handleChangeFor("oshaForm")}
                  label="What type of OSHA form do you want to export?"
                  value={this.state.export.oshaForm.value}
                  errorMessage={this.state.export.oshaForm.errorMessage}
                  options={[
                    { displayText: "300", value: "300" },
                    { displayText: "300A", value: "300A" },
                  ]}
                  required={true}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <div
                  className={`form-group is-required ${
                    this.state.export.oshaLocationId.errorMessage
                      ? "has-error"
                      : ""
                  }`}
                >
                  <OneAsyncSelectToRuleThemAll.Base
                    labelText="Location / Department"
                    value={this.state.export.oshaLocationId.value}
                    onChange={this.handleChangeFor("oshaLocationId")}
                    loadOptions={() => {
                      return Promise.resolve({
                        options: this.props.meta.locationOptions,
                        complete: true,
                      });
                    }}
                    simpleValue={true}
                    requiredInput={true}
                  />

                  {this.state.export.oshaLocationId.errorMessage && (
                    <span className="field-level-error">
                      {this.state.export.oshaLocationId.errorMessage}
                    </span>
                  )}
                </div>
              </div>
            </div>

            {this.state.export.oshaForm.value === "300A" && (
              <div className="row">
                <div className="col-xs-12">
                  <TextField
                    name="averageNumberOfEmployees"
                    onChange={this.handleChangeFor("averageNumberOfEmployees")}
                    label="Average Number of Employees"
                    value={this.state.export.averageNumberOfEmployees.value}
                    errorMessage={
                      this.state.export.averageNumberOfEmployees.errorMessage
                    }
                    required={true}
                  />
                </div>

                <div className="col-xs-12">
                  <TextField
                    name="totalHoursWorkedByEmployees"
                    onChange={this.handleChangeFor(
                      "totalHoursWorkedByEmployees"
                    )}
                    label="Total Hours Worked by Employees"
                    value={this.state.export.totalHoursWorkedByEmployees.value}
                    errorMessage={
                      this.state.export.totalHoursWorkedByEmployees.errorMessage
                    }
                    required={true}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        <div className="row">
          <div className="col-xs-12">
            <button
              type="button"
              onClick={this.handleSubmit()}
              className={`btn btn-muted-blue ${
                this.state.updating ? "disabled" : ""
              }`}
              style={{ width: "100%" }}
            >
              {this.isForCsv() ? "Download CSV" : "Download PDF"}
            </button>
          </div>
        </div>
      </div>
    );
  }

  warnAboutIncompleteIncidentsBeforeExporting = () => {
    return (
      <div>
        <div className="row">
          <div className="col-xs-12">
            <div className="flash alert alert-warning keep">
              <p>
                Some of the fields within your injury logs may be incomplete.
                Would you like to continue?
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <h4 style={{ marginBottom: "1rem" }}>Injury Log</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <table
              className="table table-striped"
              style={{ borderBottom: "none" }}
            >
              <thead>
                <tr>
                  <th>Case #</th>
                  <th>Date</th>
                  <th>Employee</th>
                  <th>Location</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.incompleteIncidents.map((incident) => {
                  return (
                    <tr key={incident.id}>
                      <td>{incident.case_number}</td>
                      <td>{incident.date_of_incident}</td>
                      <td>{incident.employee_name}</td>
                      <td>{incident.location_name}</td>
                      <td style={{ textAlign: "center" }}>
                        <a href={incident.edit_path}>View injury</a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <button
              type="button"
              onClick={this.handleSubmit({
                ignoreAnyIncidentsIncompleteWarning: true,
              })}
              className={`btn btn-muted-blue ${
                this.state.updating ? "disabled" : ""
              }`}
              style={{ width: "100%", marginTop: "2.5rem" }}
            >
              {"Continue"}
            </button>
          </div>
        </div>
      </div>
    );
  };
}

export default App;
