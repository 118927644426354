import React from "react";
import { connect } from "react-redux";
import CyberLinkWidget from "../helpers/cyber_link_widget.jsx";

import { policyYear } from "apps/cyber/shared/ducks/survey.js";
import { policyYearRange } from "shared/utils/policy_year.js";

let RmgHeader = ({ renderAsReadOnly, organization, policyYear, cyberLink }) => (
    <div>
        <p data-behavior="printed-at-timestamp" style={{ display: "none" }}></p>

        <div className="page-header">
            <h1>Cybersecurity Assessment</h1>
        </div>

        {renderAsReadOnly && organization && (
            <div>
                <hr />
                <div className="alert alert-info clearfix">
                    <h3 className="pull-left no-margin-bottom no-margin-top">
                        {organization.name}
                    </h3>
                    <span className="label label-sm label-right label-danger">
            Read-only
          </span>
                    <strong className="pull-right">{policyYearRange(policyYear)}</strong>
                </div>
            </div>
        )}

        <div className="row">
            <div className="rmg-instructional-text col-sm-9">
                <p>
                    Local public entities, like any organization, should evaluate existing in-place controls and examine
                    additional controls to reduce the likelihood and/or impact of a cybersecurity incident.
                    It is important these evaluations include technical, management, and legal personnel.
                    Please discuss any questions or concerns with VRSA as we continue to tailor our broadest
                    protections and limits to meet your entity’s coverage expectations.
                </p>
                <p>
          <span className="small-text">
          </span>
                </p>
            </div>

            <CyberLinkWidget/>
        </div>
    </div>
);

export default connect((state, props) => {
    console.log(state.cyberLink)
    return {
        policyYear: policyYear(state.survey),
        renderAsReadOnly: state.lookups.renderAsReadOnly,
        organization: state.lookups.organization,
        cyberLink: state.cyberLink
    };
})(RmgHeader);


