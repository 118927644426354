import React from 'react'
import { connect } from 'react-redux'

import EditableSurveyTabLink from './editable_survey_tab_link.jsx'

let EditableSurveyTabList = ({ coverageTypes }) => (
    <ul className="panel-body-left nav nav-stacked nav-sidebar">
        {
            coverageTypes.map((coverage, index) => (
                <EditableSurveyTabLink key={ coverage.id } coverage={ coverage } index={ index } />
            ))
        }
    </ul>
)

EditableSurveyTabList = connect(
    (state, props) => ({
        coverageTypes: state.coverageTypes
    })
)(EditableSurveyTabList)

export default EditableSurveyTabList
