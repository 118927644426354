import React from 'react'
import { connect } from 'react-redux'
import { startUpdateResponse, pretendToSendResponseToRails } from '../store'

import RmgDatePicker from '../helpers/rmg_date_picker.jsx'
import RmgCommentBox from '../helpers/rmg_comment_box.jsx'

import {
    constructRailsDateTime,
    convertToDate,
    extractDateFromRailsDateTime } from 'shared/utils/rails_datetime_formatters.js'

class SurveyAnswer extends React.Component {
    constructor(props) {
        super(props)
        this.handleAndSaveUpdate = this.handleAndSaveUpdate.bind(this)
        this.dateHasError        = this.dateHasError.bind(this)

        // Changes are saved to local state as a way to encapsulate and
        // send off the whole answer object at once in handleAndSaveUpdate
        this.state = {
            id: props.answer.id,
            answer: props.answer.answer,
            not_applicable: false,
            section_question_id: props.question,
            yes_date: props.answer.yesDate,
            comment: props.answer.comment
        }
    }

    componentWillReceiveProps(nextProps) {
        // If we just added this answer, need to update local state with the new ID
        if(nextProps.answer.id != this.props.answer.id) {
            this.setState({ id: nextProps.answer.id })
        }
    }

    handleAndSaveUpdate(event) {
        const target = event.target
        let value    = target.type === 'checkbox' ? target.checked : target.value
        let name     = target.getAttribute('data-field') ? target.getAttribute('data-field') : target.name

        if( target.getAttribute("data-behavior") === 'datepicker' ) {
            // if the date is an empty string (which can happen if the user completely deleted their date), set
            //the date to be null. Otherwise, get the correctly formatted date.
            if (value === "") {
                value = null
            }
            else{
                value = constructRailsDateTime(value, null)
            }
        }

        // if we're switching the answer to "No" or "N/A", clear out the "yes_date" and "comment" fields
        if ( name === 'answer' && ( value === 'No' || value === 'N/A' ) ) {
            this.setState( { yes_date: null, comment: null } )
        }

        this.setState({ [name]: value }, () => {
            if (!this.dateHasError()) {
                this.props.handleUpdate(this.state)
            }
        })
    }

    dateHasError() {
        const { requireDateWhenAnsweringYes, earliestAllowedDateForYes } = this.props

        // we can bail in this situation, because there's nothing to check
        if (this.state.answer !== 'Yes') { return false }

        let error = false

        if (requireDateWhenAnsweringYes) {
            if (!this.state.yes_date) { // right? null, undefined, "", etc. bad date
                error = 'Required'
            }

            // if we have a limitation on the earliest allowed date
            // show that error now
            if (!error && earliestAllowedDateForYes) {
                let _earliestAllowedDateForYes = convertToDate(earliestAllowedDateForYes)
                let _yes_date = convertToDate(this.state.yes_date)

                if (_yes_date < _earliestAllowedDateForYes) {
                    error = `Date can't be before ${extractDateFromRailsDateTime(earliestAllowedDateForYes)}`
                }
            }
        }

        if (!error && convertToDate(this.state.yes_date) > new Date()) {
            error = "Date can't be in the future"
        }

        if (!error && this.state.yes_date == 'Invalid date') {
            error = "Invalid date"
        }

        return error
    }

    render() {
        let { requireYesNoAnswer,
            allowNotApplicableAnswer,
            requireDateWhenAnsweringYes,
            answer,
            date,
            comment,
            enabled
        } = this.props

        let answerChoice = (answer = {}, choice, enabled = true) => {
            return (
                <label className="radio-inline">
                    <input type="radio"
                           onChange={ this.handleAndSaveUpdate }
                           name={ `answer_${ answer.section_question_id }_${ answer.id || Date.now() }` }
                           value={ choice }
                           disabled={ !enabled }
                           checked={ answer.answer == choice }
                           data-field='answer' />
                    { choice }
                </label>
            )
        }

        return (
            <div className={ `col-sm-12 status-${answer.status}` }>
                <div className="row">
                    <div className="col-sm-11">
                        { requireYesNoAnswer &&
                            ['Yes', 'No'].map((choice, ix) =>
                                <span key={ ix }>{ answerChoice(this.state, choice, enabled) }</span>
                            ) }

                        { requireYesNoAnswer && allowNotApplicableAnswer &&
                            answerChoice(this.state, 'N/A', enabled) }
                    </div>
                </div>
                {/* for example ;) */}
                { /*answer.status == 'saving' && <span>Saving...</span>*/ }

                { (this.state.answer == 'Yes' || this.state.answer == 'No' ) && enabled &&
                    <div className="row answer-date-and-comment">
                        { this.state.answer == 'Yes' && requireDateWhenAnsweringYes &&
                            <div className="col-xs-6">
                                <RmgDatePicker requiredInput={ requireDateWhenAnsweringYes }
                                               dateName="yes_date"
                                               dateValue={ this.props.answer.yesDate }
                                               onBlur={ this.handleAndSaveUpdate }
                                               error={ this.dateHasError() }
                                               earliestAllowedDateForYes={
                                                   extractDateFromRailsDateTime(this.props.earliestAllowedDateForYes)
                                               }
                                />
                            </div>
                        }

                        <div className="col-xs-6">
                            <RmgCommentBox comment={ this.state.comment } onBlur={ this.handleAndSaveUpdate } />
                        </div>
                    </div>
                }
            </div>
        )
    }
}

SurveyAnswer = connect(
    (state, props) => ({
        enabled: !state.lookups.renderAsReadOnly && props.status == 'enabled',
        answer: state.responses.find((response) => response.sectionQuestionId == props.question) || {},

        renderAsPreview: state.lookups.renderAsPreview,
        renderAsReadOnly: state.lookups.renderAsReadOnly
    }),
    null,
    ({ renderAsPreview, renderAsReadOnly, ...stateProps }, { dispatch }, ownProps) => ({
        ...stateProps,
        ...ownProps,

        handleUpdate: (response) => {
            renderAsPreview || renderAsReadOnly ? pretendToSendResponseToRails(dispatch, response) :
                startUpdateResponse(dispatch, response)
        }
    })
)(SurveyAnswer)


export default SurveyAnswer
