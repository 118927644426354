import React from 'react'
import Row from 'shared/components/summary/row.jsx'
import BasicColumn from 'shared/components/summary/basic_column.jsx'

const AttachmentCard = ({ attachment: { attachmentUrl, documentFileName, comment } }) => (
  <div className="card">
    <header className="card-header">
      <h5 className="card-title">
        { attachmentUrl
          ? <a target="_blank" href={attachmentUrl}>{documentFileName}</a>
          : documentFileName
        }
      </h5>
      { attachmentUrl &&
        <a className="card-header-controls hide-when-printing" target="_blank" href={attachmentUrl}>
          <i className='pull-right fa fa-download' />
        </a>
      }
    </header>

    <div className="card-body">
      <Row>
        <BasicColumn label="Comment" value={ comment || "N/A" } />
      </Row>
    </div>

    <footer className="card-footer"></footer>
  </div>
)

export default AttachmentCard
