import React from 'react'
import { Switch, Route, Link, Redirect } from 'react-router-dom'

import WitnessesIndex from './witnesses_index.jsx'
import WitnessesNew from './witnesses_new.jsx'
import WitnessesEdit from './witnesses_edit.jsx'

let WitnessesRouter = () => (
  <Switch>
    <Route exact path="/witnesses"          component={ WitnessesIndex } />
    <Route exact path="/witnesses/new"      component={ WitnessesNew } />
    <Route       path="/witnesses/:id/edit" component={ WitnessesEdit } />
  </Switch>
)

export default WitnessesRouter
