import React from 'react'
import { connect } from 'react-redux'
import { Link, Prompt, Redirect } from 'react-router-dom'

import { Panel, PanelSidebar, PanelRightColumn, PanelBody, PanelControls } from '../panel'
import { getPropertiesCount } from '../ducks/properties.js'

import PropertiesPrompt from './properties_prompt.jsx'
import PropertiesForm from './properties_form.jsx'
import PropertiesHeader from './properties_header.jsx'

class PropertiesNew extends React.Component {
  constructor(props) {
    super(props)

    this.state = { incidentReport: { wereAnyPropertiesDamaged: this.props.wereAnyPropertiesDamaged } }
  }

  renderState = () => {
    if (this.props.propertiesCount === 0 && !this.state.incidentReport.wereAnyPropertiesDamaged) {
      return "prompt"
    }
    else if (this.props.propertiesCount === 0 && this.state.incidentReport.wereAnyPropertiesDamaged) {
      return "prompt_with_form"
    }
    else {
      return "form"
    }
  }

  toggleWereAnyPropertiesDamaged = (value) => {
    this.setState((prevState, props) => ({
      incidentReport: { ...prevState.incidentReport, wereAnyPropertiesDamaged: value }
    }))
  }

  render() {
    switch (this.renderState()) {
      case "prompt": {
        return (
          <Panel>
            <PanelSidebar />

            <PanelRightColumn>
              <PanelBody>
                <PropertiesHeader />

                <div className="row">
                  <div className="col-xs-12">
                    <PropertiesPrompt onChange={ this.toggleWereAnyPropertiesDamaged }
                                      value={ this.state.incidentReport.wereAnyPropertiesDamaged } />
                  </div>
                </div>
              </PanelBody>

              <PanelControls>
                <Link to="/vehicles">
                  <button type="button"
                          className="btn btn-link">

                    Back
                  </button>
                </Link>

                <Link to="/witnesses">
                  <button type="button"
                          className="btn btn-success">

                    Next
                  </button>
                </Link>
              </PanelControls>
            </PanelRightColumn>
          </Panel>
        )
      }

      case "prompt_with_form": {
        return <PropertiesForm withPrompt
                               promptValue={ this.state.incidentReport.wereAnyPropertiesDamaged }
                               onPromptChange={ this.toggleWereAnyPropertiesDamaged }
                               incidentReport={{ ...this.state.incidentReport }} />
      }

      default: {
        return <PropertiesForm />
      }
    }
  }
}

PropertiesNew = connect(
  (state, props) => ({
    propertiesCount: getPropertiesCount(state.properties),
    wereAnyPropertiesDamaged: state.incidentReport.wereAnyPropertiesDamaged
  })
)(PropertiesNew);

export default PropertiesNew
