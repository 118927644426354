import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import { getQuestionCount } from 'apps/rmg/shared/ducks/survey.js'
import { activeCoverage } from 'apps/rmg/shared/ducks/coverage_types.js'

let QuestionCount = ({ questionCount, active }) => {
  // this is a section with 0 questions, no need for a badge
  if (questionCount === 0) { return null }

  return (
    <div className={ `number-box child-highlight ${active ? 'active' : ''}` }>
      <div>
        <span>{ questionCount }</span>
      </div>
    </div>
  )
}

QuestionCount.propTypes = {
  coverage: PropTypes.object.isRequired
}

QuestionCount = connect(
  (state, props) => {
    return {
      questionCount: getQuestionCount(state.survey, props.coverage),
      active:        activeCoverage(state.coverageTypes, state.tab).id == props.coverage.id
    }
  }
)(QuestionCount)

export default QuestionCount
