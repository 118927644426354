import React from 'react'
import PropTypes from 'prop-types';
import { reduxForm, submit as submitReduxForm } from 'redux-form'
import { connect } from 'react-redux'

import { changeToSuccessStatus, changeToFailStatus } from '../store.js'
import { submit as submitToRails } from '../utils/submit.js'

import Fields from './fields.jsx'

class Form extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { handleSubmit, onSubmit, children } = this.props;

    return (
      <form onSubmit={ handleSubmit(onSubmit) }>
        <Fields />
      </form>
    )
  }
}

// -------- misc helper functions --------
const validate = (values, props) => {
  let errors = {}

  if (!values.file) {
    errors.file = "Required"
  } else if (values.file.size > 20971520) {
    errors.file = "File is too large (> 20 MB)"
  }

  if (!values.title) {
    errors.title = "Required"
  }
  else if (values.title.length > 40) {
    errors.title = `Character limit: ${values.title.length}/40`
  }

  return errors
}

const asyncValidate = (values, dispatch, props) => {
  return new Promise((resolve, reject) => {
    if (!values.file) {
      reject({ file: "Required" })
    }
    else {
      let reader = new FileReader()

      reader.onloadend = () => {
        // stolen from: http://stackoverflow.com/a/21460351/1947079
        let contentType = reader.result.split(",")[0].split(":")[1].split(";")[0]

        resolve(contentType);
      }

      reader.readAsDataURL(values.file)
    }
  }).
  then((contentType) => {
    if (!props.allowableMimeTypes.includes(contentType)) {
      if (['.dmg', '.exe', '.app'].some((ext) => ( values.file.name.endsWith(ext) ))) {
        throw { file: 'File type not allowed (application/executable)' }
      }
      else {
        throw { file: `File type not allowed (${contentType})` }
      }
    }
  }).
  catch((error) => { throw error })
}
// ---------------------------------------

Form = reduxForm({
  form: "document",

  onSubmit: (values, dispatch, props) => {
    return submitToRails(values, props.submitPath, props.submitMethod)
  },

  onSubmitSuccess: (result, dispatch, props) => {
    dispatch(changeToSuccessStatus())
    props.notifyOnUploadSuccess(result)
  },

  onSubmitFail: (errors, dispatch, submitError) => {
    dispatch(changeToFailStatus())
  },

  initialValues: { documentType: "General to VRSA" }, // we're not allowing any others yet

  asyncValidate: asyncValidate,
  validate: validate
})(Form)

Form.propTypes = {
  allowableMimeTypes:    PropTypes.array.isRequired,
  notifyOnUploadSuccess: PropTypes.func.isRequired,
  submitPath:            PropTypes.string.isRequired,
  submitMethod:          PropTypes.string.isRequired
}

export default Form
