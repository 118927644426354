import React from 'react'
import { connect } from 'react-redux'
import { Link, Prompt, Redirect } from 'react-router-dom'

import BaseForm from 'shared/components/base_form.jsx'

import {
  FormField,
  TextField,
  PhoneNumberField,
  AddressField
} from 'shared/components/fields'

import {
  requiredValidator,
  phoneNumberValidator,
  emailValidator
} from 'shared/utils/validators'

import { asyncUpdateWitnesses } from '../ducks/witnesses.js'
import { apiEndpoint } from '../ducks/rails.js'
import { resetWizardState } from '../ducks/wizard.js'
import { Panel, PanelSidebar, PanelRightColumn, PanelBody, PanelControls } from '../panel'

import WitnessesPrompt from './witnesses_prompt.jsx'
import WitnessesHeader from './witnesses_header.jsx'

class WitnessesForm extends BaseForm {
  constructor(props) {
    super(props)

    const witness = { ...(props.witness || {}) }

    this.state = {
      witness: {
        firstName:      new FormField(witness.firstName),
        primaryPhone:   new FormField(witness.primaryPhone, phoneNumberValidator()),
        email:          new FormField(witness.email, emailValidator()),
        lastName:       new FormField(witness.lastName),
        alternatePhone: new FormField(witness.alternatePhone, phoneNumberValidator()),
        streetAddress:  new FormField(witness.streetAddress),
        optionalLine:   new FormField(witness.optionalLine),
        city:           new FormField(witness.city),
        state:          new FormField(witness.state),
        zip:            new FormField(witness.zip),
        statement:      new FormField(witness.statement)
      }
    }
  }

  handleChangeFor = (name) => ( this.handleFieldChange("witness", name) )

  componentWillUnmount() {
    this.props.resetWizardState()
  }

  prepareData = () => {
    let key = Date.now().toString()

    // the structure of what we wanna send back to rails for updating
    let data = {
      incidentReport: {
        ...(this.props.incidentReport || {}),
        witnessesAttributes: { [key]: {} } // new object to copy witness values into
      }
    }

    // if this witness has an id (i.e. rails already knows about them), add it in. new witnesses
    // don't need an id and will be given one by rails
    if ((this.props.witness || {}).id) {
      data.incidentReport.witnessesAttributes[key].id = (this.props.witness || {}).id
    }

    // collect our form values
    Object.keys(this.state.witness).map((field) => {
      data.incidentReport.witnessesAttributes[key][field] = this.state.witness[field].value
    })

    return data
  }

  handleSave = (event) => {
    event.preventDefault()

    if (this.validateFields("witness")) {
      this.props.asyncUpdateWitnesses(this.prepareData())
    }
  }

  renderControlGroup() {
    if (this.props.withPrompt) {
      return (
        <div>
          <button type="button"
                  className="btn btn-link"
                  onClick={ () => {
                    if (confirm("Are you sure you want to move away? Any unsaved changes " +
                                "made to this section will be lost.")) {
                      this.props.onPromptChange(false) // toggle the prompt back to "No"
                    }
                  }}>
            Cancel
          </button>

          <button type="button"
                  onClick={ this.handleSave }
                  className={ `btn btn-success ${this.props.updating ? "disabled" : ""}` }>

            Save to Claim
          </button>
        </div>
      )
    }
    else {
      return (
        <div>
          <Link to="/witnesses">
            <button type="button"
                    className="btn btn-link">

              Back
            </button>
          </Link>

          <button type="button"
                  onClick={ this.handleSave }
                  className={ `btn btn-success ${this.props.updating ? "disabled" : ""}` }>

            Save to Claim
          </button>
        </div>
      )
    }
  }

  render() {
    if (this.props.updateSucceeded) {
      return <Redirect to="/witnesses" />
    }

    return (
      <Panel>
        <PanelSidebar />

        <PanelRightColumn>
          <PanelBody>
            <WitnessesHeader />

            {
              this.props.withPrompt &&
                <div className="row">
                  <div className="col-xs-12">
                    <WitnessesPrompt onChange={ this.props.onPromptChange }
                                     value={ this.props.promptValue } />
                  </div>
                </div>
            }

            <hr />

            <div className="row">
              <div className="col-xs-12">
                <p>{ 'Who witnessed this occurrence and how can we contact them?' }</p>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-6">
                <TextField name="firstName"
                           onChange={ this.handleChangeFor("firstName") }
                           label="First Name"
                           value={ this.state.witness.firstName.value }
                           errorMessage={ this.state.witness.firstName.errorMessage }
                           tabIndex="1" />

                <PhoneNumberField name="primaryPhone"
                                  onChange={ this.handleChangeFor("primaryPhone") }
                                  label="Primary Phone"
                                  value={ this.state.witness.primaryPhone.value }
                                  errorMessage={ this.state.witness.primaryPhone.errorMessage }
                                  tabIndex="3" />

                <TextField name="email"
                           onChange={ this.handleChangeFor("email") }
                           label="Email Address"
                           value={ this.state.witness.email.value }
                           errorMessage={ this.state.witness.email.errorMessage }
                           tabIndex="5" />
              </div>

              <div className="col-xs-6">
                <TextField name="lastName"
                           onChange={ this.handleChangeFor("lastName") }
                           label="Last Name"
                           value={ this.state.witness.lastName.value }
                           errorMessage={ this.state.witness.lastName.errorMessage }
                           tabIndex="2" />

                <PhoneNumberField name="alternatePhone"
                                  onChange={ this.handleChangeFor("alternatePhone") }
                                  label="Alternate Phone"
                                  value={ this.state.witness.alternatePhone.value }
                                  errorMessage={ this.state.witness.alternatePhone.errorMessage }
                                  tabIndex="4" />
              </div>

              <div className="col-xs-12">
                <AddressField tabIndex="6"
                              fields={{
                                streetAddress: {
                                  name: "streetAddress",
                                  value: this.state.witness.streetAddress.value,
                                  errorMessage: this.state.witness.streetAddress.errorMessage,
                                  onChange: this.handleChangeFor("streetAddress")
                                },
                                optionalLine: {
                                  name: "optionalLine",
                                  value: this.state.witness.optionalLine.value,
                                  errorMessage: this.state.witness.optionalLine.errorMessage,
                                  onChange: this.handleChangeFor("optionalLine")
                                },
                                city: {
                                  name: "city",
                                  value: this.state.witness.city.value,
                                  errorMessage: this.state.witness.city.errorMessage,
                                  onChange: this.handleChangeFor("city")
                                },
                                state: {
                                  name: "state",
                                  value: this.state.witness.state.value,
                                  errorMessage: this.state.witness.state.errorMessage,
                                  onChange: this.handleChangeFor("state")
                                },
                                zip: {
                                  name: "zip",
                                  value: this.state.witness.zip.value,
                                  errorMessage: this.state.witness.zip.errorMessage,
                                  onChange: this.handleChangeFor("zip")
                                }
                              }} />

                <p>{ 'Please describe this witness\' statement regarding this occurence.' }</p>

                <TextField type="textarea"
                           name="statement"
                           onChange={ this.handleChangeFor("statement") }
                           label="Type..."
                           value={ this.state.witness.statement.value }
                           errorMessage={ this.state.witness.statement.errorMessage }
                           tabIndex="7" />
              </div>
            </div>
          </PanelBody>

          <PanelControls>
            <Prompt when={ this.anyChangesMade("witness", this.props.witness) }
                    message="Are you sure you want to move away? Any unsaved changes
                             made to this section will be lost." />

            { this.renderControlGroup() }
          </PanelControls>
        </PanelRightColumn>
      </Panel>
    )
  }
}

WitnessesForm = connect(
  (state, props) => ({
    updateSucceeded: state.wizard.updateSucceeded,
    updating:        state.wizard.updating,
    apiEndpoint:     apiEndpoint(state.rails, "incident_report")
  }),
  null,
  ({ apiEndpoint, ...stateProps }, { dispatch }, props) => ({
    ...stateProps,
    ...props,

    asyncUpdateWitnesses: (data) => {
      return asyncUpdateWitnesses(apiEndpoint.path, data)(dispatch)
    },

    resetWizardState: () => { dispatch(resetWizardState()) }
  })
)(WitnessesForm);

export default WitnessesForm
