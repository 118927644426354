export const filterDestroyedRecords = (nestedAttributes) => {
  if (nestedAttributes === null || nestedAttributes === undefined) { return {}; }

  // rails expects you to submit a record with the "_destroy" => true param to delete a
  // record, so if we have those it means the user deleted the record and at times we don't
  // need to show it
  //
  let copy = JSON.parse(JSON.stringify(nestedAttributes));

  return Object.keys(copy).filter((key) => (
           !Object.keys(copy[key]).includes("_destroy")
         )).
         reduce((r, key) => (r[key] = copy[key], r), {});
}

export const persistedClaimants = (nestedAttributes) => {
  if (nestedAttributes === null || nestedAttributes === undefined) { return {}; }

  let copy = JSON.parse(JSON.stringify(nestedAttributes));

  return Object.keys(copy).filter((key) => (
           Object.keys(copy[key]).includes("id") && !Object.keys(copy[key]).includes("_destroy")
         )).
         reduce((r, key) => (r[key] = copy[key], r), {});
}

export const recordWithId = (id, collection) => {
  return Object.values(collection).find((record) => (
    record.id === id
  ));
}
