import React from 'react'
import { connect } from 'react-redux'

import FloatingLabelInputs from 'shared/components/floating_label_inputs.jsx'

const ProgramSelect = ({ onChange, programs, value }) => (
    <div className="form-group">
        <FloatingLabelInputs.Select
            label="Associated Program"
            name="programId"
            className="form-control"
            value={ value || '' }
            onChange={ onChange }
        >
            <option></option>
            {
                programs.map( (program) => (
                    <option key={ program.id } value={ program.id }>
                        { program.name }
                    </option>
                ))
            }
        </FloatingLabelInputs.Select>
    </div>
)

export default connect(
    (state, props) => ({
        programs: Object.values(state.lookups.programs)
    })
)(ProgramSelect)
