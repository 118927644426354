import React from 'react'
import PropTypes from 'prop-types';

import BootstrapModal from 'shared/components/move_off_of_eventually_bootstrap_modal.jsx'
import Form from './form.jsx'
import FormButtonGroup from './form_button_group.jsx'

class Container extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <BootstrapModal title="Upload Document"
        body={
          <Form notifyOnUploadSuccess={ this.props.notifyOnUploadSuccess }
                allowableMimeTypes={ this.props.allowableMimeTypes }
                submitPath={ this.props.submitPath }
                submitMethod={ this.props.submitMethod }
          />
        }
        footer={ <FormButtonGroup /> }
        handleHide={ this.props.finishUploadingAFile }
      />
    )
  }
}

Container.propTypes = {
  finishUploadingAFile: PropTypes.func.isRequired
}

export default Container;
