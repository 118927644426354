import RailsApi from 'shared/utils/rails_api.js'

import { refreshMetaData } from './rails.js'
import { setErrorFlash, setSuccessFlash, updateSucceeded, updateFailed, updateStarted } from './wizard.js'
import { updateLineItems } from './line_items.js'
import { updateEmailsToNotify } from './emails_to_notify.js'

const LOAD   = "grant_request_form/grantRequest/LOAD"
const UPDATE = "grant_request_form/grantRequest/UPDATE"

export default function grantRequest(state = {}, action) {
  switch (action.type) {
    case LOAD: {
      return { ...action.grantRequest }
    }

    case UPDATE: {
      return { ...state, ...action.attributes }
    }

    default: {
      return state
    }
  }
}

export const loadGrantRequest = (grantRequest) => {
  return { type: LOAD, grantRequest: grantRequest }
}

export const updateGrantRequest = (grantRequest) => {
  const {
    lineItemsAttributes,
    emailsToNotifyAttributes,
    attachmentsAttributes,
    associatedCoveragesAttributes,
    ...attributes
  } = grantRequest

  return { type: UPDATE, attributes: attributes }
}

export const asyncUpdateGrantRequest = (url, data) => (dispatch) => {
  dispatch(updateStarted())

  RailsApi.patch(url, { body: data }).
    then((json) => {
      dispatch({
        type: 'RAILS_UPDATE_SUCCEEDED',
        actions: [
          refreshMetaData({ ...json.meta }),
          updateGrantRequest(json.grantRequest),
          updateLineItems(json.grantRequest.lineItemsAttributes),
          updateEmailsToNotify(json.grantRequest.emailsToNotifyAttributes),
          updateSucceeded()
        ]
      })
    }).
    catch((json) => {
      dispatch({
        type: 'RAILS_UPDATE_FAILED',
        actions: [
          updateFailed(),
          setErrorFlash(json.error.message, { details: json.error.details })
        ]
      })
    })
}
