import React from 'react'
import TextField from './text_field.jsx'

const normalizeSsn = (value) => {
  if (!value) {
    return "";
  }

  return value.replace(/[\D]/g, ''). // only numbers
    slice(0, 9); // only 9 of them
};

const formatSsn = (value) => {
  if (!value) {
    return "";
  }

  if (value.length <= 3) {
    return value;
  }

  if (value.length <= 5) {
    return `${value.slice(0, 3)}-${value.slice(3)}`;
  }

  return `${value.slice(0, 3)}-${value.slice(3, 5)}-${value.slice(5, 9)}`;
};

export default class SsnField extends React.Component {
  constructor(props) {
    super(props)

    this.state = { focused: false }
  }

  onChange = (value) => {
    this.props.onChange(normalizeSsn(value))
  }

  onFocus = () => {
    this.setState({ focused: true })
  }

  onBlur = () => {
    this.setState({ focused: false })
  }

  render() {
    const { onChange, value, ...props } = this.props

    return <TextField { ...props } type={ this.state.focused ? "text" : "password" }
                                   value={ formatSsn(value) }
                                   onChange={ this.onChange }
                                   onFocus={ this.onFocus }
                                   onBlur={ this.onBlur } />
  }
}
