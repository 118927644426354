import React from 'react'
import PropTypes from 'prop-types';

export default class CheckboxField extends React.Component {
  constructor(props) {
    super(props);
  }

  onChange = (event) => {
    this.props.onChange(event.target.checked);
  }

  render() {
    const {
      name,
      required,
      withoutBootstrapMarkup,
      bootstrapWrapperClassName,
      className,
      errorMessage,
      label,
      value,
      tabIndex,
      onChange
    } = this.props


    let wrapperClass = [].concat(bootstrapWrapperClassName || []).
    concat(errorMessage ? "has-error" : "").
    concat(required ? "is-required" : "").
    filter((n) => n).
    join(' ')

    let inputClass = [].concat(className || []).
    filter((n) => n).
    join(' ')

    return (
      <div className={ wrapperClass } style={{ marginBottom: '2.5rem' }}> {/* TODO - stolen from how .form-group styles */}
        {
          <div className="checkbox" >
            <label>
              <input type="checkbox"
                     className={ inputClass }
                     id={ name }
                     name={ name }
                     value={ value }
                     checked={ !!this.props.value }
                     tabIndex={ tabIndex }
                     onChange={ this.onChange } />
              { label }{ required ? <span></span> : "" }
            </label>
          </div>
        }
        { errorMessage && <span className="field-level-error">{ errorMessage }</span> }
      </div>

    )
  }
}

CheckboxField.defaultProps = {
  required: false
}

CheckboxField.propTypes = {
  name:                      PropTypes.string.isRequired,
  required:                  PropTypes.bool.isRequired,
  value:                     PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  bootstrapWrapperClassName: PropTypes.string,
  className:                 PropTypes.string,
  errorMessage:              PropTypes.string,
  label:                     PropTypes.string,
  instructions:              PropTypes.string,
  tabIndex:                  PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  onChange: (props) => {
    if (!props.readOnly) {
      if (typeof props.onChange !== 'function') {
        return new Error('If CheckboxField is not ReadOnly, then onChange must be defined');
      }
    }
  }
}
