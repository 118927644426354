import RailsApi from 'shared/utils/rails_api.js'

import { refreshMetaData } from './rails.js'
import { setErrorFlash, setSuccessFlash, updateSucceeded, updateFailed, updateStarted } from './wizard.js'
import { updatePersons } from './persons.js'

const LOAD   = "incident_report_form/incidentReport/LOAD"
const UPDATE = "incident_report_form/incidentReport/UPDATE"

export default function incidentReport(state = {}, action) {
  switch (action.type) {
    case LOAD: {
      return { ...action.incidentReport }
    }

    case UPDATE: {
      return { ...state, ...action.attributes }
    }

    default: {
      return state
    }
  }
}

export const loadIncidentReport = (incidentReport) => {
  return { type: LOAD, incidentReport: incidentReport }
}

export const updateIncidentReport = (incidentReport) => {
  const {
    injuredPersonsAttributes,
    damagedPropertiesAttributes,
    involvedVehiclesAttributes,
    witnessesAttributes,
    attachmentsAttributes,
    ...attributes
  } = incidentReport

  return { type: UPDATE, attributes: attributes }
}

export const asyncUpdateIncidentReport = (url, data) => (dispatch) => {
  dispatch(updateStarted())

  RailsApi.patch(url, { body: data }).
    then((json) => {
      dispatch({
        type: 'RAILS_UPDATE_SUCCEEDED',
        actions: [
          refreshMetaData({ ...json.meta }),
          updateIncidentReport(json.incidentReport),
          // We check for duplicate InjuredPersons based on the incident date,
          // so it's possible these have changed.
          updatePersons(json.incidentReport.injuredPersonsAttributes),
          updateSucceeded()
        ]
      })
    }).
    catch((json) => {
      dispatch({
        type: 'RAILS_UPDATE_FAILED',
        actions: [
          updateFailed(),
          setErrorFlash(json.error.message, { details: json.error.details })
        ]
      })
    })
}
