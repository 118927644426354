import React from "react"

const SurveyStatusMessage = ({ status }) => {
    const messages = {
        archived: `This survey is <strong>Archived</strong> and cannot be edited.`,
        active: `This survey is <strong>Active</strong>. Changes will affect the live survey.`
    }

    return !messages[status] ? ( null ) : (
        <div
            className={ `survey-status-notice status-${ status }` }
            dangerouslySetInnerHTML={{ __html: messages[status] }}>
        </div>
    )
}

export default SurveyStatusMessage
