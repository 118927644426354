import React from 'react'

import Section from 'shared/components/summary/section.jsx'
import Row from 'shared/components/summary/row.jsx'
import BasicColumn from 'shared/components/summary/basic_column.jsx'
import Phone from 'shared/components/summary/phone.jsx'
import DateAndTime from 'shared/components/summary/date_and_time.jsx'
import Address from 'shared/components/summary/address.jsx'

let WitnessCard = ({ witness, witness: { firstName, lastName } }) => {
  let name = `${firstName || ''} ${lastName || ''}`;
  name = (name.trim() === '') ? 'Unknown Name' : name;

  return (
    <div className="card">
      <header className="card-header">
        <h5 className="card-title">{ name }</h5>
      </header>

      <div className="card-body">
        <Row columnSize="xs-6">
          <Phone label="Primary Phone" value={ witness.primaryPhone } />
          <Phone label="Alternate Phone" value={ witness.alternatePhone } />
          <BasicColumn label="Email Address" value={ witness.email } />
        </Row>

        <Row columnSize="xs-12">
          <Address label="Address"
                   streetAddress={ witness.streetAddress }
                   optionalLine={ witness.optionalLine }
                   city={ witness.city }
                   state={ witness.state }
                   zip={ witness.zip } />
        </Row>

        <Row columnSize="xs-12">
          <BasicColumn label="Witness' statement regarding this occurence" value={ witness.statement } />
        </Row>
      </div>

      <footer className="card-footer"></footer>
    </div>
  )
}

export default WitnessCard;
