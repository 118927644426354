import React from 'react'
import PropTypes from 'prop-types';

import { removeSectionQuestionFromRails } from '../../ducks/survey.js'
import ActionWithConfirmation from '../action_with_confirmation.jsx'

const DeleteSectionQuestionContainer = ({ cyberSectionQuestion, handleHide }) => (
    <ActionWithConfirmation
        record={ cyberSectionQuestion }
        title="Confirm Question Delete"
        body="Are you sure you want to remove this question from the survey?"
        confirmText='Delete Question'
        apiResource="cyberSectionQuestions"
        apiMode="destroy"
        onConfirm={ removeSectionQuestionFromRails }
        handleHide={ handleHide }
    />
)

DeleteSectionQuestionContainer.propTypes = {
    handleHide: PropTypes.func.isRequired,
    cyberSectionQuestion:  PropTypes.object.isRequired
}

export default DeleteSectionQuestionContainer
