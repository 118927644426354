import React from 'react'

class UserPermissionOverridePrompt extends React.Component {
  constructor(props) {
    super(props);

    this.form           = $("form#role_form");
    this.state          = { override: false,
                            changes: { enabled: [], disabled: [] }
                          };
    this.originalValues = this.getPermissionSelections();

    this.handleSave     = this.handleSave.bind(this);
    this.handleCancel   = this.handleCancel.bind(this);
    this.updateOverride = this.updateOverride.bind(this);

    this.form.on('submit', this.promptToOverride.bind(this));
  }

  promptToOverride(event) {
    const overrideSettingsField = this.form.find('[name=override_user_settings]');
    let [enabled, disabled]   = this.getChanges(this.getPermissionSelections(), this.originalValues);

    if(overrideSettingsField.val() == '-1' && (enabled.length > 0 || disabled.length > 0)) {
      event.preventDefault();

      this.setState({
        changes: { enabled: enabled, disabled: disabled }
      });

      $('#permissionOverrideModal').modal({
        backdrop: 'static'
      });
    }
  }

  getChanges(newValues, oldValues) {
    let enabled = [], disabled = [];
    _.each(oldValues, function(oldValue, key) {
      if(newValues[key] != oldValue) {
        if(oldValue == '-1') {
          enabled.push(key);
        } else {
          disabled.push(key);
        }
      }
    });
    return [enabled, disabled];
  }

  getPermissionSelections() {
    let values = {};

    $(".permission-toggle").each(function(ix, row) {
      let label = $(row).find('> .power-name');
      let permissionToggle = $(row).find('input[type=checkbox]:checked');
      let value = permissionToggle.length > 0 ? permissionToggle.val() : '-1';

      if(!label || label.length == 0) {
        return;
      }
      values[label.text()] = value;
    });

    return values;
  }


  updateOverride(event) {
    let overrideUserSettings = event.target.checked;
    this.state.override = overrideUserSettings;
  }

  handleCancel() {
    //re-enable buttons disabled by `disable_with` property
    $.rails.enableFormElements($($.rails.formSubmitSelector));
  }

  handleSave() {
    this.form.find('[name=override_user_settings]').val(this.state.override);
    this.form.submit();
  }

  render() {
    const filtered = Object.keys(this.state.changes)
                          .filter((status) => this.state.changes[status].length > 0);
    const changes = (
      <table id='role_review_table' className="table no-margin-bottom">
        <thead>
          <tr>
            { filtered.map((status, index) =>
              <th key={ index }>{_.capitalize(status)}</th>
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            { filtered.map((status, index) =>
              <td className={'col-sm-6 bg-' + (status == 'enabled' ? 'success' : 'danger')} key={index}>
                <ul>
                { this.state.changes[status].map((permission, index) =>
                  <li key={index}>
                    {permission}
                  </li>
                )}
                </ul>
              </td>
            )}
          </tr>
        </tbody>
      </table>
    );

    return (
      <div id='permissionOverrideModal' className="modal fade">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Changes</h4>
            </div>
            { changes }
            <div className="modal-body">
              <div className='checkbox'>
                <label>
                  <input type='checkbox' onChange={this.updateOverride} />
                  Apply changes to all users with this role
                </label>
              </div>
              <span className="help-block">This will override any individual settings that are different.</span>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal" onClick={this.handleCancel}>Cancel</button>
              <button type="button" className="btn btn-primary" onClick={this.handleSave}>Save</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UserPermissionOverridePrompt;
