import React from 'react'

import Row from 'shared/components/summary/row.jsx'
import BasicColumn from 'shared/components/summary/basic_column.jsx'


class AttachmentCard extends React.Component {
  constructor(props) {
    super(props);
  }

  displayRelatedTo() {
    switch (this.props.attachment.attachableType) {
      case "InjuredPerson::Base": {
        // should fall to InjuredPerson::Employee
      }
      case "InjuredPerson::ThirdParty": {
        // should fall to InjuredPerson::Employee
      }
      case "InjuredPerson::Employee": {
        return this.displayPerson();
      }
      case "DamagedProperty": {
        return this.displayDamagedProperty();
      }
      case "InvolvedVehicle": {
        return this.displayInvolvedVehicle();
      }
      default: {
        return "The Report"
      }
    }
  }

  displayPerson() {
    let person = this.props.recordWithId(this.props.attachment.attachableId, this.props.injuredPersons)
    return `${person.firstName} ${person.lastName}`
  }

  displayDamagedProperty() {
    let property = this.props.recordWithId(this.props.attachment.attachableId, this.props.damagedProperties)
    return `Damaged Property: ${property.id}` // TODO
  }

  displayInvolvedVehicle() {
    let vehicle = this.props.recordWithId(this.props.attachment.attachableId, this.props.involvedVehicles)
    return `${vehicle.year} ${vehicle.makeAndModel} (${vehicle.vehicleIdentificationNumber})`
  }

  render() {
    const { attachment: { documentFileName, comment } } = this.props

    return (
      <div className="card">
        <header className="card-header">
          <h5 className="card-title">{ documentFileName }</h5>
        </header>

        <div className="card-body">
          <Row>
            <BasicColumn label="Related To" value={ this.displayRelatedTo() } />
            <BasicColumn label="Comment" value={ comment || "N/A" } />
          </Row>
        </div>

        <footer className="card-footer"></footer>
      </div>
    )
  }
}

export default AttachmentCard;
