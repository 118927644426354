import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { closeMobileMenu } from "../ducks/wizard.js";

class PanelSidebar extends React.Component {
  onLinkClick = (event) => {
    event.target.blur();

    if ("track" in woopra) {
      woopra.track("rails-link", {
        "link-url": event.target.href,
        "link-title": event.target.innerText,
      });
    }

    if (this.props.mobileMenuOpen) {
      this.props.closeMobileMenu();
    }

    if (this.props.onLinkClick) {
      this.props.onLinkClick(event);
    }
  };

  // badgeFor = (countKey) => {
  //   if (this.props[countKey] && this.props[countKey] > 0) {
  //     return <span className="badge">{ this.props[countKey] }</span>
  //   }
  //
  //   return null
  // }

  render() {
    return (
      <ul
        className={`panel-body-left nav nav-stacked nav-sidebar ${
          this.props.mobileMenuOpen ? "-is-open" : ""
        }`}
      >
        <li>
          <NavLink to="/member_information" onClick={this.onLinkClick}>
            Member Information
          </NavLink>
        </li>
        <li>
          <NavLink to="/request_details" onClick={this.onLinkClick}>
            Request Details
          </NavLink>
        </li>
        {/*<li>*/}
        {/*  <NavLink to="/attachments" onClick={this.onLinkClick}>*/}
        {/*    Supporting Documentation*/}
        {/*  </NavLink>*/}
        {/*</li>*/}
        <li>
          <NavLink to="/finalize" onClick={this.onLinkClick}>
            Finalize
          </NavLink>
        </li>
        <li>
          <NavLink to="/review" onClick={this.onLinkClick}>
            Review and Submit
          </NavLink>
        </li>
      </ul>
    );
  }
}

PanelSidebar = connect(
  (state, props) => ({
    mobileMenuOpen: state.wizard.mobileMenuOpen,
  }),
  (dispatch, props) => ({
    closeMobileMenu: () => {
      dispatch(closeMobileMenu());
    },
  })
)(PanelSidebar);

export default PanelSidebar;
