import React from "react";
import { connect } from "react-redux";
import { Link, Redirect, Prompt } from "react-router-dom";

import BaseForm from "shared/components/base_form.jsx";
import EmailEditorCallDetails from "../email_notifications/email_editor_call_details";
import EmailEditorPhysical from "../email_notifications/email_editor_physical";
import {
  FormField,
  TextField,
  DateAndTimeField,
  SelectField,
  ToggleSwitchField,
  MultiSelectField,
  AddressField,
} from "shared/components/fields";

import {
  requiredValidator,
  lengthValidator,
  commaSeparatedEmailsValidator,
  emailValidator,
} from "shared/utils/validators";

import {
  extractDateFromRailsDateTime,
  extractTimeFromRailsDateTime,
} from "shared/utils";

import { asyncUpdateEvent } from "../ducks/event";
import { apiEndpoint } from "../ducks/rails";
import { resetWizardState } from "../ducks/wizard";
import {
  Panel,
  PanelSidebar,
  PanelRightColumn,
  PanelBody,
  PanelControls,
} from "../panel";

class GeneralSettingsForm extends BaseForm {
  constructor(props) {
    super(props);

    const event = { ...(this.props.event || {}) };
    const errors = { ...(this.props.errors || {}) };

    this.state = {
      event: {
        title: new FormField(event.title, [
          requiredValidator(),
          lengthValidator(255),
        ]),

        status: new FormField(event.status, requiredValidator()),
        eventTypeId: new FormField(event.eventTypeId, requiredValidator()),

        date: new FormField(event.date, [requiredValidator()]),
        startTime: new FormField(event.startTime, requiredValidator()),
        endTime: new FormField(event.endTime, requiredValidator()),

        registrationOpenedAt: new FormField(event.registrationOpenedAt, [
          requiredValidator(),
        ]),
        registrationClosedAt: new FormField(event.registrationClosedAt, [
          requiredValidator(),
        ]),

        unlimitedCapacity: new FormField(event.unlimitedCapacity),
        capacity: new FormField(event.capacity),
        allowWaitlist: new FormField(event.allowWaitlist),
        showAttendees: new FormField(event.showAttendees),

        locationType: new FormField(event.locationType),

        platformType: new FormField(event.platformType),
        webinarNumber: new FormField(event.webinarNumber),
        webinarPassword: new FormField(event.webinarPassword),
        webinarLink: new FormField(event.webinarLink),
        webinarPhoneNumber: new FormField(event.phoneNumber),
        presenterNames: new FormField(event.presenterNames),

        venue: new FormField(event.venue),
        streetAddress: new FormField(event.streetAddress),
        optionalLine: new FormField(event.optionalLine),
        city: new FormField(event.city),
        state: new FormField(event.state),
        zip: new FormField(event.zip),

        callDetails: new FormField(event.callDetails),

        organizerNames: new FormField(
          event.organizerNames,
          requiredValidator()
        ),
        organizerEmails: new FormField(event.organizerEmails, [
          requiredValidator(),
          commaSeparatedEmailsValidator(),
        ]),
        ownerName: new FormField(event.ownerName, requiredValidator()),
        ownerEmail: new FormField(event.ownerEmail, [
          requiredValidator(),
          emailValidator(),
        ]),

        coverages: new FormField(event.coverages),
      },
    };

    // add any errors we found when attempting to submit
    Object.keys(this.state.event).map((key) => {
      this.state.event[key].errorMessage = (errors[key] || {}).message;
    });
  }

  handleChangeFor = (name) => this.handleFieldChange("event", name);

  toggleAll = () => {
    this.state.toggleAllCoverages
      ? this.handleChangeFor("coverages")(
          this.props.coverageOptions.map((option) => option.value)
        )
      : this.handleChangeFor("coverages")([]);
  };

  componentDidMount() {
    console.log("PROPS", this.props);
    console.log("STATE", this.state);
  }

  componentWillUnmount() {
    this.props.resetWizardState();
  }

  prepareData = () => {
    let copy = { event: { ...this.state.event } };

    // turn FormField objects in to just the raw value
    Object.keys(copy.event).map((key) => {
      copy.event[key] = copy.event[key].value;
    });

    return copy;
  };

  handleSave = (event) => {
    event.preventDefault();

    if (!this.validateFields("event")) {
      return null;
    }

    if (event.target && event.target.href) {
      // looks like we clicked a link in the sidebar
      // since we hijack clicking on the sidebar and attempt to save this form, forcing you
      // to at least deal with this first step before bouncing to anywhere in the form,
      // we need to remember what you clicked so we can send you on your way if everything checked
      // out
      this.setState({
        redirect: event.target.href.split("/edit").slice(1).join("/edit"),
      });
    } else {
      this.setState({ redirect: "/events" });
    }

    if (this.anyChangesMade("event", this.props.event)) {
      this.props.asyncUpdateEvent(this.prepareData());
    }
  };

  handleClick = () => {
    console.log("Button Clicked");
  };

  handlePlatformChange = (platform) => {
    this.handleChangeFor("platformType")(platform);

    this.handleDefaultMessage(platform);
  };

  handleChangeForLocation = (location) => {
    this.handleChangeFor("locationType")(location);
    console.log("changing location type to", this.state.event.locationType);
    this.handleDefaultMessage("empty");
  };

  handleDefaultMessage = (platform) => {
    let draft;
    switch (platform) {
      case "webex":
        draft =
          '<p>To join this virtual engagement on %date, please <span style="font-weight: bold; font-size: 18px; color: blue;">%link</span></p><p style="font-weight: bold;">Please enter any requested information and click “Join webinar.”</p><p>Event password:<strong> </strong>%eventPassword</p><p>Event number: %eventNumber</p><p>Audio dial-in number:<strong> </strong>1-650-429-3300 (the access code is the event number)</p>';
        break;
      case "ring_Central":
        draft =
          "<p>To join this RingCentral meeting on %date, please %link.</p><p>From the launch screen, select one of these available options: The Launch Meeting button, the Download Now link, or the Join from Your Browser link. For the best audio experience, please use computer audio. If you are unable to join through your computer, you may use the audio dial-in number listed below.</p><p><strong><em>Meeting ID</em>: </strong>%eventNumber</p><p><strong><em>Audio dial-in number</em>: </strong>(469) 445-0100</p>";
        break;
      case "teams":
        draft =
          "<p>To join this event virtually on %date, please %link. From the launch screen, adjust your preferences and select the Join Now button. </p><p>Audio only call-in number: (434) 338-6833 </p><p>Phone Conference ID: %eventNumber </p>";
        break;
      case "empty":
        draft = "";
        break;
      default:
        draft = "<p>No Platform type selected</p>";
    }
    console.log("changing message block to", draft);
    this.handleChangeFor("callDetails")(draft);
  };

  render() {
    if (!this.props.updating) {
      if (this.state.redirect === "/events") {
        window.location = `${window.location.origin}/events`;
      } else if (
        this.state.redirect &&
        this.state.redirect !== this.props.match.path
      ) {
        return <Redirect to={this.state.redirect} />;
      }
    }

    return (
      <Panel>
        <PanelSidebar onLinkClick={this.handleSave} />

        <PanelRightColumn>
          <PanelBody>
            <div className="row">
              <div className="col-xs-12">
                <h4>Event Details</h4>

                <TextField
                  name="title"
                  onChange={this.handleChangeFor("title")}
                  label="Event Title"
                  value={this.state.event.title.value}
                  errorMessage={this.state.event.title.errorMessage}
                  required={true}
                  tabIndex="1"
                  autoFocus={true}
                />

                <SelectField
                  name="eventType"
                  label="Event Type"
                  placeholder="Event Type"
                  onChange={this.handleChangeFor("eventTypeId")}
                  value={this.state.event.eventTypeId.value}
                  errorMessage={this.state.event.eventTypeId.errorMessage}
                  required={true}
                  tabIndex="1"
                  options={this.props.eventTypeOptions}
                />

                <SelectField
                  name="status"
                  label="Status"
                  placeholder="Status"
                  onChange={this.handleChangeFor("status")}
                  value={this.state.event.status.value}
                  errorMessage={this.state.event.status.errorMessage}
                  required={true}
                  tabIndex="1"
                  options={this.props.statusOptions}
                />

                <SelectField
                  name="location"
                  label="Location Type"
                  placeholder="Location Type"
                  onChange={(e) => this.handleChangeForLocation(e)}
                  value={this.state.event.locationType.value}
                  errorMessage={this.state.event.locationType.errorMessage}
                  required={true}
                  tabIndex="1"
                  options={this.props.locationOptions}
                />

                <DateAndTimeField
                  label={{ date: "Date of Event" }}
                  name="date"
                  includeTime={false}
                  value={this.state.event.date.value}
                  errorMessage={this.state.event.date.errorMessage}
                  onChange={(code) => {
                    this.handleChangeFor("date")(code);
                  }}
                  required={true}
                  tabIndex="1"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-6">
                <DateAndTimeField
                  label={{ time: "Event Start Time" }}
                  name="startTime"
                  includeDate={false}
                  value={this.state.event.startTime.value}
                  errorMessage={this.state.event.startTime.errorMessage}
                  onChange={(code) => {
                    this.handleChangeFor("startTime")(code);
                  }}
                  required={true}
                  tabIndex="1"
                />
              </div>
              <div className="col-xs-6">
                <DateAndTimeField
                  label={{ time: "Event End Time" }}
                  name="endTime"
                  includeDate={false}
                  value={this.state.event.endTime.value}
                  errorMessage={this.state.event.endTime.errorMessage}
                  onChange={(code) => {
                    this.handleChangeFor("endTime")(code);
                  }}
                  required={true}
                  tabIndex="1"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <p>
                  Set the window for which registration will be open. By default
                  it opens today. You must provide a close date / time, but you
                  can set it to the event start to allow registrations up to the
                  last minute.
                </p>

                <br />

                <div className="full-width-date-and-time-inputs">
                  <DateAndTimeField
                    label={{
                      date: "Registration Open Date",
                      time: "Registration Open Time",
                    }}
                    name="registrationOpenedAt"
                    defaultTimeTo="12:00 AM"
                    value={this.state.event.registrationOpenedAt.value}
                    errorMessage={
                      this.state.event.registrationOpenedAt.errorMessage
                    }
                    onChange={this.handleChangeFor("registrationOpenedAt")}
                    required={true}
                    tabIndex="1"
                  />

                  <DateAndTimeField
                    name="registrationClosedAt"
                    onChange={this.handleChangeFor("registrationClosedAt")}
                    label={{
                      date: "Registration Close Date",
                      time: "Registration Close Time",
                    }}
                    defaultTimeTo="12:00 AM"
                    value={this.state.event.registrationClosedAt.value}
                    errorMessage={
                      this.state.event.registrationClosedAt.errorMessage
                    }
                    required={true}
                    tabIndex="1"
                  />
                </div>

                <p>{"Unlimited Capacity?"}</p>
                <ToggleSwitchField
                  name="unlimitedCapacity"
                  onChange={this.handleChangeFor("unlimitedCapacity")}
                  value={this.state.event.unlimitedCapacity.value}
                  errorMessage={this.state.event.unlimitedCapacity.errorMessage}
                  tabIndex="1"
                />
                {!this.state.event.unlimitedCapacity.value && (
                  <div>
                    <TextField
                      label="Max Attendees"
                      name="capacity"
                      value={this.state.event.capacity.value}
                      errorMessage={this.state.event.capacity.errorMessage}
                      onChange={this.handleChangeFor("capacity")}
                      required={true}
                      tabIndex="1"
                    />

                    <p>{"Allow Waitlist?"}</p>
                    <ToggleSwitchField
                      name="allowWaitlist"
                      onChange={this.handleChangeFor("allowWaitlist")}
                      value={this.state.event.allowWaitlist.value}
                      errorMessage={this.state.event.allowWaitlist.errorMessage}
                      tabIndex="1"
                    />
                  </div>
                )}
                <p>
                  {
                    "Show attendees who are participating from their entity on frontend?"
                  }
                </p>

                <ToggleSwitchField
                  name="showAttendees"
                  onChange={this.handleChangeFor("showAttendees")}
                  value={this.state.event.showAttendees.value}
                  errorMessage={this.state.event.showAttendees.errorMessage}
                  tabIndex="1"
                />

                <TextField
                  label="Presenter names separated by commas"
                  name="presenterNames"
                  value={this.state.event.presenterNames.value}
                  errorMessage={this.state.event.presenterNames.errorMessage}
                  onChange={this.handleChangeFor("presenterNames")}
                  tabIndex="1"
                />

                {this.state.event.locationType.value !== "physical" && (
                  <div className="row">
                    <div className="col-xs-12">
                      <h5>Virtual Information</h5>
                    </div>

                    <div className="col-xs-12">
                      <SelectField
                        name="platform"
                        label="Platform Type"
                        placeholder="Platform Type"
                        onChange={(platform) =>
                          this.handlePlatformChange(platform)
                        }
                        value={this.state.event.platformType.value}
                        errorMessage={
                          this.state.event.platformType.errorMessage
                        }
                        required={true}
                        tabIndex="1"
                        options={this.props.platformOptions}
                      />

                      {!!this.state.event.platformType.value && (
                        <span key={this.state.event.platformType.value}>
                          <EmailEditorCallDetails
                            tabIndex={4}
                            defaultMessage={this.state.event.callDetails.value}
                            onChange={(value) => {
                              console.log(value);
                              this.handleChangeFor("callDetails")(value);
                            }}
                          />
                        </span>
                      )}

                      <TextField
                        name="webinarLink"
                        onChange={this.handleChangeFor("webinarLink")}
                        label="Virtual Engagement Link"
                        value={this.state.event.webinarLink.value}
                        errorMessage={this.state.event.webinarLink.errorMessage}
                        tabIndex="1"
                        autoFocus={false}
                      />

                      <TextField
                        name="webinarPassword"
                        onChange={this.handleChangeFor("webinarPassword")}
                        label="Event Password"
                        value={this.state.event.webinarPassword.value}
                        errorMessage={
                          this.state.event.webinarPassword.errorMessage
                        }
                        tabIndex="1"
                        autoFocus={false}
                      />

                      <TextField
                        name="webinarNumber"
                        onChange={this.handleChangeFor("webinarNumber")}
                        label="Event Number"
                        value={this.state.event.webinarNumber.value}
                        errorMessage={
                          this.state.event.webinarNumber.errorMessage
                        }
                        tabIndex="1"
                        autoFocus={false}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {this.state.event.locationType.value === "physical" && (
              <div className="row">
                <div className="col-xs-12">
                  <EmailEditorPhysical
                    tabIndex={4}
                    defaultMessage={this.state.event.callDetails.value}
                    onChange={(value) => {
                      console.log(value);
                      this.handleChangeFor("callDetails")(value);
                    }}
                  />
                </div>
              </div>
            )}
            {this.state.event.locationType.value !== "virtual" && (
              <div className="row">
                <div className="col-xs-12">
                  <h5>Event Location</h5>
                </div>

                <div className="col-xs-12">
                  <TextField
                    label="Venue"
                    name="venue"
                    value={this.state.event.venue.value}
                    errorMessage={this.state.event.venue.errorMessage}
                    onChange={this.handleChangeFor("venue")}
                    tabIndex="1"
                  />
                </div>

                <div className="col-xs-12">
                  <AddressField
                    fields={{
                      streetAddress: {
                        name: "streetAddress",
                        value: this.state.event.streetAddress.value,
                        errorMessage:
                          this.state.event.streetAddress.errorMessage,
                        onChange: this.handleChangeFor("streetAddress"),
                      },
                      optionalLine: {
                        name: "optionalLine",
                        value: this.state.event.optionalLine.value,
                        errorMessage:
                          this.state.event.optionalLine.errorMessage,
                        onChange: this.handleChangeFor("optionalLine"),
                      },
                      city: {
                        name: "city",
                        value: this.state.event.city.value,
                        errorMessage: this.state.event.city.errorMessage,
                        onChange: this.handleChangeFor("city"),
                      },
                      state: {
                        name: "state",
                        value: this.state.event.state.value,
                        errorMessage: this.state.event.state.errorMessage,
                        onChange: this.handleChangeFor("state"),
                      },
                      zip: {
                        name: "zip",
                        value: this.state.event.zip.value,
                        errorMessage: this.state.event.zip.errorMessage,
                        onChange: this.handleChangeFor("zip"),
                      },
                    }}
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-xs-12">
                <h4>Event Contacts</h4>
                <TextField
                  label="Organizer names separated by commas"
                  name="organizerNames"
                  value={this.state.event.organizerNames.value}
                  errorMessage={this.state.event.organizerNames.errorMessage}
                  onChange={this.handleChangeFor("organizerNames")}
                  required={true}
                  tabIndex="1"
                />

                <TextField
                  label="Organizer emails separated by commas"
                  name="organizerEmails"
                  value={this.state.event.organizerEmails.value}
                  errorMessage={this.state.event.organizerEmails.errorMessage}
                  onChange={this.handleChangeFor("organizerEmails")}
                  required={true}
                  tabIndex="1"
                />

                <TextField
                  label="Event Owner Name"
                  name="ownerName"
                  value={this.state.event.ownerName.value}
                  errorMessage={this.state.event.ownerName.errorMessage}
                  onChange={this.handleChangeFor("ownerName")}
                  required={true}
                  tabIndex="1"
                />

                <TextField
                  label="Owner Email"
                  name="ownerEmail"
                  value={this.state.event.ownerEmail.value}
                  errorMessage={this.state.event.ownerEmail.errorMessage}
                  onChange={this.handleChangeFor("ownerEmail")}
                  required={true}
                  tabIndex="1"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <h4>Member Eligibilty</h4>
                <p>
                  Select the required member coverage to register for this
                  event.
                </p>

                <MultiSelectField
                  type="checkbox"
                  name="coverages"
                  value={this.state.event.coverages.value}
                  errorMessage={this.state.event.coverages.errorMessage}
                  onChange={this.handleChangeFor("coverages")}
                  tabIndex="1"
                  required={true}
                  allowToggleAll={true}
                  options={this.props.coverageOptions}
                />
              </div>
            </div>
            <hr />

            <p>{`Created by ${
              this.props.event.createdBy
            } at ${extractDateFromRailsDateTime(
              this.props.event.createdAt
            )} ${extractTimeFromRailsDateTime(this.props.event.createdAt)}`}</p>
            <p>
              {this.props.event.lastUpdatedBy &&
                `Last updated by ${
                  this.props.event.lastUpdatedBy
                } at ${extractDateFromRailsDateTime(
                  this.props.event.updatedAt
                )} ${extractTimeFromRailsDateTime(this.props.event.updatedAt)}`}
            </p>
          </PanelBody>

          <PanelControls>
            <button
              type="button"
              onClick={this.handleSave}
              className={`btn btn-success ${
                this.props.updating ? "disabled" : ""
              }`}
            >
              Save &amp; Exit
            </button>
          </PanelControls>
        </PanelRightColumn>
      </Panel>
    );
  }
}

GeneralSettingsForm = connect(
  (state, props) => ({
    event: { ...state.event },
    errors: state.submissionErrors.event,
    updateSucceeded: state.wizard.updateSucceeded,
    updating: state.wizard.updating,
    apiEndpoint: apiEndpoint(state.rails, "event"),
    coverageOptions: state.rails.coverageOptions,
    eventTypeOptions: state.rails.eventOptions,
    statusOptions: state.rails.enumOptionsForSelect["Event"].status,
    locationOptions: state.rails.enumOptionsForSelect["Event"].locationType,
    platformOptions: state.rails.enumOptionsForSelect["Event"].platformType,
  }),
  null,
  ({ apiEndpoint, ...stateProps }, { dispatch }, props) => ({
    ...stateProps,
    ...props,
    asyncUpdateEvent: (data, updated = true) => {
      return asyncUpdateEvent(apiEndpoint.path, data, updated)(dispatch);
    },
    resetWizardState: () => {
      dispatch(resetWizardState());
    },
  })
)(GeneralSettingsForm);

export default GeneralSettingsForm;
