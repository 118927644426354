const LOAD = 'cyber/shared/ducks/survey/LOAD'

export default (state = {}, action) => {
    switch (action.type) {
        case LOAD: {
            return { ...state, ...action.survey }
        }

        default: return state;
    }
}

export const load = (props) => {
    // this accepts all the props passed to app.jsx
    return {
        type: LOAD,
        survey: {
            ...props.survey
        }
    }
}

export const surveyId = (state) => {
    return state.surveyId
}

export const policyYear = (state) => {
    return state.policyYear
}

export const sections = (state) => {
    return { ...state.cyberSurveySectionsAttributes }
}

export const allSections = (state) => {
    return Object.values(state.cyberSurveySectionsAttributes)
}

export const allQuestions = (state) => {

    // get the section questions
    let cyberSectionQuestions = allSections(state).map((cyberSectionQuestion) => cyberSectionQuestion.cyberSectionQuestionsAttributes)

    // transform the nested array of section questions into a one-dimensional array
    let questions = cyberSectionQuestions.reduce((acc, val) => {
        return acc.concat(Object.values(val).filter((v) => ( v === Object(v) )))
    }, [])

    return questions
}

export const filterSectionsToCoverage = (state, coverage) => {
    let coverageId = coverage ? coverage.id : null

    return Object.values(state.cyberSurveySectionsAttributes).
    filter((section) => {
        return section.coverageTypeId == coverageId
    }).
    sort((a, b) => {
        if (a.order < b.order) { return -1 }
        if (a.order > b.order) { return 1 }
        return 0
    })
}

export const sortSectionQuestionsFromSection = (section) => {
    return Object.values(section.cyberSectionQuestionsAttributes || []).
    sort((a, b) => {
        if (a.order < b.order) { return -1 }
        if (a.order > b.order) { return 1 }
        return 0
    })
}

export const responseForSectionQuestion = (state, section_question_id) => {
    return state.find((response) => response.sectionQuestionId == section_question_id) || {}
}

export const getOverallScore = (state) => {
    let score = state.scores.find((score) => score.coverageTypeId == -1)
    score = (score === undefined) ? 0 : score.score
    return score
}

export const getQuestionCountBadge = (survey_state, responses_state, coverage) => {
    let badge = { answered: 0, total: 0 }

    // we can turn our response array into an object for essentially constant time lookup later
    // perhaps we should have started this way, dammit Rails 😡
    const _responses = {}
    for (let response of responses_state) {
        _responses[response.sectionQuestionId] = response
    }

    // reusing code, but the unneeded sorting here may slow things down, idk, keep an eye on it 👀
    const cyberSectionQuestions = [].concat.apply([],
        filterSectionsToCoverage(survey_state, coverage).
        map((section) => (sortSectionQuestionsFromSection(section)))
    )

    for (let cyberSectionQuestion of cyberSectionQuestions) {

        if (cyberSectionQuestion.status !== 'disabled_and_visible' &&
            cyberSectionQuestion.cyberQuestionAttributes.requireYesNoAnswer &&
            (_responses[cyberSectionQuestion.id] || {}).answer !== 'N/A') {

            badge.total += 1 // we can count this queston against your score
        }

        if ((_responses[cyberSectionQuestion.id] || {}).answer === 'Yes') {
            badge.answered += 1 // we can count this questoin as affirmitively answered, well done 👏
        }
    }

    return badge
}

export const getQuestionCount = (survey_state, coverage) => {
    // reusing code, but the unneeded sorting here may slow things down, idk, keep an eye on it 👀
    const cyberSectionQuestions = [].concat.apply([],
        filterSectionsToCoverage(survey_state, coverage).
        map((section) => (sortSectionQuestionsFromSection(section)))
    )

    return Object.keys(cyberSectionQuestions).length
}
