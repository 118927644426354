export const normalizeZip = (value) => {
  if (!value) {
    return "";
  }

  return value.replace(/[\D]/g, ''). // only numbers
               slice(0, 9);          // only 9 of them
};

export const formatZip = (value) => {
  if (!value) {
    return "";
  }

  let _value = value.replace(/[\D]/g, ''). // only numbers
                     slice(0, 9);          // only 9 of them

  if (_value.length <= 5) {
    return _value;
  }
  else {
    return `${_value.slice(0, 5)}-${_value.slice(5, 9)}`;
  }
};
