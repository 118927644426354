import React from "react"

const IconWithTooltip = ({ icon, content }) => {
  return content && content.length > 0 ? (
      <div className='tooltip-icon'>
        <i className={ `fa ${ icon }` } />
        <div className='tooltip' dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    ) : ( null )
}

export default IconWithTooltip
