import React from 'react';
import { extractDateFromRailsDateTime } from 'shared/utils/rails_datetime_formatters.js'

class RmgDatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = { dateValue: (extractDateFromRailsDateTime(this.props.dateValue) || '') };

    this.floatLabels      = this.floatLabels.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  floatLabels() {
    let label_floater = new App.LabelFloater({
        $label: $(this.dateLabel),
        $input: $(this.dateInput) }
    )

    label_floater.initialize();
  }

  handleDateChange(event) {
    let formatted = (event.target.value || "").replace(/[\D]/g, '').slice(0, 8);

    if (!formatted) {
      formatted = "";
    }
    else if (formatted.length <= 2) {
      formatted = formatted;
    }
    else if (formatted.length <= 4) {
      formatted = `${formatted.slice(0, 2)}/${formatted.slice(2)}`
    }
    else {
      formatted = `${formatted.slice(0, 2)}/${formatted.slice(2, 4)}/${formatted.slice(4, 8)}`
    }

    this.setState({ dateValue: formatted });

    // call the onBlur property we passed in so that we can send the updated survey response to rails
    this.props.onBlur(event);
  }

  componentDidMount() {
    $(this.dateInput).on('changeDate', this.handleDateChange);

    this.floatLabels();
  }

  componentDidUpdate() {
    this.floatLabels();
  }

  render() {
    let cssClasses = [
      "form-group input-group",
      this.props.requiredInput ? "is-required" : "",
      this.props.error ? "has-error" : ""
    ].filter((n) => n).join(" ")

    return (
      <div className="form-inline datetime-group">
        <div className={ cssClasses }>
          <span className="input-group-addon" data-behavior="datepicker-icon">
            <i className="fa fa-calendar"></i>
          </span>

          <label ref={ (label) => { this.dateLabel = label } }>
            Date
            { this.props.requiredInput ? <span></span> : "" }
          </label>

          {/* `data-date-start-date` will be an empty string if it's not set, which seems to be properly
              ignored by the date picker library, but if we're having an issue, perhaps check that */}
          <input
            type="text"
            className="form-control"
            data-behavior="datepicker"
            data-date-end-date="0d"
            data-date-start-date={ this.props.earliestAllowedDateForYes }
            ref={ (input) => { this.dateInput = input; } }
            name={ this.props.dateName }
            value={ this.state.dateValue }
            onChange={ this.handleDateChange }
          />

          {
            this.props.error &&
              <span className="field-level-error">{ this.props.error }</span>
          }

        </div>

        {
          this.props.requiredInput &&
            <small>If the exact date is unknown, please enter the first day of the month.</small>
        }
      </div>
    )
  }
}

export default RmgDatePicker;
