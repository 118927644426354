import React from 'react'
import { connect } from 'react-redux'
import FlipMove from 'react-flip-move';

import { activeCoverage } from 'apps/rmg/shared/ducks/coverage_types.js'
import { filterSectionsToCoverage } from 'apps/rmg/shared/ducks/survey.js'

import EditableSurveySection from './editable_survey_section.jsx'
import AddSurveySectionButton from './survey_section/add_survey_section_button.jsx'
import DraggableSurveySection from './draggable_survey_section.jsx'
import DragPreviewSurveySection from './drag_preview_survey_section.jsx'

import { canCreateSections, canEditSections, submitSurveyToRails } from '../ducks/survey.js'
import { apiEndpoint } from '../ducks/rails.js'

let EditableSurveyTab = ({ activeCoverage, sections, canCreateSections, canEditSections, handleSectionReordering }) => (
  <div className="panel-body-right">
    <div className="panel-body-right-inner-padding">

        <FlipMove easing="ease" duration={ 500 } staggerDurationBy={ 15 } staggerDelayBy={ 20 }>
          {
            sections.map((section) => (
              canEditSections ?
                <DraggableSurveySection key={ section.id }
                                        id={ section.id }
                                        order={ section.order }
                                        surveyId={ section.surveyId }
                                        section={ section }
                                        handleEndDrag={ handleSectionReordering } />
              :
                <EditableSurveySection key={ section.id }
                                       id={ section.id }
                                       order={ section.order }
                                       surveyId={ section.surveyId }
                                       section={ section } />
            ))
          }
        </FlipMove>

      { canEditSections && <DragPreviewSurveySection key="__preview"/> }

      { canCreateSections && <AddSurveySectionButton coverageToAddSectionToId={ activeCoverage.id } /> }
    </div>
  </div>
)

EditableSurveyTab = connect(
  (state, props) => {
    const _activeCoverage = activeCoverage(state.coverageTypes, state.tab)

    return {
      activeCoverage:    _activeCoverage,
      sections:          filterSectionsToCoverage(state.survey, _activeCoverage),
      canCreateSections: canCreateSections(state.survey),
      canEditSections:   canEditSections(state.survey),

      survey:            state.survey,
      apiEndpoint:       apiEndpoint(state.rails, { resource: "survey", mode: "update" })
    }
  },

  null,

  (stateProps, dispatchProps, ownProps) => {
    let { apiEndpoint: { path, method }, survey, ...rest } = stateProps

    return {
      ...rest,
      handleSectionReordering: () => { submitSurveyToRails(survey, path, method, dispatchProps.dispatch) }
    }
  }
)(EditableSurveyTab)

export default EditableSurveyTab
