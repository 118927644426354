import React from 'react'
import { connect } from 'react-redux'
import { Link, Prompt, Redirect } from 'react-router-dom'

import BaseForm from 'shared/components/base_form.jsx'
import SameAsCheckbox from 'shared/components/same_as_checkbox.jsx'
import SelectWithAsyncOptionsFromRails from 'shared/components/select_with_async_options_from_rails'


import {
  FormField,
  TextField,
  RadioField,
  PhoneNumberField,
  AddressField,
  DollarValueField,
  SelectField
} from 'shared/components/fields'

import {
  requiredValidator,
  phoneNumberValidator,
  emailValidator,
  lengthValidator,
  dollarValueValidator
} from 'shared/utils/validators'

import { asyncUpdateVehicles } from '../ducks/vehicles.js'
import { apiEndpoint } from '../ducks/rails.js'
import { resetWizardState } from '../ducks/wizard.js'
import { Panel, PanelSidebar, PanelRightColumn, PanelBody, PanelControls } from '../panel'

import VehiclesPrompt from './vehicles_prompt.jsx'
import VehiclesHeader from './vehicles_header.jsx'
import SearchInsuredVehiclesField from './search_insured_vehicles_field.jsx'

const requiredIfMemberOwned = (value, state) => {
  if (state.memberOwned.value) {
    return requiredValidator()(value)
  }
}

const requiredIfMemberOwnedAndSomeoneWasDriving = (value, state) => {
  if (state.memberOwned.value && state.wasSomeoneDriving.value) {
    return requiredValidator()(value)
  }
}

const yearValidator = (value, state) => {
  if (value) {
    if (value.length < 4 || value.length > 4) {
      return `Use a four digit year like ${new Date().getFullYear()}`
    }
  }
}

class VehiclesForm extends BaseForm {
  constructor(props) {
    super(props)

    const vehicle = { ...(props.vehicle || {}) }

    this.state = {
      vehicle: {
        memberOwned: new FormField(vehicle.memberOwned, requiredValidator()),

        year:                        new FormField(vehicle.year,                        [requiredIfMemberOwned, yearValidator]),
        makeAndModel:                new FormField(vehicle.makeAndModel,                requiredIfMemberOwned),
        vehicleIdentificationNumber: new FormField(vehicle.vehicleIdentificationNumber, requiredIfMemberOwned),
        vehicleTypeName:             new FormField(vehicle.vehicleTypeName, requiredIfMemberOwned),
        licensePlateNumber:          new FormField(vehicle.licensePlateNumber),
        ogmVehicleId:                   new FormField(vehicle.vehicleId),

        ownerFirstName:     new FormField(vehicle.ownerFirstName),
        ownerLastName:      new FormField(vehicle.ownerLastName),
        ownerPrimaryPhone:  new FormField(vehicle.ownerPrimaryPhone, phoneNumberValidator()),

        ownerStreetAddress: new FormField(vehicle.ownerStreetAddress),
        ownerOptionalLine:  new FormField(vehicle.ownerOptionalLine),
        ownerCity:          new FormField(vehicle.ownerCity),
        ownerState:         new FormField(vehicle.ownerState),
        ownerZip:           new FormField(vehicle.ownerZip),

        insuranceProviderStreetAddress: new FormField(vehicle.insuranceProviderStreetAddress),
        insuranceProviderOptionalLine:  new FormField(vehicle.insuranceProviderOptionalLine),
        insuranceProviderCity:          new FormField(vehicle.insuranceProviderCity),
        insuranceProviderState:         new FormField(vehicle.insuranceProviderState),
        insuranceProviderZip:           new FormField(vehicle.insuranceProviderZip),
        insuranceProviderName:          new FormField(vehicle.insuranceProviderName),
        policyNumber:                   new FormField(vehicle.policyNumber),
        insuranceProviderPhone:         new FormField(vehicle.insuranceProviderPhone, phoneNumberValidator()),

        claimBeingMade:                       new FormField(vehicle.claimBeingMade, requiredValidator()),
        descriptionOfDamage:                  new FormField(vehicle.descriptionOfDamage, lengthValidator(255)),
        estimatedCostOfRepair:                new FormField(vehicle.estimatedCostOfRepair, dollarValueValidator()),
        contactForInspectingVehicleFirstName: new FormField(vehicle.contactForInspectingVehicleFirstName),
        contactForInspectingVehicleLastName:  new FormField(vehicle.contactForInspectingVehicleLastName),
        contactForInspectingVehicleEmail:     new FormField(vehicle.contactForInspectingVehicleEmail, emailValidator()),
        contactForInspectingVehiclePhone:     new FormField(vehicle.contactForInspectingVehiclePhone, phoneNumberValidator()),
        vehicleStreetAddress:                 new FormField(vehicle.vehicleStreetAddress),
        vehicleOptionalLine:                  new FormField(vehicle.vehicleOptionalLine),
        vehicleCity:                          new FormField(vehicle.vehicleCity),
        vehicleState:                         new FormField(vehicle.vehicleState),
        vehicleZip:                           new FormField(vehicle.vehicleZip),

        wasSomeoneDriving:        new FormField(vehicle.wasSomeoneDriving, requiredValidator()),
        driverFirstName:          new FormField(vehicle.driverFirstName, requiredIfMemberOwnedAndSomeoneWasDriving),
        driverLastName:           new FormField(vehicle.driverLastName, requiredIfMemberOwnedAndSomeoneWasDriving),
        driverPrimaryPhone:       new FormField(vehicle.driverPrimaryPhone, phoneNumberValidator()),
        driverEmail:              new FormField(vehicle.driverEmail, emailValidator()),
        driverAlternatePhone:     new FormField(vehicle.driverAlternatePhone, phoneNumberValidator()),
        driverLicenseNumber:      new FormField(vehicle.driverLicenseNumber),
        driverStreetAddress:      new FormField(vehicle.driverStreetAddress),
        driverOptionalLine:       new FormField(vehicle.driverOptionalLine),
        driverCity:               new FormField(vehicle.driverCity),
        driverState:              new FormField(vehicle.driverState),
        driverZip:                new FormField(vehicle.driverZip),

        // only asked if was_someone_driving && member_owned
        hadPermissionToUse:       new FormField(vehicle.hadPermissionToUse, requiredIfMemberOwnedAndSomeoneWasDriving),
        driverType:               new FormField(vehicle.driverType),
        driverReasonToUseVehicle: new FormField(vehicle.driverReasonToUseVehicle),

        passengerCount:     new FormField(vehicle.passengerCount),
        additionalComments: new FormField(vehicle.additionalComments, lengthValidator(255))
      }
    }
  }

  handleChangeFor = (name) => ( this.handleFieldChange("vehicle", name) )

  componentWillUnmount() {
    this.props.resetWizardState()
  }

  prepareData = () => {
    let key = Date.now().toString()

    // the structure of what we wanna send back to rails for updating
    let data = {
      incidentReport: {
        ...(this.props.incidentReport || {}),
        involvedVehiclesAttributes: { [key]: {} } // new object to copy vehicle values into
      }
    }

    // if this vehicle has an id (i.e. rails already knows about them), add it in. new vehicles
    // don't need an id and will be given one by rails
    if ((this.props.vehicle || {}).id) {
      data.incidentReport.involvedVehiclesAttributes[key].id = (this.props.vehicle || {}).id
    }

    // collect our form values
    Object.keys(this.state.vehicle).map((field) => {
      data.incidentReport.involvedVehiclesAttributes[key][field] = this.state.vehicle[field].value
    })

    return data
  }

  handleSave = (event) => {
    event.preventDefault()

    if (this.validateFields("vehicle")) {
      this.props.asyncUpdateVehicles(this.prepareData())
    }
  }

  renderControlGroup() {
    if (this.props.withPrompt) {
      return (
        <div>
          <button type="button"
                  className="btn btn-link"
                  onClick={ () => {
                    if (confirm("Are you sure you want to move away? Any unsaved changes " +
                                "made to this section will be lost.")) {
                      this.props.onPromptChange(false) // toggle the prompt back to "No"
                    }
                  }}>
            Cancel
          </button>

          <button type="button"
                  onClick={ this.handleSave }
                  className={ `btn btn-success ${this.props.updating ? "disabled" : ""}` }>

            Save to Claim
          </button>
        </div>
      )
    }
    else {
      return (
        <div>
          <Link to="/vehicles">
            <button type="button"
                    className="btn btn-link">

              Back
            </button>
          </Link>

          <button type="button"
                  onClick={ this.handleSave }
                  className={ `btn btn-success ${this.props.updating ? "disabled" : ""}` }>

            Save to Claim
          </button>
        </div>
      )
    }
  }

  render() {
    if (this.props.updateSucceeded) {
      return <Redirect to="/vehicles" />
    }

    return (
      <Panel>
        <PanelSidebar />

        <PanelRightColumn>
          <PanelBody>
            <VehiclesHeader />

            {
              this.props.withPrompt &&
                <div className="row">
                  <div className="col-xs-12">
                    <VehiclesPrompt onChange={ this.props.onPromptChange }
                                    value={ this.props.promptValue } />
                  </div>
                </div>
            }

            <hr />

            <div className="row">
              <div className="col-xs-12">
                <RadioField name="memberOwned"
                            onChange={ this.handleChangeFor("memberOwned") }
                            label="Is this a member vehicle?"
                            value={ this.state.vehicle.memberOwned.value }
                            errorMessage={ this.state.vehicle.memberOwned.errorMessage }
                            required={ true }
                            options={[
                              { displayText: 'Yes', value: true },
                              { displayText: 'No', value: false }
                            ]} />
              </div>
            </div>

            {
              /* don't show the other fields until they've answered this question */
              (this.state.vehicle.memberOwned.value === false || this.state.vehicle.memberOwned.value === true) &&
                <div>
                  {
                    (() => {
                      if (this.state.vehicle.memberOwned.value) {
                        return (
                          <div>
                            <div className="row">
                              <div className="col-xs-12">
                                <p>Search for a vehicle by VIN, make, or model</p>

                                <div className="form-group">
                                  <SearchInsuredVehiclesField
                                    onChange={ (option) => {
                                      if (option) {
                                        this.handleChangeFor("year")(option.year || '')
                                        this.handleChangeFor("makeAndModel")(option.make_and_model || '')
                                        this.handleChangeFor("vehicleIdentificationNumber")(option.vin || '')
                                        this.handleChangeFor("vehicleTypeName")(option.vehicle_type_name || '')
                                        this.handleChangeFor("ogmVehicleId")(option.id || '')
                                      }
                                      else {
                                        this.handleChangeFor("year")("")
                                        this.handleChangeFor("makeAndModel")("")
                                        this.handleChangeFor("vehicleIdentificationNumber")("")
                                        this.handleChangeFor("vehicleTypeName")("")
                                        this.handleChangeFor("ogmVehicleId")("")
                                      }
                                    }}
                                    tabIndex="2"
                                  />
                                </div>
                              </div>

                              <div className="col-xs-12">
                                <p>Or add a new vehicle</p>
                              </div>

                              <div className="col-xs-6">
                                <TextField name="makeAndModel"
                                           onChange={ this.handleChangeFor("makeAndModel") }
                                           label="Make and Model"
                                           value={ this.state.vehicle.makeAndModel.value }
                                           errorMessage={ this.state.vehicle.makeAndModel.errorMessage }
                                           shouldFloatLabelOnUpdate={ true }
                                           required={ true }
                                           tabIndex="3" />

                                <TextField name="year"
                                           onChange={ (value) => {
                                             let _value = value ? value.replace(/[\D]/g, '').slice(0, 4) : ""
                                             this.handleChangeFor("year")(_value)
                                           }}
                                           label="Year"
                                           value={ this.state.vehicle.year.value }
                                           errorMessage={ this.state.vehicle.year.errorMessage }
                                           shouldFloatLabelOnUpdate={ true }
                                           required={ true }
                                           tabIndex="5" />

                                 <TextField name="licensePlateNumber"
                                           onChange={ this.handleChangeFor("licensePlateNumber") }
                                           label="License Plate #"
                                           value={ this.state.vehicle.licensePlateNumber.value }
                                           errorMessage={ this.state.vehicle.licensePlateNumber.errorMessage }
                                           tabIndex="7" />
                              </div>

                              <div className="col-xs-6">
                                <TextField name="vehicleIdentificationNumber"
                                           onChange={ this.handleChangeFor("vehicleIdentificationNumber") }
                                           label="VIN #"
                                           value={ this.state.vehicle.vehicleIdentificationNumber.value }
                                           errorMessage={ this.state.vehicle.vehicleIdentificationNumber.errorMessage }
                                           shouldFloatLabelOnUpdate={ true }
                                           required={ true }
                                           tabIndex="4" />
                                <SelectWithAsyncOptionsFromRails loadPath={ this.props.vehicleTypesPath }
                                                                 label="Vehicle Type..."
                                                                 value={ this.state.vehicle.vehicleTypeName.value }
                                                                 errorMessage={ this.state.vehicle.vehicleTypeName.errorMessage }
                                                                 onChange={ this.handleChangeFor("vehicleTypeName") }
                                                                 required={ true }
                                                                 tabIndex="6" />
                                  <div style={{display: 'none'}}>
                                 <TextField name="ogmVehicleId"
                                           onChange={ this.handleChangeFor("ogmVehicleId") }
                                           label="Vehicle ID"
                                           readOnly
                                           value={ this.state.vehicle.ogmVehicleId.value }
                                           errorMessage={ this.state.vehicle.ogmVehicleId.errorMessage }
                                           shouldFloatLabelOnUpdate={ true }
                                           tabIndex="8" />
                                           </div>
                                                                 
                              </div>
                            </div>
                          </div>
                        )
                      }
                      else {
                        return (
                          <div>
                            <div className="row">
                              <div className="col-xs-6">
                                <TextField name="makeAndModel"
                                           onChange={ this.handleChangeFor("makeAndModel") }
                                           label="Make and Model"
                                           value={ this.state.vehicle.makeAndModel.value }
                                           errorMessage={ this.state.vehicle.makeAndModel.errorMessage }
                                           tabIndex="3" />

                                <TextField name="year"
                                           onChange={ (value) => {
                                             let _value = value ? value.replace(/[\D]/g, '').slice(0, 4) : ""
                                             this.handleChangeFor("year")(_value)
                                           }}
                                           label="Year"
                                           value={ this.state.vehicle.year.value }
                                           errorMessage={ this.state.vehicle.year.errorMessage }
                                           tabIndex="5" />
                              </div>

                              <div className="col-xs-6">
                                <TextField name="vehicleIdentificationNumber"
                                           onChange={ this.handleChangeFor("vehicleIdentificationNumber") }
                                           label="VIN #"
                                           value={ this.state.vehicle.vehicleIdentificationNumber.value }
                                           errorMessage={ this.state.vehicle.vehicleIdentificationNumber.errorMessage }
                                           tabIndex="4" />

                                <TextField name="licensePlateNumber"
                                           onChange={ this.handleChangeFor("licensePlateNumber") }
                                           label="License Plate #"
                                           value={ this.state.vehicle.licensePlateNumber.value }
                                           errorMessage={ this.state.vehicle.licensePlateNumber.errorMessage }
                                           tabIndex="6" />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-xs-12">
                                <p>{ 'Who owns this vehicle?' }</p>
                              </div>

                              <div className="col-xs-6">
                                <TextField name="ownerFirstName"
                                           onChange={ this.handleChangeFor("ownerFirstName") }
                                           label="First Name"
                                           value={ this.state.vehicle.ownerFirstName.value }
                                           errorMessage={ this.state.vehicle.ownerFirstName.errorMessage }
                                           tabIndex="7" />

                                <PhoneNumberField name="ownerPrimaryPhone"
                                                  onChange={ this.handleChangeFor("ownerPrimaryPhone") }
                                                  label="Phone Number"
                                                  value={ this.state.vehicle.ownerPrimaryPhone.value }
                                                  errorMessage={ this.state.vehicle.ownerPrimaryPhone.errorMessage }
                                                  tabIndex="9" />
                              </div>

                              <div className="col-xs-6">
                                <TextField name="ownerLastName"
                                           onChange={ this.handleChangeFor("ownerLastName") }
                                           label="Last Name"
                                           value={ this.state.vehicle.ownerLastName.value }
                                           errorMessage={ this.state.vehicle.ownerLastName.errorMessage }
                                           tabIndex="8" />
                              </div>

                              <div className="col-xs-12">
                                <AddressField tabIndex="10"
                                              fields={{
                                                streetAddress: {
                                                  name: "ownerStreetAddress",
                                                  value: this.state.vehicle.ownerStreetAddress.value,
                                                  errorMessage: this.state.vehicle.ownerStreetAddress.errorMessage,
                                                  onChange: this.handleChangeFor("ownerStreetAddress")
                                                },
                                                optionalLine: {
                                                  name: "ownerOptionalLine",
                                                  value: this.state.vehicle.ownerOptionalLine.value,
                                                  errorMessage: this.state.vehicle.ownerOptionalLine.errorMessage,
                                                  onChange: this.handleChangeFor("ownerOptionalLine")
                                                },
                                                city: {
                                                  name: "ownerCity",
                                                  value: this.state.vehicle.ownerCity.value,
                                                  errorMessage: this.state.vehicle.ownerCity.errorMessage,
                                                  onChange: this.handleChangeFor("ownerCity")
                                                },
                                                state: {
                                                  name: "ownerState",
                                                  value: this.state.vehicle.ownerState.value,
                                                  errorMessage: this.state.vehicle.ownerState.errorMessage,
                                                  onChange: this.handleChangeFor("ownerState")
                                                },
                                                zip: {
                                                  name: "ownerZip",
                                                  value: this.state.vehicle.ownerZip.value,
                                                  errorMessage: this.state.vehicle.ownerZip.errorMessage,
                                                  onChange: this.handleChangeFor("ownerZip")
                                                }
                                              }} />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-xs-12">
                                <p>{ 'Third-party insurance information to report?' }</p>
                              </div>

                              <div className="col-xs-12">
                                <TextField name="insuranceProviderName"
                                           onChange={ this.handleChangeFor("insuranceProviderName") }
                                           label="Insurance Provider Name"
                                           value={ this.state.vehicle.insuranceProviderName.value }
                                           errorMessage={ this.state.vehicle.insuranceProviderName.errorMessage }
                                           tabIndex="10" />
                              </div>

                              <div className="col-xs-6">
                                <TextField name="policyNumber"
                                           onChange={ this.handleChangeFor("policyNumber") }
                                           label="Policy Number"
                                           value={ this.state.vehicle.policyNumber.value }
                                           errorMessage={ this.state.vehicle.policyNumber.errorMessage }
                                           tabIndex="10" />
                              </div>

                              <div className="col-xs-6">
                                <PhoneNumberField name="insuranceProviderPhone"
                                                  onChange={ this.handleChangeFor("insuranceProviderPhone") }
                                                  label="Phone Number"
                                                  value={ this.state.vehicle.insuranceProviderPhone.value }
                                                  errorMessage={ this.state.vehicle.insuranceProviderPhone.errorMessage }
                                                  tabIndex="10" />
                              </div>

                              <div className="col-xs-12">
                                <AddressField tabIndex="10"
                                              fields={{
                                                streetAddress: {
                                                  name: "insuranceProviderStreetAddress",
                                                  value: this.state.vehicle.insuranceProviderStreetAddress.value,
                                                  errorMessage: this.state.vehicle.insuranceProviderStreetAddress.errorMessage,
                                                  onChange: this.handleChangeFor("insuranceProviderStreetAddress")
                                                },
                                                optionalLine: {
                                                  name: "insuranceProviderOptionalLine",
                                                  value: this.state.vehicle.insuranceProviderOptionalLine.value,
                                                  errorMessage: this.state.vehicle.insuranceProviderOptionalLine.errorMessage,
                                                  onChange: this.handleChangeFor("insuranceProviderOptionalLine")
                                                },
                                                city: {
                                                  name: "insuranceProviderCity",
                                                  value: this.state.vehicle.insuranceProviderCity.value,
                                                  errorMessage: this.state.vehicle.insuranceProviderCity.errorMessage,
                                                  onChange: this.handleChangeFor("insuranceProviderCity")
                                                },
                                                state: {
                                                  name: "insuranceProviderState",
                                                  value: this.state.vehicle.insuranceProviderState.value,
                                                  errorMessage: this.state.vehicle.insuranceProviderState.errorMessage,
                                                  onChange: this.handleChangeFor("insuranceProviderState")
                                                },
                                                zip: {
                                                  name: "insuranceProviderZip",
                                                  value: this.state.vehicle.insuranceProviderZip.value,
                                                  errorMessage: this.state.vehicle.insuranceProviderZip.errorMessage,
                                                  onChange: this.handleChangeFor("insuranceProviderZip")
                                                }
                                              }} />
                              </div>
                            </div>
                          </div>
                        )
                      }
                    })()
                  }

                  <hr />

                  <div className="row">
                    <div className="col-xs-12">
                      <RadioField name="claimBeingMade"
                                  onChange={ this.handleChangeFor("claimBeingMade") }
                                  label="Is a claim being made for damage to this vehicle?"
                                  value={ this.state.vehicle.claimBeingMade.value }
                                  errorMessage={ this.state.vehicle.claimBeingMade.errorMessage }
                                  required={ true }
                                  options={[
                                    { displayText: 'Yes', value: true },
                                    { displayText: 'No', value: false }
                                  ]} />
                    </div>
                  </div>

                  {
                    this.state.vehicle.claimBeingMade.value &&
                      <div>
                        <div className="row">
                          <div className="col-xs-12">
                            <p>Describe what was damaged and how.</p>

                            <TextField type="textarea"
                                       name="descriptionOfDamage"
                                       onChange={ this.handleChangeFor("descriptionOfDamage") }
                                       label="Type..."
                                       value={ this.state.vehicle.descriptionOfDamage.value }
                                       errorMessage={ this.state.vehicle.descriptionOfDamage.errorMessage }
                                       tabIndex="12"
                                       warn={ (value) => ( value && `Character limit: ${value.length}/255` ) } />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-12">
                            <p>{ 'What is the estimated cost to repair this vehicle?' }</p>

                            <DollarValueField name="estimatedCostOfRepair"
                                              onChange={ this.handleChangeFor("estimatedCostOfRepair") }
                                              label="Estimated Cost of Repair"
                                              value={ this.state.vehicle.estimatedCostOfRepair.value }
                                              errorMessage={ this.state.vehicle.estimatedCostOfRepair.errorMessage }
                                              tabIndex="13" />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-12">
                            <p>{ 'Where can this vehicle be seen and who should be contacted for viewing?' }</p>
                          </div>

                          <div className="col-xs-6">
                            <TextField name="contactForInspectingVehicleFirstName"
                                       onChange={ this.handleChangeFor("contactForInspectingVehicleFirstName") }
                                       label="First Name"
                                       value={ this.state.vehicle.contactForInspectingVehicleFirstName.value }
                                       errorMessage={ this.state.vehicle.contactForInspectingVehicleFirstName.errorMessage }
                                       tabIndex="14" />
                          </div>

                          <div className="col-xs-6">
                            <TextField name="contactForInspectingVehicleLastName"
                                       onChange={ this.handleChangeFor("contactForInspectingVehicleLastName") }
                                       label="Last Name"
                                       value={ this.state.vehicle.contactForInspectingVehicleLastName.value }
                                       errorMessage={ this.state.vehicle.contactForInspectingVehicleLastName.errorMessage }
                                       tabIndex="15" />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-6">
                            <TextField name="contactForInspectingVehicleEmail"
                                       onChange={ this.handleChangeFor("contactForInspectingVehicleEmail") }
                                       label="Email Address"
                                       value={ this.state.vehicle.contactForInspectingVehicleEmail.value }
                                       errorMessage={ this.state.vehicle.contactForInspectingVehicleEmail.errorMessage }
                                       tabIndex="16" />
                          </div>

                          <div className="col-xs-6">
                            <PhoneNumberField name="contactForInspectingVehiclePhone"
                                              onChange={ this.handleChangeFor("contactForInspectingVehiclePhone") }
                                              label="Phone Number"
                                              value={ this.state.vehicle.contactForInspectingVehiclePhone.value }
                                              errorMessage={ this.state.vehicle.contactForInspectingVehiclePhone.errorMessage }
                                              tabIndex="17" />
                          </div>

                          <div className="col-xs-12">
                            <AddressField tabIndex="18"
                                          fields={{
                                            streetAddress: {
                                              name: "vehicleStreetAddress",
                                              value: this.state.vehicle.vehicleStreetAddress.value,
                                              errorMessage: this.state.vehicle.vehicleStreetAddress.errorMessage,
                                              onChange: this.handleChangeFor("vehicleStreetAddress")
                                            },
                                            optionalLine: {
                                              name: "vehicleOptionalLine",
                                              value: this.state.vehicle.vehicleOptionalLine.value,
                                              errorMessage: this.state.vehicle.vehicleOptionalLine.errorMessage,
                                              onChange: this.handleChangeFor("vehicleOptionalLine")
                                            },
                                            city: {
                                              name: "vehicleCity",
                                              value: this.state.vehicle.vehicleCity.value,
                                              errorMessage: this.state.vehicle.vehicleCity.errorMessage,
                                              onChange: this.handleChangeFor("vehicleCity")
                                            },
                                            state: {
                                              name: "vehicleState",
                                              value: this.state.vehicle.vehicleState.value,
                                              errorMessage: this.state.vehicle.vehicleState.errorMessage,
                                              onChange: this.handleChangeFor("vehicleState")
                                            },
                                            zip: {
                                              name: "vehicleZip",
                                              value: this.state.vehicle.vehicleZip.value,
                                              errorMessage: this.state.vehicle.vehicleZip.errorMessage,
                                              onChange: this.handleChangeFor("vehicleZip")
                                            }
                                          }} />
                          </div>
                        </div>
                      </div>
                  }

                  <hr />

                  <div className="row">
                    <div className="col-xs-12">
                      <div className="is-required">
                        <label>{ 'Was someone operating this vehicle at the time of the incident?' }<span></span></label>
                      </div>

                      <RadioField name="wasSomeoneDriving"
                                  onChange={ this.handleChangeFor("wasSomeoneDriving") }
                                  value={ this.state.vehicle.wasSomeoneDriving.value }
                                  errorMessage={ this.state.vehicle.wasSomeoneDriving.errorMessage }
                                  options={[
                                    { displayText: 'Yes', value: true },
                                    { displayText: 'No', value: false }
                                  ]} />
                    </div>
                  </div>

                  {
                    this.state.vehicle.wasSomeoneDriving.value &&
                      <div>
                        <div className="row">
                          <div className="col-xs-12">
                            <p>{ 'Who was operating this vehicle at the time of the incident?' }</p>
                          </div>

                          { /* provide an option to use owner's infor when not member-owned */
                            !this.state.vehicle.memberOwned.value &&
                              <SameAsCheckbox fields={[
                                                {
                                                  source: this.state.vehicle.ownerFirstName.value,
                                                  target: this.state.vehicle.driverFirstName.value,
                                                  handleChange: this.handleChangeFor("driverFirstName")
                                                },
                                                {
                                                  source: this.state.vehicle.ownerLastName.value,
                                                  target: this.state.vehicle.driverLastName.value,
                                                  handleChange: this.handleChangeFor("driverLastName")
                                                },
                                                {
                                                  source: this.state.vehicle.ownerPrimaryPhone.value,
                                                  target: this.state.vehicle.driverPrimaryPhone.value,
                                                  handleChange: this.handleChangeFor("driverPrimaryPhone")
                                                },
                                                {
                                                  source: this.state.vehicle.ownerStreetAddress.value,
                                                  target: this.state.vehicle.driverStreetAddress.value,
                                                  handleChange: this.handleChangeFor("driverStreetAddress")
                                                },
                                                {
                                                  source: this.state.vehicle.ownerOptionalLine.value,
                                                  target: this.state.vehicle.driverOptionalLine.value,
                                                  handleChange: this.handleChangeFor("driverOptionalLine")
                                                },
                                                {
                                                  source: this.state.vehicle.ownerCity.value,
                                                  target: this.state.vehicle.driverCity.value,
                                                  handleChange: this.handleChangeFor("driverCity")
                                                },
                                                {
                                                  source: this.state.vehicle.ownerState.value,
                                                  target: this.state.vehicle.driverState.value,
                                                  handleChange: this.handleChangeFor("driverState")
                                                },
                                                {
                                                  source: this.state.vehicle.ownerZip.value,
                                                  target: this.state.vehicle.driverZip.value,
                                                  handleChange: this.handleChangeFor("driverZip")
                                                }
                                              ]}
                                              label="Same as owner?"
                                              wrapperClass="col-xs-12" />
                          }

                          <div className="col-xs-6">
                            <TextField name="driverFirstName"
                                       onChange={ this.handleChangeFor("driverFirstName") }
                                       label="First Name"
                                       value={ this.state.vehicle.driverFirstName.value }
                                       errorMessage={ this.state.vehicle.driverFirstName.errorMessage }
                                       shouldFloatLabelOnUpdate={ true }
                                       tabIndex="20" />
                          </div>

                          <div className="col-xs-6">
                            <TextField name="driverLastName"
                                       onChange={ this.handleChangeFor("driverLastName") }
                                       label="Last Name"
                                       value={ this.state.vehicle.driverLastName.value }
                                       errorMessage={ this.state.vehicle.driverLastName.errorMessage }
                                       shouldFloatLabelOnUpdate={ true }
                                       tabIndex="21" />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-6">
                            <PhoneNumberField name="driverPrimaryPhone"
                                              onChange={ this.handleChangeFor("driverPrimaryPhone") }
                                              label="Primary Phone"
                                              value={ this.state.vehicle.driverPrimaryPhone.value }
                                              errorMessage={ this.state.vehicle.driverPrimaryPhone.errorMessage }
                                              shouldFloatLabelOnUpdate={ true }
                                              tabIndex="22" />

                            <TextField name="driverEmail"
                                       onChange={ this.handleChangeFor("driverEmail") }
                                       label="Email Address"
                                       value={ this.state.vehicle.driverEmail.value }
                                       errorMessage={ this.state.vehicle.driverEmail.errorMessage }
                                       tabIndex="24" />
                          </div>

                          <div className="col-xs-6">
                            <PhoneNumberField name="driverAlternatePhone"
                                              onChange={ this.handleChangeFor("driverAlternatePhone") }
                                              label="Alternate Phone"
                                              value={ this.state.vehicle.driverAlternatePhone.value }
                                              errorMessage={ this.state.vehicle.driverAlternatePhone.errorMessage }
                                              tabIndex="23" />

                            <TextField name="driverLicenseNumber"
                                       onChange={ this.handleChangeFor("driverLicenseNumber") }
                                       label="Driver's License Number"
                                       value={ this.state.vehicle.driverLicenseNumber.value }
                                       errorMessage={ this.state.vehicle.driverLicenseNumber.errorMessage }
                                       tabIndex="25" />
                          </div>

                          <div className="col-xs-12">
                            <AddressField tabIndex="25"
                                          fields={{
                                            streetAddress: {
                                              name: "driverStreetAddress",
                                              value: this.state.vehicle.driverStreetAddress.value,
                                              errorMessage: this.state.vehicle.driverStreetAddress.errorMessage,
                                              onChange: this.handleChangeFor("driverStreetAddress")
                                            },
                                            optionalLine: {
                                              name: "driverOptionalLine",
                                              value: this.state.vehicle.driverOptionalLine.value,
                                              errorMessage: this.state.vehicle.driverOptionalLine.errorMessage,
                                              onChange: this.handleChangeFor("driverOptionalLine")
                                            },
                                            city: {
                                              name: "driverCity",
                                              value: this.state.vehicle.driverCity.value,
                                              errorMessage: this.state.vehicle.driverCity.errorMessage,
                                              onChange: this.handleChangeFor("driverCity")
                                            },
                                            state: {
                                              name: "driverState",
                                              value: this.state.vehicle.driverState.value,
                                              errorMessage: this.state.vehicle.driverState.errorMessage,
                                              onChange: this.handleChangeFor("driverState")
                                            },
                                            zip: {
                                              name: "driverZip",
                                              value: this.state.vehicle.driverZip.value,
                                              errorMessage: this.state.vehicle.driverZip.errorMessage,
                                              onChange: this.handleChangeFor("driverZip")
                                            }
                                          }}
                                          shouldFloatLabelsOnUpdate={ true } />
                          </div>
                        </div>

                        { /* ask some additional questions about the driver when memberOwned */
                          this.state.vehicle.memberOwned.value &&
                            <div>
                              <div className="row">
                                <div className="col-xs-12">
                                  <RadioField name="hadPermissionToUse"
                                              onChange={ this.handleChangeFor("hadPermissionToUse") }
                                              label="Was this vehicle used with permission?"
                                              value={ this.state.vehicle.hadPermissionToUse.value }
                                              errorMessage={ this.state.vehicle.hadPermissionToUse.errorMessage }
                                              required={ true }
                                              options={[
                                                { displayText: 'Yes', value: true },
                                                { displayText: 'No', value: false }
                                              ]} />
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-xs-12">
                                  <SelectField name="driverType"
                                               onChange={ this.handleChangeFor("driverType") }
                                               label="What is the driver's relationship to the organization?"
                                               placeholder="Select..."
                                               value={ this.state.vehicle.driverType.value }
                                               errorMessage={ this.state.vehicle.driverType.errorMessage }
                                               tabIndex="27"
                                               options={ this.props.driverTypeOptions } />
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-xs-12">
                                  <SelectField name="driverReasonToUseVehicle"
                                               onChange={ this.handleChangeFor("driverReasonToUseVehicle") }
                                               label="For what purpose was this vehicle in use at the time of the incident?"
                                               placeholder="Select..."
                                               value={ this.state.vehicle.driverReasonToUseVehicle.value }
                                               errorMessage={ this.state.vehicle.driverReasonToUseVehicle.errorMessage }
                                               tabIndex="28"
                                               options={ this.props.driverReasonToUseVehicleOptions } />
                                </div>
                              </div>
                            </div>
                        }
                      </div>
                  }

                  <hr />

                  <div className="row">
                    <div className="col-xs-12">
                      <h4>Miscellaneous</h4>

                        <p>{ 'How many passengers were in the vehicle at the time of the ' +
                             'incident? (In the case of a school bus, this would include students.)' }</p>

                           <TextField name="passengerCount"
                                   onChange={ this.handleChangeFor("passengerCount") }
                                   label="Passenger Count"
                                   value={ this.state.vehicle.passengerCount.value }
                                   errorMessage={ this.state.vehicle.passengerCount.errorMessage }
                                   tabIndex="36" />

                      <p>{ 'Any additional comments to report at this time?' }</p>

                      <TextField name="additionalComments"
                                 type="textarea"
                                 onChange={ this.handleChangeFor("additionalComments") }
                                 label="Type..."
                                 value={ this.state.vehicle.additionalComments.value }
                                 errorMessage={ this.state.vehicle.additionalComments.errorMessage }
                                 tabIndex="37"
                                 warn={ (value) => ( value && `Character limit: ${value.length}/255` ) } />
                    </div>
                  </div>
                </div>
            }
          </PanelBody>

          <PanelControls>
            <Prompt when={ this.anyChangesMade("vehicle", this.props.vehicle) }
                    message="Are you sure you want to move away? Any unsaved changes
                             made to this section will be lost." />

            { this.renderControlGroup() }
          </PanelControls>
        </PanelRightColumn>
      </Panel>
    )
  }
}

VehiclesForm = connect(
  (state, props) => ({
    updateSucceeded: state.wizard.updateSucceeded,
    updating:        state.wizard.updating,

    driverTypeOptions: state.rails.enumOptionsForSelect["InvolvedVehicle"].driverType,
    driverReasonToUseVehicleOptions: state.rails.enumOptionsForSelect["InvolvedVehicle"].driverReasonToUseVehicle,
    vehicleTypesPath: state.rails.asyncHelpers.vehicleTypes,

    apiEndpoint:     apiEndpoint(state.rails, "incident_report")
  }),
  null,
  ({ apiEndpoint, ...stateProps }, { dispatch }, props) => ({
    ...stateProps,
    ...props,

    asyncUpdateVehicles: (data) => {
      return asyncUpdateVehicles(apiEndpoint.path, data)(dispatch)
    },

    resetWizardState: () => { dispatch(resetWizardState()) }
  })
)(VehiclesForm);

export default VehiclesForm
