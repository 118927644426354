import React from 'react'
import { connect } from 'react-redux'
import { Link, Prompt, Redirect } from 'react-router-dom'

import { Panel, PanelSidebar, PanelRightColumn, PanelBody, PanelControls } from '../panel'
import { getWitnessesCount } from '../ducks/witnesses.js'

import WitnessesPrompt from './witnesses_prompt.jsx'
import WitnessesForm from './witnesses_form.jsx'
import WitnessesHeader from './witnesses_header.jsx'

class WitnessesNew extends React.Component {
  constructor(props) {
    super(props)

    this.state = { incidentReport: { wereThereAnyWitnesses: this.props.wereThereAnyWitnesses } }
  }

  renderState = () => {
    if (this.props.witnessesCount === 0 && !this.state.incidentReport.wereThereAnyWitnesses) {
      return "prompt"
    }
    else if (this.props.witnessesCount === 0 && this.state.incidentReport.wereThereAnyWitnesses) {
      return "prompt_with_form"
    }
    else {
      return "form"
    }
  }

  toggleWereThereAnyWitnesses = (value) => {
    this.setState((prevState, props) => ({
      incidentReport: { ...prevState.incidentReport, wereThereAnyWitnesses: value }
    }))
  }

  render() {
    switch (this.renderState()) {
      case "prompt": {
        return (
          <Panel>
            <PanelSidebar />

            <PanelRightColumn>
              <PanelBody>
                <WitnessesHeader />

                <div className="row">
                  <div className="col-xs-12">
                    <WitnessesPrompt onChange={ this.toggleWereThereAnyWitnesses }
                                     value={ this.state.incidentReport.wereThereAnyWitnesses } />
                  </div>
                </div>
              </PanelBody>

              <PanelControls>
                <Link to="/properties">
                  <button type="button"
                          className="btn btn-link">

                    Back
                  </button>
                </Link>

                <Link to="/attachments">
                  <button type="button"
                          className="btn btn-success">

                    Next
                  </button>
                </Link>
              </PanelControls>
            </PanelRightColumn>
          </Panel>
        )
      }

      case "prompt_with_form": {
        return <WitnessesForm withPrompt
                             promptValue={ this.state.incidentReport.wereThereAnyWitnesses }
                             onPromptChange={ this.toggleWereThereAnyWitnesses }
                             incidentReport={{ ...this.state.incidentReport }} />
      }

      default: {
        return <WitnessesForm />
      }
    }
  }
}

WitnessesNew = connect(
  (state, props) => ({
    witnessesCount: getWitnessesCount(state.witnesses),
    wereThereAnyWitnesses: state.incidentReport.wereThereAnyWitnesses
  })
)(WitnessesNew);

export default WitnessesNew
