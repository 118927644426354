import React from 'react'
import { Provider } from 'react-redux'

import { dispatchCustomEvent } from 'shared/utils/events.js'

import store, { resetStore } from './store.js'
import { default as DocumentUploadContainer } from './components/container.jsx'

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = { currentlyUploadingAFile: false }

    this.beginUploadingAFile   = this.beginUploadingAFile.bind(this)
    this.finishUploadingAFile  = this.finishUploadingAFile.bind(this)
    this.notifyOnUploadSuccess = this.notifyOnUploadSuccess.bind(this)
  }

  beginUploadingAFile() {
    this.setState({ currentlyUploadingAFile: true })
  }

  finishUploadingAFile() {
    this.setState(
      { currentlyUploadingAFile: false },
      () => { store.dispatch(resetStore()) }
    );
  }

  notifyOnUploadSuccess(row) {
    dispatchCustomEvent(this, 'file_share:uploaded', row)
  }

  render() {
    return (
      <Provider store={ store }>
        <div>
          <a className="btn btn-sm btn-brand-yellow" onClick={ this.beginUploadingAFile }>
            Upload Document
          </a>

          {
            this.state.currentlyUploadingAFile &&
              <DocumentUploadContainer
                finishUploadingAFile={ this.finishUploadingAFile }
                notifyOnUploadSuccess={ this.notifyOnUploadSuccess }
                allowableMimeTypes={ this.props.allowableMimeTypes }
                submitPath={ this.props.submitPath }
                submitMethod={ this.props.submitMethod }
              />
          }
        </div>
      </Provider>
    )
  }
}

export default App;
