import RailsApi from 'shared/utils/rails_api.js'

import { refreshMetaData } from './rails.js'
import { setErrorFlash, setSuccessFlash, updateSucceeded, updateFailed, updateStarted } from './wizard.js'
import { updateIncidentReport } from './incident_report.js'

const LOAD_WITNESSES   = "incident_report_form/witnesses/LOAD_WITNESSES"
const UPDATE_WITNESSES = "incident_report_form/witnesses/UPDATE_WITNESSES"

export default function witnesses(state = {}, action) {
  switch (action.type) {
    case LOAD_WITNESSES: {
      return { ...action.witnesses }
    }

    case UPDATE_WITNESSES: {
      return { ...action.witnesses }
    }

    default: {
      return state
    }
  }
}

export const loadWitnesses = (witnesses) => {
  return { type: LOAD_WITNESSES, witnesses: witnesses }
}

export const updateWitnesses = (witnesses) => {
  return { type: UPDATE_WITNESSES, witnesses: witnesses }
}

export const getWitnessesCount = (state) => ( Object.keys(state).length )

export const getWitness = (state, id) => {
  return Object.values(state).find((property) => (
    property.id && property.id.toString() === id.toString()
  ))
}

export const asyncUpdateWitnesses = (url, data) => (dispatch) => {
  dispatch(updateStarted())
  
  return RailsApi.patch(url, { body: data }).
    then((json) => {
      dispatch({
        type: 'RAILS_UPDATE_SUCCEEDED',
        actions: [
          refreshMetaData({ ...json.meta }),
          updateIncidentReport(json.incidentReport),
          updateWitnesses(json.incidentReport.witnessesAttributes),
          updateSucceeded(),
          setSuccessFlash("Witnesses updated", { keep: true })
        ]
      })
    }).
    catch((json) => {
      console.log(json)

      dispatch({
        type: 'RAILS_UPDATE_FAILED',
        actions: [
          updateFailed(),
          setErrorFlash(json.error.message, { details: json.error.details })
        ]
      })
    })
}

export const asyncDeleteWitness = (url, id, witnessesCount) => (dispatch) => {
  let data = {
    incidentReport: {
      witnessesAttributes: {
        [id]: { id: id, "_destroy": true }
      }
    }
  }

  if (witnessesCount === 1) { // we're about to delete the last witness 😱
    data.incidentReport.wereThereAnyWitnesses = false
  }

  dispatch(updateStarted())

  return RailsApi.patch(url, { body: data }).
    then((json) => {
      dispatch({
        type: 'RAILS_UPDATE_SUCCEEDED',
        actions: [
          refreshMetaData({ ...json.meta }),
          updateIncidentReport(json.incidentReport),
          updateWitnesses(json.incidentReport.witnessesAttributes),
          updateSucceeded(),
          setSuccessFlash("Property deleted", { keep: witnessesCount === 1 }) // we redirect when we delete the last property
        ]
      })
    }).
    catch((json) => {
      console.log(json)

      dispatch({
        type: 'RAILS_UPDATE_FAILED',
        actions: [
          updateFailed(),
          setErrorFlash(json.error.message, { details: json.error.details })
        ]
      })
    })
}
