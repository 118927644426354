import React from 'react'
import { Link } from 'react-router-dom'

import { Panel, PanelSidebar, PanelRightColumn, PanelBody, PanelControls } from '../panel'

import OverviewForm from './overview_form.jsx'

let OverviewRouter = () => ( // this doesn't have any nested routes to worry about, so we punt
  <OverviewForm />
)

export default OverviewRouter
