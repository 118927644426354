import React from 'react'
import PropTypes from 'prop-types';
import { change, touch } from 'redux-form'
import { connect } from 'react-redux'

class FileInput extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    let { dispatch, form, name, fieldToPopulateWithFilename } = this.props;

    dispatch(change(form, name, event.target.files[0] || null))
    dispatch(touch(form, name))

    if (fieldToPopulateWithFilename) {
      dispatch(change(
        form,
        fieldToPopulateWithFilename,
        event.target.files[0] ? event.target.files[0].name : ''
      ))
    }
  }

  render() {
    let { form, name, dispatch, className, fieldToPopulateWithFilename, ...filtered } = this.props;

    return (
      <input { ...filtered } type="file" className={ className || 'form-control' }
        onChange={ this.handleChange }
      />
    )
  }
}

FileInput.propTypes = {
  form: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,

  fieldToPopulateWithFilename: PropTypes.string
}

FileInput = connect()(FileInput);

export default FileInput;
