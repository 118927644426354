import React from 'react'
import { BrowserRouter as Router, Route, Prompt } from 'react-router-dom'

import RailsApi from 'shared/utils/rails_api.js'
import BaseForm from 'shared/components/base_form.jsx'
import {
  FormField,
  TextField,
  SelectField
} from 'shared/components/fields'
import { requiredValidator } from 'shared/utils/validators'

import { apiEndpoint } from './ducks/rails.js'
import { Panel, PanelRightColumn, PanelBody, PanelControls } from './panel'

class App extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      elasticube: {
        server:   new FormField(props.elasticube.server, [requiredValidator()]),
        name:     new FormField(props.elasticube.name, [requiredValidator()]),

        table:    new FormField(props.elasticube.table, [requiredValidator()]),
        column:   new FormField(props.elasticube.column, [requiredValidator()]),
        dataType: new FormField('text', [requiredValidator()]),

        strategy: new FormField(props.elasticube.strategy, [requiredValidator()])
      },

      updating: false,
      flash: null
    }
  }

  handleChangeFor = (name) => ( this.handleFieldChange("elasticube", name) )

  prepareData = () => {
    let copy = { elasticube: { ...this.state.elasticube } }

    // turn FormField objects in to just the raw value
    Object.keys(copy.elasticube).map((key) => {
      copy.elasticube[key] = copy.elasticube[key].value
    })

    return copy
  }

  handleSubmit = (event) => {
    event.preventDefault()
    event.target.blur()

    this.setState({ updating: true }, () => {
      if (this.validateFields("elasticube") ) {
        const { path, method } = apiEndpoint(this.props.meta, "elasticube")
        const data = this.prepareData()

        RailsApi.submit(path, method, { body: data }).
          then((json) => {
            window.location = json.redirect
          }).
          catch((json) => {
            this.setState({ flash: { type: 'danger', ...json.error }, updating: false })
          })
      }
      else {
        this.setState({ updating: false })
      }
    })
  }

  render() {
    // figure out what the basename should be based on where rails told us we can save the form to
    const { path, method } = apiEndpoint(this.props.meta, "elasticube")
    const basename = `${path}/${method === "POST" ? "new" : "edit"}`

    return (
      <Router basename={ basename }>
        <div className="panel panel-default panel-incident-form">
          <div className="panel-heading">
            <div className="row">
              <div className="col-xs-12 col-sm-8">
                <h2 className="panel-title">
                  { method === "POST" ? 'New' : 'Edit' } Elasticube
                </h2>
              </div>

              <div className="col-xs-12 col-sm-4 text-right">

                <a href="/sisense/elasticubes"
                   onClick={ (event) => {
                     if (!confirm("Are you sure you want to move away? Any unsaved changes " +
                                  "made to this section will be lost.")) {
                       event.preventDefault()
                     }
                   }}>

                  <button className="cancel-btn btn btn-link">
                    Cancel
                  </button>
                </a>
              </div>
            </div>
          </div>

          {/* routing (there's only one) */}
          <Route exact path="/" render={ () => (
            <Panel>
              <PanelRightColumn>
                <PanelBody flash={ this.state.flash }>
                  <div className="row">
                    <div className="col-xs-12">
                      <h4>Elasticube Details</h4>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-6">
                      <TextField name="server"
                                 onChange={ this.handleChangeFor("server") }
                                 label="Server"
                                 value={ this.state.elasticube.server.value }
                                 errorMessage={ this.state.elasticube.server.errorMessage }
                                 required={ true } />
                    </div>

                    <div className="col-xs-6">
                      <TextField name="name"
                                 onChange={ this.handleChangeFor("name") }
                                 label="Name"
                                 value={ this.state.elasticube.name.value }
                                 errorMessage={ this.state.elasticube.name.errorMessage }
                                 required={ true } />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-12">
                      <h4>Where is the member_number located on this cube?</h4>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-6">
                      <TextField name="table"
                                 onChange={ this.handleChangeFor("table") }
                                 label="Table"
                                 value={ this.state.elasticube.table.value }
                                 errorMessage={ this.state.elasticube.table.errorMessage }
                                 required={ true } />
                    </div>

                    <div className="col-xs-6">
                      <TextField name="column"
                                 onChange={ this.handleChangeFor("column") }
                                 label="Column"
                                 value={ this.state.elasticube.column.value }
                                 errorMessage={ this.state.elasticube.column.errorMessage }
                                 required={ true } />
                    </div>

                    <div className="col-xs-6">
                      <TextField name="dataType"
                                 label="Data Type"
                                 value={ this.state.elasticube.dataType.value }
                                 errorMessage={ this.state.elasticube.dataType.errorMessage }
                                 readOnly={ true }
                                 required={ true } />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-12">
                      <hr />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-12">
                      <SelectField name="strategy"
                                   onChange={ this.handleChangeFor("strategy") }
                                   label="What strategy should be used to apply access rights?"
                                   placeholder="Select..."
                                   value={ this.state.elasticube.strategy.value }
                                   errorMessage={ this.state.elasticube.strategy.errorMessage }
                                   options={ this.props.meta.enumOptionsForSelect["Elasticube"].strategy }
                                   required={ true } />
                    </div>
                  </div>
                </PanelBody>

                <PanelControls>
                  <Prompt when={ this.anyChangesMade("elasticube", this.props.elasticube) }
                          message="Are you sure you want to move away? Any elasticube details will be lost." />

                  <button type="button"
                          onClick={ this.handleSubmit }
                          className={ `btn btn-success ${this.state.updating ? "disabled" : ""}` } >
                    Submit
                  </button>
                </PanelControls>
              </PanelRightColumn>
            </Panel>
          ) } />
          {/* end routing */}
        </div>
      </Router>
    )
  }
}

export default App
