import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { resetWizardState } from '../ducks/wizard.js'

import AttachmentsForm from './attachments_form.jsx'

class AttachmentsIndex extends React.Component {
  constructor(props) {
    super(props)
  }

  componentWillUnmount() {
    this.props.resetWizardState()
  }

  render() {
    return (
      <AttachmentsForm addingNewAttachments={ false } attachments={ this.props.attachments } />
    )
  }
}

AttachmentsIndex = connect(
  (state, props) => ({
    attachments: Object.values(state.attachments)
  }),
  null,
  ({ ...stateProps }, { dispatch }, props) => ({
    ...stateProps,
    ...props,

    resetWizardState: () => { dispatch(resetWizardState()) }
  })
)(AttachmentsIndex)

export default AttachmentsIndex