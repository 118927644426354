import React from 'react'
import PropTypes from 'prop-types';

export default class MultiSelectField extends React.Component {
  constructor(props) {
    super(props)
    const optionValues = props.options.map(option => option.value);
    this.state = {
      checked: this.props.value || [],
      allSelected: false || optionValues.every(v => this.props.value.includes(v))
    }
  }

  onChange = (event) => {
    let checked = event.target.checked
    let value = event.target.value

    if (checked) {
      this.setState(
        (prevState) => {
          return {
            checked: prevState.checked.concat(value).filter((e, i, a) => ( a.indexOf(e) === i )).sort()
          }
        },
        () => { this.props.onChange(this.state.checked) }
      )
    }
    else {
      this.setState(
        (prevState) => {
          let index = prevState.checked.indexOf(value)
          if (index === -1) { return { checked: prevState.checked } }

          return {
            checked: [
              ...prevState.checked.slice(0, index),
              ...prevState.checked.slice(index + 1)
            ].filter((e, i, a) => ( a.indexOf(e) === i )).sort()
          }
        },
        () => { this.props.onChange(this.state.checked) }
      )
    }
  }

  toggleAll = () => {
    const optionValues = this.props.options.map(option => option.value);
    if(this.state.allSelected) {
      this.setState(
        () => {
          return {
            checked: [],
            allSelected: false
          }
        },
        () => { this.props.onChange(this.state.checked) }
      )
    } else {
      this.setState(
        () => {
          return {
            checked: optionValues,
            allSelected: true
          }
        },
        () => { this.props.onChange(this.state.checked) }
      )
    }
  }

  render() {
    const {
      name,
      required,
      options,
      bootstrapWrapperClassName,
      className,
      errorMessage,
      label,
      tabIndex,
      allowToggleAll
    } = this.props

    let wrapperClass = [].concat(bootstrapWrapperClassName || []).
      concat(errorMessage ? "has-error" : "").
      concat(required ? "is-required" : "").
      filter((n) => n).
      join(' ')

    let inputClass = [].concat(className || []).
      filter((n) => n).
      join(' ')

    return (
      <div className={ wrapperClass } style={{ marginBottom: '2.5rem' }}> {/* TODO - stolen from how .form-group styles */}
        { label && <label>{ label }{ required ? <span></span> : "" }</label> }
        { allowToggleAll ?
          <div className="checkbox" key={ 'toggleAll' }>
            <label>
              <input type="checkbox"
                      id={ `${name}_toggleAll` }
                      name={ `${name}_toggleAll` }
                      value={ 'toggleAll' }
                      onChange={ () => this.toggleAll() }
                      checked={this.state.allSelected}
                      value="coverages_toggleAll" />Toggle All
            </label>
          </div> :
          null
        }
        {
          options.map((option, index) => (
            <div className="checkbox" key={ index }>
              <label>
                <input type="checkbox"
                       className={ inputClass }
                       id={ `${name}_${option.value}` }
                       name={ `${name}_${option.value}` }
                       value={ option.value }
                       checked={ this.state.checked.indexOf(option.value.toString()) !== -1 }
                       tabIndex={ tabIndex }
                       onChange={ this.onChange } /> { option.displayText }
              </label>
            </div>
          ))
        }

        { errorMessage && <span className="field-level-error">{ errorMessage }</span> }
      </div>
    )
  }
}

MultiSelectField.defaultProps = {
  required: false
}

MultiSelectField.propTypes = {
  name:                      PropTypes.string.isRequired,
  required:                  PropTypes.bool.isRequired,
  options:                   PropTypes.array.isRequired,
  value:                     PropTypes.array.isRequired,

  bootstrapWrapperClassName: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  className:                 PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),

  errorMessage:              PropTypes.string,
  label:                     PropTypes.string,
  instructions:              PropTypes.string,
  tabIndex:                  PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  onChange: (props) => {
    if (!props.readOnly) {
      if (typeof props.onChange !== 'function') {
        return new Error('If MultiSelectField is not ReadOnly, then onChange must be defined');
      }
    }
  }
}
