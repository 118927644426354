// normally against acronyms but socialSecurityNumberValidator is pushing it...
const ssnValidator = (message = "Invalid format") => function(value, state) {
  if (value && value.length < 9) {
    return message;
  }

  return null;
};

export default ssnValidator;
