import React from 'react'
import { Link } from 'react-router-dom'

const PropertiesCard = ({ handleDeleteClicked, property }) => {
  return (
    <div className="card">
      <header className="card-header">
        <h5 className="card-title">{ property.propertyDescription || 'Damaged Property' }</h5>

        <div className="card-header-controls">
          <Link to={ `/properties/${property.id}/edit` }>
            <span className="glyphicon glyphicon-pencil"></span>
          </Link>

          <a onClick={ handleDeleteClicked }>
            <span className="glyphicon glyphicon-trash"></span>
          </a>
        </div>
      </header>

      <div className="card-body">
        {
          property.propertyType &&
            <p>
              { property.propertyType.humanize() }
            </p>
        }

        {
          property.claimBeingMade ?
            <p>A claim is being made for damages to this property.</p>
          :
            <p>A claim is not being made for damages to this property.</p>
        }

        {
          property.streetAddress &&
            <p>
              { property.streetAddress }<br />
              {
                property.optionalLine &&
                  <span>{ property.optionalLine } <br /></span>
              }
              { `${property.city}, ${property.state} ${property.zip}` }
            </p>
        }
      </div>

      <footer className="card-footer"></footer>
    </div>
  )
}

export default PropertiesCard
