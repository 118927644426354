import React from 'react'

// Add a colon if the question doesn't already end with a : or ?
const formatInlineLabel = (label) => {
  if(typeof label === 'string' && label.slice(-1) != '?' && label.slice(-1) != ':') {
    return label + ": "
  }

  return label
}

const Label = ({ children }) => (
  <label>{ formatInlineLabel(children) }</label>
)

export default Label