import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import RailsApi from "shared/utils/rails_api.js";
import BootstrapModal from "shared/components/bootstrap_modal.jsx";
import { default as GrantRequestSummary } from "apps/grant_request_summary/app.jsx";

import {
  Panel,
  PanelSidebar,
  PanelRightColumn,
  PanelBody,
  PanelControls,
} from "../panel";
import SubmissionValidator from "../submission_validator.js";
import { loadSubmissionErrors } from "../ducks/submission_errors.js";
import { apiEndpoint } from "../ducks/rails.js";
import {
  resetWizardState,
  updateStarted,
  updateFailed,
  setErrorFlash,
} from "../ducks/wizard.js";

class ReviewRouter extends React.Component {
  constructor(props) {
    super(props);

    this.state = { confirmingSubmission: false };

    // figure out the errors, and save the to the store. this does kinda rely on knowing that we're
    // not gonna be re-rendering this component, we'd unmount it if / when we navigate away, but
    // that's it
    this.validator = new SubmissionValidator(props);
    this.validator.isValid(); // call this to populate errors
    props.loadSubmissionErrors(this.validator.errors);
  }

  componentWillUnmount() {
    this.props.resetWizardState();
  }

  handleSubmitClicked = (event) => {
    this.setState({ confirmingSubmission: true });
    if ("track" in woopra) {
      woopra.track("rails-link", {
        "link-url": event.target.href,
        "link-title": event.target.innerText,
      });
    }
  };

  handleModalClosed = () => {
    this.setState({ confirmingSubmission: false });
  };

  displaySubmitButton = () => {
    // success / button enabled
    if (
      this.validator.isValid() &&
      this.props.meta.currentlyAcceptingGrantRequests
    ) {
      return (
        <>
          <Link to="/finalize">
            <button type="button" className="btn btn-link">
              Back
            </button>
          </Link>

          <button
            type="button"
            className="btn btn-success"
            onClick={this.handleSubmitClicked}
          >
            Submit
          </button>
        </>
      );
    }

    // either grant requests are not currently being accepted or there are errors in the form
    return (
      <>
        <div className="pull-right">
          <Link to="/finalize">
            <button type="button" className="btn btn-link">
              Back
            </button>
          </Link>

          <button type="button" className="disabled btn btn-success">
            Submit
          </button>
        </div>

        {!this.props.meta.currentlyAcceptingGrantRequests && (
          <div>
            <p>
              {
                this.props.meta
                  .grantRequestsMessageToDisplayWhenNotCurrentlyAccepting
              }
            </p>
          </div>
        )}
      </>
    );
  };

  displaySubmitButton = () => {
    // success / button enabled
    if (
      this.validator.isValid() &&
      this.props.meta.currentlyAcceptingGrantRequests
    ) {
      return (
        <>
          <Link to="/finalize">
            <button type="button" className="btn btn-link">
              Back
            </button>
          </Link>

          <button
            type="button"
            className="btn btn-success"
            onClick={this.handleSubmitClicked}
          >
            Submit
          </button>
        </>
      );
    }

    // either grant requests are not currently being accepted or there are errors in the form
    return (
      <>
        <div className="pull-right">
          <Link to="/finalize">
            <button type="button" className="btn btn-link">
              Back
            </button>
          </Link>

          <button type="button" className="disabled btn btn-success">
            Submit
          </button>
        </div>

        {!this.props.meta.currentlyAcceptingGrantRequests && (
          <div>
            <p>
              {
                this.props.meta
                  .grantRequestsMessageToDisplayWhenNotCurrentlyAccepting
              }
            </p>
          </div>
        )}
      </>
    );
  };

  handleSubmit = () => {
    let {
      grantRequest,
      lineItems,
      attachments,
      emailsToNotify,
      dispatch,
      apiEndpoint,
    } = this.props;

    let data = {
      grantRequest: {
        lineItemsAttributes: lineItems,
        attachmentsAttributes: attachments,
        emailsToNotifyAttributes: emailsToNotify,
      },
    };

    dispatch(updateStarted());
    return RailsApi.patch(`${apiEndpoint.path}/submit`, { body: data })
      .then((json) => {
        window.location = json.redirect;
      })
      .catch((json) => {
        console.log(json);

        dispatch({
          type: "RAILS_UPDATE_FAILED",
          actions: [
            updateFailed(),
            setErrorFlash(json.error.message, { details: json.error.details }),
          ],
        });
      });
  };

  render() {
    return (
      <Panel>
        <PanelSidebar />

        <PanelRightColumn>
          <PanelBody>
            {!this.validator.isValid() && (
              <div className="row">
                <div className="col-xs-12">
                  <div className="flash alert alert-danger">
                    <p>
                      Please address the following before submitting this grant
                      request:
                    </p>
                    <br />

                    {!this.validator.isValid() && (
                      <ul>
                        {this.validator
                          .grantRequestErrors()
                          .map(({ path, message }, index) => (
                            <li key={index}>
                              <Link to={path}>
                                {message} (Click here to fix)
                              </Link>
                            </li>
                          ))}

                        {this.validator.errors.lineItems.message && (
                          <li>
                            <Link
                              to={{
                                pathname: this.validator.errors.lineItems.path,
                                state: { fromReviewLineItemError: true },
                              }}
                            >
                              {this.validator.errors.lineItems.message} (Click
                              here to fix)
                            </Link>
                          </li>
                        )}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            )}

            <GrantRequestSummary
              grantRequest={{
                ...this.props.grantRequest,
                lineItemsAttributes: this.props.lineItems,
                attachmentsAttributes: this.props.attachments,
                emailsToNotifyAttributes: this.props.emailsToNotify,
              }}
              meta={{
                organizationName: this.props.meta.organization.name,
                threeCharacterMemberNumber:
                  this.props.meta.organization.threeCharacterMemberNumber,
              }}
              {...this.props.meta}
            />

            {this.state.confirmingSubmission && (
              <BootstrapModal
                title="Confirm"
                handleHide={this.handleModalClosed}
                buttons={[
                  { className: "btn btn-link", dismiss: true, name: "Cancel" },
                  {
                    className: "btn btn-default btn-success",
                    onClick: this.handleSubmit,
                    loading: this.props.submitting,
                    name: "Submit",
                  },
                ]}
              >
                <p>{"Are you sure you want to submit this grant request?"}</p>
              </BootstrapModal>
            )}
          </PanelBody>

          <PanelControls>{this.displaySubmitButton()}</PanelControls>
        </PanelRightColumn>
      </Panel>
    );
  }
}

ReviewRouter = connect(
  (state, props) => ({
    grantRequest: state.grantRequest,
    lineItems: state.lineItems,
    attachments: state.attachments,
    emailsToNotify: state.emailsToNotify,

    meta: state.rails,

    submitting: state.wizard.updating,
    submissionSucceeded: state.wizard.updateSucceeded,

    currentUser: state.rails.currentUser,

    apiEndpoint: apiEndpoint(state.rails, "grant_request"),
  }),
  (dispatch, props) => ({
    dispatch,
    resetWizardState: () => {
      dispatch(resetWizardState());
    },
    loadSubmissionErrors: (errors) => {
      dispatch(loadSubmissionErrors(errors));
    },
  })
)(ReviewRouter);

export default ReviewRouter;
