import React from 'react'
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom'

class BootstrapModal extends React.Component {
  componentDidMount() {
    $(ReactDOM.findDOMNode(this)).modal();

    if (this.props.handleHide) {
      $(ReactDOM.findDOMNode(this)).on('hidden.bs.modal', this.props.handleHide);
    }
  }

  componentWillUnmount() {
    // make sure this gets cleaned up
    $(ReactDOM.findDOMNode(this)).modal('hide');
  }

  handleClickThenHide = (onClick) => (event) => {
    Promise.resolve(onClick()).then(() => { $(ReactDOM.findDOMNode(this)).modal('hide'); })
  }

  generateButtons = () => {
    // TODO - there's probs a <Button> component here...
    return this.props.buttons.map((button, i) => {
      if(React.isValidElement(button)) {
        return React.cloneElement(button, { key: i })
      } else {
        const { name, onClick, dismiss, loading, hideAfterClicking = true, ...buttonProps } = button

        const buttonText = loading ?
          <span>
            <i className="fa fa-spinner fa-spin fa-fw" aria-hidden="true">
            </i> { name }
          </span>
        :
          name

        if (dismiss) {
          return <button { ...buttonProps } key={ i } onClick={ onClick } data-dismiss="modal">{ buttonText }</button>
        }
        else {
          return <button { ...buttonProps } key={ i }
                                            onClick={ hideAfterClicking ? this.handleClickThenHide(onClick) : onClick }>
                   { buttonText }
                 </button>
        }
      }
    })
  }

  render() {
    const uuid = this.uuid(); // overkill? should allow for more than one of these on a page though

    return (
      <div className="modal fade" id={ `${uuid}_modal` } tabIndex="-1" role="dialog"
                          aria-labelledby={ `${uuid}_modal_label` }
                          data-backdrop="static"
                          data-keyboard="false">

        <div className="modal-dialog" role="document">

          <div className="modal-content">
            <div className="modal-header">

              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title" id={ `${uuid}_modal_label` }>{ this.props.title }</h4>
            </div>

            <div className="modal-body">
              { this.props.children }
            </div>

            {
              this.props.buttons.length > 0 &&
                <div className="modal-footer">
                  { this.generateButtons() }
                </div>
            }
          </div>
        </div>
      </div>
    )
  }

  uuid = () => {
    // from: http://stackoverflow.com/a/2117523/1947079
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r&0x3 | 0x8);
      return v.toString(16);
    });
  }
}

BootstrapModal.defaultProps = {
  buttons: []
}

BootstrapModal.propTypes = {
  title:           PropTypes.string.isRequired,

  handleHide:      PropTypes.func,
  buttons:         PropTypes.arrayOf(PropTypes.object)
}

export default BootstrapModal;
