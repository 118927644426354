import React from 'react'

class RmgCommentBox extends React.Component {

  constructor(props) {
    super(props);

    this.floatLabel = this.floatLabel.bind(this);
  }

  floatLabel() {
    let label_floater = new App.LabelFloater({
        $label: $(this.label),
        $input: $(this.input) }
    )

    label_floater.initialize();
  }

  componentDidMount() {
    this.floatLabel();
  }

  render() {
    return (
      <div className="form-group">
        <label ref={ (label) => { this.label = label } } htmlFor="comment">
          Comment
        </label>
        <textarea ref={ (input) => { this.input = input } } className="form-control"
          name="comment" placeholder="Type..." defaultValue={ this.props.comment } onBlur={ this.props.onBlur } />
      </div>
    )
  }
}

export default RmgCommentBox;