import React from 'react'
import PropTypes from 'prop-types';

let Card = ({ fill, controls, title, body, footer }) => (
  <div className="card">
    <header className={ fill ? "card-header" : "card-header no-fill" }>
      <h5 className="card-title">{ title }</h5>

      <div className="card-header-controls">
        {
          controls.map((control, index) => <div key={ index }>{ control }</div>)
        }
      </div>
    </header>

    {
      body && <div className="card-body">
                { body }
              </div>
    }

    { footer && <footer className="card-footer">{ footer }</footer> }
  </div>
)

Card.propTypes = {
  // expecting an array of ControlButtons (or similar)
  controls: PropTypes.array,
  fill:     PropTypes.bool,

  title:    PropTypes.node.isRequired,
  body:     PropTypes.node,
  footer:   PropTypes.node
}

Card.defaultProps = {
  controls: [],
  fill: true
}

export default Card
