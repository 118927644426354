import React from 'react'
import { connect } from 'react-redux'

import { getProperty } from '../ducks/properties.js'

import PropertiesForm from './properties_form.jsx'

let PropertiesEdit = ({ property }) => (
  <PropertiesForm property={ property } />
)

PropertiesEdit = connect(
  (state, props) => ({
    property: getProperty(state.properties, props.match.params.id)
  })
)(PropertiesEdit)
export default PropertiesEdit
