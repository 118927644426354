import React from 'react'
import { connect } from 'react-redux'
import { Link, Redirect, Prompt } from 'react-router-dom'

import BaseForm from 'shared/components/base_form.jsx'
import {
  FormField,
  TextField,
  DollarValueField,
  MultiSelectField
} from 'shared/components/fields'

import {
  requiredValidator,
  lengthValidator,
  dollarValueValidator
} from 'shared/utils/validators'

import { asyncUpdateGrantRequest } from '../ducks/grant_request.js'
import { apiEndpoint } from '../ducks/rails.js'
import { resetWizardState } from '../ducks/wizard.js'
import { Panel, PanelSidebar, PanelRightColumn, PanelBody, PanelControls } from '../panel'

const requiredIfPersistedOrPartiallyFilledOut = (value, state) => {
  if (!state._id && state.id || // a persisted record
      state.description.value ||
      state.estimatedCost.value) {

    return requiredValidator()(value)
  }
}

class RequestDetailsForm extends BaseForm {
  constructor(props) {
    super(props)

    const grantRequest       = { ...(this.props.grantRequest || {}) }
    const grantRequestErrors = { ...(this.props.grantRequestErrors || {}) }

    const lineItems = [ ...(this.props.lineItems || []) ]

    this.state = {
      grantRequest: {
        description:       new FormField(grantRequest.description, [requiredValidator(), lengthValidator(50)]),
        howWillThisBeUsed: new FormField(grantRequest.howWillThisBeUsed),
        coverageIds:       new FormField(grantRequest.coverageIds, requiredValidator())
      },

      lineItems: lineItems.map((lineItem) => ({
        id:            lineItem.id,
        description:   new FormField(lineItem.description, requiredIfPersistedOrPartiallyFilledOut),
        estimatedCost: new FormField(lineItem.estimatedCost, [requiredIfPersistedOrPartiallyFilledOut, dollarValueValidator({ max: 99999.99 })])
      })).concat({
        // we have a setup where we always put an intial empty line item in the table for the user
        // to fill out
        _id: Date.now(),
        description:   new FormField('', requiredIfPersistedOrPartiallyFilledOut),
        estimatedCost: new FormField('', [requiredIfPersistedOrPartiallyFilledOut, dollarValueValidator({ max: 99999.99 })])
      })
    }

    // add any errors we found when attempting to submit
    Object.keys(this.state.grantRequest).map((key) => {
      this.state.grantRequest[key].errorMessage = (grantRequestErrors[key] || {}).message
    })
  }

  componentWillUnmount() {
    this.props.resetWizardState()
  }

  componentDidMount() {
    if (this.props.scrollToLineItemsOnMount) {
      window.scrollBy(0, document.querySelector('td.add-line-item').getBoundingClientRect().top)
    }
  }

  handleChangeFor = (name) => ( this.handleFieldChange("grantRequest", name) )

  handleLineItemChangeFor = (id, name) => ( this.handleFieldChangeInList("lineItems", id, name) )

  deleteLineItem = (lineItem) => () => {
    if (lineItem._id && !lineItem.id) { // not persisted to rails, so just ditch it
      this.removeFromList("lineItems", lineItem._id)
    }
    else {
      this.markToDestroyInList("lineItems", lineItem.id)
    }
  }

  addLineItem = () => {
    this.addToList("lineItems", {
      _id: Date.now(),
      description:   new FormField('', requiredIfPersistedOrPartiallyFilledOut),
      estimatedCost: new FormField('', [requiredIfPersistedOrPartiallyFilledOut, dollarValueValidator({ max: 99999.99 })])
    })
  }

  anyChangesMadeToLineItems = () => (
    // a little bit of a hack but this stops the intial empty record we added in the
    // constructor from counting as a change
    this.anyChangesMadeInList("lineItems", this.props.lineItems.concat({
                                             _id: Date.now(),
                                             description:   '',
                                             estimatedCost: ''
                                           }))
  )

  prepareData = () => {
    let copy = { grantRequest: { ...this.state.grantRequest } }

    Object.keys(copy.grantRequest).map((key) => {
      copy.grantRequest[key] = copy.grantRequest[key].value
    })

    copy.grantRequest.lineItemsAttributes = {}

    this.state.lineItems.forEach((lineItem, index) => {
      // this is an un-persisted blank record--we can just ignore this and not bog down the user
      if (lineItem._id && !lineItem.estimatedCost.value && !lineItem.description.value) { return }

      let { _id, ...filtered } = lineItem

      Object.keys(filtered).map((key) => {
        if (key === "_destroy" || key === "id") { return }

        filtered[key] = filtered[key].value
      })

      copy.grantRequest.lineItemsAttributes[index.toString()] = filtered
    })

    return copy
  }

  handleSave = (event) => {
    event.preventDefault()

    if (this.validateFields("grantRequest") && this.validateFieldsInList("lineItems")) {
      this.props.asyncUpdateGrantRequest(this.prepareData())
    }
  }

  render() {
    if (this.props.updateSucceeded) {
      return <Redirect to={ this.state.redirect || "/attachments" } />
    }

    return (
      <Panel>
        <PanelSidebar />

        <PanelRightColumn>
          <PanelBody>
            <div className="row">
              <div className="col-xs-12">
                <h4>Requested Items and Services</h4>

                <p>{ 'Provide a brief description of this grant request.' }</p>

                <TextField type="textarea"
                           name="description"
                           onChange={ this.handleChangeFor("description") }
                           label="Description"
                           value={ this.state.grantRequest.description.value }
                           errorMessage={ this.state.grantRequest.description.errorMessage }
                           warn={ (value) => ( value && `Character limit: ${value.length}/50` ) }
                           tabIndex="1" />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <p>{ 'How will this grant be used to help reduce the probability of claims and ' +
                     'enhance your risk management effort? Please be specific.' }</p>

                <TextField type="textarea"
                           name="howWillThisBeUsed"
                           onChange={ this.handleChangeFor("howWillThisBeUsed") }
                           label="How will this be used?"
                           value={ this.state.grantRequest.howWillThisBeUsed.value }
                           errorMessage={ this.state.grantRequest.howWillThisBeUsed.errorMessage }
                           tabIndex="2" />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <MultiSelectField label="Check only the coverage box(es) that relate to your request."
                                  type="checkbox"
                                  name="coverageIds"
                                  value={ this.state.grantRequest.coverageIds.value }
                                  errorMessage={ this.state.grantRequest.coverageIds.errorMessage }
                                  onChange={ this.handleChangeFor("coverageIds") }
                                  tabIndex="3"
                                  required={ true }
                                  options={ this.props.coverageOptions } />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th className="col-xs-6">Description</th>
                      <th>Estimated Cost</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      this.state.lineItems.filter((lineItem) => ( !lineItem._destroy )).map((lineItem, index, array) => {
                        let lineItemId = lineItem.id || lineItem._id
                        let isLast = (array.length - 1) === index

                        return (
                          <tr key={ lineItemId }>
                            <td>
                              <TextField type="textarea"
                                         bootstrapWrapperClassName="no-margin-bottom"
                                         name={ `lineItems[${lineItemId}][description]` }
                                         onChange={ this.handleLineItemChangeFor(lineItemId, 'description') }
                                         label="Description"
                                         value={ lineItem.description.value }
                                         errorMessage={ lineItem.description.errorMessage }
                                         tabIndex="3" />
                            </td>

                            <td>
                              <DollarValueField name={ `lineItems[${lineItemId}][estimatedCost]` }
                                                onChange={ this.handleLineItemChangeFor(lineItemId, 'estimatedCost') }
                                                label="Estimated Cost"
                                                value={ lineItem.estimatedCost.value }
                                                errorMessage={ lineItem.estimatedCost.errorMessage }
                                                tabIndex="3" />
                            </td>

                            {
                              isLast ?
                                <td className="add-line-item">
                                  <a onClick={ this.addLineItem }>
                                    <i className='fa fa-plus-circle'></i>
                                  </a>
                                </td>
                              :
                                <td className="delete-line-item">
                                  <a onClick={ this.deleteLineItem(lineItem) }>
                                    <i className='fa fa-trash-o'></i>
                                  </a>
                                </td>
                            }
                          </tr>
                        )
                      })
                    }
                  </tbody>

                  <tfoot className='highlighted'>
                    <tr>
                      <td></td>
                      <td>
                        TOTAL COST: ${ this.state.lineItems.filter((lineItem) => ( !lineItem._destroy )).reduce((t, l) => {
                                         return t + (Number(l.estimatedCost.value) || 0)
                                         // 👇 stolen from: https://stackoverflow.com/a/14428340/1947079
                                     }, 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') }
                      </td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>

                {
                  this.props.lineItemsErrors.message &&
                    <span className="field-level-error">{ this.props.lineItemsErrors.message }</span>
                }
              </div>
            </div>
          </PanelBody>

          <PanelControls>
            <Prompt when={
                      this.anyChangesMade("grantRequest", this.props.grantRequest) ||
                        this.anyChangesMadeToLineItems()
                    }
                    message="Are you sure you want to move away? Any unsaved changes
                             made to this section will be lost." />

            <Link to="/member_information">
              <button type="button"
                      className="btn btn-link"
                      tabIndex="4">
                Back
              </button>
            </Link>

            <Link to="/attachments" onClick={ this.handleSave }>
              <button type="button"
                      className={ `btn btn-success ${this.props.updating ? "disabled" : ""}` }
                      tabIndex="4">

                Save & Continue
              </button>
            </Link>
          </PanelControls>
        </PanelRightColumn>
      </Panel>
    )
  }
}

RequestDetailsForm = connect(
  (state, props) => ({
    grantRequest: {
      description:       state.grantRequest.description,
      howWillThisBeUsed: state.grantRequest.howWillThisBeUsed,
      coverageIds:       state.grantRequest.coverageIds.map((a) => ( a.toString() )).sort()
    },

    grantRequestErrors: state.submissionErrors.grantRequest,

    lineItems: Object.values(state.lineItems || {}).map((lineItem) => ({
      id:            lineItem.id,
      description:   lineItem.description,
      estimatedCost: lineItem.estimatedCost
    })),

    lineItemsErrors: state.submissionErrors.lineItems,

    coverageOptions: state.rails.coverageOptions,

    updateSucceeded: state.wizard.updateSucceeded,
    updating:        state.wizard.updating,
    apiEndpoint:     apiEndpoint(state.rails, "grant_request")
  }),
  null,
  ({ apiEndpoint, ...stateProps }, { dispatch }, props) => ({
    ...stateProps,
    ...props,

    asyncUpdateGrantRequest: (data) => {
      return asyncUpdateGrantRequest(apiEndpoint.path, data)(dispatch)
    },

    resetWizardState: () => { dispatch(resetWizardState()) }
  })
)(RequestDetailsForm)

export default RequestDetailsForm
