import React from 'react'

import Section from 'shared/components/summary/section.jsx'
import Row from 'shared/components/summary/row.jsx'
import BasicColumn from 'shared/components/summary/basic_column.jsx'
import Phone from 'shared/components/summary/phone.jsx'
import DateAndTime from 'shared/components/summary/date_and_time.jsx'
import Address from 'shared/components/summary/address.jsx'
import BooleanColumn from 'shared/components/summary/boolean_column.jsx'
import RailsEnum from 'shared/components/summary/rails_enum.jsx'

let PersonCard = ({
  person,
  person: {
    firstName,
    lastName,
    type,
    primaryPhone,
    initialTreatment,
    injuredAreaOfTheBodyDescription,
    natureOfInjuryDescription
  }
}) => {
  let name = `${firstName || ''} ${lastName || ''}`;
  name = (name.trim() === '') ? 'Unknown Name' : name;

  return (
    <div className="card">
      <header className="card-header">
        <h5 className="card-title">{ name }</h5>
      </header>

      <div className="card-body">
        <Row columnSize="xs-6" >
          <BasicColumn label="Employee or Third Party?"
                       value={ type === 'InjuredPerson::Employee' ? 'Employee' : 'Third Party' }/>

          {/* it sucks to have to check type here, but since we default workersCompClaim to true
              for usability reasons and we don't want to diplay true when we know it's not. */}
          <BooleanColumn label="Is Workers Comp. Claim?"
                         value={ type === 'InjuredPerson::ThirdParty' ? false : person.workersCompClaim } />
        </Row>

        <Row columnSize="xs-6" >
          <Phone label="Primary Phone" value={ person.primaryPhone } />
          <Phone label="Alternate Phone" value={ person.alternatePhone } />
          <BasicColumn label="Email Address" value={ person.emailAddress } />
        </Row>

        <Row columnSize="xs-6" >
          <BasicColumn label="Injured Area of the Body" value={ injuredAreaOfTheBodyDescription } />
          <BasicColumn label="Nature of Injury" value={ natureOfInjuryDescription } />
        </Row>

        <Row>
          <BasicColumn label="Description of injury, illness, or other harm"
                       value={ person.additionalInjuryInformation } />
        </Row>

        {
          type === 'InjuredPerson::Employee' &&
            <div>
              <Row>
                <DateAndTime label="Date of Birth" value={ person.dateOfBirth } />
                <BasicColumn label="Gender" value={ person.gender } />
                <RailsEnum label="Marital Status" value={ person.maritalStatus } />
              </Row>

              <Row>
                <Address label="Address"
                         streetAddress={ person.streetAddress }
                         optionalLine={ person.optionalLine }
                         city={ person.city }
                         state={ person.state }
                         zip={ person.zip } />
              </Row>

              <Row columnSize="xs-12">
                <BooleanColumn label="Was a Panel of Physicians provided?" value={ person.panelOfPhysiciansProvided } />
              </Row>

              <Row>
                <BasicColumn label="Job Classification" value={ person.jobClassificationDescription } />
                <BasicColumn label="Job Title" value={ person.jobTitle } />

                <BasicColumn label="Employee SSN"
                  value={
                    person.socialSecurityNumber ? `***-**-${person.socialSecurityNumber.slice(5, 9)}` : ""
                  }
                />

                <RailsEnum label="Employment Type" value={ person.employmentType } />
                <DateAndTime label="Hired Date" value={ person.hiredDate } />
                <BasicColumn label="Gross Earnings" value={ person.totalEarningsInPeriod } />
                <RailsEnum label="Earning Period" value={ person.earningPeriod } />
                <BasicColumn label="Supervisor's Name" value={ person.supervisorName } />
                <Phone label="Supervisor's Phone Number" value={ person.supervisorPhone } />

                <DateAndTime label="Date Disability Began" value={ person.dateDisabilityBegan } />
                <BooleanColumn label="Were wages paid on the date of injury?"
                               value={ person.wagesPaidOnDateOfInjury } />

                <RailsEnum label="Returned to Work?" value={ person.returnType } />
                <DateAndTime label="Actual/Anticipated Return to Work Date" value={ person.returnToWorkDate } />
                <BooleanColumn label="Modified duty available?" value={ person.modifiedDutyAvailable } />
                <RailsEnum label="Were Safeguards or Safety Equipment provided?" value={ person.safetyEquipmentProvided } />
                <RailsEnum label="Were Safeguards or Safety Equipment utilized?" value={ person.safetyEquipmentUtilized } />
              </Row>

              <Row columnSize="xs-12">
                <DateAndTime label="When did the employee start work on the day of injury?"
                             value={ person.beganWorkAt } />
              </Row>

              <Row columnSize="xs-12">
                <BasicColumn label="Where did the injury occur? (e.g. Loading dock north end)"
                             value={ person.descriptionOfWhereInjuryOccurred } />
              </Row>

              <Row columnSize="xs-12">
                <BasicColumn label="What was this individual doing prior to the injury?"
                             value={ person.descriptionOfActivityAtTimeOfIncident } />
              </Row>

              <Row>
                <BasicColumn label="What substance or object caused this injury or illness?"
                             value={ person.substanceOrObjectCausingInjury } />
              </Row>
            </div>
        }

        <Row columnSize="xs-12">
          <RailsEnum label="Initial Treatment?" value={ person.initialTreatment } />
        </Row>

        <Row columnSize="xs-6" >
          <BasicColumn label="Doctor First Name" value={ person.doctorFirstName } />
          <BasicColumn label="Doctor Last Name" value={ person.doctorLastName } />
          <BasicColumn label="Medical Facility" value={ person.nameOfMedicalFacility } />
        </Row>

        <Row>
          <BasicColumn label="Who is this individual's health insurer?" value={ person.insuranceProviderName } />
        </Row>

        {
          (!(type === "InjuredPerson::Employee" && person.workersCompClaim)) &&
            <div>
              <Row columnSize="xs-6" >
                <BooleanColumn label="Does this involve an employment discrimination issue?"
                               value={ person.sufferedEmploymentDiscrimination } />
                <BooleanColumn label="Does this involve a civil rights violation?"
                               value={ person.sufferedACivilRightsViolation } />
              </Row>
              <Row columnSize="xs-12" >
                {
                  type !== "InjuredPerson::Employee" &&
                    <BooleanColumn label="Does this involve a student issue other than physical injury?"
                                   value={ person.involvedAStudentIssue } />
                }
              </Row>
            </div>
        }

        <Row columnSize="xs-12">
          <BooleanColumn label="Additional Comments" value={ person.additionalComments } />
        </Row>
      </div>

      <footer className="card-footer"></footer>
    </div>
  );
}

export default PersonCard;
