import React from "react";
import { connect } from "react-redux";

import { activeCoverage } from "apps/cyber/shared/ducks/coverage_types.js";
import { changeTab } from "apps/cyber/shared/ducks/tabs.js";

import { apiEndpoint } from "../ducks/rails.js";
import {
    canUpdateCoverageType,
    submitCoverageTypeToRails,
} from "../ducks/coverage_types.js";

import QuestionCount from "./question_count.jsx";

class EditableSurveyTabLink extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: false,
            inputValue: this.props.coverage.alias || this.props.coverage.name,
        };

        this.startEditing = this.startEditing.bind(this);
        this.handleAliasChange = this.handleAliasChange.bind(this);
        this._stopEditing = this._stopEditing.bind(this);
        this.cancelEditing = this.cancelEditing.bind(this);
        this.saveEdits = this.saveEdits.bind(this);
    }

    handleAliasChange(event) {
        this.setState({ inputValue: event.target.value });
    }

    startEditing(event) {
        // don't switch tabs
        event.stopPropagation();
        event.preventDefault();

        if (this.props.canUpdateCoverageType) {
            this.setState({ editing: true });
        }
    }

    _stopEditing(event, shouldSave = false) {
        // don't switch tabs
        event.stopPropagation();
        event.preventDefault();

        let {
            apiEndpoint: { path, method },
            dispatch,
        } = this.props;
        path = path.replace(/:id/g, this.props.coverage.id);

        this.setState({ editing: false }, () => {
            if (shouldSave) {
                submitCoverageTypeToRails(
                    { alias: this.state.inputValue },
                    path,
                    method,
                    dispatch
                );
            }
        });
    }

    cancelEditing(event) {
        this.setState({
            inputValue: this.props.coverage.alias || this.props.coverage.name,
        });
        this._stopEditing(event);
        if ("track" in woopra) {
            woopra.track("rails-link", {
                "link-url": event.target.href,
                "link-title": event.target.innerText,
            });
        }
    }

    saveEdits(event) {
        this._stopEditing(event, true);
        if ("track" in woopra) {
            woopra.track("rails-link", {
                "link-url": event.target.href,
                "link-title": event.target.innerText,
            });
        }
    }

    render() {
        if (this.props.canUpdateCoverageType && this.state.editing) {
            return (
                <li className="parent-highlight">
                    <div className="form-inline">
                        <div className="form-group" style={{ marginBottom: "0px" }}>
                            <input
                                type="text"
                                autoFocus={true}
                                className="form-control"
                                value={this.state.inputValue}
                                onChange={this.handleAliasChange}
                            />
                        </div>

                        <div className="form-group" style={{ marginBottom: "0px" }}>
                            <button
                                type="submit"
                                className="btn btn-sm btn-link"
                                onClick={this.cancelEditing}
                            >
                                Cancel
                            </button>
                        </div>

                        <div className="form-group" style={{ marginBottom: "0px" }}>
                            <button
                                type="submit"
                                className="btn btn-sm btn-success"
                                onClick={this.saveEdits}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </li>
            );
        } else {
            return (
                <li className="parent-highlight">
                    <a
                        onClick={this.props.switchActiveCoverage}
                        className={this.props.active ? "active" : ""}
                    >
                        {this.props.canUpdateCoverageType && (
                            <span
                                className="glyphicon glyphicon-pencil"
                                onClick={this.startEditing}
                            ></span>
                        )}
                        &nbsp;{this.state.inputValue}
                    </a>
                    <QuestionCount coverage={this.props.coverage} />
                </li>
            );
        }
    }
}

EditableSurveyTabLink = connect(
    (state, props) => ({
        active:
            activeCoverage(state.coverageTypes, state.tab).id == props.coverage.id,
        apiEndpoint: apiEndpoint(state.rails, {
            controller: "cyber/coverage_types",
            resource: "coverageTypes",
            mode: "update",
        }),
        canUpdateCoverageType: canUpdateCoverageType(props.coverage),
    }),

    null,

    (stateProps, dispatchProps, ownProps) => ({
        ...stateProps,
        ...dispatchProps,
        ...ownProps,

        switchActiveCoverage: () => {
            dispatchProps.dispatch(changeTab(ownProps.index));
        },
    })
)(EditableSurveyTabLink);

export default EditableSurveyTabLink;
