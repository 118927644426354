import React from 'react'
import PropTypes from 'prop-types';

import BootstrapModal from 'shared/components/bootstrap_modal.jsx'

import DocumentUploadTable from './document_upload_table.jsx'
import UploadButton from './upload_button.jsx'

const DocumentUploadModal = ({ documents, handleDeleteFor, handleHideModal, handleSubmit, handleAddFiles, handleTitleChangeFor, submitting }) => (
  <BootstrapModal title="Upload Documents"
                  handleHide={ handleHideModal }
                  buttons={[
                    { className: "btn btn-link pull-left", dismiss: true, name: "Cancel" },
                    <UploadButton label="Add More..." className="btn btn-default" handleChooseFiles={ handleAddFiles } />,
                    {
                      className: "btn btn-default btn-success",
                      onClick: handleSubmit,
                      loading: submitting,
                      name: "Upload Files",
                      hideAfterClicking: false
                    }
                  ]}>
    <DocumentUploadTable
      documents={ documents }
      handleDeleteFor={ handleDeleteFor }
      handleTitleChangeFor={ handleTitleChangeFor }
    />
  </BootstrapModal>
)


DocumentUploadModal.propTypes = {
  documents: PropTypes.array,
  handleSubmit: PropTypes.func,
  handleDeleteFor: PropTypes.func,
  handleAddFiles: PropTypes.func,
  handleTitleChangeFor: PropTypes.func,
  submitting: PropTypes.bool
}

export default DocumentUploadModal
