import React from 'react'
import PropTypes from 'prop-types';

import BootstrapModal from 'shared/components/bootstrap_modal'
import { TextField } from 'shared/components/fields'

const Button = () => <a className='btn btn-default'>Button!</a>

const EditDocumentModal = ({
  document,
  handleCloseModal,
  handleSaveDocument,
  handleTitleChange,
  saving
}) => (
  <BootstrapModal
    title="Edit Title"
    buttons={[
      { name: "Cancel",
        dismiss: true,
        className: "btn btn-link pull-left"
      },
      { name: "Save",
        className: "btn btn-success",
        onClick: handleSaveDocument,
        dismiss: false,
        loading: saving,
        hideAfterClicking: false
      }
    ]}
    handleHide={ handleCloseModal }
  >
    <table className='table'>
      <thead>
        <tr>
          <th>ID</th>
          <th>Uploaded</th>
          <th>Uploader</th>
          <th>File Name</th>
          <th>Size</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{ document.id }</td>
          <td>{ document.created_at }</td>
          <td>{ document.uploaded_by }</td>
          <td>{ document.file_name }</td>
          <td>{ document.file_size }</td>
        </tr>
      </tbody>
    </table>

    <hr />

    <h4>Edit Title</h4>
    <TextField
      label="Title"
      required={ false }
      name="title"
      value={ document.title }
      bootstrapWrapperClassName="form-group--no-margin-bottom"
      onChange={ handleTitleChange } />

  </BootstrapModal>
)

EditDocumentModal.propTypes = {
  document: PropTypes.shape({
    id: PropTypes.number.isRequired,
    created_at: PropTypes.string,
    uploaded_by: PropTypes.string,
    file_name: PropTypes.string,
    file_size: PropTypes.string,
    title: PropTypes.string
  }).isRequired,

  handleCloseModal: PropTypes.func,
  handleSaveDocument: PropTypes.func.isRequired,
  handleTitleChange: PropTypes.func.isRequired,

  saving: PropTypes.bool
}

export default EditDocumentModal
