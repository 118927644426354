import React from 'react'
import { connect } from 'react-redux'
import IconWithTooltip from 'shared/components/icon_with_tooltip.jsx'
import { extractDateFromRailsDateTime,
    extractTimeFromRailsDateTime
} from 'shared/utils/rails_datetime_formatters.js'

const ResponseLastEditedBy = ({ answer }) => {
    if(!answer.lastEditedBy) { return null }

    const lastEditedDate = extractDateFromRailsDateTime(answer.updatedAt)
    const lastEditedTime = extractTimeFromRailsDateTime(answer.updatedAt)
    const lastEditedBy = answer.lastEditedBy &&
        `${ answer.lastEditedBy.firstName } ${ answer.lastEditedBy.lastName } at `
    const content = `
    <div>
      <h4>Last Edited By:</h4>
      ${ lastEditedBy } 
      ${ lastEditedTime } ${ lastEditedDate }
    </div>`
    return (
        <IconWithTooltip icon="fa-user" content={ content } />
    )
}

export default connect(
    (state, props) => ({
        answer: state.responses.find((response) => response.sectionQuestionId == props.sectionQuestionId) || {}
    })
)(ResponseLastEditedBy)
