import React from 'react'
import { connect } from 'react-redux'

import { changeTab } from 'apps/rmg/shared/ducks/tabs'

import SurveyTabLink from './survey_tab_link.jsx'
import AnswerCount from './answer_count.jsx'

let SurveyTabList = ({ coverageTypes }) => (
  <ul className="panel-body-left nav nav-stacked nav-sidebar">
    {
      coverageTypes.map((coverage, index) => (
        <li className="parent-highlight" key={ coverage.id }>
          <SurveyTabLink coverage={ coverage } index={ index } />
          <AnswerCount coverage={ coverage } />
        </li>
      ))
    }
  </ul>
)

SurveyTabList = connect(
  (state, props) => ({
    coverageTypes: state.coverageTypes
  })
)(SurveyTabList)

export default SurveyTabList
