import React from 'react'
import { connect } from 'react-redux'
import { Link, Prompt, Redirect } from 'react-router-dom'

import { Panel, PanelSidebar, PanelRightColumn, PanelBody, PanelControls } from '../panel'
import { getPersonsCount } from '../ducks/persons.js'

import PersonsPrompt from './persons_prompt.jsx'
import PersonsForm from './persons_form.jsx'
import PersonsHeader from './persons_header.jsx'

class PersonsNew extends React.Component {
  constructor(props) {
    super(props)

    this.state = { incidentReport: { wereAnyPeopleInjured: this.props.wereAnyPeopleInjured } }
  }

  renderState = () => {
    if (this.props.personsCount === 0 && !this.state.incidentReport.wereAnyPeopleInjured) {
      return "prompt"
    }
    else if (this.props.personsCount === 0 && this.state.incidentReport.wereAnyPeopleInjured) {
      return "prompt_with_form"
    }
    else {
      return "form"
    }
  }

  toggleWereAnyPeopleInjured = (value) => {
    this.setState((prevState, props) => ({
      incidentReport: { ...prevState.incidentReport, wereAnyPeopleInjured: value }
    }))
  }

  render() {
    switch (this.renderState()) {
      case "prompt": {
        return (
          <Panel>
            <PanelSidebar />

            <PanelRightColumn>
              <PanelBody>
                <PersonsHeader />

                <hr />

                <div className="row">
                  <div className="col-xs-12">
                    <PersonsPrompt onChange={ this.toggleWereAnyPeopleInjured }
                                   value={ this.state.incidentReport.wereAnyPeopleInjured } />
                  </div>
                </div>
              </PanelBody>

              <PanelControls>
                <Link to="/overview">
                  <button type="button"
                          className="btn btn-link">

                    Back
                  </button>
                </Link>

                <Link to="/vehicles">
                  <button type="button"
                          className="btn btn-success">

                    Next
                  </button>
                </Link>
              </PanelControls>
            </PanelRightColumn>
          </Panel>
        )
      }

      case "prompt_with_form": {
        return <PersonsForm withPrompt
                            promptValue={ this.state.incidentReport.wereAnyPeopleInjured }
                            onPromptChange={ this.toggleWereAnyPeopleInjured }
                            incidentReport={{ ...this.state.incidentReport }} />
      }

      default: {
        return <PersonsForm />
      }
    }
  }
}

PersonsNew = connect(
  (state, props) => ({
    personsCount: getPersonsCount(state.persons),
    wereAnyPeopleInjured: state.incidentReport.wereAnyPeopleInjured
  })
)(PersonsNew);

export default PersonsNew
