import React from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'

import { getPropertiesCount, asyncDeleteProperty } from '../ducks/properties.js'
import { apiEndpoint } from '../ducks/rails.js'
import { resetWizardState } from '../ducks/wizard.js'
import { Panel, PanelSidebar, PanelRightColumn, PanelBody, PanelControls } from '../panel'
import BootstrapModal from 'shared/components/bootstrap_modal.jsx'

import PropertiesCard from './properties_card.jsx'

class PropertiesIndex extends React.Component {
  constructor(props) {
    super(props)

    this.state = { confirmingDelete: false, deletingId: null }
  }

  componentWillUnmount() {
    this.props.resetWizardState()
  }

  handleDeleteClicked = (propertyId) => (event) => {
    this.setState({ confirmingDelete: true, deletingId: propertyId })
  }

  handleDelete = () => {
    return this.props.asyncDeleteProperty(this.state.deletingId, this.props.propertiesCount)
  }

  handleModalClosed = () => {
    this.setState({ confirmingDelete: false, deletingId: null })
  }

  render() {
    // we have a problem where, when/if the last property card is deleted we'll redirect before
    // the confirmation modal hides. that unmounts this and that before we have a chance to
    // cleanup state or the dom. this is a sucky pattern, but the alternatives are to move the
    // bootstrap modal up in the heiarchy, or track whether this and the modal are mounted or not.
    //
    if (this.props.propertiesCount === 0 && !this.state.confirmingDelete) {
      return <Redirect to="/properties/new" />
    }

    return (
      <Panel>
        <PanelSidebar />

        <PanelRightColumn>
          <PanelBody>
            <div className="row">
              <div className="col-xs-12">
                <h4>Property and Loss Report</h4>

                <p>
                  This section records damage to buildings, machinery, and
                  other <strong>non-auto</strong> type of property. Please use the Involved Vehicles
                  section to report auto property damage.
                </p>

                <p>
                  Below, you can add, edit, or delete property loss records related to this report.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <p>
                  <Link to="/properties/new">
                    <button type="button" className="btn btn-success btn-with-icon plus">
                      Add New
                    </button>
                  </Link>
                </p>
              </div>
            </div>

            <div className="row">
              {
                Object.entries(this.props.properties).map(([_, property]) => (
                  <div key={ property.id } className="col-xs-12">
                    <PropertiesCard id={ property.id }
                                    handleDeleteClicked={ this.handleDeleteClicked(property.id) }
                                    property={ property } />
                  </div>
                ))
              }
            </div>

            {
              this.state.confirmingDelete &&
                <BootstrapModal title="Confirm Deletion"
                                handleHide={ this.handleModalClosed }
                                buttons={[
                                  { className: "btn btn-link", dismiss: true, name: "Cancel" },
                                  {
                                    className: "btn btn-default btn-danger",
                                    onClick: this.handleDelete,
                                    loading: this.props.deletingProperty,
                                    name: "Delete"
                                  }
                                ]}>

                  <p>{ 'Are you sure you want to delete this property or loss record?' }</p>
                </BootstrapModal>
            }
          </PanelBody>

          <PanelControls>
            <Link to="/vehicles">
              <button type="button"
                      className="btn btn-link">
                Back
              </button>
            </Link>


            <Link to="/witnesses">
              <button type="button"
                      className="btn btn-success">

                Save & Continue
              </button>
            </Link>
          </PanelControls>
        </PanelRightColumn>
      </Panel>
    )
  }
}

PropertiesIndex = connect(
  (state, props) => ({
    propertiesCount:  getPropertiesCount(state.properties),
    properties:       state.properties,
    deletingProperty: state.wizard.updating,
    apiEndpoint:      apiEndpoint(state.rails, "incident_report")
  }),
  null,
  ({ apiEndpoint, ...stateProps }, { dispatch }, props) => ({
    ...stateProps,
    ...props,

    asyncDeleteProperty: (id, propertiesCount) => {
      return asyncDeleteProperty(apiEndpoint.path, id, propertiesCount)(dispatch)
    },

    resetWizardState: () => { dispatch(resetWizardState()) }
  })
)(PropertiesIndex);

export default PropertiesIndex
