import React from 'react'
import { Provider, connect } from 'react-redux'
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from 'react-router-dom'

import store from './store.js'
import { loadMetaData, apiEndpoint } from './ducks/rails.js'
import { loadIncidentReport } from './ducks/incident_report.js'
import { loadPersons } from './ducks/persons.js'
import { loadVehicles } from './ducks/vehicles.js'
import { loadProperties } from './ducks/properties.js'
import { loadWitnesses } from './ducks/witnesses.js'
import { loadAttachments } from './ducks/attachments.js'
import { setSuccessFlash, openMobileMenu, closeMobileMenu } from './ducks/wizard.js'

import OverviewRouter from './overview/overview_router.jsx'
import ContactDetailsRouter from './contact_details/contact_details_router.jsx'
import PersonsRouter from './persons/persons_router.jsx'
import VehiclesRouter from './vehicles/vehicles_router.jsx'
import PropertiesRouter from './properties/properties_router.jsx'
import WitnessesRouter from './witnesses/witnesses_router.jsx'
import AttachmentsRouter from './attachments/attachments_router.jsx'
import ReviewRouter from './review/review_router.jsx'
import AskForHelp from './ask_for_help.jsx'

class AppHeader extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-8">
          <h2 className="panel-title">
            { this.props.reportStatus === 'in_progress' ? 'Edit' : 'New' } Occurrence Report
          </h2>
        </div>

        <div className="col-xs-12 col-sm-4 text-right">

          {
            this.props.usersThatCanBeAskedForHelp.length > 0 &&
              <AskForHelp users={ this.props.usersThatCanBeAskedForHelp } />
          }

          <a href="/incident_reports"
             onClick={ (event) => {
               if (!confirm("Are you sure you want to move away? Any unsaved changes " +
                            "made to this section will be lost.")) {
                 event.preventDefault()
               }
             }}>

            <button className="cancel-btn btn btn-link">Cancel</button>
          </a>

          <button className="mobile-claims-menu-btn btn btn-primary" onClick={ this.props.toggleMobileMenu }>
            <i className="fa fa-ellipsis-v"></i>
          </button>
        </div>
      </div>
    )
  }
}
AppHeader = connect(
  (state, props) => ({
    reportStatus:   state.incidentReport.status,
    mobileMenuOpen: state.wizard.mobileMenuOpen,
    usersThatCanBeAskedForHelp: state.rails.usersThatCanBeAskedForHelp
  }),
  null,
  ({ mobileMenuOpen, ...otherProps }, { dispatch }, props) => ({
    ...otherProps,
    ...props,

    toggleMobileMenu: () => { dispatch(mobileMenuOpen ? closeMobileMenu() : openMobileMenu()) }
  })
)(AppHeader)

class App extends React.Component {
  constructor(props) {
    super(props);

    const {
      injuredPersonsAttributes:    persons,
      involvedVehiclesAttributes:  vehicles,
      damagedPropertiesAttributes: properties,
      witnessesAttributes:         witnesses,
      attachmentsAttributes:       attachments,
      ...incidentReport
    } = props.incidentReport

    store.dispatch({
      type: 'INITIAL_LOAD_OF_DATA_FROM_RAILS',
      actions: [
        loadMetaData(props.meta),
        loadIncidentReport(incidentReport),
        loadPersons(persons),
        loadVehicles(vehicles),
        loadProperties(properties),
        loadWitnesses(witnesses),
        loadAttachments(attachments)
      ]
    })
  }

  render() {
    // figure out what the basename should be based on where rails told us we can save the form to
    const { path, method } = apiEndpoint(this.props.meta, "incident_report")
    const basename = `${path}/${method === "POST" ? "new" : "edit"}`

    return (
      <Provider store={ store }>
        <Router basename={ basename }>
          <div className="panel panel-default panel-incident-form">
            <div className="panel-heading">

              <AppHeader />

            </div>

            {/* routing */}
            {/* TODO - remove this???? <FollowRedirectFromRails /> */}
            <Route exact path="/" render={ () => <Redirect to="/overview" /> } />

            <Route path="/overview" component={ OverviewRouter } />
            <Route path="/contact_details" component={ ContactDetailsRouter } />
            <Route path="/persons" component={ PersonsRouter } />
            <Route path="/vehicles" component={ VehiclesRouter } />
            <Route path="/properties" component={ PropertiesRouter } />
            <Route path="/witnesses" component={ WitnessesRouter } />
            <Route path="/attachments" component={ AttachmentsRouter } />
            <Route path="/review" component={ ReviewRouter } />
            {/* end routing */}
          </div>
        </Router>
      </Provider>
    )
  }
}

export default App
