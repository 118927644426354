export const normalizePhoneNumber = (value) => {
  if (!value) {
    return "";
  }

  return value.replace(/[\D]/g, ''). // only numbers
    slice(0, 10); // only 10 of them
};

export const formatPhoneNumber = (value) => {
  if (!value) {
    return "";
  }

  if (value.length <= 3) {
    return value;
  }

  if (value.length <= 6) {
    return `(${value.slice(0, 3)}) ${value.slice(3)}`;
  }

  return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
};
