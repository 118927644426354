import React, { Component } from "react";
import ReactQuill from "react-quill";

class EmailEditor extends Component {
  constructor(props) {
    super(props);

    this.reactQuillRef = null;
    this.quillRef = null;

    this.state = {
      text: props.defaultMessage || "",
      showHelp: false,
    };
  }

  componentDidMount() {
    this.attachRefs();
  }

  componentDidUpdate() {
    this.attachRefs();
  }

  attachRefs() {
    // wait to make sure our first ref has actually been associated with the element
    if (typeof this.reactQuillRef.getEditor !== "function") {
      return;
    }

    // and if we already have gotten this far, we're good
    if (this.quillRef != null) {
      return;
    }

    const quillRef = this.reactQuillRef.getEditor();
    if (quillRef != null) {
      this.quillRef = quillRef;
    }
  }

  handleChange = (value) => {
    this.setState({ text: value });
    this.props.onChange(value);
  };

  insertShortcode = (name) => {
    const codeMap = {
      all: "%all",
      event_title: "%title",
      event_date: "%date",
      event_start: "%start",
      event_end: "%end",
      call_details: "%callDetails",
      job_title: "%job",
      first_name: "%first",
      last_name: "%last",
      link: "%link",
      event_number: "%eventNumber",
      event_password: "%eventPassword",

      event_venue: "%eventVenue",
      event_city: "%eventCity",
      event_state: "%eventState",
      event_zip: "%eventZip",
      event_street: "%eventStreet",
      event_street2: "%eventOptionalStreet",

      member_id: "%ID",
      member_entity: "%entity",
      phone: "%phone",
      extension: "%extension",
      email: "%email",
      badge: "%badge",
      dietary: "%dietary",
      additional_attendees: "%additionalAttendees",
      custom_1: "%custom1",
      custom_2: "%custom2",
      custom_3: "%custom3",
      custom_4: "%custom4",
    };

    const range = this.quillRef.getSelection();
    const position = range ? range.index : 0;
    this.quillRef.insertText(position, codeMap[name]);
  };

  renderFormattingHelp = () => (
    <pre>
      Use the following variables to
      <br />
      dynamically insert text into the email message:
      <br />
      <button onClick={() => this.insertShortcode("all")}>{"%all"}</button>
      {"= The attendee's responses for all questions"}
      <br />
      <button onClick={() => this.insertShortcode("event_title")}>
        {"%title"}
      </button>
      {"= The event's title"}
      <br />
      <button onClick={() => this.insertShortcode("event_date")}>
        {"%date"}
      </button>
      {"= The event's date"}
      <br />
      <button onClick={() => this.insertShortcode("event_start")}>
        {"%start"}
      </button>
      {"= The event's start time"}
      <br />
      <button onClick={() => this.insertShortcode("event_end")}>
        {"%end"}
      </button>
      {"= The event's end time"}
      <br />
      <button onClick={() => this.insertShortcode("call_details")}>
        {"%callDetails"}
      </button>
      {"= The event's call details"}
      <br />
      <button onClick={() => this.insertShortcode("job_title")}>
        {"%job"}
      </button>
      {"= The attendee's job title"}
      <br />
      <button onClick={() => this.insertShortcode("first_name")}>
        {"%first"}
      </button>
      {"= The attendee's first name"}
      <br />
      <button onClick={() => this.insertShortcode("last_name")}>
        {"%last"}
      </button>
      {"= The attendee's last name"}
      <br />
      <button onClick={() => this.insertShortcode("link")}>{"%link"}</button>
      {"= The virtual engagement url"}
      <br />
      <button onClick={() => this.insertShortcode("event_number")}>
        {"%eventNumber"}
      </button>
      {"= The virtual engagement event number"}
      <br />
      <button onClick={() => this.insertShortcode("event_password")}>
        {"%eventPassword"}
      </button>
      {"= The virtual engagement event password"}
      <br />
      {/* Locations */}
      <button onClick={() => this.insertShortcode("event_venue")}>
        {"%eventVenue"}
      </button>
      {"= The event venue"}
      <br />
      <button onClick={() => this.insertShortcode("event_city")}>
        {"%eventCity"}
      </button>
      {"= The event city"}
      <br />
      <button onClick={() => this.insertShortcode("event_state")}>
        {"%eventState"}
      </button>
      {"= The event state"}
      <br />
      <button onClick={() => this.insertShortcode("event_zip")}>
        {"%eventZip"}
      </button>
      {"= The event zip"}
      <br />
      <button onClick={() => this.insertShortcode("event_street")}>
        {"%eventStreet"}
      </button>
      {"= The event primary street"}
      <br />
      <button onClick={() => this.insertShortcode("event_street2")}>
        {"%eventOptionalStreet"}
      </button>
      {"= The event optional street"}
      <br />
      <button onClick={() => this.insertShortcode("member_id")}>{"%ID"}</button>
      {"= The attendee's Member ID"}
      <br />
      <button onClick={() => this.insertShortcode("member_entity")}>
        {"%entity"}
      </button>
      {"= The attendee's Member Entity"}
      <br />
      <button onClick={() => this.insertShortcode("phone")}>{"%phone"}</button>
      {"= The attendee's phone number"}
      <br />
      <button onClick={() => this.insertShortcode("extension")}>
        {"%extension"}
      </button>
      {"= The attendee's phone number extension"}
      <br />
      <button onClick={() => this.insertShortcode("email")}>{"%email"}</button>
      {"= The attendee's email address"}
      <br />
      <button onClick={() => this.insertShortcode("badge")}>{"%badge"}</button>
      {"= The attendee's badge name"}
      <br />
      <button onClick={() => this.insertShortcode("dietary")}>
        {"%dietary"}
      </button>
      {"= The attendee's dietary restrictions"} <br />
      <button onClick={() => this.insertShortcode("additional_attendees")}>
        {"%additionalAttendees"}
      </button>
      {"= The number of additional attendees"} <br />
      <button onClick={() => this.insertShortcode("custom_1")}>
        {"%custom1"}
      </button>
      {"= The attendee's response to Custom Question #1"} <br />
      <button onClick={() => this.insertShortcode("custom_2")}>
        {"%custom2"}
      </button>
      {"= The attendee's response to Custom Question #2"} <br />
      <button onClick={() => this.insertShortcode("custom_3")}>
        {"%custom3"}
      </button>
      {"= The attendee's response to Custom Question #3"} <br />
      <button onClick={() => this.insertShortcode("custom_4")}>
        {"%custom4"}
      </button>
      {"= The attendee's response to Custom Question #4"} <br />
    </pre>
  );

  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };

  render() {
    return (
      <div>
        <ReactQuill
          ref={(el) => {
            this.reactQuillRef = el;
          }}
          placeholder="Optional additional details can be added here . . ."
          value={this.state.text}
          onChange={this.handleChange}
          tabIndex={this.props.tabIndex}
          modules={this.modules}
        />
        <button
          className="btn btn-primary btn-block"
          onClick={() => {
            this.setState((state) => ({
              showHelp: !state.showHelp,
            }));
          }}
        >
          {`${this.state.showHelp ? "Hide" : "Show"} Formatting Help`}
        </button>
        {this.state.showHelp && this.renderFormattingHelp()}
      </div>
    );
  }
}

export default EmailEditor;
