import React from "react"
import { connect } from 'react-redux'

import { responseForSectionQuestion } from 'apps/rmg/shared/ducks/survey.js'

import { extractDateFromRailsDateTime,
         extractTimeFromRailsDateTime
       } from 'shared/utils/rails_datetime_formatters.js'

let SurveyReviewQuestion = ({ questionCode, question, answer }) => (

  <div className="review-question">
    <div className="row">
      <div className="col-xs-12">
        <h4 className="question-content">
          { questionCode && `${questionCode}. ` }
          <span dangerouslySetInnerHTML={{ __html: question.questionContent }} />
        </h4>
      </div>
    </div>

    <div className="row">
      <div className="col-xs-12">
        <p>
          {
            answer.answer ?
              <span><strong>Answer:</strong> { answer.answer }</span>
            :
              <em>No answer provided</em>
          }

          { answer.yesDate && <br /> }
          {
            answer.yesDate &&
              <span><strong>Date:</strong> { moment(answer.yesDate).format("MM/DD/YYYY") }</span>
          }

          { answer.comment && <br /> }
          {
            answer.comment &&
              <span><strong>Comment:</strong> { answer.comment }</span>
          }

          { answer.lastEditedBy && <br /> }
          {
            answer.lastEditedBy &&
              <span>
                <strong>Last Edited By:</strong> {
                `
                  ${answer.lastEditedBy.firstName} ${answer.lastEditedBy.lastName} at
                  ${extractTimeFromRailsDateTime(answer.updatedAt)}
                  ${extractDateFromRailsDateTime(answer.updatedAt)}
                `
              }</span>
          }
        </p>
      </div>
    </div>
  </div>

)

SurveyReviewQuestion = connect(
  (state, props) => ({
    answer: responseForSectionQuestion(state.responses, props.id)
  })
)(SurveyReviewQuestion)

export default SurveyReviewQuestion
