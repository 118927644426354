import React from 'react'

const Section = ({ title, children, divider = true }) => (
  <div>
    { title &&
        <div className="row">
          <div className="col-xs-12">
            <h4>{ title }</h4>
          </div>
        </div>
    }

    { children }

    { divider &&
        <div className="row">
          <div className="col-xs-12">
            <hr />
          </div>
        </div>
    }
  </div>
)

export default Section;
