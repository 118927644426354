import React from 'react'
import { connect } from 'react-redux'

let SurveyActionButtons = ({ linkToPreview }) => (
  <div>
    <a href='/rmg/admin/surveys' className='btn btn-link'>Close</a>
    <a href={ linkToPreview } target="_blank"
       className='btn btn-default'
       onClick={ (event) => { event.target.blur() } }>

      Preview
    </a>
  </div>
)

SurveyActionButtons = connect(
  (state, props) => ({
    linkToPreview: state.lookups.linkToPreview
  })
)(SurveyActionButtons)

export default SurveyActionButtons
