import React, { Component } from "react";
import ReactQuill from "react-quill";

class EmailEditorPhysical extends Component {
  constructor(props) {
    super(props);

    this.reactQuillRef = null;
    this.quillRef = null;

    this.state = {
      text: props.defaultMessage || "",
      showHelp: false,
    };
  }

  componentDidMount() {
    this.attachRefs();
  }

  componentDidUpdate() {
    this.attachRefs();
  }

  attachRefs() {
    // wait to make sure our first ref has actually been associated with the element
    if (typeof this.reactQuillRef.getEditor !== "function") {
      return;
    }

    // and if we already have gotten this far, we're good
    if (this.quillRef != null) {
      return;
    }

    const quillRef = this.reactQuillRef.getEditor();
    if (quillRef != null) {
      this.quillRef = quillRef;
    }
  }

  handleChange = (value) => {
    this.setState({ text: value });
    this.props.onChange(value);
  };

  insertShortcode = (name) => {
    const codeMap = {
      event_title: "%title",
      event_date: "%date",
      event_start: "%start",
      event_end: "%end",
      event_venue: "%eventVenue",
      event_city: "%eventCity",
      event_state: "%eventState",
      event_zip: "%eventZip",
      event_street: "%eventStreet",
      event_street2: "%eventOptionalStreet",
    };

    const range = this.quillRef.getSelection();
    const position = range ? range.index : 0;
    this.quillRef.insertText(position, codeMap[name]);
  };

  renderFormattingHelp = () => (
    <pre>
      Use the following variables to
      <br />
      dynamically insert text into the email message:
      <br />
      <button onClick={() => this.insertShortcode("event_title")}>
        {"%title"}
      </button>
      {"= The event's title"}
      <br />
      <button onClick={() => this.insertShortcode("event_date")}>
        {"%date"}
      </button>
      {"= The event's date"}
      <br />
      <button onClick={() => this.insertShortcode("event_start")}>
        {"%start"}
      </button>
      {"= The event's start time"}
      <br />
      <button onClick={() => this.insertShortcode("event_end")}>
        {"%end"}
      </button>
      {"= The event's end time"}
      <br />
      {/* Locations */}
      <button onClick={() => this.insertShortcode("event_venue")}>
        {"%eventVenue"}
      </button>
      {"= The event venue"}
      <br />
      <button onClick={() => this.insertShortcode("event_city")}>
        {"%eventCity"}
      </button>
      {"= The event city"}
      <br />
      <button onClick={() => this.insertShortcode("event_state")}>
        {"%eventState"}
      </button>
      {"= The event state"}
      <br />
      <button onClick={() => this.insertShortcode("event_zip")}>
        {"%eventZip"}
      </button>
      {"= The event zip"}
      <br />
      <button onClick={() => this.insertShortcode("event_street")}>
        {"%eventStreet"}
      </button>
      {"= The event primary street"}
      <br />
      <button onClick={() => this.insertShortcode("event_street2")}>
        {"%eventOptionalStreet"}
      </button>
      {"= The event optional street"}
      <br />
    </pre>
  );

  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };

  render() {
    return (
      <div>
        <ReactQuill
          ref={(el) => {
            this.reactQuillRef = el;
          }}
          placeholder="Optional additional details can be added here. Examples: parking instructions, masking requirements, details about lunch and/or refreshments, etc."
          value={this.state.text}
          onChange={this.handleChange}
          tabIndex={this.props.tabIndex}
          modules={this.modules}
        />
        <button
          className="btn btn-primary btn-block"
          onClick={() => {
            this.setState((state) => ({
              showHelp: !state.showHelp,
            }));
          }}
        >
          {`${this.state.showHelp ? "Hide" : "Show"} Formatting Help`}
        </button>
        {this.state.showHelp && this.renderFormattingHelp()}
      </div>
    );
  }
}

export default EmailEditorPhysical;
