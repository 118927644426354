import { requiredValidator } from 'shared/utils/validators'

export default class SubmissionValidator {
  constructor(state) {
    this.grantRequest = { ...state.grantRequest }
    this.lineItems    = { ...state.lineItems }
    this.attachments  = { ...state.attachments }
    this.currentUser =  { ...state.currentUser }

    // {
    //   grantRequest: {
    //     [field]: { path: "", message: "" }
    //   },
    //
    //   lineItems:   { path: "", message: "it doesn't make sense to validate these per--top level errors only"}
    // }
    //
    this.errors = {
      grantRequest: {},
      lineItems:    { path: "", message: "" }
    }
  }

  isValid = () => {
    this.checkForErrors()

    return Object.keys(this.errors.grantRequest).length === 0 &&
      !this.errors.lineItems.message
  }

  grantRequestErrors = () => {
    return Object.values(this.errors.grantRequest)
  }

  // -------- not a part of the 'public' interface --------

  checkForErrors = () => {
    this.checkGrantRequest()
    this.checkLineItems()
  }

  checkField = (values, errors) => (path) => (field, message) => {
    let errorMessage = requiredValidator(message || `${field.humanize()} can't be blank`)(
      values[field]
    )

    if (errorMessage) {
      errors[field] = { message: errorMessage, path: path }
    }
  }

  checkGrantRequest = () => {
    let _check = this.checkField(this.grantRequest, this.errors.grantRequest)("/member_information")

    _check('applicantName')
    _check('applicantTitle')
    _check('mailingStreetAddress')
    _check('mailingCity')
    _check('mailingState')
    _check('mailingZip')
    _check('applicantPhone')
    _check('applicantEmail')

    _check = this.checkField(this.grantRequest, this.errors.grantRequest)("/request_details")

    _check('description')
    _check('howWillThisBeUsed', 'You must describe how this will be used')

    if ((this.grantRequest.coverageIds || []).length === 0) {
      this.errors.grantRequest.coverageIds = {
        message: "You must select at least one applicable coverage",
        path: "/request_details"
      }
    }

    _check = this.checkField(this.grantRequest, this.errors.grantRequest)("/finalize")

    _check('caoName',  'CAO name can\'t be blank')
    _check('caoEmail', 'CAO email can\'t be blank')
    _check('caoPhone', 'CAO phone can\'t be blank')
    _check('caoTitle', 'CAO title can\'t be blank')

    if (this.grantRequest.signature != this.currentUser.name) {
      this.errors.grantRequest.signature = {
        message: `You're logged in as '${this.currentUser.name}', but you signed the request as ` +
                 `'${this.grantRequest.signature}'. We expect these to match.`,
        path: "/finalize"
      }
    }

    if (!this.grantRequest.caoAwareOfRequest) {
      this.errors.grantRequest.caoAwareOfRequest = {
        message: "You must acknowledge that the CAO is aware of the request",
        path: "/finalize"
      }
    }

    if (!this.grantRequest.informationLawfullyEntered) {
      this.errors.grantRequest.informationLawfullyEntered = {
        message: "You must acknowledge that you are the one signing and information is accurate",
        path: "/finalize"
      }
    }

    if (!this.grantRequest.conditionsOfFunding) {
      this.errors.grantRequest.conditionsOfFunding = {
        message: "You must acknowledge the conditions of funding",
        path: "/finalize"
      }
    }
  }

  checkLineItems = () => {
    if (Object.values(this.lineItems).length === 0) {
      this.errors.lineItems = {
        path: "/request_details",
        message: "You must request at least one item or service"
      }
    }
  }
}
