import React from 'react'

// our forms use global wizard state and have relied on a component structure kinda like this:
//
//     ```js
//     class StepOne extends React.Component {
//       componentWillUnmount() {
//         this.props.resetWizardState()
//         this.props.resetSubmissionErrors()
//       }
//
//       render() {
//         if (this.props.updateScucceeded) {
//           return <Redirect to={"/step_two"} />
//         }
//       }
//     }
//
//     class StepTwo extends React.Component {
//       render() {
//         if (this.props.updatedSucceeded) {
//           return <Redirect to={"/step_three"} />
//         }
//       }
//     }
//     ````
//
// meaning, we were relying on `StepOne` to clean up the wizard state in its `componentWillUnmount`
// _before_ `StepTwo` mounted. in react 16 they changed `componentWillUnmount` to run
// asynchronoushly so we can no longer rely on that timing
// (ref: https://github.com/facebook/react/issues/11106)`.
//
// so this hoc allows us to opt into instead watching for a successful update, and then after
// resetting the wizard form, before we perform the navigation if we instead use the
// `updateSucceededAndWizardReset` state this hoc provides
//
function withSynchronousCheckForUpdateSucceeded(Component) {
  return class extends React.Component {
    constructor(props) {
      super(props)
      
      if (!(props.resetWizardState && props.resetSubmissionErrors)) {
        throw new Error("ArgumentError: withSynchronousCheckForUpdateSucceded called without " +
                          "receiving needed props")
      }

      this.state = { updateSucceededAndWizardReset: false }
    }

    componentDidUpdate(prevProps) {
      if (!prevProps.updateSucceeded && this.props.updateSucceeded) {
        this.props.resetWizardState()
        this.props.resetSubmissionErrors()
      }

      if (prevProps.updateSucceeded && this.props.updateSucceeded === null) {
        this.setState({ updateSucceededAndWizardReset: true })
      }
    }

    render() {
      return <Component { ...this.props }
                        updateSucceededAndWizardReset={this.state.updateSucceededAndWizardReset} />
    }
  }
}

export { withSynchronousCheckForUpdateSucceeded }
