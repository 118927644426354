// stolen from: http://edgeguides.rubyonrails.org/active_record_validations.html#custom-validators
const REGEX = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i

const emailValidator = (message = "Invalid email") => function(value, state) {
  if (value && !REGEX.test(value)) {
    return message;
  }

  return null;
};

export default emailValidator;
