import ReactDOM from "react-dom"

export const dispatchCustomEvent = (component, eventName, data) => {
  let element = ReactDOM.findDOMNode(component);

  // ugh, internet explorer is so dumb:
  // https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Creating_and_triggering_events#The_old-fashioned_way

  let event = document.createEvent('CustomEvent');
  event.initCustomEvent(eventName, true, true, data)

  element.dispatchEvent(event);
}
