export const submit = (values, path, method) => {
  let data = new FormData();

  if (values.file)         { data.append("document[file]",          values.file) }
  if (values.title)        { data.append("document[title]",         values.title) }
  if (values.documentType) { data.append("document[document_type]", values.documentType) }
  if (values.notes)        { data.append("document[notes]",         values.notes) }


  // redux-form needs a rejected or resolved Promise, and fetch always resolves, so let's wrap fetch
  // feels like that could be better, but whatevas 🤷‍

  return new Promise((resolve, reject) => {
    fetch(path, {
      headers:     {
        "Accept":       "application/json; charset=UTF-8",
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
      },
      credentials: 'same-origin',
      method:      method,
      body:        data
    }).
    then((response) => {
      if (response.ok) {
        resolve(response.json())
      }
      else {
        reject(response.statusText)
      }
    })
  })
}
