import React from "react";
import { connect } from "react-redux";

import { getOverallScore } from "apps/cyber/shared/ducks/survey.js";

let RmgScoreWidget = ({ score, renderAsPreview }) => {
    var viewBox = "0 0 90 90",
        strokeLinecap = "round",
        strokeDasharray = "250",
        currentOffset =
            parseInt(strokeDasharray, 10) -
            parseInt(strokeDasharray, 10) * (score * 0.01),
        strokeDashoffset = currentOffset,
        keepIt100 =
            score == 100 ? "rmg-score-percentage keepIt100" : "rmg-score-percentage";
    return (
        <div className="card card-rmg">
            <div className="card-header">Your score</div>

            <div className="card-body">
                {(function () {
                    if (renderAsPreview) {
                        return (
                            <div className="alert alert-warning">
                                <div className="alert-warning">
                                    <p className="small">
                                        This is a preview of the Cybersecurity Assessment, so the
                                        score will not update.
                                    </p>
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <div>
                                <svg id="rmg-svg" viewBox={viewBox}>
                                    <path
                                        fill="none"
                                        strokeLinecap={strokeLinecap}
                                        strokeWidth="10"
                                        stroke="#ced4de"
                                        strokeDasharray={strokeDasharray}
                                        strokeDashoffset="0"
                                        d="M 45,5
                         a 40,40 0 0 1 0 80
                         a 40,40 0 0 1 0 -80"
                                    />
                                    <path
                                        fill="none"
                                        strokeLinecap={strokeLinecap}
                                        strokeWidth="10"
                                        stroke="#3D547B"
                                        strokeDasharray={strokeDasharray}
                                        strokeDashoffset={strokeDashoffset}
                                        d="M 45,5
                         a 40,40 0 0 1 0 80
                         a 40,40 0 0 1 0 -80"
                                    />
                                    <path
                                        fill="none"
                                        strokeLinecap={strokeLinecap}
                                        strokeWidth="8"
                                        stroke="#1D7D59"
                                        strokeDasharray={strokeDasharray}
                                        strokeDashoffset={strokeDashoffset}
                                        d="M 45,5
                         a 40,40 0 0 1 0 80
                         a 40,40 0 0 1 0 -80"
                                    />
                                    <path
                                        fill="none"
                                        strokeLinecap={strokeLinecap}
                                        strokeWidth="3"
                                        stroke="#26A777"
                                        strokeDasharray={strokeDasharray}
                                        strokeDashoffset={strokeDashoffset}
                                        d="M 45,5
                         a 40,40 0 0 1 0 80
                         a 40,40 0 0 1 0 -80"
                                    />
                                </svg>

                                <div className="rmg-score">
                                    <h2 className={keepIt100}>
                                        {score}
                                        <sup>%</sup>
                                    </h2>
                                    {/*<div className="rmg-score-completion-ratio">
                      <span className="rmg-score-completed-questions">10</span>
                      /
                      <span className="rmg-score-total-questions">18</span>
                    </div>*/}
                                </div>
                            </div>
                        );
                    }
                })()}
            </div>
        </div>
    );
};

RmgScoreWidget = connect((state, props) => ({
    score: getOverallScore(state),
    renderAsPreview: state.lookups.renderAsPreview,
}))(RmgScoreWidget);

export default RmgScoreWidget;
