import React from 'react'
import PropTypes from 'prop-types';

import ProgressBar from 'shared/components/progress_bar.jsx'
import { TextField } from 'shared/components/fields'
import ImagePreview from 'shared/components/image_preview.jsx'

import { numberToHumanSize } from 'shared/utils/files'

/***** DUP CODE EXTRACT **/
const ErrorMessage = ({ attachment }) => {
  let errors = [
    attachment.document && attachment.document.errorMessage,
    attachment.contentType.errorMessage,
    attachment.size.errorMessage,
    attachment.error.value
  ].filter((n) => n)

  if (errors.length === 0) { return null }

  return (
    <div className="alert alert-danger alert-condensed" role="alert">
      {
        errors.map((error, index) => (
          <p key={ index }>{ error }</p>
        ))
      }
    </div>
  )
}
/*************/

const DocumentUploadTable = ({ documents, handleDeleteFor, handleTitleChangeFor }) => (
  <table className='table '>
    <thead>
      <tr>
        <th>File</th>
        <th>Title</th>
        <th>Size</th>
        <th></th>
      </tr>
    </thead>

    <tbody>
      { documents
        ? documents.map(document => <DocumentRow
                                       key={ document.id || document._id }
                                       handleDelete={ handleDeleteFor(document) }
                                       handleTitleChange={ handleTitleChangeFor(document) }
                                       document={ document } />)
        : <tr><td>No documents selected</td></tr>
      }
    </tbody>
  </table>
)

DocumentUploadTable.propTypes = {
  documents: PropTypes.array,
  handleDeleteFor: PropTypes.func,
  handleTitleChangeFor: PropTypes.func,
}

const DocumentRow = ({ document, handleDelete, handleTitleChange }) => (
  <tr>
    <td className="preview">
      <div className='filename'>
        { document.changed.value && <small>*</small> }
        { document.filename.value }
      </div>

      <ProgressBar percentage={ document.updating.value ? document.percentComplete.value : null }
                   hideWhenComplete={ false }
                   hideBeforeStart={ true } />
    </td>

    <td>
      <ErrorMessage attachment={ document } />

      <TextField name="title"
                 onChange={ handleTitleChange }
                 label="Title..."
                 value={ document.title.value }
                 errorMessage={ document.title.errorMessage }
                 bootstrapWrapperClassName='no-margin-bottom'
      />
    </td>
    <td>
      { numberToHumanSize(document.size.value) }
    </td>
    <td>
      <a className='remove-attachment' onClick={ handleDelete }>
        <i className='fa fa-trash-o'></i>
      </a>
    </td>
  </tr>
)

DocumentRow.propTypes = {
  document: PropTypes.object,
  handleDelete: PropTypes.func,
  handleTitleChange: PropTypes.func,
}

export default DocumentUploadTable
