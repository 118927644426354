import React from "react"
import { connect } from 'react-redux'

import { reorderable } from 'apps/cyber/shared/components/reorderable.jsx'
import EditableSurveySection from './editable_survey_section.jsx'

import { reorderSection } from '../ducks/survey.js'

let DraggableSurveySection = reorderable(EditableSurveySection, 'DraggableSurveySection', {
    moveHandlerName:      '_reorderSection',
    beginDragHandlerName: 'handleBeginDrag',
    endDragHandlerName:   'handleEndDrag',

    validDropTarget: (targetProps, sourceProps) => {
        return targetProps.surveyId === sourceProps.surveyId
    }
})

DraggableSurveySection = connect(
    null,
    (dispatch, props) => ({
        _reorderSection: (source, target) => {
            dispatch(reorderSection(source, target))
        }
    })
)(DraggableSurveySection)

export default React.forwardRef((props, ref) => {
    return <DraggableSurveySection { ...props } forwardedRef={ ref } />
});
