import React from 'react'
import { Link } from 'react-router-dom'

const WitnessesCard = ({ handleDeleteClicked, witness }) => {
  let name = `${witness.firstName || ''} ${witness.lastName || ''}`
  name = (name.trim() === '') ? 'Unknown Name' : name

  return (
    <div className="card">
      <header className="card-header">
        <h5 className="card-title">{ name || 'Unknown Name' }</h5>

        <div className="card-header-controls">
          <Link to={ `/witnesses/${witness.id}/edit` }>
            <span className="glyphicon glyphicon-pencil"></span>
          </Link>

          <a onClick={ handleDeleteClicked }>
            <span className="glyphicon glyphicon-trash"></span>
          </a>
        </div>
      </header>

      <div className="card-body">
        <p>{ witness.email }</p>
      </div>

      <footer className="card-footer"></footer>
    </div>
  )
}

export default WitnessesCard
