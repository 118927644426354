import React from 'react'
import { BrowserRouter as Router, Route, Prompt } from 'react-router-dom'

import RailsApi from 'shared/utils/rails_api.js'
import BaseForm from 'shared/components/base_form.jsx'
import {
  AddressField,
  FormField,
  TextField
} from 'shared/components/fields'
import { lengthValidator, requiredValidator, phoneNumberValidator } from 'shared/utils/validators'

import { apiEndpoint } from '../shared/ducks/rails.js'
import { Panel, PanelSidebar, PanelRightColumn, PanelBody, PanelControls } from '../shared/panel'
import PhoneNumberField from 'shared/components/fields/phone_number_field';
class App extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      location: {
        name:           new FormField(props.location.name, [requiredValidator()]),
        departmentCode: new FormField(props.location.departmentCode),
        
        companyExecutiveRole: new FormField(props.location.companyExecutiveRole),
        companyExecutivePhone: new FormField(props.location.companyExecutivePhone, [phoneNumberValidator()]),


        streetAddress:  new FormField(props.location.streetAddress),
        optionalLine:   new FormField(props.location.optionalLine),
        city:           new FormField(props.location.city),
        state:          new FormField(props.location.state),
        zip:            new FormField(props.location.zip),

        industryDescription:                   new FormField(props.location.industryDescription),
        northAmericanIndustrialClassification: new FormField(
                                                 props.location.northAmericanIndustrialClassification,
                                                 [lengthValidator({ eq: 6 }, "Must be a six digit code")]
                                               )
      },

      updating: false,
      flash: null,
      mobileMenuOpen: false
    }
  }

  handleChangeFor = (name) => ( this.handleFieldChange("location", name) )

  prepareData = () => {
    let copy = { location: { ...this.state.location } }

    // turn FormField objects in to just the raw value
    Object.keys(copy.location).map((key) => {
      copy.location[key] = copy.location[key].value
    })

    return copy
  }

  handleSubmit = (event) => {
    event.preventDefault()
    event.target.blur()

    this.setState({ updating: true }, () => {
      if (this.validateFields("location") ) {
        const { path, method } = apiEndpoint(this.props.meta, "location")
        const data = this.prepareData()

        RailsApi.submit(path, method, { body: data }).
          then((json) => {
            window.location = json.redirect
          }).
          catch((json) => {
            this.setState({ flash: { type: 'danger', ...json.error }, updating: false })
          })
      }
      else {
        this.setState({ updating: false })
      }
    })
  }

  toggleMobileMenu = (event) => {
    this.setState((prev) => ({ ...prev, mobileMenuOpen: !prev.mobileMenuOpen }))
  }

  render() {
    // figure out what the basename should be based on where rails told us we can save the form to
    const { path, method } = apiEndpoint(this.props.meta, "location")
    const basename = `${path}/${method === "POST" ? "new" : "edit"}`

    return (
      <Router basename={ basename }>
        <div className="panel panel-default panel-incident-form">
          <div className="panel-heading">
            <div className="row">
              <div className="col-xs-12 col-sm-8">
                <h2 className="panel-title">
                  { method === "POST" ? 'New' : 'Edit' } {"Location"}
                </h2>
              </div>

              <div className="col-xs-12 col-sm-4 text-right">

                <a href="/osha/locations"
                   onClick={ (event) => {
                     if (!confirm("Are you sure you want to move away? Any unsaved changes " +
                                  "made to this section will be lost.")) {
                       event.preventDefault()
                     }
                   }}>

                  <button className="cancel-btn btn btn-link">
                    Cancel
                  </button>
                </a>

                <button className="mobile-claims-menu-btn btn btn-primary"
                        onClick={ this.toggleMobileMenu }>
                  <i className="fa fa-ellipsis-v"></i>
                </button>
              </div>
            </div>
          </div>

          {/* routing (there's only one) */}
          <Route exact path="/" render={ () => (
            <Panel>
              <PanelSidebar mobileMenuOpen={ this.state.mobileMenuOpen } />

              <PanelRightColumn>
                <PanelBody flash={ this.state.flash }>
                  <div className="row">
                    <div className="col-xs-12">
                      <h4>Locations / Departments</h4>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-6">
                      <TextField name="name"
                                 onChange={ this.handleChangeFor("name") }
                                 label="Location"
                                 value={ this.state.location.name.value }
                                 errorMessage={ this.state.location.name.errorMessage }
                                 required={ true } />
                    </div>

                    <div className="col-xs-6">
                      <TextField name="departmentCode"
                                 onChange={ this.handleChangeFor("departmentCode") }
                                 label="Department Code"
                                 value={ this.state.location.departmentCode.value }
                                 errorMessage={ this.state.location.departmentCode.errorMessage } />
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="col-xs-6">
                      <TextField name="companyExecutiveRole"
                                 onChange={ this.handleChangeFor("companyExecutiveRole") }
                                 label="Company Executive Role"
                                 value={ this.state.location.companyExecutiveRole.value }
                                 errorMessage={ this.state.location.companyExecutiveRole.errorMessage } />
                    </div>
                    <div className="col-xs-6">
                      <PhoneNumberField name="companyExecutivePhone"
                                 onChange={ this.handleChangeFor("companyExecutivePhone") }
                                 label="Company Executive Phone"
                                 value={ this.state.location.companyExecutivePhone.value }
                                 errorMessage={ this.state.location.companyExecutivePhone.errorMessage } />
                    </div>
                  </div>


                  <div className="row">
                    <div className="col-xs-12">
                      <AddressField fields={{
                                      streetAddress: {
                                        name: "streetAddress",
                                        value: this.state.location.streetAddress.value,
                                        errorMessage: this.state.location.streetAddress.errorMessage,
                                        onChange: this.handleChangeFor("streetAddress")
                                      },
                                      optionalLine: {
                                        name: "optionalLine",
                                        value: this.state.location.optionalLine.value,
                                        errorMessage: this.state.location.optionalLine.errorMessage,
                                        onChange: this.handleChangeFor("optionalLine")
                                      },
                                      city: {
                                        name: "city",
                                        value: this.state.location.city.value,
                                        errorMessage: this.state.location.city.errorMessage,
                                        onChange: this.handleChangeFor("city")
                                      },
                                      state: {
                                        name: "state",
                                        value: this.state.location.state.value,
                                        errorMessage: this.state.location.state.errorMessage,
                                        onChange: this.handleChangeFor("state")
                                      },
                                      zip: {
                                        name: "zip",
                                        value: this.state.location.zip.value,
                                        errorMessage: this.state.location.zip.errorMessage,
                                        onChange: this.handleChangeFor("zip")
                                      }
                                    }} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-12">
                      <TextField name="industryDescription"
                                 onChange={ this.handleChangeFor("industryDescription") }
                                 label="Industry Description"
                                 value={ this.state.location.industryDescription.value }
                                 errorMessage={ this.state.location.industryDescription.errorMessage } />
                    </div>

                    <div className="col-xs-12">
                      <TextField type="numbers_only"
                                 name="northAmericanIndustrialClassification"
                                 onChange={ this.handleChangeFor("northAmericanIndustrialClassification") }
                                 label="North American Industrial Classification"
                                 value={ this.state.location.northAmericanIndustrialClassification.value }
                                 errorMessage={ this.state.location.northAmericanIndustrialClassification.errorMessage } />
                    </div>
                  </div>
                </PanelBody>

                <PanelControls>
                  <Prompt when={ this.anyChangesMade("location", this.props.location) }
                          message="Are you sure you want to move away? Any location details will be lost." />

                  <button type="button"
                          onClick={ this.handleSubmit }
                          className={ `btn btn-success ${this.state.updating ? "disabled" : ""}` } >
                    Submit
                  </button>
                </PanelControls>
              </PanelRightColumn>
            </Panel>
          ) } />
          {/* end routing */}
        </div>
      </Router>
    )
  }
}

export default App
