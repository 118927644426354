// TODO - refactor `labelText` to `label`
//      - refactor `requiredInput` to `required`
//
import React from 'react'
import Select from 'react-select'

class BaseWithSensibleDefaults extends React.Component {
  constructor(props) {
    super(props);

    this.selectComponent = this.selectComponent.bind(this);
  }

  selectComponent() {
    return this.selectRef
  }

  render() {
    return (
      <Select.Async
        cache={ {} }
        simpleValue={ true }
        searchable={ true }
        clearable={ true }
        { ...this.props }
        ref={ (select) => { this.selectRef = select; } }
      />
    )
  }
}

const decorateWithAggressiveReloading = function decorateWithAggressiveReloading(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.selectComponent           = this.selectComponent.bind(this);
      this.aggressivelyReloadOptions = this.aggressivelyReloadOptions.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.props.shouldAggressivelyReload) {
        this.aggressivelyReloadOptions()
      }
    }

    // you can pass the shouldAggressivelyReload option to the SelectWithAsyncOptionsFromRails
    // component that wraps this one, which will make sure to load the options when the component
    // gets focus or opens. this is necessary on the property and loss page where we change the
    // loadOptions prop based on the user's selection of property type.
    //
    // other people having this problem:
    // https://github.com/JedWatson/react-select/issues/761#issuecomment-280487963
    // https://github.com/JedWatson/react-select/pull/1480
    aggressivelyReloadOptions() {
      if (this.props.shouldAggressivelyReload) {
        this.selectComponent().loadOptions("");
      }
    }

    selectComponent() { // always expose access to the underlying Select.Async
      return this.wrappedRef.selectComponent();
    }

    render() {
      // TODO - figure out what whenThisUpdatesReloadOptions is. i don't see it used anywhre...?
      const { shouldAggressivelyReload, whenThisUpdatesReloadOptions, ...filtered } = this.props;

      // react-select won't let us say clear a cache, so if we're aggressively reloading
      // we need to also not cache anything
      if (shouldAggressivelyReload) {
        filtered.cache = false
      }

      return <WrappedComponent { ...filtered }
                               ref={ (wrapped) => { this.wrappedRef = wrapped; } } />
    }
  }
}

const decorateForUseWithReduxForm = function decorateForUseWithReduxForm(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.selectComponent = this.selectComponent.bind(this);
    }

    selectComponent() { // always expose access to the underlying Select.Async
      return this.wrappedRef.selectComponent();
    }

    render() {
      const { input, ...filtered } = this.props;

      return (
        <WrappedComponent
          ref={ (wrapped) => { this.wrappedRef = wrapped; } }
          { ...filtered }
          value={ input.value }
          onChange={ input.onChange }
          onBlur={ () => input.onBlur(input.value) }
        />
      )
      // ☝️ value, onChange, and onBlur are things we need manually set from the props passed
      // by wrapping this in a redux-form Field component
    }
  }
}

const decorateWithFloatingLabel = function decorateWithFloatingLabel(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.selectComponent = this.selectComponent.bind(this);
    }

    selectComponent() { // always expose access to the underlying Select.Async
      return this.wrappedRef.selectComponent();
    }

    render() {
      const { labelText = "Select...", requiredInput, ...filtered } = this.props;

      return (
        <WrappedComponent
          ref={ (wrapped) => { this.wrappedRef = wrapped; } }
          { ...filtered }
          placeholder={ placeholderComponentFactory({ labelText: labelText, requiredInput: requiredInput }) }
          valueComponent={ valueComponentFactory({ labelText: labelText, requiredInput: requiredInput }) }
        />
      )
    }
  }
}

const placeholderComponentFactory = function placeholderComponentFactory(options) {
  return (
    <div className={ options.requiredInput ? "is-required" : ""}>
      { options.labelText }{ options.requiredInput && <span></span> }
    </div>
  )
}

const valueComponentFactory = function valueComponentFactory(options) {
  const valueComponent = (props) => (
    <div>
      <div className="Select-placeholder">
        { placeholderComponentFactory(options) }
      </div>

      <div className="Select-value">
        <span className="Select-value-label">
          { props.children }
        </span>
      </div>
    </div>
  )

  return valueComponent;
}

const OneAsyncSelectToRuleThemAll = {
  ForUseWithReduxForm: decorateWithAggressiveReloading(
    decorateForUseWithReduxForm(
      decorateWithFloatingLabel(
        BaseWithSensibleDefaults
      )
    )
  ),
  Base: decorateWithAggressiveReloading(decorateWithFloatingLabel(BaseWithSensibleDefaults))
}

export default OneAsyncSelectToRuleThemAll;
