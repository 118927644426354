import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import BootstrapModal from 'shared/components/move_off_of_eventually_bootstrap_modal.jsx'
import { surveyId } from 'apps/rmg/shared/ducks/survey.js'

import { extractDateFromRailsDateTime, constructRailsDateTime } from 'shared/utils/rails_datetime_formatters.js'

import EditQuestion from './edit_question.jsx'
import ConfirmAndSaveModalFooter from 'shared/components/confirm_and_save_modal_footer.jsx'
import { submitQuestionToRails } from '../../ducks/survey.js'
import { apiEndpoint } from '../../ducks/rails.js'

class EditQuestionContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sectionQuestion: {
        id: null,
        surveySectionId: this.props.surveySectionId,
        earliestAllowedDateForYes: '',
        questionCode: '',
        entityTierId: null,
        questionAttributes: {
          resetAnswerAnnually: false,
          requireDateWhenAnsweringYes: false,
          requireYesNoAnswer: false,
          allowNotApplicableAnswer: false,
          questionContent: '',
          helpContent: '',
          entityTypeIds: []
        },
        ...props.sectionQuestion
      },
      errorMessageFromSaveAttempt: ''
    }

    this.handleGlobalQuestionInputChange = this.handleGlobalQuestionInputChange.bind(this)
    this.handleSectionQuestionInputChange = this.handleSectionQuestionInputChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  handleGlobalQuestionInputChange(event) {
    const [ name, value ] = this.getNameAndValue(event.target)

    this.setState((prevState) => ({
      sectionQuestion: {
        ...prevState.sectionQuestion,
        questionAttributes: {
          ...prevState.sectionQuestion.questionAttributes,
          [name]: value
        }
      }
    }))
  }

  handleSectionQuestionInputChange(event) {
    const [ name, value ] = this.getNameAndValue(event.target)

    this.setState((prevState) => ({
      sectionQuestion: {
        ...prevState.sectionQuestion,
        [name]: value
      }
    }))
  }

  getNameAndValue(element) {
    const value = this.getValue(element)
    const name = element.name

    return [ name, value ]
  }

  getValue(element) {
    if(element.getAttribute("data-behavior") == 'datepicker') {
      return element.value && constructRailsDateTime(element.value, null);
    }

    switch(element.type) {
      case 'checkbox':
        return element.checked
      case 'select-multiple':
        return [...element.options].filter(o => o.selected).map(o => o.value)
      default:
        return element.value
    }
  }

  handleSave() {
    let { apiEndpoint: { path, method }, mode, dispatch, handleHide } = this.props
    path = path.replace(/:id/g, this.state.sectionQuestion.id)

    return submitQuestionToRails(this.state.sectionQuestion, path, method, mode, dispatch).
      then(() => {
        handleHide()
      }).
      catch((json) => {
        this.setState({
          errorMessageFromSaveAttempt: json.error || "Unable to save question"
        })
      })
  }

  render() {
    return (
      <BootstrapModal
        title={ this.props.sectionQuestion && this.props.sectionQuestion.id ? 'Edit Question' : 'Add a New Question' }
        body={ <EditQuestion
                  sectionQuestion={ this.state.sectionQuestion }
                  onGlobalQuestionChange={ this.handleGlobalQuestionInputChange }
                  onSectionQuestionChange={ this.handleSectionQuestionInputChange }
               /> }
        footer={ <ConfirmAndSaveModalFooter
                    handleSave={ this.handleSave }
                    errorMessageFromSaveAttempt={ this.state.errorMessageFromSaveAttempt }
                    confirmBeforeSave={ this.props.surveyStatus === 'active' }
                    confirmText="This will change the active RMG survey. Are you sure?"
                  /> }
        handleHide={ this.props.handleHide }
      />
    )
  }
}

EditQuestionContainer.propTypes = {
  surveySectionId: PropTypes.number.isRequired,
  handleHide: PropTypes.func.isRequired,
  sectionQuestion: PropTypes.object
}

export default connect(
  (state, props) => {
    const _mode = props.sectionQuestion && props.sectionQuestion.id ? "update" : "create"

    return {
      surveyStatus:       state.survey.status,
      surveyId:           surveyId(state.rails),
      apiEndpoint:        apiEndpoint(state.rails, { resource: "sectionQuestions", mode: _mode }),
      mode:               _mode
    }
  }
)(EditQuestionContainer)
