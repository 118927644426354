import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import { getQuestionCountBadge } from 'apps/rmg/shared/ducks/survey.js'
import { activeCoverage } from 'apps/rmg/shared/ducks/coverage_types.js'

let AnswerCount = ({ answered, total, active }) => {
  // this is a section with 0 questions (most likely "Review"), no need for a badge
  if (total === 0) { return null }

  return (
    <div className={ `number-box child-highlight ${active ? 'active' : ''}` }>
      <div className="">
        <span>{ answered }</span>/<span>{ total }</span>
      </div>
    </div>
  )
}

AnswerCount.propTypes = {
  coverage: PropTypes.object.isRequired
}

AnswerCount = connect(
  (state, props) => {
    const badge = getQuestionCountBadge(state.survey, state.responses, props.coverage)

    return {
      answered: badge.answered,
      total:    badge.total,
      active:   activeCoverage(state.coverageTypes, state.tab).id == props.coverage.id
    }
  }
)(AnswerCount)

export default AnswerCount
