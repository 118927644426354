import { convertToDate } from 'shared/utils/rails_datetime_formatters.js'

const notInPastValidator = (message = "Date can't be in the past") => function(value, state) {
  if (value && convertToDate(value) < new Date()) {
    return message;
  }

  return null;
};

export default notInPastValidator;
