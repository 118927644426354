import React from 'react'
import PropTypes from 'prop-types';

const onlyAllowNumbers = (value) => {
  if (!value) {
    return "";
  }

  return value.replace(/[\D]/g, '');
};

export default class TextField extends React.Component {
  constructor(props) {
    super(props)

    this.state = { focused: false }
  }

  onChange = (event) => {
    switch(this.props.type) {
      case "numbers_only": {
        this.props.onChange(onlyAllowNumbers(event.target.value));
        break;
      }
      default: {
        this.props.onChange(event.target.value);
      }
    }
  }

  onFocus = (event) => {
    if (this.props.onFocus) {
      this.setState({ focused: true }, this.props.onFocus)
    }

    this.setState({ focused: true })
  }

  onBlur = (event) => {
    if (this.props.onBlur) {
      this.setState({ focused: false }, this.props.onBlur)
    }

    this.setState({ focused: false })
  }

  floatLabel = () => {
    if (this.label) {
      let label_floater = new App.LabelFloater({
        $label: $(this.label),
        $input: $(this.input)
      })

      label_floater.initialize();
    }
  }

  componentDidUpdate() {
    // opt-in to floating the label on update so we only have to call this code on inputs
    // that are populated by dispatching a change action. otherwise, it'll happen on every
    // keypress, blur, click, etc. on every input 😳
    if (this.props.shouldFloatLabelOnUpdate) {
      this.floatLabel();
    }
  }

  componentDidMount() {
    this.floatLabel();
  }

  render() {
    const {
      name,
      required,
      withoutBootstrapMarkup,
      bootstrapWrapperClassName,
      className,
      errorMessage,
      label,
      placeholder,
      readOnly,
      type,
      value,
      instructions,
      tabIndex,
      autoFocus,
      onFocus,
      onBlur,
      warn,
      rows,
      min,
      max
    } = this.props

    let warningMessage = this.state.focused && warn && warn(value)

    let wrapperClass = [withoutBootstrapMarkup ? "" : "form-group"].
      concat(bootstrapWrapperClassName || []).
      concat(errorMessage ? "has-error" : "").
      concat(!errorMessage && warningMessage ? "has-warning" : "").
      concat(required && required.warn ? "is-required-but-warn-about-it" : "").
      concat(required && (required.warn === null || required.warn === undefined) ? "is-required" : "").
      filter((n) => n).
      join(' ')

    let inputClass = [withoutBootstrapMarkup ? "" : "form-control"].
      concat(className || []).
      filter((n) => n).
      join(' ')

    return (
      <div className={ wrapperClass }>
        {
          label &&
            <label ref={ (label) => { this.label = label } } htmlFor={ name }>
              { label }{ required ? <span></span> : "" }
            </label>
        }

        {
          type == "textarea" ?
            <textarea
              ref={ (input) => { this.input = input } }
              className={ inputClass }
              id={ name }
              name={ name }
              placeholder= { placeholder }
              readOnly={ readOnly }
              value={ value }
              tabIndex={ tabIndex }
              autoFocus={ autoFocus }
              onChange={ this.onChange }
              onFocus={ this.onFocus }
              onBlur={ this.onBlur }
              rows={ rows }
            />
          :
            <input
              ref={ (input) => { this.input = input } }
              className={ inputClass }
              id={ name }
              name={ name }
              placeholder= { placeholder }
              readOnly={ readOnly }
              type={ type }
              value={ value }
              tabIndex={ tabIndex }
              autoFocus={ autoFocus }
              onChange={ this.onChange }
              onFocus={ this.onFocus }
              onBlur={ this.onBlur }
              min={ min }
              max={ max }
            />
        }

        {
          errorMessage && <span className="field-level-error">{ errorMessage }</span>
        }

        {/*
          we have a problem where you may have a field with a warning focused when you go to click
          the "save button". you're now racing between your mouseup triggering the onClick and your
          mousedown bluring the field with a warning, the warning going away, and the button sliding
          up on the page. if you lose the race, it _feels_ like you clicked the button and it did
          nothing, but really the button moved in time to not register the click.

          thanks react 😑

          to address this, if you tell us your field has a warning, we're going to always render
          the space for that warning. if this becomes visually insufficient there are other
          things we could do: increase that space consistently across all inputs, blur on scroll,
          not handle warnings this way, etc. for now, this feels the least hacky
        */}
        {
          warn &&
            <span className="field-level-warning"
                  style={{ visibility: !errorMessage && warningMessage ? "visible" : "hidden" }}>
              { warningMessage }
            </span>
        }

        {
          instructions &&
            (typeof instructions == 'string') ? <p>{ instructions }</p> : instructions
        }
      </div>
    )
  }
}

TextField.defaultProps = {
  required: false,
  type:     'text',
  withoutBootstrapMarkup: false,
  shouldFloatLabelOnUpdate: false
};

TextField.propTypes = {
  name:                      PropTypes.string.isRequired,
  required:                  PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  withoutBootstrapMarkup:    PropTypes.bool.isRequired,

  bootstrapWrapperClassName: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  className:                 PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),

  errorMessage:              PropTypes.string,
  label:                     PropTypes.string,
  placeholder:               PropTypes.string,
  readOnly:                  PropTypes.bool,
  type:                      PropTypes.string,
  value:                     PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  instructions:              PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  tabIndex:                  PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoFocus:                 PropTypes.bool,
  shouldFloatLabelOnUpdate:  PropTypes.bool,
  onFocus:                   PropTypes.func,
  onBlur:                    PropTypes.func,
  warn:                      PropTypes.func,
  rows:                      PropTypes.number,

  onChange: (props) => {
    if (!props.readOnly) {
      if (typeof props.onChange !== 'function') {
        return new Error('If TextField is not ReadOnly, then onChange must be defined');
      }
    }
  }
};
