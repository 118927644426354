import React from 'react'
import ReactDOM from 'react-dom'

import OneAsyncSelectToRuleThemAll from 'shared/components/one_async_select_to_rule_them_all.jsx'

class MemberSearchOnRegistration extends React.Component {
  constructor(props) {
    super(props);

    this.state = { value: this.props.value || '' };

    this.getOptions  = this.getOptions.bind(this);
    this.onChange    = this.onChange.bind(this);
  }

  getOptions(input) {
    const { loadPath } = this.props;
    const query = `${ loadPath || '/users.json' }?search=${input || this.state.value || ''}`;

    return fetch(query, { credentials: 'same-origin' }).
      then((response) => ( response.json() )).
      then((json) => {
        return { options: json }
      });
  }

  onChange(value) {
    this.setState({ value: value });

    // fire a custom change event that can be picked up by jquery to hide any errors
    var dom = ReactDOM.findDOMNode(this),
      event = document.createEvent('HTMLEvents', value);
    event.initEvent('membersearchreg-change', true, true);
    dom.dispatchEvent(event);
  }

  render() {
    return (
      <OneAsyncSelectToRuleThemAll.Base
        name={ this.props.name || '' }
        onChange={ this.onChange }
        value={ this.state.value }
        loadOptions={ this.getOptions }
        labelText="Member name"
        valueKey={ this.props.valueKey || "value" }
        labelKey={ this.props.labelKey || "label" }
        shouldAggressivelyReload={ true }
      />
    )
  }
}

export default MemberSearchOnRegistration;
