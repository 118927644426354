import { createStore, combineReducers } from 'redux';

import event from './ducks/event.js'
import eventQuestionSet from './ducks/event_question_set.js'
import eventEmail from './ducks/event_email.js'
import wizard from './ducks/wizard.js'
import rails from './ducks/rails.js'
import submissionErrors from './ducks/submission_errors.js'

/* WARNING - this does not support thunks */
const handleBatches = (combined) => (state = {}, action) => {
  switch (action.type) {
    case 'INITIAL_LOAD_OF_DATA_FROM_RAILS':
    case 'RAILS_UPDATE_SUCCEEDED':
    case 'RAILS_UPDATE_FAILED': {
      return action.actions.reduce((collectedState, batchedAction) => {
        return combined(collectedState, batchedAction)
      }, { ...state })
    }

    default: { return combined(state, action) }
  }
}

const store = createStore(
  handleBatches(
    combineReducers({
      rails,
      wizard,
      event,
      eventQuestionSet,
      eventEmail,
      submissionErrors
    })
  ),

  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store
