import React from 'react'
import { connect } from 'react-redux'
import { Link, Redirect, Prompt } from 'react-router-dom'

import BaseFormWithAttachments from 'shared/components/base_form_with_attachments.jsx'

import { FormField, FileField } from 'shared/components/fields'
import { fileSizeValidator, mimeTypeValidator } from 'shared/utils/validators'

import { Panel, PanelSidebar, PanelRightColumn, PanelBody, PanelControls } from '../panel'
import { apiEndpoint } from '../ducks/rails.js'
import { getAttachmentsCount, asyncDeleteAttachment, asyncUpdateAttachments } from '../ducks/attachments.js'
import { updateSucceeded } from '../ducks/wizard.js'

import AttachmentsTable from './attachments_table.jsx'

class AttachmentsForm extends BaseFormWithAttachments {
  constructor(props) {
    super(props)

    // when this is empty, we either have 0 attachments, or we're on the screen for adding new
    // attachments
    const attachments = [ ...(this.props.attachments || []) ]

    this.state = {
      attachments: attachments.map((attachment) => ({
        id:          attachment.id,

        comment:     new FormField(attachment.comment),
        contentType: new FormField(attachment.documentContentType),
        filename:    new FormField(attachment.documentFileName),
        url:         new FormField(attachment.attachmentUrl),
        size:        new FormField(''),

        updating:        new FormField(false),
        reading:         new FormField(false),
        changed:         new FormField(false),
        percentComplete: new FormField(0),
        error:           new FormField('')
      }))
    }
  }

  componentWillReceiveProps(nextProps) {
    // because we're updating in place on the index screen (i.e. i can change a comment, click
    // "save" and we don't move pages), we've gotta update the state when rails re-hydrates the
    // attachments in the redux store (i.e. AttachmentsIndex gets new attachments props and passes
    // it down).
    //
    // this isn't a concern on AttachmentsNew (or uploading) because it doesn't receive an
    // attachments prop, and instead just adds to the state when uploading, and pops off when
    // uploads to rails succeed
    //
    if (!this.props.addingNewAttachments) {
      this.setState({
        attachments: nextProps.attachments.map((attachment) => ({
          id:          attachment.id,

          comment:     new FormField(attachment.comment),
          contentType: new FormField(attachment.documentContentType),
          filename:    new FormField(attachment.documentFileName),
          url:         new FormField(attachment.attachmentUrl),
          size:        new FormField(''),

          updating:        new FormField(false),
          reading:         new FormField(false),
          changed:         new FormField(false),
          percentComplete: new FormField(0),
          error:           new FormField('')
        }))
      })
    }
  }

  handleCommentChangeFor = (attachment) => {
    return this.handleAttachmentChangeFor(attachment.id || attachment._id, "comment", () => {
      this.handleAttachmentChangeFor(attachment.id || attachment._id, "changed")(true)
    })
  }

  addAttachments = (files) => { // could be more than one from file input
    let newAttachments = []

    for (let i = 0; i < files.length; i++) {
      newAttachments.push({
        _id:      Date.now() + i,
        document: new FormField(files[i]),

        comment:     new FormField(''),
        contentType: new FormField('', mimeTypeValidator(this.props.allowableMimeTypes)),
        filename:    new FormField(files[i].name),
        url:         new FormField(''),
        size:        new FormField(files[i].size, fileSizeValidator(52428800, "File is too large (> 50 MB)")),

        updating:        new FormField(false),
        reading:         new FormField(false),
        changed:         new FormField(true),
        percentComplete: new FormField(0),
        error:           new FormField('')
      })
    }

    this.setState(
      (prevState) => ({
        ...prevState,

        attachments: prevState.attachments.concat(newAttachments)
      }),

      this.determineUrlAndContentType
    )
  }
  anyChangesToAttachments = () => {
    return this.state.attachments.some((a) => ( a.changed.value ))
  }

  render() {
    if (this.props.updateSucceeded && this.props.addingNewAttachments) {
      return <Redirect to="/attachments" />
    }

    return (
      <Panel>
        <PanelSidebar />

        <PanelRightColumn>
          <PanelBody>
            {
              // decide should we show the file upload input or just an "Add New" button?
              (() => {
                if (this.props.addingNewAttachments) {
                  return (
                    <div>
                      <h4>Upload Supporting Documentation</h4>

                      <FileField
                        name="documents"
                        onChange={ this.addAttachments }
                        multiple={ true }
                        className="form-control" />
                    </div>
                  )
                }
                else {
                  return (
                    <div>
                      <h4>Supporting Documentation</h4>

                      <p>
                        Supporting documentation may be submitted with your grant application, but
                        is not required. Examples of supporting documentation include
                        catalogue/website screen shots, invoices and quotes.
                      </p>

                      <Link to="/attachments/new">
                        <button
                          type="button"
                          className="btn btn-success btn-with-icon plus">

                          Add Supporting Documentation
                        </button>
                      </Link>
                    </div>
                  )
                }
              })()
            }

            <AttachmentsTable attachments={ this.state.attachments.filter((a) => ( !a._destroy )) }
                              handleCommentChangeFor={ this.handleCommentChangeFor }
                              deleteAttachment={ this.handleDeleteFor } />
          </PanelBody>

          <PanelControls>
            <Prompt when={ this.anyChangesToAttachments() }
                    message="Are you sure you want to move away? Any unsaved changes
                             made to this section will be lost." />
            {
              // decide on a back button or cancel button strategy
              (() => {
                if (this.props.addingNewAttachments) {
                  return (
                    <Link to="/attachments">
                      <button type="button"
                              className="btn btn-link">
                        Cancel
                      </button>
                    </Link>
                  )
                }
                else {
                  return (
                    <Link to="/request_details">
                      <button type="button"
                              className="btn btn-link">

                        Back
                      </button>
                    </Link>
                  )
                }
              })()
            }

            {
              // decide on "Save Attachments", "Save Changes", or "Next" button strategy
              (() => {
                if (!this.anyChangesToAttachments() && !this.props.updating) {
                  return (
                    <Link to="/finalize">
                      <button type="button"
                              className="btn btn-success"
                              tabIndex="2">

                        Save & Continue
                      </button>
                    </Link>
                  )
                }
                else {
                  return (
                    <button type="button"
                          onClick={ this.handleSave }
                            disabled={ this.props.updating }
                            className={ `btn btn-success ${this.props.updating ? "disabled" : ""}` }
                            tabIndex="2">

                      { this.props.addingNewAttachments ? 'Save Supporting Documentation' : 'Save Changes' }
                    </button>
                  )
                }
              })()
            }
          </PanelControls>
        </PanelRightColumn>
      </Panel>
    )
  }
}

AttachmentsForm.defaultProps = {
  baseKey: 'grantRequest',
  listKey: 'attachments',
  attachmentAttributes: ["_destroy", "id", "document", "comment"]
}

AttachmentsForm = connect(
  (state, props) => ({
    updating:         state.wizard.updating,
    updateSucceeded:  state.wizard.updateSucceeded,

    allowableMimeTypes: state.rails.allowableMimeTypes,
    attachmentsCount:   getAttachmentsCount(state.attachments),
    apiEndpoint: apiEndpoint(state.rails, "grant_request")
  }),
  null,
  ({ apiEndpoint, ...stateProps }, { dispatch }, props) => ({
    ...stateProps,
    ...props,

    asyncUpdateAttachments: (data, options) => {
      return asyncUpdateAttachments(apiEndpoint.path, data, options)(dispatch)
    },

    asyncDeleteAttachment: (id, attachmentsCount) => {
      return asyncDeleteAttachment(apiEndpoint.path, id, attachmentsCount)(dispatch)
    },

    dispatchUpdateSucceeded: () => {
      return dispatch(updateSucceeded()) // even though some attachments could fail, we'll handle that case-by-case
    }
  })
)(AttachmentsForm)


export default AttachmentsForm
