import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import OneAsyncSelectToRuleThemAll from 'shared/components/one_async_select_to_rule_them_all.jsx'

class SearchInsuredEquipmentField extends React.Component {
  constructor(props) {
    super(props)

    this.state = { value: '' }
  }

  getVehicles = (input) => {
    return fetch(`${this.props.loadPath}&description=${input || ""}`, { credentials: 'same-origin' }).
      then((response) => {
        return response.json();
      }).
      then((json) => {
        console.log("Response for Equipment", '\n', {'response': json}, '\n')
        return { options: json, complete: true };
      });
  }

  onChange = (option) => {
    this.setState({ value: option && option.fake_id }, () => { this.props.onChange(option) })
  }

  render() {
    return (
      <OneAsyncSelectToRuleThemAll.Base
        labelText="Search for Equipment"
        tabIndex={ this.props.tabIndex }
        value={ this.state.value }
        onChange={ this.onChange }
        loadOptions={ this.getVehicles }
        valueKey="fake_id"
        labelKey="select_label"
        simpleValue={ false }
      />
    )
  }
}

SearchInsuredEquipmentField.propTypes = {
  loadPath: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,

  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number])

}

SearchInsuredEquipmentField = connect(
  (state, props) => ({
    loadPath: `${state.rails.asyncHelpers.searchInsuredEquipment}?member_number=${state.rails.memberNumber}`
  })
)(SearchInsuredEquipmentField)

export default SearchInsuredEquipmentField
