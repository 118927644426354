import React from 'react'
import Label from './label.jsx'
import Response from './response.jsx'

import {
  extractDateFromRailsDateTime,
  extractTimeFromRailsDateTime
} from 'shared/utils/rails_datetime_formatters.js'

// allowed types = ["JUST_DATE", "JUST TIME", "BOTH"]
const DateAndTime = ({ label, value, missingText = "Unspecified", type = "JUST_DATE" }) => {
  const formattedDate = value ? extractDateFromRailsDateTime(value) : value
  const formattedTime = value ? extractTimeFromRailsDateTime(value) : value

  return (
    <div className={ !value ? "unanswered" : "" }>
      <Label>{ label }</Label>
      {
        (function() {
          if (value) {
            switch (type) {
              case "BOTH": {
                return <Response>{ formattedDate } { formattedTime }</Response>
              }
              case "JUST_TIME": {
                return <Response>{ formattedTime }</Response>
              }
              default: {
                return <Response>{ formattedDate }</Response>
              }
            }
          }
          else {
            return <Response>{ missingText }</Response>
          }
        })()
      }
    </div>
  )
}

export default DateAndTime;
