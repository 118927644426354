import React from 'react'

const Row = ({ columnSize = "xs-12", children }) => (
  <div className="row">
    {
      (typeof children.map === 'function') ?
        children.map((child, index) => (
          <div key={ index } className={ `col-${columnSize} ${ index + 1 == children.length ? 'last' : '' }` }>
            { child }
          </div>
        ))
      :
        <div className={ `col-${columnSize}` }>
          { children }
        </div>
    }
  </div>
)

export default Row;
