export const formatDollarValue = (value) => {
  if (!value) {
    return "";
  }

  if(typeof value == "number") {
    value = value.toFixed(2).toString()
  }

  let left = value.split(".")[0]
  let right = value.split(".")[1]

  // we had a value like $12.10 and the server ditches the 0 when it sends it back
  if (right.length == 1) { right = `${right}0` }

  return `$${left.replace(/(.)(?=(\d{3})+$)/g, '$1,')}.${right}`;
}