import React from 'react'
import { connect } from 'react-redux'

import { getPerson } from '../ducks/persons.js'

import PersonsForm from './persons_form.jsx'

let PersonsEdit = ({ person, errors }) => (
  <PersonsForm person={ person } errors={ errors } />
)

PersonsEdit = connect(
  (state, props) => ({
    person: getPerson(state.persons, props.match.params.id),
    errors: state.submissionErrors.persons[props.match.params.id]
  })
)(PersonsEdit)
export default PersonsEdit
