const LOAD   = "event_attendee_form/event/LOAD"

export default function events(state = {}, action) {
  switch (action.type) {
    case LOAD: {
      return action.events
    }

    default: {
      return state
    }
  }
}

export const loadEvents = (events) => {
  return { type: LOAD, events: events }
}


