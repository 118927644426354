import RailsApi from 'shared/utils/rails_api.js'

import { refreshMetaData } from './rails.js'
import { setErrorFlash, setSuccessFlash, updateSucceeded, updateFailed, updateStarted } from './wizard.js'

const LOAD   = "event_form/eventQuestionSet/LOAD"
const UPDATE = "event_form/eventQuestionSet/UPDATE"

export default function eventQuestionSet(state = {}, action) {
  switch (action.type) {
    case LOAD: {
      return { ...action.eventQuestionSet }
    }

    case UPDATE: {
      return { ...state, ...action.attributes }
    }

    default: {
      return state
    }
  }
}

export const loadEventQuestionSet = (eventQuestionSet) => {
  return { type: LOAD, eventQuestionSet: eventQuestionSet }
}

export const updateEventQuestionSet = (eventQuestionSet) => {
  const { ...attributes } = eventQuestionSet

  return { type: UPDATE, attributes: attributes }
}

export const asyncUpdateEventQuestionSet = (url, data, updated) => (dispatch) => {
  dispatch(updateStarted())

  RailsApi.patch(url, { body: data }).
    then((json) => {
      dispatch({
        type: 'RAILS_UPDATE_SUCCEEDED',
        actions: [
          refreshMetaData({ ...json.meta }),
          updateEventQuestionSet(json.eventQuestionSet),
          updateSucceeded(),
          setSuccessFlash("Event Updated", { keep: updated })
        ]
      })
    }).
    catch((json) => {
      dispatch({
        type: 'RAILS_UPDATE_FAILED',
        actions: [
          updateFailed(),
          setErrorFlash(json.error.message, { details: json.error.details })
        ]
      })
    })
}
