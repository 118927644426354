import React from 'react'
import { connect } from 'react-redux'

import BaseForm from 'shared/components/base_form.jsx'
import { FormField } from 'shared/components/fields'
//
// /**
//  * Base class for forms with lists of attachments.
//  * Assumptions:
//  * - Your attached field is named `document`
//  *
//  * Configuration:
//  * -
//  *
//  * @extends BaseForm
//  */
export default class BaseFormWithAttachments extends BaseForm {
  constructor(props) {
    super(props)

    console.log("Props", props)

    // Key of the list in the state object (this.state[this.listKey])
    this.listKey = props.listKey || 'attachments'

    // State key of the model holding the attachments list
    // { baseKey: {
    //    listKeyAttributes: {}
    // }}
    // Ex:
    // - grantRequest
    this.baseKey = props.baseKey || 'grantRequest'

    // Whitelist of fields we want to send back to Rails
    // - Append custom things to this from subclasses
    this.attachmentAttributes = props.attachmentAttributes || ["_destroy", "id", "document"]
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }


  handleDeleteFor = (attachment) => () => {
    if (attachment._id && !attachment.id) { // not persisted to rails, so just ditch it
      this.removeFromList(this.listKey, attachment._id)
    }
    else {
      this.markToDestroyInList(this.listKey, attachment.id, () => {
        this.handleAttachmentChangeFor(attachment.id, "changed")(true)
      })
    }
  }

  prepareAttachment = (attachment) => {
    return {
      [this.baseKey]: {
        [this.listKey + 'Attributes']: {
          // we whitelist what to send back since we add a bunch of meta stuffs
          [attachment.id || attachment._id]: this.attachmentAttributes.reduce((f, field) => {
            if (attachment.hasOwnProperty(field)) {
              f[field] = (attachment[field] instanceof FormField) ? attachment[field].value : attachment[field]
            }

            return f
          }, {})
        }
      }
    }
  }

  determineUrlAndContentType = () => {
    this.state[this.listKey].filter((a) => ( a.document && !a.reading.value )).forEach((attachment) => {
      this.handleAttachmentChangeFor(attachment._id, "reading")(true) // we know it's only new attachments that need to be read

      let reader = new FileReader();

      reader.onloadend = () => {
        let url = reader.result

        // stolen from: http://stackoverflow.com/a/21460351/1947079
        let contentType = url.split(",")[0].split(":")[1].split(";")[0]

        // it's possible for this to fire after the component has unmounted
        if (this._isMounted) {
          this.handleAttachmentChangeFor(attachment._id, "url")(url)
          this.handleAttachmentChangeFor(attachment._id, "contentType")(contentType)
          this.handleAttachmentChangeFor(attachment._id, "reading")(false)
        }
      }

      reader.readAsDataURL(attachment.document.value);
    })
  }

  handleSave = (event) => {
    if(event) event.preventDefault()

    if (this.validateFieldsInList(this.listKey)) {
      this.state[this.listKey]
        .filter((a) => ( a.changed.value ))
        .forEach((attachment) => {
          this.props.asyncUpdateAttachments(
            this.prepareAttachment(attachment),
            this.xhrCallbacksFor(attachment)
          )
        })
    }
  }

  xhrCallbacksFor = (attachment) => {
    let id = attachment.id || attachment._id

    let allAttachmentsSucceeded = () => {
      return this.state[this.listKey].every((a) => ( !a.updating.value && !a.error.value ))
    }

    let checkIfAllAttachmentsSucceeded = () => {
      if (allAttachmentsSucceeded()) {
        this.props.dispatchUpdateSucceeded()
      }
    }

    return {
      onSuccess: (json) => {
        this.handleAttachmentChangeFor(id, "updating", () => {
          if (!allAttachmentsSucceeded() && attachment._id) {
            // if this wasn't the last new file to successfully upload,
            // (because the last one will just redirect and we don't want to try and setState
            // once this unmounts) remove it from the attachments list
            this.handleDeleteFor(attachment)()
          }

          checkIfAllAttachmentsSucceeded()
        })(false)
      },

      onProgress: (percentComplete) => {
        this.handleAttachmentChangeFor(id, "updating")(true)
        this.handleAttachmentChangeFor(id, "percentComplete")(percentComplete)
      },

      onError: (json) => {
        this.handleAttachmentChangeFor(id, "error")(json.error ? json.error.message : null)
        this.handleAttachmentChangeFor(id, "updating", checkIfAllAttachmentsSucceeded)(false)
      }
    }
  }


  handleAttachmentChangeFor = (id, name, callback) => (
    this.handleFieldChangeInList(this.listKey, id, name, callback)
  )

  handleFieldChangeFor = (attachment, field) => {
    return this.handleFieldChangeInList(this.listKey, attachment.id || attachment._id, field, () => {
      this.handleFieldChangeInList(this.listKey, attachment.id || attachment._id, "changed")(true)
    })
  }
}
