import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import ActionWithConfirmation from '../action_with_confirmation.jsx'
import ControlButton from 'shared/components/control_button.jsx'

import { submitQuestionToRails, canLockOrHideQuestion } from '../../ducks/survey.js'

class LockSectionQuestionButton extends React.Component {
  state = { confirmingLock: false }

  handleLockClick = () => { this.setState({ confirmingLock: true }) }
  handleLockModalHide = () => { this.setState({ confirmingLock: false }) }

  lockQuestion = (record, ...args) => {
    submitQuestionToRails({ ...record, status: 'disabled_and_visible' }, ...args)
  }

  unlockQuestion = (record, ...args) => {
    submitQuestionToRails({ ...record, status: 'enabled' }, ...args)
  }

  render() {
    const lockQuestion = (
      <ActionWithConfirmation
        record={ this.props.sectionQuestion }
        title="Confirm Question Lock"
        body="Are you sure you want to lock this question? It will be uneditable on the survey, and no longer scored."
        confirmText="Lock Question"
        apiResource="sectionQuestions"
        apiMode="update"
        onConfirm={ this.lockQuestion }
        handleHide={ this.handleLockModalHide }
      />
    )

    const unlockQuestion = (
      <ActionWithConfirmation
        record={ this.props.sectionQuestion }
        title="Confirm Question Unlock"
        body="Are you sure you want to unlock this question? It will become editable on the survey."
        confirmText="Unlock Question"
        apiResource="sectionQuestions"
        apiMode="update"
        onConfirm={ this.unlockQuestion }
        handleHide={ this.handleLockModalHide }
      />
    )

    const iconClass = this.props.sectionQuestion.status == 'disabled_and_visible' ?
                        'fa fa-unlock-alt text-brand-yellow' :
                        'fa fa-lock'

    return !this.props.canLockOrHideQuestion ? ( null ) : (
      <div>
        <ControlButton
          handler={ this.handleLockClick }
          iconClass={ iconClass }
        />

        { this.state.confirmingLock && (this.props.sectionQuestion.status == 'disabled_and_visible' ?
                  unlockQuestion : lockQuestion) }
      </div>
    )
  }
}

LockSectionQuestionButton.propTypes = {
  sectionQuestion:  PropTypes.object.isRequired
}

LockSectionQuestionButton = connect(
  (state, props) => ({
    canLockOrHideQuestion: canLockOrHideQuestion(state.survey)
  })
)(LockSectionQuestionButton)

export default LockSectionQuestionButton
