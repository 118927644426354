import React from 'react'

class WitnessesPrompt extends React.Component {
  handleToggle = (event) => {
    this.props.onChange(event.target.checked)
  }

  render() {
    return (
      <div>
        <p>Were there any witnesses to this occurrence?</p>

        <div className="form-group toggle-switch-group">
          <div className="toggle-switch-container">
            <span>No</span>

            <label className="toggle-switch">
              <input type="checkbox" onChange={ this.handleToggle }
                                     value={ this.props.value }
                                     checked={ !!this.props.value }/>

              <div className="toggle-slider -round"></div>
            </label>

            <span>Yes</span>
          </div>
        </div>
      </div>
    )
  }
}

export default WitnessesPrompt
