import React from 'react'
import { Provider, connect } from 'react-redux'
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from 'react-router-dom'

import { extractDateFromRailsDateTime } from 'shared/utils/rails_datetime_formatters.js'

import store from './store.js'
import { loadMetaData, apiEndpoint } from './ducks/rails.js'
import { loadEvent } from './ducks/event.js'
import { loadEventQuestionSet } from './ducks/event_question_set.js'
import { loadEventEmail } from './ducks/event_email.js';
import { setSuccessFlash, openMobileMenu, closeMobileMenu } from './ducks/wizard.js'

import GeneralSettings from './general_settings/general_settings_form.jsx'
import EventQuestions from './event_questions/event_questions_form.jsx'
import EmailNotifications from './email_notifications/email_notifications_form.jsx'
import EmailAttendees from './email_attendees/email_attendees_form.jsx'


class AppHeader extends React.Component {
  renderHeader = (status) => {
    if(status !== 'in_progress') { return 'New Event'}
    return (
      <span>
        Edit Event
        <p className="event-title">
          ID: { this.props.eventId } - { this.props.eventTitle }
          { ' on' } { extractDateFromRailsDateTime(this.props.eventDate) }
        </p>
      </span>
    )
  }

  render() {
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-8">
          <h2 className="panel-title">
            { this.renderHeader(this.props.eventStatus) }
          </h2>
        </div>

        <div className="col-xs-12 col-sm-4 text-right">
          { this.props.eventStatus === 'new' &&
            <a href={`/events/${this.props.eventId}/cancel`}
              onClick={ (event) => {
                if (!confirm("Are you sure you want to move away? Any unsaved changes " +
                              "made to this section will be lost.")) {
                  event.preventDefault()
                }
              }}>

              <button className="cancel-btn btn btn-link">Cancel</button>
            </a>
          }
          <button className="mobile-claims-menu-btn btn btn-primary" onClick={ this.props.toggleMobileMenu }>
            <i className="fa fa-ellipsis-v"></i>
          </button>
        </div>
      </div>
    )
  }
}
AppHeader = connect(
  (state, props) => ({
    eventTitle: state.event.title,
    eventDate: state.event.date,
    eventId: state.event.id,
    eventStatus:   state.event.title !== null ? 'in_progress' : 'new',
    mobileMenuOpen: state.wizard.mobileMenuOpen,
  }),
  null,
  ({ mobileMenuOpen, ...otherProps }, { dispatch }, props) => ({
    ...otherProps,
    ...props,

    toggleMobileMenu: () => { dispatch(mobileMenuOpen ? closeMobileMenu() : openMobileMenu()) }
  })
)(AppHeader)

class App extends React.Component {
  constructor(props) {
    super(props);

    const {
      event,
      eventQuestionSet,
      eventEmail
    } = props

    store.dispatch({
      type: 'INITIAL_LOAD_OF_DATA_FROM_RAILS',
      actions: [
        loadMetaData(props.meta),
        loadEvent(event),
        loadEventQuestionSet(eventQuestionSet),
        loadEventEmail(eventEmail)
      ]
    })
  }

  render() {
    // figure out what the basename should be based on where rails told us we can save the form to
    const { path, method } = apiEndpoint(this.props.meta, "event")
    const basename = `${path}/${method === "POST" ? "new" : "edit"}`

    return (
      <Provider store={ store }>
        <Router basename={ basename }>
          <div className="panel panel-default panel-event-form">
            <div className="panel-heading">

              <AppHeader />

            </div>

            {/* routing */}
            <Route exact path="/" render={ () => <Redirect to="/general_settings" /> } />

            <Route path="/general_settings"    component={ GeneralSettings } />
            <Route path="/event_questions"     component={ EventQuestions } />
            <Route path="/email_notifications" component={ EmailNotifications } />
            <Route path="/email_attendees"     component={ EmailAttendees } />
            {/* end routing */}
          </div>
        </Router>
      </Provider>
    )
  }
}

export default App
