

export default function railsFactory(namespace, defaultState = {}) {
  const LOAD = `${namespace}/rails/LOAD`

  function rails(state = {}, action) {
    switch (action.type) {
      case LOAD: {
        console.log("LOAD", state, action.meta)
        return { ...state, ...action.meta }
      }

      default: {
        return state
      }
    }
  }

  const loadMetaData = (meta) => {
    return { type: LOAD, meta: meta }
  }
  const refreshMetaData = (meta) => {
    return loadMetaData(meta)
  }

  const apiEndpoint = (state, name) => {
    const { path, method } = state.apiEndpoints[name]
    return { path: path, method: method }
  }


  return {
    default: rails,
    loadMetaData: loadMetaData,
    refreshMetaData: refreshMetaData,
    apiEndpoint: apiEndpoint
  }
}
