import React from 'react'

import Section from 'shared/components/summary/section.jsx'
import Row from 'shared/components/summary/row.jsx'
import BasicColumn from 'shared/components/summary/basic_column.jsx'
import Phone from 'shared/components/summary/phone.jsx'
import DateAndTime from 'shared/components/summary/date_and_time.jsx'
import Address from 'shared/components/summary/address.jsx'
import BooleanColumn from 'shared/components/summary/boolean_column.jsx'
import RailsEnum from 'shared/components/summary/rails_enum.jsx'

let Vehicle = ({ vehicle }) => {
  return (
    <div className="card">
      <header className="card-header">
        <h5 className="card-title">Involved Vehicle</h5>
      </header>

      <div className="card-body">
        <Row>
          <BooleanColumn label="Member-owned?"
                         value={ vehicle.memberOwned } />
        </Row>

        <Row columnSize="xs-6" >
          <BasicColumn label="Type" value={ vehicle.vehicleTypeName } />
          <BasicColumn label="Make and Model" value={ vehicle.makeAndModel } />
          <BasicColumn label="Year" value={ vehicle.year } />
          <BasicColumn label="VIN #" value={ vehicle.vehicleIdentificationNumber } />
          <BasicColumn label="License Plate #" value={ vehicle.licensePlateNumber } />
        </Row>
        {
          !vehicle.memberOwned &&
            <div>
              <Row columnSize="xs-6" >
                <BasicColumn label="Owner First Name" value={ vehicle.ownerFirstName } />
                <BasicColumn label="Owner Last Name" value={ vehicle.ownerLastName } />
              </Row>
              <Row>
                <Phone label="Owner Primary Phone" value={ vehicle.ownerPrimaryPhone } />
              </Row>

              <Row>
                <Address label="Owner Address"
                         streetAddress={ vehicle.ownerStreetAddress }
                         optionalLine={ vehicle.ownerOptionalLine }
                         city={ vehicle.ownerCity }
                         state={ vehicle.ownerState }
                         zip={ vehicle.ownerZip } />
              </Row>

              <Row columnSize="xs-12">
                <BasicColumn label="Third-party insurance provider name"
                             value={ vehicle.insuranceProviderName } />
              </Row>

              <Row columnSize="xs-6">
                <BasicColumn label="Third-party insurance policy number"
                             value={ vehicle.policyNumber } />

                <Phone label="Third-party insurance phone number"
                       value={ vehicle.insuranceProviderPhone } />
              </Row>

              <Row columnSize="xs-12">
                <Address label="Third-party insurance provider address"
                         streetAddress={ vehicle.insuranceProviderStreetAddress }
                         optionalLine={ vehicle.insuranceProviderOptionalLine }
                         city={ vehicle.insuranceProviderCity }
                         state={ vehicle.insuranceProviderState }
                         zip={ vehicle.insuranceProviderZip } />
              </Row>
            </div>
        }

        <Row columnSize="xs-12">
          <BooleanColumn label="Is a claim being made for damage to this vehicle?" value={ vehicle.claimBeingMade } /> 
        </Row>

        {
          vehicle.claimBeingMade &&
            <div>
              {/* see note in invovled_vehiles/damage_fields why this is gone */}
              {/*
              <Row columnSize="xs-12">
                <RailsEnum label="Where was this vehicle damaged?" value={ vehicle.whereWasVehicleDamaged } />
              </Row>
              */}

              <Row columnSize="xs-6">
                <BasicColumn label="Describe what was damaged and how" value={ vehicle.descriptionOfDamage } />

                <BasicColumn label="What is the estimated cost to repair this vehicle?"
                             value={ vehicle.estimatedCostOfRepair } />
              </Row>

              <Row columnSize="xs-6" >
                <BasicColumn label="Contact for Inspecting First Name"
                             value={ vehicle.contactForInspectingVehicleFirstName } />

                <BasicColumn label="Contact for Inspecting Last Name"
                             value={ vehicle.contactForInspectingVehicleLastName } />
              </Row>
              <Row columnSize="xs-6" >
                <BasicColumn label="Contact for Inspecting Email"
                             value={ vehicle.contactForInspectingVehicleEmail } />

                <Phone label="Contact for Inspecting Phone" value={ vehicle.contactForInspectingVehiclePhone } />
              </Row>

              <Row columnSize="xs-12">
                <Address label="Vehicle Location"
                         streetAddress={ vehicle.vehicleStreetAddress }
                         optionalLine={ vehicle.vehicleOptionalLine }
                         city={ vehicle.vehicleCity }
                         state={ vehicle.vehicleState }
                         zip={ vehicle.vehicleZip } />
              </Row>
            </div>
        }

        <Row columnSize="xs-12">
          <BooleanColumn label="Was someone operating this vehicle at the time of the incident?"
                         value={ vehicle.wasSomeoneDriving } />
        </Row>

        {
          vehicle.wasSomeoneDriving &&
            <div>
              <Row columnSize="xs-6" >
                <BasicColumn label="Driver First Name" value={ vehicle.driverFirstName } />
                <BasicColumn label="Driver Last Name" value={ vehicle.driverLastName } />
              </Row>
              <Row columnSize="xs-6" >
                <Phone label="Driver Primary Phone" value={ vehicle.driverPrimaryPhone } />
                <Phone label="Driver Alternate Phone" value={ vehicle.driverAlternatePhone } />
              </Row>
              <Row columnSize="xs-6">
                <BasicColumn label="Driver Email" value={ vehicle.driverEmail } />
                <BasicColumn label="Driver's License Number" value={ vehicle.driverLicenseNumber } />
              </Row>
              <Row>
                <Address label="Vehicle Location"
                         streetAddress={ vehicle.vehicleStreetAddress }
                         optionalLine={ vehicle.vehicleOptionalLine }
                         city={ vehicle.vehicleCity }
                         state={ vehicle.vehicleState }
                         zip={ vehicle.vehicleZip } />
              </Row>

              {
                vehicle.memberOwned &&
                  <div>
                    <Row columnSize="xs-6">
                      <BooleanColumn label="Was this vehicle used with permission?" value={ vehicle.hadPermissionToUse } />

                      <RailsEnum label="What is the driver's relationship to the organization?"
                                 value={ vehicle.driverType } />

                      <RailsEnum label="For what purpose was this vehicle in use at the time of the incident?"
                                 value={ vehicle.driverReasonToUseVehicle } />
                    </Row>
                  </div>
              }
            </div>
        }

        <Row columnSize="xs-12">
          <BasicColumn label="Passenger count" value={ vehicle.passengerCount } />
          <BasicColumn label="Additional Comments" value={ vehicle.additionalComments } />
        </Row>
      </div>

      <footer className="card-footer"></footer>
    </div>
  )
}

export default Vehicle;
