import React from 'react'
import { connect } from 'react-redux'

const EntityTypeMultiSelect = ({ onChange, entityTypes, value }) => (
  <div className="form-group">
    <label className="-elevate">Associated Entities</label>
    <select multiple 
            className="form-control" 
            name="entityTypeIds" 
            onChange={ onChange }
            value={ value.length > 0 ? value : [''] }
    >
      <option value=''>All Types</option>
      { 
        entityTypes.map( (entityType) => (
          <option key={ entityType.id } value={ entityType.id }>
            { entityType.name }
          </option>
        )) 
      }
    </select>
  </div>
)

export default connect(
  (state, props) => ({
    entityTypes: Object.values(state.lookups.entityTypes)
  })
)(EntityTypeMultiSelect)
