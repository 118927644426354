import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types';

class AffixWhenScrolledPast extends React.Component {
  state = {
    visible: false
  }

  componentDidMount() {
    let domEl = ReactDOM.findDOMNode(this.refs[this.props.id + '_ref'])
    let pos = domEl.getBoundingClientRect()
    let parentWidth = domEl.clientWidth

    // Fixed elements are relative to viewport, so if we want the width to be relative to
    // the parent, manually set it.
    if(this.props.inheritWidth) {
      domEl.children[0].style.width = parentWidth + 'px'
    }

    window.addEventListener('scroll', _.debounce((ev) => {
      let pos = domEl.getBoundingClientRect()

      if(pos.top <= 0) {
        this.setState({visible: true})
      } else {
        this.setState({visible: false})
      }
    }, 20))
  }

  render() {
    return (
      <div className={ this.props.className } ref={ `${this.props.id}_ref` }>
        <div style={ this.state.visible ? { position: 'fixed', top: 0, zIndex: 80 } : {}}>
          { (!this.props.hideUntilFixed || this.state.visible) && this.props.children }
        </div>
      </div>
    )
  }
}

AffixWhenScrolledPast.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  inheritWidth: PropTypes.bool,
  hideUntilFixed: PropTypes.bool
}

export default AffixWhenScrolledPast
