import wizardFactory from 'shared/ducks/wizard_factory.js'

let wizard = wizardFactory("business_continuity_document" /*, { optional: different_state }*/)

export default wizard.default
export const setErrorFlash    = wizard.setErrorFlash
export const setSuccessFlash  = wizard.setSuccessFlash
export const updateSucceeded  = wizard.updateSucceeded
export const updateFailed     = wizard.updateFailed
export const updateStarted    = wizard.updateStarted
export const resetWizardState = wizard.resetWizardState
export const openMobileMenu   = wizard.openMobileMenu
export const closeMobileMenu  = wizard.closeMobileMenu
