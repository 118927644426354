import React from 'react'
import { connect } from 'react-redux'

import AttachmentsRow from './attachments_row.jsx'

class AttachmentsTable extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div>
        <table id="attachment-table" className="table table-striped">
          <thead>
            <tr>
              <th className="col-xs-4">File Preview</th>
              <th>File Comment</th>
              <th className="col-xs-1"></th>
            </tr>
          </thead>

          <tbody>
            {
              this.props.attachments.map((attachment) => {
                return (
                  <AttachmentsRow
                    key={ attachment.id || attachment._id }
                    attachment={ attachment }
                    handleCommentChange={ this.props.handleCommentChangeFor(attachment) }
                    deleteAttachment={ this.props.deleteAttachment(attachment) }
                  />
                )
              })
            }

            {
              this.props.attachments.length === 0 &&
                <tr>
                  <td colSpan='3'>
                    <em>No files selected.</em>
                  </td>
                </tr>
            }
          </tbody>
        </table>
      </div>
    )
  }
}

export default AttachmentsTable
