import React from 'react'
import { connect } from 'react-redux'
import { Link, Redirect, Prompt } from 'react-router-dom'

import BaseForm from 'shared/components/base_form.jsx'
import {
  FormField,
  TextField,
  PhoneNumberField,
  CheckboxField
} from 'shared/components/fields'

import {
  requiredValidator,
  emailValidator,
  phoneNumberValidator
} from 'shared/utils/validators'

import { asyncUpdateGrantRequest } from '../ducks/grant_request.js'
import { apiEndpoint } from '../ducks/rails.js'
import { resetWizardState } from '../ducks/wizard.js'
import { Panel, PanelSidebar, PanelRightColumn, PanelBody, PanelControls } from '../panel'
import IconWithTooltip from 'shared/components/icon_with_tooltip.jsx'

const requiredIfPersisted = (value, state) => {
  if (!state._id && state.id) { // a persisted record
    return requiredValidator()(value)
  }
}

class FinalizeForm extends BaseForm {
  constructor(props) {
    super(props)
    const grantRequest = { ...(this.props.grantRequest || {} )}
    const errors       = { ...(this.props.errors || {} )}

    const emailsToNotify = [ ...(this.props.emailsToNotify || []) ]
    const organization   = { ...(this.props.organization || {}) }

    this.state = {
      grantRequest: {
        caoName:  new FormField(grantRequest.caoName || organization.caoName, requiredValidator()),
        caoEmail: new FormField(grantRequest.caoEmail || organization.caoEmail,  [emailValidator(), requiredValidator()]),
        caoPhone: new FormField(grantRequest.caoPhone || organization.caoPhone, [phoneNumberValidator(), requiredValidator()]),
        caoTitle: new FormField(grantRequest.caoTitle || organization.caoTitle, requiredValidator()),

        signature:                  new FormField(grantRequest.signature, requiredValidator()),
        conditionsOfFunding:        new FormField(grantRequest.conditionsOfFunding, requiredValidator()),
        caoAwareOfRequest:          new FormField(grantRequest.caoAwareOfRequest, requiredValidator()),
        informationLawfullyEntered: new FormField(grantRequest.informationLawfullyEntered, requiredValidator())

      },

      emailsToNotify: emailsToNotify.map((emailToNotify) => ({
        id:    emailToNotify.id,
        email: new FormField(emailToNotify.email, [requiredIfPersisted, emailValidator()])
      })).concat({
        // we have a setup where we always put an intial empty email in the table for the user
        // to fill out
        _id:   Date.now(),
        email: new FormField('', [requiredIfPersisted, emailValidator()])
      })
    }

    // add any errors we found when attempting to submit
    Object.keys(this.state.grantRequest).map((key) => {

      // handles these separately to add more clarity...
      if (['caoName', 'caoEmail', 'caoPhone', 'caoTitle'].includes(key)) {

        // ...if it looks like there's an error about this field 'can't be blank'...
        if (((errors[key] || {}).message || '').includes("blank")) {

          // ...and it looks like we've prefilled the form value, but it's blank in the db (redux store)...
          if (!grantRequest[key] && organization[key]) {

            // ...just clear the error because they'll have to save at some point for
            //    their sinature at least...
            this.state.grantRequest[key].errorMessage = null

            return // ...and then bail, because we're good
          }
        }
      }

      // everyone else just pulls their error out of the errors object like normal
      this.state.grantRequest[key].errorMessage = (errors[key] || {}).message
    })
  }

  componentWillUnmount() {
    this.props.resetWizardState()
  }

  handleChangeFor = (name) => ( this.handleFieldChange("grantRequest", name) )

  handleEmailToNotifyChangeFor = (id, name) => ( this.handleFieldChangeInList("emailsToNotify", id, name) )

  deleteEmailToNotify = (emailToNotify) => () => {
    if (emailToNotify._id && !emailToNotify.id) { // not persisted to rails, so just ditch it
      this.removeFromList("emailsToNotify", emailToNotify._id)
    }
    else {
      this.markToDestroyInList("emailsToNotify", emailToNotify.id)
    }
  }

  addEmailToNotify = () => {
    this.addToList("emailsToNotify", {
      _id:   Date.now(),
      email: new FormField('', [requiredIfPersisted, emailValidator()])
    })
  }

  anyChangesMadeToEmailsToNotify = () => (
    // a little bit of a hack but this stops the intial empty record we added in the
    // constructor from counting as a change
    this.anyChangesMadeInList("emailsToNotify", this.props.emailsToNotify.concat({
                                                  _id: Date.now(),
                                                  email: ''
                                                }))
  )

  prepareData = () => {
    let copy = { grantRequest: { ...this.state.grantRequest } }

    // turn FormField objects in to just the raw value
    Object.keys(copy.grantRequest).map((key) => {
      if (key === 'signature') {
        copy.grantRequest[key] = copy.grantRequest[key].value.replace(/\s+/g, ' ').trim()
      } else {
        copy.grantRequest[key] = copy.grantRequest[key].value
      }
    })

    copy.grantRequest.emailsToNotifyAttributes = {}

    this.state.emailsToNotify.forEach((emailToNotify, index) => {
      // this is an un-persisted blank record--we can just ignore this and not bog down the user
      if (emailToNotify._id && !emailToNotify.email.value) { return }

      let { _id, ...filtered } = emailToNotify

      Object.keys(filtered).map((key) => {
        if (key === "_destroy" || key === "id") { return }

        filtered[key] = filtered[key].value
      })

      copy.grantRequest.emailsToNotifyAttributes[index.toString()] = filtered
    })

    return copy
  }

  handleSave = (event) => {
    event.preventDefault()

    if (this.validateFields("grantRequest")  && this.validateFieldsInList("emailsToNotify")) {
      this.props.asyncUpdateGrantRequest(this.prepareData())
    }
  }

  render() {
    if (this.props.updateSucceeded) {
      return <Redirect to="/review" />
    }

    return (
        <Panel>
          <PanelSidebar />

          <PanelRightColumn>
            <PanelBody>
              <h4>Finalize & Submit</h4>

              <div className="row">
                <div className="col-sm-5">
                  <TextField type="text"
                             name="caoName"
                             onChange={ this.handleChangeFor("caoName") }
                             label="CAO Name"
                             value={ this.state.grantRequest.caoName.value }
                             errorMessage={ this.state.grantRequest.caoName.errorMessage }
                             required={ true }
                             tabIndex="1"
                             autoFocus={ true } />
                </div>
                <div className="col-sm-1">
                  <IconWithTooltip
                      icon="fa-question-circle"
                      content={ "The chief appointed official (CAO) is the administrative manager of your organization " +
                      "and may also be referred to as the chief executive officer (CEO) or chief administrative officer (CAO)." }
                  />
                </div>
                <div className="col-sm-6">
                  <TextField type="text"
                             name="caoEmail"
                             onChange={ this.handleChangeFor("caoEmail") }
                             label="CAO Email Address"
                             value={ this.state.grantRequest.caoEmail.value }
                             errorMessage={ this.state.grantRequest.caoEmail.errorMessage }
                             required={ true }
                             tabIndex="2" />
                </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <PhoneNumberField name="caoPhone"
                                  onChange={ this.handleChangeFor("caoPhone") }
                                  label="CAO Phone"
                                  value={ this.state.grantRequest.caoPhone.value }
                                  errorMessage={ this.state.grantRequest.caoPhone.errorMessage }
                                  required={ true }
                                  tabIndex="3" />

              </div>
              <div className="col-sm-6">
                <TextField type="text"
                           name="caoTitle"
                           onChange={ this.handleChangeFor("caoTitle") }
                           label="CAO Title"
                           value={ this.state.grantRequest.caoTitle.value }
                           errorMessage={ this.state.grantRequest.caoTitle.errorMessage }
                           required={ true }
                           tabIndex="4" />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <CheckboxField name="conditionsOfFunding"
                               label="I understand that my organization will not receive funds until receipts are provided to VRSA"
                               value={ this.state.grantRequest.conditionsOfFunding.value }
                               errorMessage={ this.state.grantRequest.conditionsOfFunding.errorMessage }
                               onChange={ this.handleChangeFor("conditionsOfFunding") }
                               required={ true }
                               tabIndex="5" />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <CheckboxField name="caoAwareOfRequest"
                               label="I verify that my Chief Appointed Officer is aware of this grant application"
                               value={ this.state.grantRequest.caoAwareOfRequest.value }
                               errorMessage={ this.state.grantRequest.caoAwareOfRequest.errorMessage }
                               onChange={ this.handleChangeFor("caoAwareOfRequest") }
                               required={ true }
                               tabIndex="6" />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <CheckboxField name="informationLawfullyEntered"
                               label={ `I verify that I am ${this.props.currentUser.name}, and I certify the information provided in this grant application is correct` }
                               value={ this.state.grantRequest.informationLawfullyEntered.value }
                               errorMessage={ this.state.grantRequest.informationLawfullyEntered.errorMessage }
                               onChange={ this.handleChangeFor("informationLawfullyEntered") }
                               required={ true }
                               tabIndex="7" />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-6">
                <TextField type="text"
                           name="signature"
                           onChange={ this.handleChangeFor("signature") }
                           label="Your name"
                           value={ this.state.grantRequest.signature.value }
                           errorMessage={ this.state.grantRequest.signature.errorMessage }
                           required={ true }
                           tabIndex="8" />
              </div>

              <div className="col-xs-6">
                <p><i>Your name serves as an electronic signature on this application form.</i></p>
              </div>
            </div>


            <div className="row">
              <p><b>In addition to the CAO, send a copy of this grant request to:</b></p>
              <div className="col-xs-12">
                <table className="table table-striped">
                  <thead>
                    <tr>
                     <th>Email</th>
                     <th className="col-xs-1"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.emailsToNotify.filter((emailToNotify) => ( !emailToNotify._destroy )).map((emailToNotify, index, array) => {
                        let emailToNotifyId = emailToNotify.id || emailToNotify._id
                        let isLast = (array.length - 1) === index

                        return (
                          <tr key={ emailToNotifyId }>
                            <td>
                              <TextField type="text"
                                         length="120"
                                         bootstrapWrapperClassName="no-margin-bottom"
                                         name={ `emailsToNotify[${emailToNotifyId}][email]` }
                                         onChange={ this.handleEmailToNotifyChangeFor(emailToNotifyId, 'email') }
                                         label="Email"
                                         value={ emailToNotify.email.value }
                                         errorMessage={ emailToNotify.email.errorMessage }
                                         tabIndex="9" />
                            </td>

                            {
                              isLast ?
                                <td className="add-line-item">
                                  <a onClick={ this.addEmailToNotify }>
                                    <i className='fa fa-plus-circle'></i>
                                  </a>
                                </td>
                              :
                                <td className="delete-line-item">
                                  <a onClick={ this.deleteEmailToNotify(emailToNotify) }>
                                    <i className='fa fa-trash-o'></i>
                                  </a>
                                </td>
                            }
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </PanelBody>

          <PanelControls>
            <Prompt when={
                      this.anyChangesMade("grantRequest", this.props.grantRequest)||
                        this.anyChangesMadeToEmailsToNotify()
                    }
                    message="Are you sure you want to move away? Any unsaved changes made
                           to this section will be lost."  />

            <Link to="/attachments">
              <button type="button"
                      className="btn btn-link"
                      tabIndex="10">
                Back
              </button>
            </Link>

            <Link to="/review">
              <button type="button"
                      onClick={ this.handleSave }
                      className="btn btn-success"
                      tabIndex="10">
                Save and Continue
              </button>
            </Link>
          </PanelControls>
        </PanelRightColumn>
      </Panel>
    )
  }
}

FinalizeForm = connect(
  (state, props) => ({
    grantRequest: {
      caoName:       state.grantRequest.caoName,
      caoEmail:      state.grantRequest.caoEmail,
      caoPhone:      state.grantRequest.caoPhone,
      caoTitle:      state.grantRequest.caoTitle,
      signature:     state.grantRequest.signature,
      conditionsOfFunding:     state.grantRequest.conditionsOfFunding,
      caoAwareOfRequest:     state.grantRequest.caoAwareOfRequest,
      informationLawfullyEntered:     state.grantRequest.informationLawfullyEntered
    },

    errors: state.submissionErrors.grantRequest,

    emailsToNotify: Object.values(state.emailsToNotify || {}).map((emailToNotify) => ({
      id:            emailToNotify.id,
      email:   emailToNotify.email
    })),

    updateSucceeded: state.wizard.updateSucceeded,
    updating:        state.wizard.updating,
    apiEndpoint:     apiEndpoint(state.rails, "grant_request"),

    organization: { ...state.rails.organization },
    currentUser: state.rails.currentUser
  }),
  null,
  ({ apiEndpoint, ...stateProps }, { dispatch }, props) => ({
    ...stateProps,
    ...props,

    asyncUpdateGrantRequest: (data) => {
      return asyncUpdateGrantRequest(apiEndpoint.path, data)(dispatch)
    },

    resetWizardState: () => { dispatch(resetWizardState()) }
  })

)(FinalizeForm);

export default FinalizeForm
