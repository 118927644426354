import React from 'react'
import { formatDollarValue } from 'shared/utils/dollar_values.js'

import TextField from './text_field.jsx'

const normalizeDollarValue = (value) => {
  if (!value) {
    return "";
  }

  let _value = value.replace(/[\D]/g, '') // only numbers
  _value = _value.replace(/^0+/g, '') // ditch leading zeros

  if (!_value) {
    return "";
  }
  else if (_value.length === 1) {
    return `0.0${_value}`
  }
  else if (_value.length === 2) {
    return `0.${_value}`
  }
  else {
    let left = _value.slice(0, _value.length - 2)
    let right = _value.slice(_value.length - 2)

    return `${left}.${right}`
  }
};


export default class DollarValueField extends React.Component {
  onChange = (value) => {
    this.props.onChange(normalizeDollarValue(value))
  }

  render() {
    const { onChange, value, ...props } = this.props

    return <TextField { ...props } type="text" value={ formatDollarValue(value) } onChange={ this.onChange } />
  }
}
