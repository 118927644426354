import React from 'react'
import PropTypes from 'prop-types';

import AddOrImportQuestion from './add_or_import_question.jsx'
import BootstrapModal from 'shared/components/move_off_of_eventually_bootstrap_modal.jsx'
import EditQuestionContainer from '../section_question/edit_question_container.jsx'

class AddOrImportQuestionPopup extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      addingQuestion: false
    }

    this.handleAddQuestionClick       = this.handleAddQuestionClick.bind(this)
    this.handleHideEditQuestionModal  = this.handleHideEditQuestionModal.bind(this)
    this.handleHideAddOrImportModal   = this.handleHideAddOrImportModal.bind(this)
  }

  handleAddQuestionClick() {
    this.setState({ addingQuestion: true })
  }

  handleHideAddOrImportModal() {
    // Don't hide if we're opening the EditQuestion modal (instead of closing this modal)
    if(!this.state.addingQuestion) {
      this.props.handleHide()
    }
  }

  handleHideEditQuestionModal() {
    this.props.handleHide()
  }

  render() {
    return this.state.addingQuestion ?
            ( <EditQuestionContainer surveySectionId={ this.props.surveySectionId }
                                     handleHide={ this.handleHideEditQuestionModal } />
            ) : (
              <BootstrapModal
                title='Add or Import Questions'
                body={
                  <AddOrImportQuestion onAddQuestionClick={ this.handleAddQuestionClick }
                                       surveySectionId={ this.props.surveySectionId }
                                       coverageTypeId={ this.props.coverageTypeId } />
                }
                handleHide={ this.handleHideAddOrImportModal }
              />
            )
  }
}

AddOrImportQuestionPopup.propTypes = {
  surveySectionId: PropTypes.number,
  coverageTypeId: PropTypes.number,

  handleHide: PropTypes.func.isRequired
}

export default AddOrImportQuestionPopup
