import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { closeMobileMenu } from '../ducks/wizard.js'

class PanelSidebar extends React.Component {
  onLinkClick = (eventAttendee) => {
    eventAttendee.target.blur()

    if (this.props.mobileMenuOpen) { this.props.closeMobileMenu() }

    if (this.props.onLinkClick) {
      this.props.onLinkClick(eventAttendee)
    }
  }

  badgeFor = (countKey) => {
    if (this.props[countKey] && this.props[countKey] > 0) {
      return <span className="badge">{ this.props[countKey] }</span>
    }

    return null
  }

  render() {
    return (
      <ul className={ `panel-body-left nav nav-stacked nav-sidebar ${this.props.mobileMenuOpen ? "-is-open" : ""}` }>
        <li><NavLink to="/attendee_information" onClick={ this.onLinkClick }>Attendee Information</NavLink></li>
      </ul>
    )
  }
}

PanelSidebar = connect(
  (state, props) => ({
    mobileMenuOpen:   state.wizard.mobileMenuOpen
  }),
  (dispatch, props) => ({
    closeMobileMenu: () => { dispatch(closeMobileMenu()) }
  })
)(PanelSidebar)

export default PanelSidebar
