import { createStore, combineReducers } from 'redux';

import railsFactory from 'shared/ducks/rails_factory.js'
import wizardFactory from 'shared/ducks/wizard_factory.js'

// import attachments from './ducks/documents.js'
// import submissionErrors from './ducks/submission_errors.js'

let rails  = railsFactory("business_continuity_document", {}).default
let wizard = wizardFactory("business_continuity_document", {}).default


/* WARNING - this does not support thunks */
const handleBatches = (combined) => (state = {}, action) => {
  switch (action.type) {
    case 'INITIAL_LOAD_OF_DATA_FROM_RAILS':
    case 'RAILS_UPDATE_SUCCEEDED':
    case 'RAILS_UPDATE_FAILED': {
      return action.actions.reduce((collectedState, batchedAction) => {
        return combined(collectedState, batchedAction)
      }, { ...state })
    }

    default: { return combined(state, action) }
  }
}

const store = createStore(
  handleBatches(
    combineReducers({
      rails,
      wizard,
      // attachments,
      //submissionErrors
    }),
  ),

  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store
