import React from 'react'
import { connect } from 'react-redux'
import { Link, Redirect, Prompt } from 'react-router-dom'

import BaseForm from 'shared/components/base_form.jsx'

import {
  FormField,
  TextField,
  PhoneNumberField,
  AddressField
} from 'shared/components/fields'

import {
  requiredValidator,
  emailValidator,
  phoneNumberValidator,
  lengthValidator,
  zipValidator
} from 'shared/utils/validators'

import { asyncUpdateGrantRequest } from '../ducks/grant_request.js'
import { apiEndpoint } from '../ducks/rails.js'
import { resetWizardState } from '../ducks/wizard.js'
import { Panel, PanelSidebar, PanelRightColumn, PanelBody, PanelControls } from '../panel'

import MemberInfo from './member_info.jsx'

class MemberInformationForm extends BaseForm {
  constructor(props) {
    super(props)

    const grantRequest = { ...(this.props.grantRequest || {}) }
    const errors = { ...(this.props.errors || {}) }

    const organization = { ...(this.props.organization || {}) }
    const currentUser =  { ...(this.props.currentUser || {}) }

    this.state = {
      grantRequest: {
        department: new FormField(grantRequest.department, [requiredValidator(), lengthValidator(80)]),

        applicantName:  new FormField(grantRequest.applicantName || currentUser.name, requiredValidator()),
        applicantTitle: new FormField(grantRequest.applicantTitle || currentUser.title, requiredValidator()),
        applicantPhone: new FormField(grantRequest.applicantPhone || currentUser.phone, phoneNumberValidator()),
        applicantEmail: new FormField(
          grantRequest.applicantEmail || currentUser.email,
          [emailValidator(), requiredValidator()]
        ),

        mailingStreetAddress: new FormField(
          grantRequest.mailingStreetAddress || organization.mailingStreetAddress,
          requiredValidator()
        ),

        mailingOptionalLine: new FormField(
          grantRequest.mailingOptionalLine || organization.mailingOptionalLine
        ),

        mailingCity: new FormField(
          grantRequest.mailingCity || organization.mailingCity,
          requiredValidator()
        ),

        mailingState: new FormField(
          grantRequest.mailingState || organization.mailingState,
          requiredValidator()
        ),

        mailingZip: new FormField(
          grantRequest.mailingZip || organization.mailingZip,
          [requiredValidator(), zipValidator()]
        )
      }
    }

    // add any errors we found when attempting to submit
    Object.keys(this.state.grantRequest).map((key) => {
      this.state.grantRequest[key].errorMessage = (errors[key] || {}).message
    })
  }

  handleChangeFor = (name) => ( this.handleFieldChange("grantRequest", name) )

  componentWillUnmount() {
    this.props.resetWizardState()

    //this.props.resetSubmissionErrors()

  }

  prepareData = () => {
    let copy = { grantRequest: { ...this.state.grantRequest } }

    // turn FormField objects in to just the raw value
    Object.keys(copy.grantRequest).map((key) => {
      copy.grantRequest[key] = copy.grantRequest[key].value
    })

    return copy
  }

  handleSave = (event) => {
    event.preventDefault()

    if (this.validateFields("grantRequest") ) {
      this.props.asyncUpdateGrantRequest(this.prepareData())
    }
  }


  render() {
    if (this.props.updateSucceeded) {
      return <Redirect to="/request_details" />
    }

    return (
      <Panel>
        <PanelSidebar />

        <PanelRightColumn>
          <PanelBody>
            <MemberInfo />

            <hr />

            <div className="row">
              <div className="col-xs-12">
                <TextField type="text"
                         name="department"
                         onChange={ this.handleChangeFor("department") }
                         label="Department Requesting Funds"
                         value={ this.state.grantRequest.department.value }
                         errorMessage={ this.state.grantRequest.department.errorMessage }
                         required={ true }
                         tabIndex="1"
                         autoFocus={ true }
                         warn={ (value) => ( value && `Character limit: ${value.length}/80` ) } />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <TextField type="text"
                         name="applicantName"
                         onChange={ this.handleChangeFor("applicantName") }
                         label="Name of Applicant"
                         value={ this.state.grantRequest.applicantName.value }
                         errorMessage={ this.state.grantRequest.applicantName.errorMessage }
                         required={ true }
                         tabIndex="2" />
              </div>

              <div className="col-xs-12 col-sm-6">
                <TextField type="text"
                           name="applicantTitle"
                           onChange={ this.handleChangeFor("applicantTitle") }
                           label="Title of Applicant"
                           value={ this.state.grantRequest.applicantTitle.value }
                           errorMessage={ this.state.grantRequest.applicantTitle.errorMessage }
                           required={ true }
                           tabIndex="3" />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <AddressField required={ true }
                              tabIndex="4"
                              fields={{
                                streetAddress: {
                                  name: "mailingStreetAddress",
                                  value: this.state.grantRequest.mailingStreetAddress.value,
                                  errorMessage: this.state.grantRequest.mailingStreetAddress.errorMessage,
                                  onChange: this.handleChangeFor("mailingStreetAddress")
                                },
                                optionalLine: {
                                  name: "mailingOptionalLine",
                                  value: this.state.grantRequest.mailingOptionalLine.value,
                                  errorMessage: this.state.grantRequest.mailingOptionalLine.errorMessage,
                                  onChange: this.handleChangeFor("mailingOptionalLine")
                                },
                                city: {
                                  name: "mailingCity",
                                  value: this.state.grantRequest.mailingCity.value,
                                  errorMessage: this.state.grantRequest.mailingCity.errorMessage,
                                  onChange: this.handleChangeFor("mailingCity")
                                },
                                state: {
                                  name: "mailingState",
                                  value: this.state.grantRequest.mailingState.value,
                                  errorMessage: this.state.grantRequest.mailingState.errorMessage,
                                  onChange: this.handleChangeFor("mailingState")
                                },
                                zip: {
                                  name: "mailingZip",
                                  value: this.state.grantRequest.mailingZip.value,
                                  errorMessage: this.state.grantRequest.mailingZip.errorMessage,
                                  onChange: this.handleChangeFor("mailingZip")
                                }
                              }} />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <PhoneNumberField name="applicantPhone"
                                onChange={ this.handleChangeFor("applicantPhone") }
                                label="Phone Number"
                                value={ this.state.grantRequest.applicantPhone.value }
                                errorMessage={ this.state.grantRequest.applicantPhone.errorMessage }
                                tabIndex="5" />
              </div>
              <div className="col-xs-12 col-sm-6">
                <TextField type="text"
                         name="applicantEmail"
                         onChange={ this.handleChangeFor("applicantEmail") }
                         label="Email Address"
                         value={ this.state.grantRequest.applicantEmail.value }
                         errorMessage={ this.state.grantRequest.applicantEmail.errorMessage }
                         required={ true }
                         tabIndex="6" />
              </div>
            </div>
          </PanelBody>
          <PanelControls>
            <Prompt when={ this.anyChangesMade("grantRequest", this.props.grantRequest)}
                    message="Are you sure you want to move away? Any unsaved changes made
                             to this section will be lost."  />
            <Link to="/request_details">
              <button type="button"
                      onClick={ this.handleSave }
                      className={ `btn btn-success ${this.props.updating ? "disabled" : ""}` }
                      tabIndex="7">
                Save & Continue
              </button>
            </Link>
          </PanelControls>
        </PanelRightColumn>
      </Panel>
    )
  }
}

MemberInformationForm = connect(
  (state, props) => ({
    grantRequest: {
      department:           state.grantRequest.department,

      applicantName:        state.grantRequest.applicantName,
      applicantTitle:       state.grantRequest.applicantTitle,
      applicantPhone:       state.grantRequest.applicantPhone,
      applicantEmail:       state.grantRequest.applicantEmail,

      mailingStreetAddress: state.grantRequest.mailingStreetAddress,
      mailingOptionalLine:  state.grantRequest.mailingOptionalLine,
      mailingCity:          state.grantRequest.mailingCity,
      mailingState:         state.grantRequest.mailingState,
      mailingZip:           state.grantRequest.mailingZip,
    },

    errors: state.submissionErrors.grantRequest,

    updateSucceeded: state.wizard.updateSucceeded,
    updating:        state.wizard.updating,
    apiEndpoint:     apiEndpoint(state.rails, "grant_request"),

    currentUser:  { ...state.rails.currentUser },
    organization: { ...state.rails.organization }
  }),
  null,
  ({ apiEndpoint, ...stateProps }, { dispatch }, props) => ({
    ...stateProps,
    ...props,

    asyncUpdateGrantRequest: (data) => {
      return asyncUpdateGrantRequest(apiEndpoint.path, data)(dispatch)
    },

    resetWizardState: () => { dispatch(resetWizardState()) }
  })
)(MemberInformationForm);

export default MemberInformationForm
