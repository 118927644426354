import React from 'react'
import PropTypes from 'prop-types';

import OneAsyncSelectToRuleThemAll from 'shared/components/one_async_select_to_rule_them_all.jsx'

class SelectWithAsyncOptionsFromRails extends React.Component {
  constructor(props) {
    super(props)

    this.state = { shouldAggressivelyReload: props.shouldAggressivelyReload }
  }

  // one level up we'll pass a prop to check if this thing shouldAggressivelyReload because
  // we might chang it's loadPath. but here we want to make sure we don't make undo network
  // requests. so we'll manage our own shouldAggressivelyReload in state.
  //
  componentDidUpdate(prevProps) {
    // basically if our parent told us we should shouldAggressivelyReload (via the prop)
    // _and_ it looks like our loadpath just changed, make sure we're set to aggressivelyReload
    if (this.props.shouldAggressivelyReload && this.props.loadPath !== prevProps.loadPath) {
      this.setState({ shouldAggressivelyReload: true })
    }

    // but if it doesn't look like the load path changed, and we're currently set to
    // aggressivelyReload turn that functionality off
    else if (this.state.shouldAggressivelyReload) {
      this.setState({ shouldAggressivelyReload: false })
    }
  }

  // all of our async option loaders return an object like { value: '', label: '' },
  // sometimes we'll be interested in saving the label with the value. an example of this is when
  // the value is just a code from origami. by saving the description of that code alongside it
  // we can display meaningful information without querying the database.
  // we handle this by just passing both the value and label to onChange, let upstream decide what
  // to do.
  //
  onChange = (option) => {
    if (option) { // option will be null if they cleared a selection with the "x"
      this.props.onChange(option.value, option.label)
    }
    else {
      this.props.onChange("", "") // just clear them
    }
  }

  getSelectOptions = () => {
    return fetch(this.props.loadPath, { credentials: 'same-origin' }).
      then((response) => {
        return response.json();
      }).
      then((json) => {
        return { options: json, complete: true };
      });
  }

  render() {
    const {
      loadPath,
      required,
      label,
      onChange,
      errorMessage,
      value,
      shouldAggressivelyReload,
      tabIndex
    } = this.props;

    return (
      <div className={ `form-group ${errorMessage ? 'has-error' : ''}` }>
        <OneAsyncSelectToRuleThemAll.Base
          labelText={ label }
          requiredInput={ required }
          tabIndex={ tabIndex }
          value={ value }
          onChange={ this.onChange }
          loadOptions={ this.getSelectOptions }
          simpleValue={ false }
          shouldAggressivelyReload={ this.state.shouldAggressivelyReload }
        />

        { errorMessage && <span className="field-level-error">{ errorMessage }</span> }
      </div>
    )
  }
}

SelectWithAsyncOptionsFromRails.defaultProps = {
  required: false,
  shouldAggressivelyReload: false
}

SelectWithAsyncOptionsFromRails.propTypes = {
  loadPath:                 PropTypes.string.isRequired,
  required:                 PropTypes.bool.isRequired,
  label:                    PropTypes.string.isRequired,
  onChange:                 PropTypes.func.isRequired,
  shouldAggressivelyReload: PropTypes.bool.isRequired,

  errorMessage:             PropTypes.string,
  value:                    PropTypes.string
}

export default SelectWithAsyncOptionsFromRails;
