import React from "react"
import { connect } from 'react-redux'

import { default as SectionQuestion } from './section_question.jsx'

const SurveySection = ({ header, content, questions }) => (
    <div className='row survey-section'>
        <div className='col-sm-12'>
            <h4>{ header }</h4>
            <p>{ content }</p>
        </div>
        {
            Object.values(questions).map((cyberSectionQuestion) =>
                <SectionQuestion key={ cyberSectionQuestion.id }
                                 id={ cyberSectionQuestion.id }
                                 question={ cyberSectionQuestion.cyberQuestionAttributes }
                                 { ...cyberSectionQuestion } />
            )
        }
        <div className="col-sm-12">
            <hr />
        </div>
    </div>
)

export default connect()(SurveySection)
