import React from 'react'
import PropTypes from 'prop-types';

import { normalizeZip, formatZip } from 'shared/utils/addresses.js'

import TextField from './text_field.jsx'
import SelectField from './select_field.jsx'

class AddressField extends React.Component {
  handleZipChange = (value) => {
    this.props.fields.zip.onChange(normalizeZip(value))
  }

  formatZip = (value) => { // TODO - currently used as a hook for testing though not strictly necessary to redefine here
    return formatZip(value)
  }

  render() {
    const {
      fields,
      required,
      shouldFloatLabelsOnUpdate,
      instructions,
      tabIndex
    } = this.props

    return (
      <div className="address-group">
        <TextField type="text"
                   bootstrapWrapperClassName="address-line-1-group"
                   className="form-control-address-line-1"
                   name={ fields.streetAddress.name }
                   onChange={ fields.streetAddress.onChange }
                   label="Address Line 1"
                   value={ fields.streetAddress.value }
                   errorMessage={ fields.streetAddress.errorMessage }
                   required={ required }
                   instructions={ instructions }
                   shouldFloatLabelOnUpdate={ shouldFloatLabelsOnUpdate }
                   tabIndex={ tabIndex } />

        <TextField type="text"
                   bootstrapWrapperClassName="address-line-2-group"
                   className="form-control-address-line-2"
                   name={ fields.optionalLine.name }
                   onChange={ fields.optionalLine.onChange }
                   label="Address Line 2 (Optional)"
                   value={ fields.optionalLine.value }
                   errorMessage={ fields.optionalLine.errorMessage }
                   shouldFloatLabelOnUpdate={ shouldFloatLabelsOnUpdate }
                   tabIndex={ tabIndex } />

        <div className="form-inline">
          <TextField type="text"
                     bootstrapWrapperClassName="form-group-city pad-right"
                     className="form-control-city"
                     name={ fields.city.name }
                     onChange={ fields.city.onChange }
                     label="City"
                     value={ fields.city.value }
                     errorMessage={ fields.city.errorMessage }
                     required={ required }
                     instructions={ instructions }
                     shouldFloatLabelOnUpdate={ shouldFloatLabelsOnUpdate }
                     tabIndex={ tabIndex } />

          <SelectField bootstrapWrapperClassName="form-group-state pad-right"
                       className="form-control-state"
                       name={ fields.state.name }
                       onChange={ fields.state.onChange }
                       label="State"
                       placeholder="State"
                       value={ fields.state.value }
                       errorMessage={ fields.state.errorMessage }
                       required={ required }
                       instructions={ instructions }
                       shouldFloatLabelOnUpdate={ shouldFloatLabelsOnUpdate }
                       tabIndex={ tabIndex }
                       options={[
                         { displayText: 'AL', value: 'AL' },
                         { displayText: 'AK', value: 'AK' },
                         { displayText: 'AZ', value: 'AZ' },
                         { displayText: 'AR', value: 'AR' },
                         { displayText: 'CA', value: 'CA' },
                         { displayText: 'CO', value: 'CO' },
                         { displayText: 'CT', value: 'CT' },
                         { displayText: 'DE', value: 'DE' },
                         { displayText: 'DC', value: 'DC' },
                         { displayText: 'FL', value: 'FL' },
                         { displayText: 'GA', value: 'GA' },
                         { displayText: 'HI', value: 'HI' },
                         { displayText: 'ID', value: 'ID' },
                         { displayText: 'IL', value: 'IL' },
                         { displayText: 'IN', value: 'IN' },
                         { displayText: 'IA', value: 'IA' },
                         { displayText: 'KS', value: 'KS' },
                         { displayText: 'KY', value: 'KY' },
                         { displayText: 'LA', value: 'LA' },
                         { displayText: 'ME', value: 'ME' },
                         { displayText: 'MD', value: 'MD' },
                         { displayText: 'MA', value: 'MA' },
                         { displayText: 'MI', value: 'MI' },
                         { displayText: 'MN', value: 'MN' },
                         { displayText: 'MS', value: 'MS' },
                         { displayText: 'MO', value: 'MO' },
                         { displayText: 'MT', value: 'MT' },
                         { displayText: 'NE', value: 'NE' },
                         { displayText: 'NV', value: 'NV' },
                         { displayText: 'NH', value: 'NH' },
                         { displayText: 'NJ', value: 'NJ' },
                         { displayText: 'NM', value: 'NM' },
                         { displayText: 'NY', value: 'NY' },
                         { displayText: 'NC', value: 'NC' },
                         { displayText: 'ND', value: 'ND' },
                         { displayText: 'OH', value: 'OH' },
                         { displayText: 'OK', value: 'OK' },
                         { displayText: 'OR', value: 'OR' },
                         { displayText: 'PA', value: 'PA' },
                         { displayText: 'RI', value: 'RI' },
                         { displayText: 'SC', value: 'SC' },
                         { displayText: 'SD', value: 'SD' },
                         { displayText: 'TN', value: 'TN' },
                         { displayText: 'TX', value: 'TX' },
                         { displayText: 'UT', value: 'UT' },
                         { displayText: 'VT', value: 'VT' },
                         { displayText: 'VA', value: 'VA' },
                         { displayText: 'WA', value: 'WA' },
                         { displayText: 'WV', value: 'WV' },
                         { displayText: 'WI', value: 'WI' },
                         { displayText: 'WY', value: 'WY' }
                       ]}/>

          <TextField type="text"
                     bootstrapWrapperClassName="form-group-zipcode"
                     className="form-control-zipcode"
                     name={ fields.zip.name }
                     onChange={ this.handleZipChange }
                     label="Zip"
                     value={ this.formatZip(fields.zip.value) }
                     errorMessage={ fields.zip.errorMessage }
                     required={ required }
                     instructions={ instructions }
                     shouldFloatLabelOnUpdate={ shouldFloatLabelsOnUpdate }
                     tabIndex={ tabIndex } />
        </div>
      </div>
    )
  }
}

AddressField.defaultProps = {
  required: false,
  shouldFloatLabelsOnUpdate: false
}

AddressField.propTypes = {
  fields: PropTypes.shape({
    streetAddress: PropTypes.object, // { name, value, errorMessage, onChange }
    optionalLine:  PropTypes.object,
    city:          PropTypes.object,
    state:         PropTypes.object,
    zip:           PropTypes.object
  }).isRequired,

  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  shouldFloatLabelsOnUpdate: PropTypes.bool,
  instructions: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  tabIndex: PropTypes.string
}

export default AddressField
