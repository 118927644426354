import React from 'react'
import { connect } from 'react-redux'
import { Prompt } from 'react-router-dom'
import { asyncLoadEventQuestionSet } from '../ducks/event_question_set';
import BaseForm from 'shared/components/base_form.jsx'

import {
  FormField,
  TextField,
  SelectField,
  ToggleSwitchField
} from 'shared/components/fields'

import {
  extractDateFromRailsDateTime,
  extractTimeFromRailsDateTime
} from 'shared/utils';

import {
  requiredValidator,
  emailValidator,
  greaterThanValidator,
  phoneNumberValidator
} from 'shared/utils/validators'

import RailsApi from 'shared/utils/rails_api.js'

import SearchOrganizations from './search_organizations_field';
import SearchUsers from './search_users_field';

import { apiEndpoint } from '../ducks/rails'
import { resetWizardState, updateStarted, updateFailed, setErrorFlash } from '../ducks/wizard'
import { resetErrorsForEventAttendee } from '../ducks/submission_errors'
import { Panel, PanelSidebar, PanelRightColumn, PanelBody, PanelControls } from '../panel'
import PhoneNumberField from 'shared/components/fields/phone_number_field';

class AttendeeInformationForm extends BaseForm {
  constructor(props) {
    super(props)
    const eventAttendee = { ...(this.props.eventAttendee || {}) }
    const errors = { ...(this.props.errors || {}) }

    this.state = {
      eventAttendee: {
        eventId:                new FormField(eventAttendee.eventId, requiredValidator()),
        status:                 new FormField(eventAttendee.status, requiredValidator()),
        attended:               new FormField(eventAttendee.attended),
        prefix:                 new FormField(eventAttendee.prefix),
        firstName:              new FormField(eventAttendee.firstName, requiredValidator()),
        lastName:               new FormField(eventAttendee.lastName, requiredValidator()),
        email:                  new FormField(eventAttendee.email, [requiredValidator(), emailValidator()]),
        organizationId:         new FormField(eventAttendee.organizationId, requiredValidator()),
        organizationName:       new FormField(eventAttendee.organizationName, requiredValidator()),
        organizationNumber:     new FormField(eventAttendee.organizationNumber, requiredValidator()),
        jobTitle:               new FormField(eventAttendee.jobTitle),
        phoneExtension:         new FormField(eventAttendee.phoneExtension),
        phoneNumber:            new FormField(eventAttendee.phoneNumber, phoneNumberValidator()),
        badgeName:              new FormField(eventAttendee.badgeName),
        dietaryRestrictions:    new FormField(eventAttendee.dietaryRestrictions),
        additionalAttendees:    new FormField(eventAttendee.additionalAttendees),
        customQuestionOne:      new FormField(eventAttendee.customQuestionOne),
        customQuestionTwo:      new FormField(eventAttendee.customQuestionTwo),
        customQuestionThree:    new FormField(eventAttendee.customQuestionThree),
        customQuestionFour:    new FormField(eventAttendee.customQuestionFour),
        receiveEmails:          new FormField(eventAttendee.receiveEmails)
      }
    }

    // add any errors we found when attempting to submit
    Object.keys(this.state.eventAttendee).map((key) => {
      this.state.eventAttendee[key].errorMessage = (errors[key] || {}).message
    })
  }

  handleChangeFor = (name) => ( this.handleFieldChange("eventAttendee", name) )

  isEventSelectedButQuestionsNotLoaded = () => (
    !this.props.eventQuestionSet.loading &&
    this.state.eventAttendee.eventId.value &&
    !this.props.eventQuestionSet.id
  )

  didQuestionSetJustLoad = (prevProps) => (
    !prevProps.eventQuestionSet.id &&
    this.props.eventQuestionSet.id
  )

  noEventOrQuestionSetLoaded = () => (
    this.props.eventQuestionSet === null ||
    !this.state.eventAttendee.eventId.value
  );


  componentDidMount() {
    if(this.isEventSelectedButQuestionsNotLoaded()) {
      this.props.asyncLoadEventQuestionSet(this.state.eventAttendee.eventId.value);
    }
  }

  componentDidUpdate(prevProps) {
    if(this.isEventSelectedButQuestionsNotLoaded()) {
      this.props.asyncLoadEventQuestionSet(this.state.eventAttendee.eventId.value);
    }
    if(this.didQuestionSetJustLoad(prevProps)) {
      const eventAttendee = { ...(this.props.eventAttendee || {}) }
      const { eventQuestionSet } = this.props;
      this.setState({
        eventAttendee: {
          ...this.state.eventAttendee,
          prefix:              new FormField(eventAttendee.prefix, eventQuestionSet.requirePrefix ? requiredValidator() : []),
          jobTitle:            new FormField(eventAttendee.jobTitle, eventQuestionSet.requireJobTitle ? requiredValidator() : []),
          phoneNumber:         new FormField(eventAttendee.phoneNumber, eventQuestionSet.phoneNumber ? [requiredValidator(), phoneNumberValidator()] : [phoneNumberValidator()]),
          badgeName:           new FormField(eventAttendee.badgeName, eventQuestionSet.requireBadgeName ? requiredValidator() : []),
          dietaryRestrictions: new FormField(eventAttendee.dietaryRestrictions, eventQuestionSet.requireDietaryRestrictions ? requiredValidator() : []),
          additionalAttendees: new FormField(eventAttendee.additionalAttendees, eventQuestionSet.requireAdditionalAttendees ? requiredValidator() : []),
          customQuestionOne:   new FormField(eventAttendee.customQuestionOne, eventQuestionSet.requireCustomQuestionOne ? requiredValidator() : []),
          customQuestionTwo:   new FormField(eventAttendee.customQuestionTwo, eventQuestionSet.requireCustomQuestionTwo ? requiredValidator() : []),
          customQuestionThree: new FormField(eventAttendee.customQuestionThree, eventQuestionSet.requireCustomQuestionThree ? requiredValidator() : []),
          customQuestionFour: new FormField(eventAttendee.customQuestionFour, eventQuestionSet.requireCustomQuestionFour ? requiredValidator() : []),
          receiveEmails:       new FormField(eventAttendee.receiveEmails || new Date() < new Date(eventQuestionSet.eventDate))
        }
      })
    }
  }

  componentWillUnmount() {
    this.props.resetWizardState()
    this.props.resetSubmissionErrors()
  }

  prepareData = () => {
    let copy = { eventAttendee: { ...this.state.eventAttendee } }

    // turn FormField objects in to just the raw value
    Object.keys(copy.eventAttendee).map((key) => {
      copy.eventAttendee[key] = copy.eventAttendee[key].value
    })

    return copy
  }

  handleSave = (eventAttendee) => {
    if (!this.validateFields("eventAttendee")) {
      console.log("invalid")
      return null;
    }
    eventAttendee.preventDefault();

    let { dispatch, apiEndpoint } = this.props

    if (this.anyChangesMade("eventAttendee", this.props.eventAttendee)) {
      this.props.dispatch(updateStarted())

      return RailsApi.patch(`${apiEndpoint.path}`, { body: this.prepareData() }).
        then((json) => {
          window.location = json.redirect
        }).
        catch((json) => {
          dispatch({
            type: 'RAILS_UPDATE_FAILED',
            actions: [
              updateFailed(),
              setErrorFlash(json.error.message, { details: json.error.details })
            ]
          })
        })
    }
  }

  renderQuestionSet = () => {
    if (this.noEventOrQuestionSetLoaded()) { return null; }
    return (
      <div>
        <div className="row">
          <div className="col-xs-12">
            <SelectField name="attendee_statuses"
                          onChange={ this.handleChangeFor("status") }
                          label="Select Status"
                          placeholder="Select Status"
                          value={ this.state.eventAttendee.status.value }
                          errorMessage={ this.state.eventAttendee.status.errorMessage }
                          tabIndex="1"
                          options={ this.props.eventAttendee.attendee_statuses } />
            </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SearchUsers
              onChange={ (option) => {
                if (option) {
                  this.handleChangeFor("firstName")(option.first_name || '')
                  this.handleChangeFor("lastName")(option.last_name || '')
                  this.handleChangeFor("email")(option.email || '')
                  this.handleChangeFor("jobTitle")(option.job_title || '')
                  this.handleChangeFor("phoneNumber")(option.phone_number || '')
                  this.handleChangeFor("phoneExtension")(option.phone_extension || '')
                  this.handleChangeFor("organizationId")(option.organization_id || '')
                  this.handleChangeFor("organizationNumber")(option.organization_number || '')
                  this.handleChangeFor("organizationName")(option.organization_name || '')
                }
              }}
              tabIndex="1"
            />

            { this.props.eventQuestionSet.enablePrefix &&
              <TextField type="textarea"
                          name="prefix"
                          onChange={ this.handleChangeFor("prefix") }
                          label="Prefix"
                          value={ this.state.eventAttendee.prefix.value }
                          errorMessage={ this.state.eventAttendee.prefix.errorMessage }
                          required={this.props.eventQuestionSet.requirePrefix || false}
                          tabIndex="1" />
            }

            <TextField type="textarea"
                        name="firstName"
                        onChange={ this.handleChangeFor("firstName") }
                        label="First Name"
                        value={ this.state.eventAttendee.firstName.value }
                        errorMessage={ this.state.eventAttendee.firstName.errorMessage }
                        required={true}
                        shouldFloatLabelOnUpdate
                        tabIndex="1" />

            <TextField type="textarea"
                        name="lastName"
                        onChange={ this.handleChangeFor("lastName") }
                        label="Last Name"
                        value={ this.state.eventAttendee.lastName.value }
                        errorMessage={ this.state.eventAttendee.lastName.errorMessage }
                        required={true}
                        shouldFloatLabelOnUpdate
                        tabIndex="1" />

            <TextField type="textarea"
                        name="email"
                        onChange={ this.handleChangeFor("email") }
                        label="Email"
                        value={ this.state.eventAttendee.email.value }
                        errorMessage={ this.state.eventAttendee.email.errorMessage }
                        required={true}
                        shouldFloatLabelOnUpdate
                        tabIndex="1" />

            { this.props.eventQuestionSet.enableJobTitle &&
              <TextField type="textarea"
                          name="jobTitle"
                          onChange={ this.handleChangeFor("jobTitle") }
                          label="Job Title"
                          value={ this.state.eventAttendee.jobTitle.value }
                          errorMessage={ this.state.eventAttendee.jobTitle.errorMessage }
                          required={this.props.eventQuestionSet.requireJobTitle || false}
                          shouldFloatLabelOnUpdate
                          tabIndex="1" />
            }

            { this.props.eventQuestionSet.enableBadgeName &&
              <TextField type="textarea"
                          name="badgeName"
                          onChange={ this.handleChangeFor("badgeName") }
                          label="Badge Name"
                          value={ this.state.eventAttendee.badgeName.value }
                          errorMessage={ this.state.eventAttendee.badgeName.errorMessage }
                          required={this.props.eventQuestionSet.requireBadgeName || false}
                          shouldFloatLabelOnUpdate
                          tabIndex="1" />
            }


            <SearchOrganizations
                        onChange={ (option) => {
                          if (option) {
                            this.handleChangeFor("organizationId")(option.id || '');
                            this.handleChangeFor("organizationNumber")(option.organizationNumber || '')
                            this.handleChangeFor("organizationName")(option.organizationName || '')
                          }
                          else {
                            this.handleChangeFor("organizationId")("")
                          }
                        }}
                        value={this.state.eventAttendee.organizationId.value}
                        tabIndex="1"
                      />
          </div>
        </div>
        <div className="row">
          <p></p>
          <div className="col-xs-5">
            <TextField name="organizationNumber"
                       label="Org Number"
                       placeholder="Org Number"
                       value={ this.state.eventAttendee.organizationNumber.value }
                       onChange={ this.handleChangeFor("organizationNumber") }
                       errorMessage={ this.state.eventAttendee.organizationNumber.errorMessage }
                       shouldFloatLabelOnUpdate
                       required={ true } />
          </div>
          <div className="col-xs-7">
            <TextField name="organizationName"
                      label="Org Name"
                      placeholder="Org Name"
                      value={ this.state.eventAttendee.organizationName.value }
                      onChange={ this.handleChangeFor("organizationName") }
                      errorMessage={ this.state.eventAttendee.organizationName.errorMessage }
                      shouldFloatLabelOnUpdate
                      required={ true } />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            {
              this.props.eventQuestionSet.enablePhoneNumber &&
                <div className="row">
                  <div className="col-xs-8">
                    <PhoneNumberField name="phoneNumber"
                                      onChange={ this.handleChangeFor("phoneNumber") }
                                      label="Phone Number"
                                      value={ this.state.eventAttendee.phoneNumber.value }
                                      errorMessage={ this.state.eventAttendee.phoneNumber.errorMessage }
                                      required={ this.props.eventQuestionSet.requirePhoneNumber || false }
                                      shouldFloatLabelOnUpdate={ true }
                                      tabIndex="1" />
                  </div>
                  <div className="col-xs-4">
                    <TextField type="text"
                                name="phoneExtension"
                                onChange={ this.handleChangeFor("phoneExtension") }
                                label="Phone Extension"
                                value={ this.state.eventAttendee.phoneExtension.value }
                                errorMessage={ this.state.eventAttendee.phoneExtension.errorMessage }
                                shouldFloatLabelOnUpdate={ true }
                                tabIndex="1" />
                  </div>
                </div>
            }

            { this.props.eventQuestionSet.enableDietaryRestrictions &&
              <TextField type="textarea"
                          name="dietaryRestrictions"
                          onChange={ this.handleChangeFor("dietaryRestrictions") }
                          label="Dietary Restrictions"
                          value={ this.state.eventAttendee.dietaryRestrictions.value }
                          errorMessage={ this.state.eventAttendee.dietaryRestrictions.errorMessage }
                          required={this.props.eventQuestionSet.requireDietaryRestrictions || false}
                          tabIndex="1" />
            }

            { this.props.eventQuestionSet.enableAdditionalAttendees &&
              <div>
                <p className={ this.props.eventQuestionSet.requireAdditionalAttendees ? "is-required" : "" }>
                  <label htmlFor="additionalAttendees">
                  <span>If you plan to broadcast this event to coworkers, how many additional attendees do you expect will join?</span>
                  </label>
                </p>

                <TextField type="number"
                           name="additionalAttendees"
                           onChange={ this.handleChangeFor("additionalAttendees") }
                           value={ this.state.eventAttendee.additionalAttendees.value }
                           errorMessage={ this.state.eventAttendee.additionalAttendees.errorMessage }
                           required={this.props.eventQuestionSet.requireAdditionalAttendees || false}
                           tabIndex="1" />
              </div>
            }

            { this.props.eventQuestionSet.enableCustomQuestionOne &&
              <TextField type="textarea"
                         name="customQuestionOne"
                         onChange={ this.handleChangeFor("customQuestionOne") }
                         label={ this.props.eventQuestionSet.customQuestionOne }
                         value={ this.state.eventAttendee.customQuestionOne.value }
                         errorMessage={ this.state.eventAttendee.customQuestionOne.errorMessage }
                         required={this.props.eventQuestionSet.requireCustomQuestionOne || false}
                         tabIndex="1" />
            }

            { this.props.eventQuestionSet.enableCustomQuestionTwo &&
              <TextField type="textarea"
                         name="customQuestionTwo"
                         onChange={ this.handleChangeFor("customQuestionTwo") }
                         label={ this.props.eventQuestionSet.customQuestionTwo }
                         value={ this.state.eventAttendee.customQuestionTwo.value }
                         errorMessage={ this.state.eventAttendee.customQuestionTwo.errorMessage }
                         required={this.props.eventQuestionSet.requireCustomQuestionTwo || false}
                         tabIndex="1" />
            }

            { this.props.eventQuestionSet.enableCustomQuestionThree &&
              <TextField type="textarea"
                         name="customQuestionThree"
                         onChange={ this.handleChangeFor("customQuestionThree") }
                         label={ this.props.eventQuestionSet.customQuestionThree }
                         value={ this.state.eventAttendee.customQuestionThree.value }
                         errorMessage={ this.state.eventAttendee.customQuestionThree.errorMessage }
                         required={this.props.eventQuestionSet.requireCustomQuestionThree || false}
                         tabIndex="1" />
            }

            { this.props.eventQuestionSet.enableCustomQuestionFour &&
              <TextField type="textarea"
                         name="customQuestionFour"
                         onChange={ this.handleChangeFor("customQuestionFour") }
                         label={ this.props.eventQuestionSet.customQuestionFour }
                         value={ this.state.eventAttendee.customQuestionFour.value }
                         errorMessage={ this.state.eventAttendee.customQuestionFour.errorMessage }
                         required={this.props.eventQuestionSet.requireCustomQuestionFour || false}
                         tabIndex="1" />
            }


            <div className="row">
              <div className="col-xs-6">
                <p>Attended?</p>
                <ToggleSwitchField name="attended"
                                        onChange={ this.handleChangeFor('attended') }
                                        value={ this.state.eventAttendee.attended.value }
                                        errorMessage={ this.state.eventAttendee.attended.errorMessage }
                                        tabIndex="1" />
              </div>
            </div>

            <p>Send attendee confirmation, update and waitlist automated emails?</p>
            <ToggleSwitchField name="receiveEmails"
                                    onChange={ this.handleChangeFor('receiveEmails') }
                                    value={ this.state.eventAttendee.receiveEmails.value }
                                    errorMessage={ this.state.eventAttendee.receiveEmails.errorMessage }
                                    tabIndex="1" />
            <hr />
            <p>{ this.props.eventAttendee.lastUpdatedBy &&
                `Last updated by ${this.props.eventAttendee.lastUpdatedBy} at ${extractDateFromRailsDateTime(this.props.eventAttendee.updatedAt)} ${extractTimeFromRailsDateTime(this.props.eventAttendee.updatedAt)}`}
            </p>
            <p></p>
            <a data-confirm="Are you sure?" data-method="delete" href={`/event_attendees/${this.props.eventAttendee.id}`} rel="nofollow">
              <button type="button"
                      className={ `btn btn-danger btn-block ${this.props.updating ? "disabled" : ""}` }>

                Delete Attendee
              </button>
            </a>

          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <Panel>
        <PanelSidebar onLinkClick={ this.handleSave } />

        <PanelRightColumn>
          <PanelBody>
            <div className="row">
              <div className="col-xs-12">
                <h4>Attendee Information</h4>

                {/* Get this first, then grab the events question set
                    and load questions accordingly */}
                <SelectField name="events"
                             onChange={ this.handleChangeFor("eventId") }
                             label="Select Event"
                             placeholder="Select Event"
                             value={ this.state.eventAttendee.eventId.value }
                             errorMessage={ this.state.eventAttendee.eventId.errorMessage }
                             required={this.props.eventQuestionSet.requireDisplayOrganization}
                             tabIndex="1"
                             options={ this.props.events } />
              </div>
            </div>

            { this.renderQuestionSet() }
          </PanelBody>

          <PanelControls>
            <Prompt when={ this.anyChangesMade("eventAttendee", this.props.eventAttendee) }
                    message="Are you sure you want to move away? Any unsaved changes
                             made to this section will be lost." />

            <button type="button"
                    onClick={ this.handleSave }
                    className={ `btn btn-success ${this.props.updating ? "disabled" : ""}` }>

              Save &amp; Continue
            </button>
          </PanelControls>
        </PanelRightColumn>
      </Panel>
    )
  }
}

AttendeeInformationForm = connect(
  (state, props) => ({
    eventAttendee:    state.eventAttendee,
    events:           state.events,
    eventQuestionSet: state.eventQuestionSet,
    errors:           state.submissionErrors.eventAttendee,
    updateSucceeded:  state.wizard.updateSucceeded,
    updating:         state.wizard.updating,
    apiEndpoint:      apiEndpoint(state.rails, "event_attendees"),
  }),
  (dispatch, props) => ({
    dispatch,
    asyncLoadEventQuestionSet: (data) => {
      return asyncLoadEventQuestionSet(data)(dispatch)
    },
    resetWizardState: () => { dispatch(resetWizardState()) },
    resetSubmissionErrors: () => { dispatch(resetErrorsForEventAttendee()) }
  })
)(AttendeeInformationForm);

export default AttendeeInformationForm
