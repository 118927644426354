import React from "react"

import SurveyReviewQuestion from './survey_review_question.jsx'


let SurveyReviewSection = ({ header, questions }) => (
    <div className="row review-section">
        <div className="col-xs-12">
            <h4>{ header }</h4>
            {
                Object.values(questions).map((cyberSectionQuestion) =>
                    <div key={ cyberSectionQuestion.id } className="row">
                        <div className="col-xs-12">
                            <SurveyReviewQuestion id={ cyberSectionQuestion.id }
                                                  question={ cyberSectionQuestion.cyberQuestionAttributes }
                                                  { ...cyberSectionQuestion } />
                        </div>
                    </div>

                )
            }
        </div>
    </div>
)

export default SurveyReviewSection
