import RailsApi from 'shared/utils/rails_api.js'

import { refreshMetaData } from './rails.js'
import { setErrorFlash, setSuccessFlash, updateSucceeded, updateFailed, updateStarted } from './wizard.js'
import { updateIncidentReport } from './incident_report.js'

const LOAD_ATTACHMENTS   = "incident_report_form/attachments/LOAD_ATTACHMENTS"
const UPDATE_ATTACHMENTS = "incident_report_form/attachments/UPDATE_ATTACHMENTS"

export default function attachments(state = {}, action) {
  switch (action.type) {
    case LOAD_ATTACHMENTS: {
      return { ...action.attachments }
    }

    case UPDATE_ATTACHMENTS: {
      return { ...action.attachments }
    }

    default: {
      return state
    }
  }
}

export const loadAttachments = (attachments) => {
  return { type: LOAD_ATTACHMENTS, attachments: attachments }
}

export const updateAttachments = (attachments) => {
  return { type: UPDATE_ATTACHMENTS, attachments: attachments }
}

export const getAttachmentsCount = (state) => ( Object.keys(state).length )

export const getAttachment = (state, id) => {
  return Object.values(state).find((attachment) => (
    attachment.id && attachment.id.toString() === id.toString()
  ))
}

export const asyncUpdateAttachments = (
  url,
  attachments,
  successCallback,
  errorCallback,
  progressCallback
) => (dispatch) => {
  dispatch(updateStarted())

  return RailsApi.uploadWithXhr(url, 'PATCH', {
    body: attachments,
    
    onSuccess: (json) => {
      dispatch({
        type: 'RAILS_UPDATE_SUCCEEDED',
        actions: [
          refreshMetaData({ ...json.meta }),
          updateIncidentReport(json.incidentReport),
          updateAttachments(json.incidentReport.attachmentsAttributes),
          updateSucceeded(),
          setSuccessFlash("Attachments updated", { keep: true })
        ]
      })

      successCallback(json)
    },

    onError: (error) => {
      dispatch({
        type: 'RAILS_UPDATE_FAILED',
        actions: [
          updateFailed()
        ]
      })

      errorCallback(error)
    },

    onProgress: (progress) => {
      progressCallback(progress)
    }
  })
}

export const asyncDeleteAttachment = (url, id, attachmentsCount) => (dispatch) => {
  let data = {
    incidentReport: {
      attachmentsAttributes: {
        [id]: { id: id, "_destroy": true }
      }
    }
  }

  dispatch(updateStarted())

  return RailsApi.patch(url, { body: data }).
    then((json) => {
      dispatch({
        type: 'RAILS_UPDATE_SUCCEEDED',
        actions: [
          refreshMetaData({ ...json.meta }),
          updateIncidentReport(json.incidentReport),
          updateAttachments(json.incidentReport.attachmentsAttributes),
          updateSucceeded(),
          setSuccessFlash("Attachment deleted", { keep: attachmentsCount === 1 })
        ]
      })
    }).
    catch((json) => {
      dispatch({
        type: 'RAILS_UPDATE_FAILED',
        actions: [
          updateFailed(),
          setErrorFlash(json.error.message, json.error.details)
        ]
      })
    })
}
