import React from 'react'
import PropTypes from 'prop-types';

import EntityTypeMultiSelect from './fields/entity_type_multi_select.jsx'
import ProgramSelect from './fields/program_select.jsx'
import ClientTierSelect from './fields/client_tier_select.jsx'
import ToggleSwitchGroup from './fields/toggle_switch_group.jsx'
import EarliestDatePicker from './fields/earliest_date_picker.jsx'

import { extractDateFromRailsDateTime } from 'shared/utils/rails_datetime_formatters.js'

import FloatingLabelInputs from 'shared/components/floating_label_inputs.jsx'

class EditQuestion extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const {
            onGlobalQuestionChange,
            onSectionQuestionChange,
            cyberSectionQuestion: {
                questionCode,
                entityTierId
            }
        } = this.props

        const earliestAllowedDateForYes = extractDateFromRailsDateTime(this.props.cyberSectionQuestion.earliestAllowedDateForYes || null)

        const {
            questionContent,
            helpContent,
            programId,
            entityTypeIds,
            requireYesNoAnswer,
            requireDateWhenAnsweringYes,
            allowNotApplicableAnswer,
            resetAnswerAnnually
        } = this.props.cyberSectionQuestion.cyberQuestionAttributes


        return (
            <div>
                <div className="form-group is-required">
                    <FloatingLabelInputs.TextArea
                        label="Question Text"
                        isRequired={ true }
                        name="questionContent"
                        className="form-control"
                        value={ questionContent || '' }
                        rows='3'
                        onChange={ onGlobalQuestionChange }
                    />
                </div>

                <div className="form-group">
                    <FloatingLabelInputs.TextArea
                        label="Tip Text"
                        name="helpContent"
                        className="form-control"
                        value={ helpContent || '' }
                        rows='3'
                        onChange={ onGlobalQuestionChange }
                    />
                </div>

                <div className="row">
                    <div className="col-sm-6">
                        <EntityTypeMultiSelect value={ entityTypeIds } onChange={ onGlobalQuestionChange } />
                    </div>

                    <div className="col-sm-6">
                        <div className="form-group">
                            <FloatingLabelInputs.Input
                                label="Question Code"
                                name="questionCode"
                                className="form-control"
                                value={ questionCode || '' }
                                onChange={ onSectionQuestionChange }
                            />
                        </div>

                        <ClientTierSelect value={ entityTierId } onChange={ onSectionQuestionChange } />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <ProgramSelect value={ programId } onChange={ onGlobalQuestionChange } />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <EarliestDatePicker
                                name="earliestAllowedDateForYes"
                                value={ earliestAllowedDateForYes || '' }
                                handleDateChange={ onSectionQuestionChange }
                            />
                        </div>
                    </div>
                </div>

                <ToggleSwitchGroup label="Show Yes|No Toggle"
                                   field="requireYesNoAnswer"
                                   onChange={ onGlobalQuestionChange }
                                   checked={ requireYesNoAnswer } />

                { requireYesNoAnswer &&
                    <div>
                        <ToggleSwitchGroup label="Show separate N/A option?"
                                           field="allowNotApplicableAnswer"
                                           onChange={ onGlobalQuestionChange }
                                           checked={ allowNotApplicableAnswer } />
                        <ToggleSwitchGroup label="Does this question require a date?"
                                           field="requireDateWhenAnsweringYes"
                                           onChange={ onGlobalQuestionChange }
                                           checked={ requireDateWhenAnsweringYes } />
                        <ToggleSwitchGroup label="Should this question's answer be reset annually?"
                                           field="resetAnswerAnnually"
                                           onChange={ onGlobalQuestionChange }
                                           checked={ resetAnswerAnnually } />
                    </div>
                }
            </div>
        )
    }
}

EditQuestion.propTypes = {
    cyberSectionQuestion: PropTypes.object
}

export default EditQuestion
