import React from 'react'
import Label from './label.jsx'
import Response from './response.jsx'

const RailsEnum = ({ label, value, missingText = "Unspecified" }) => {
  const formattedValue = value ? value.humanize() : value

  return (
    <div className={ !formattedValue ? "unanswered" : "" }>
      <Label>{ label }</Label>
      <Response>{ formattedValue ? formattedValue : missingText }</Response>
    </div>
  )
}

export default RailsEnum;
