import React from 'react'
import { BrowserRouter as Router, Route, Prompt } from 'react-router-dom'

import RailsApi from 'shared/utils/rails_api.js'
import {
  extractDateFromRailsDateTime,
  extractTimeFromRailsDateTime
} from 'shared/utils/rails_datetime_formatters.js'
import BaseForm from 'shared/components/base_form.jsx'
import {
  AddressField,
  CheckboxField,
  DateAndTimeField,
  FormField,
  SelectField,
  RadioField,
  TextField
} from 'shared/components/fields'
import OneAsyncSelectToRuleThemAll from 'shared/components/one_async_select_to_rule_them_all.jsx'
import { lengthValidator, notBeforeValidator, notInFutureValidator, numberValidator, requiredValidator, phoneNumberValidator } from 'shared/utils/validators'

import { apiEndpoint } from '../shared/ducks/rails.js'
import { Panel, PanelRightColumn, PanelBody, PanelControls } from '../shared/panel'

import PhoneNumberField from 'shared/components/fields/phone_number_field';
class App extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      incident: {
        oshaLocationId:               new FormField(props.incident.oshaLocationId),
        recordable:                   new FormField(props.incident.recordable),
        hideEmployee:                 new FormField(props.incident.hideEmployee),
        caseNumber:                   new FormField(props.incident.caseNumber),

        employeeName:                 new FormField(props.incident.employeeName, [requiredValidator()]),
        employeeTitle:                new FormField(props.incident.employeeTitle),

        dateOfIncident:               new FormField(
                                        props.incident.dateOfIncident,
                                        [
                                          requiredValidator(),
                                          notBeforeValidator(props.meta.notAllowingIncidentsToBeReportedPriorTo),
                                          notInFutureValidator()
                                        ]
                                      ),
        whereTheEventOccurred:        new FormField(props.incident.whereTheEventOccurred),
        descriptionOfInjuryOrIllness: new FormField(props.incident.descriptionOfInjuryOrIllness),

        classification:               new FormField(props.incident.classification),
        daysAwayFromWork:             new FormField(props.incident.daysAwayFromWork, [numberValidator({ lte: 180 })]),
        daysOnTransferOrRestriction:  new FormField(props.incident.daysOnTransferOrRestriction, [numberValidator({ lte: 180 })]),
        injuryTypeOrIllness:          new FormField(props.incident.injuryTypeOrIllness)
      },

      location: {
        name:           new FormField("", [requiredValidator()]),
        companyExecutiveRole: new FormField(""),
        companyExecutivePhone: new FormField("", [phoneNumberValidator()]),


        streetAddress:  new FormField(""),
        optionalLine:   new FormField(""),
        city:           new FormField(""),
        state:          new FormField(""),
        zip:            new FormField(""),
        industryDescription:                   new FormField(""),
        northAmericanIndustrialClassification: new FormField("", [lengthValidator({ eq: 6 }, "Must be a six digit code")])
      },

      addingNewLocation: false,
      updating: false,
      flash: null
    }
  }

  handleChangeFor = (form) => ( (name) => ( this.handleFieldChange(form, name) ) )

  prepareData = () => {
    let copy = { incident: { ...this.state.incident } }

    // turn FormField objects in to just the raw value
    Object.keys(copy.incident).map((key) => {
      copy.incident[key] = copy.incident[key].value
    })

    if (this.state.addingNewLocation) {
      copy.incident.locationAttributes = { ...this.state.location }

      // turn FormField objects in to just the raw value
      Object.keys(copy.incident.locationAttributes).map((key) => {
        copy.incident.locationAttributes[key] = copy.incident.locationAttributes[key].value
      })
    }

    return copy
  }

  handleSubmit = (event) => {
    event.preventDefault()
    event.target.blur()

    this.setState({ updating: true }, () => {
      let isIncidentValid = this.validateFields("incident")
      let isLocationValidIfAddingOne = this.state.addingNewLocation ? this.validateFields("location") : true

      if (isIncidentValid && isLocationValidIfAddingOne) {
        const { path, method } = apiEndpoint(this.props.meta, "incident")
        const data = this.prepareData()

        RailsApi.submit(path, method, { body: data }).
          then((json) => {
            window.location = json.redirect
          }).
          catch((json) => {
            this.setState({ flash: { type: 'danger', ...json.error }, updating: false })
          })
      }
      else {
        this.setState({ updating: false })
      }
    })
  }

  render() {
    // figure out what the basename should be based on where rails told us we can save the form to
    const { path, method } = apiEndpoint(this.props.meta, "incident")
    const basename = `${path}/${method === "POST" ? "new" : "edit"}`

    return (
      <Router basename={ basename }>
        <div className="panel panel-default panel-incident-form">
          <div className="panel-heading">
            <div className="row">
              <div className="col-xs-12 col-sm-8">
                <h2 className="panel-title">
                  { method === "POST" ? 'New' : 'Edit' } {"Incident"}
                </h2>
              </div>

              <div className="col-xs-12 col-sm-4 text-right">

                <a href="/osha/incidents"
                   onClick={ (event) => {
                     if (!confirm("Are you sure you want to move away? Any unsaved changes " +
                                  "made to this section will be lost.")) {
                       event.preventDefault()
                     }
                   }}>

                  <button className="cancel-btn btn btn-link">
                    Cancel
                  </button>
                </a>
              </div>
            </div>
          </div>

          {/* routing (there's only one) */}
          <Route exact path="/" render={ () => (
            <Panel>
              <PanelRightColumn>
                <PanelBody flash={ this.state.flash }>
                  <div className="row">
                    <div className="col-xs-12">
                      <h4>Incident Details</h4>
                    </div>
                  </div>

                  {
                    this.props.incident.vrsaClaimNumber &&
                      <div>
                        <div className="row">
                          <div className="col-xs-6">
                            <b>VRSA Claim Number</b>
                            <p style={{ marginBottom: 0 }}>{ this.props.incident.vrsaClaimNumber }</p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-12">
                            <hr style={{ marginBottom: "3.5rem" }} />
                          </div>
                        </div>
                      </div>
                  }

                  <div className="row">
                    <div className="col-xs-12 col-sm-4">
                      <TextField name="caseNumber"
                                 onChange={ this.handleChangeFor("incident")("caseNumber") }
                                 label="Case Number"
                                 value={ this.state.incident.caseNumber.value }
                                 errorMessage={ this.state.incident.caseNumber.errorMessage } />
                    </div>

                    <div className="col-xs-12 col-sm-4">
                      <CheckboxField name="hideEmployee"
                                     label="Is this a privacy case?"
                                     value={ this.state.incident.hideEmployee.value }
                                     errorMessage={ this.state.incident.hideEmployee.errorMessage }
                                     onChange={ this.handleChangeFor("incident")("hideEmployee") } />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-12 col-sm-4">
                      <TextField name="employeeName"
                                 onChange={ this.handleChangeFor("incident")("employeeName") }
                                 label="Employee's Name"
                                 value={ this.state.incident.employeeName.value }
                                 errorMessage={ this.state.incident.employeeName.errorMessage }
                                 required={ true } />
                    </div>

                    <div className="col-xs-12 col-sm-4">
                      <TextField name="employeeTitle"
                                 onChange={ this.handleChangeFor("incident")("employeeTitle") }
                                 label="Employee's Job Title"
                                 value={ this.state.incident.employeeTitle.value }
                                 errorMessage={ this.state.incident.employeeTitle.errorMessage } />
                    </div>

                    <div className="col-xs-12 col-sm-4">
                      <RadioField name="recordable"
                                  onChange={ this.handleChangeFor("incident")("recordable") }
                                  label="Is this an OSHA recordable injury / illness?"
                                  value={ this.state.incident.recordable.value }
                                  errorMessage={ this.state.incident.recordable.errorMessage }
                                  options={[
                                    { displayText: 'Yes', value: true },
                                    { displayText: 'No',  value: false }
                                  ]} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-12 col-sm-4">
                      <DateAndTimeField label={ { date: "Date of Injury / Illness" } }
                                        name="dateOfIncident"
                                        includeTime={ false }
                                        value={ this.state.incident.dateOfIncident.value }
                                        errorMessage={ this.state.incident.dateOfIncident.errorMessage }
                                        onChange={ this.handleChangeFor("incident")("dateOfIncident") }
                                        style={{ marginBottom: 0 }}
                                        required={ true } />
                    </div>

                    <div className="col-xs-12 col-sm-4">
                      <div className={ `form-group ${this.state.incident.oshaLocationId.errorMessage ? 'has-error' : ''}` }>
                        <OneAsyncSelectToRuleThemAll.Base
                          labelText={ "Location / Department" }
                          value={!this.state.addingNewLocation ? this.state.incident.oshaLocationId.value : "Add new location" }
                          onChange={ (value) => {
                            if (value === "Add new location") {
                              this.setState(
                                { addingNewLocation: true },
                                () => { this.handleChangeFor("incident")("oshaLocationId")('Add New Location') }
                              )
                            }
                            else {
                              this.setState(
                                { addingNewLocation: false },
                                () => { this.handleChangeFor("incident")("oshaLocationId")(value) }
                              )
                            }
                          } }
                          loadOptions={ () => {
                            return Promise.resolve({
                              options: [{ value: "Add new location", label: "Add new location" }, ...this.props.meta.locationOptions ],
                              complete: true
                            })
                          } }
                          simpleValue={ true }
                        />

                        {
                          this.state.incident.oshaLocationId.errorMessage &&
                            <span className="field-level-error">{ this.state.incident.oshaLocationId.errorMessage }</span>
                        }
                      </div>
                    </div>
                  </div>
                  
                  {
                    this.state.addingNewLocation &&
                      <div>
                        <div className="row">
                          <div className="col-xs-12">
                            <h4>{ "Add New Location / Department" }</h4>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-12">
                            <TextField name="name"
                                       onChange={ this.handleChangeFor("location")("name") }
                                       label="Location"
                                       value={ this.state.location.name.value }
                                       errorMessage={ this.state.location.name.errorMessage }
                                       required={ true } />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-6">
                            <TextField name="companyExecutiveRole"
                                      onChange={ this.handleChangeFor("location")("companyExecutiveRole") }
                                      label="Company Executive Role"
                                      value={ this.state.location.companyExecutiveRole.value }
                                      errorMessage={ this.state.location.companyExecutiveRole.errorMessage } />
                          </div>
                          <div className="col-xs-6">
                            <PhoneNumberField name="companyExecutivePhone"
                                      onChange={ this.handleChangeFor("location")("companyExecutivePhone") }
                                      label="Company Executive Phone"
                                      value={ this.state.location.companyExecutivePhone.value }
                                      errorMessage={ this.state.location.companyExecutivePhone.errorMessage } />
                          </div>
                        </div>
                  


                        <div className="row">
                          <div className="col-xs-12">
                            <AddressField fields={{
                                            streetAddress: {
                                              name: "streetAddress",
                                              value: this.state.location.streetAddress.value,
                                              errorMessage: this.state.location.streetAddress.errorMessage,
                                              onChange: this.handleChangeFor("location")("streetAddress")
                                            },
                                            optionalLine: {
                                              name: "optionalLine",
                                              value: this.state.location.optionalLine.value,
                                              errorMessage: this.state.location.optionalLine.errorMessage,
                                              onChange: this.handleChangeFor("location")("optionalLine")
                                            },
                                            city: {
                                              name: "city",
                                              value: this.state.location.city.value,
                                              errorMessage: this.state.location.city.errorMessage,
                                              onChange: this.handleChangeFor("location")("city")
                                            },
                                            state: {
                                              name: "state",
                                              value: this.state.location.state.value,
                                              errorMessage: this.state.location.state.errorMessage,
                                              onChange: this.handleChangeFor("location")("state")
                                            },
                                            zip: {
                                              name: "zip",
                                              value: this.state.location.zip.value,
                                              errorMessage: this.state.location.zip.errorMessage,
                                              onChange: this.handleChangeFor("location")("zip")
                                            }
                                          }} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xs-12">
                            <TextField name="industryDescription"
                                      onChange={ this.handleChangeFor("location")("industryDescription") }
                                      label="Industry Description"
                                      value={ this.state.location.industryDescription.value }
                                      errorMessage={ this.state.location.industryDescription.errorMessage } />
                          </div>

                          <div className="col-xs-12">
                            <TextField type="numbers_only"
                                      name="northAmericanIndustrialClassification"
                                      onChange={ this.handleChangeFor("location")("northAmericanIndustrialClassification") }
                                      label="North American Industrial Classification"
                                      value={ this.state.location.northAmericanIndustrialClassification.value }
                                      errorMessage={ this.state.location.northAmericanIndustrialClassification.errorMessage } />
                          </div>
                        </div>
                      </div>
                  }

                  

                  <div className="row">
                    <div className="col-xs-12">
                      <h4>{ "Injury / Illness Description" }</h4>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-12">
                      <TextField name="whereTheEventOccurred"
                                 onChange={ this.handleChangeFor("incident")("whereTheEventOccurred") }
                                 label="What physical location or job site did this incident occur?"
                                 value={ this.state.incident.whereTheEventOccurred.value }
                                 errorMessage={ this.state.incident.whereTheEventOccurred.errorMessage } />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-12">
                      <TextField name="descriptionOfInjuryOrIllness"
                                 type="textarea"
                                 
                                 onChange={ this.handleChangeFor("incident")("descriptionOfInjuryOrIllness") }
                                 label="Description"
                                 value={ this.state.incident.descriptionOfInjuryOrIllness.value }
                                 errorMessage={ this.state.incident.descriptionOfInjuryOrIllness.errorMessage } />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-12 col-sm-4">
                      <SelectField name="classification"
                                   onChange={ this.handleChangeFor("incident")("classification") }
                                   label="Classification"
                                   placeholder="Select..."
                                   value={ this.state.incident.classification.value }
                                   errorMessage={ this.state.incident.classification.errorMessage }
                                   options={ this.props.meta.enumOptionsForSelect["Osha::Incident"].classification } />
                    </div>

                    {
                      (this.state.incident.classification.value === "days_away_from_work" ||
                        this.state.incident.classification.value === "job_transfer_or_restriction") &&
                        <div>
                          <div className="col-xs-12 col-sm-4">
                            <TextField type="numbers_only"
                                       name="daysAwayFromWork"
                                       onChange={ this.handleChangeFor("incident")("daysAwayFromWork") }
                                       label="Days Away from Work"
                                       value={ this.state.incident.daysAwayFromWork.value }
                                       errorMessage={ this.state.incident.daysAwayFromWork.errorMessage } />
                          </div>

                          <div className="col-xs-12 col-sm-4">
                            <TextField type="numbers_only"
                                       name="daysOnTransferOrRestriction"
                                       onChange={ this.handleChangeFor("incident")("daysOnTransferOrRestriction") }
                                       label="Days on Job Transfer / Restriction"
                                       value={ this.state.incident.daysOnTransferOrRestriction.value }
                                       errorMessage={ this.state.incident.daysOnTransferOrRestriction.errorMessage } />
                          </div>
                        </div>
                    }
                  </div>

                  <div className="row">
                    <div className="col-xs-12 col-sm-4">
                      <SelectField name="injuryTypeOrIllness"
                                   onChange={ this.handleChangeFor("incident")("injuryTypeOrIllness") }
                                   label="Injury Type / Illness"
                                   placeholder="Select most serious outcome..."
                                   value={ this.state.incident.injuryTypeOrIllness.value }
                                   errorMessage={ this.state.incident.injuryTypeOrIllness.errorMessage }
                                   options={ this.props.meta.enumOptionsForSelect["Osha::Incident"].injuryTypeOrIllness } />
                    </div>
                  </div>

                  {
                    (this.props.incident.creator || this.props.incident.lastEditedBy) &&
                      <div className="row">
                        <div className="col-xs-12">
                          <hr />
                        </div>
                      </div>
                  }

                  {
                    this.props.incident.creator &&
                      <div className="row">
                        <div className="col-xs-12">
                          <h4 style={{ marginBottom: "1rem" }}>Created By</h4>
                          <p>
                            {
                              `
                                ${this.props.incident.creator.firstName} ${this.props.incident.creator.lastName} at
                                ${extractTimeFromRailsDateTime(this.props.incident.createdAt)}
                                ${extractDateFromRailsDateTime(this.props.incident.createdAt)}
                              `
                            }
                          </p>
                        </div>
                      </div>
                  }

                  {
                    this.props.incident.lastEditedBy &&
                      <div className="row" style={{ marginTop: this.props.incident.creator ? "2.5rem" : 0 }}>
                        <div className="col-xs-12">
                          <h4 style={{ marginBottom: "1rem" }}>Last Edited By</h4>
                          <p>
                            {
                              `
                                ${this.props.incident.lastEditedBy.firstName} ${this.props.incident.lastEditedBy.lastName} at
                                ${extractTimeFromRailsDateTime(this.props.incident.updatedAt)}
                                ${extractDateFromRailsDateTime(this.props.incident.updatedAt)}
                              `
                            }
                          </p>
                        </div>
                      </div>
                  }
                </PanelBody>

                <PanelControls>
                  <Prompt when={ this.anyChangesMade("incident", this.props.incident) }
                          message="Are you sure you want to move away? Any incident details will be lost." />

                  <button type="button"
                          onClick={ this.handleSubmit }
                          className={ `btn btn-success ${this.state.updating ? "disabled" : ""}` } >
                    Submit
                  </button>
                </PanelControls>
              </PanelRightColumn>
            </Panel>
          ) } />
          {/* end routing */}
        </div>
      </Router>
    )
  }
}

export default App