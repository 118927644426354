import React from 'react'
import { connect } from 'react-redux'

import { policyYear } from 'apps/rmg/shared/ducks/survey.js'
import { policyYearRange } from 'shared/utils/policy_year.js'

let SurveyHeader = ({ policyYear, tier }) => (
   <div className='clearfix'>
    <h1 className='panel-title rmg-panel-title pull-left'>
      Risk Management Assessment
      <small> ({ policyYearRange(policyYear) })</small>
    </h1>

  </div>
)

SurveyHeader = connect(
  (state, props) => ({
    tier: state.lookups.tier,
    policyYear: policyYear(state.survey)
  })
)(SurveyHeader)

export default SurveyHeader
