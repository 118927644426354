import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import Preview from 'shared/components/image_preview.jsx'

class PreviewContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = { url: '', contentType: '' }
    this.determineUrlAndContentType = this.determineUrlAndContentType.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.determineUrlAndContentType(this.props);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps === this.props) { return; }

    this.determineUrlAndContentType(nextProps);
  }

  determineUrlAndContentType({ file, attachmentUrl, documentContentType }) {
    if (file) { // this was just uploaded
      let reader = new FileReader();

      reader.onloadend = () => {
        let url = reader.result

        // TODO - it's possible for this to fire after the component has unmounted. might be a more
        //        elegant way to handle this, but for now...
        if (this._isMounted) {
          this.setState({
            url: url,
            // stolen from: http://stackoverflow.com/a/21460351/1947079
            contentType: url.split(",")[0].split(":")[1].split(";")[0]
          });
        }
      }

      reader.readAsDataURL(file);
    }

    else if (documentContentType) { // this came from rails
      this.setState({
        url:         attachmentUrl,
        contentType: documentContentType
      });
    }

    else {
      this.setState({
        url:         '',
        contentType: ''
      });
    }
  }

  render() {
    return <Preview { ...this.state } />
  }
}

PreviewContainer.propTypes = {
  file:                PropTypes.object,
  attachmentUrl:       PropTypes.string,
  documentContentType: PropTypes.string
}

PreviewContainer = connect()(PreviewContainer);

export default PreviewContainer;
