import React from 'react'
import ReactDOM from 'react-dom'

export default class BaseForm extends React.Component {

  // depends on state that looks something like:
  //
  //   lineItems: [
  //     { id: '' , description: new FormField(...), estimatedCost: new FormField(...) }
  //   ]
  //
  // usage: this.handleFieldChangeInList("lineItems", '', 'description')
  //
  // records will have an id key if it's been persisted and an _id key if it's temporary for
  // identifying in react state before persistence
  //
  handleFieldChangeInList = (list, id, field, callback = () => {}) => (value) => {
    this.setState((prevState) => {
      return {
        ...prevState,

        [list]: prevState[list].map((form) => {
          if (form.id === id || form._id === id) { // this isn't the record we're looking for
            form[field].value = value

            let errorMessage = form[field].validators.reduce((message, validator) => (
              message || validator(form[field].value, form)
            ), null)

            form[field].errorMessage = errorMessage
          }

          return form
        })
      }
    }, callback)
  }

  validateFieldsInList = function(list) {
    let allValid = true
    this.setState({ validating: this })

    let listCopy = { [list]: [...this.state[list]] }

    listCopy[list].forEach((form) => {
      if (form._destroy) { return }

      Object.keys(form).forEach((field) => {
        if (field === "id" || field === "_id") { return }

        let errorMessage = form[field].validators.reduce((message, validator) => (
          message || validator(form[field].value, form)
        ), null)

        allValid = allValid && !errorMessage

        form[field].errorMessage = errorMessage
      })
    })

    if (allValid) { this.setState({ validating: null }) }
    this.setState(listCopy)

    return allValid
  }

  anyChangesMadeInList = (list, originalState) => {
    let _originalState = originalState || []

    if (this.state[list].length !== _originalState.length) { return true }

    let allTheSame = true

    for (let i = 0; i < this.state[list].length; i++) {

      // if they don't have the same number of fields, they're not the same
      if (Object.keys(this.state[list][i]).length !== Object.keys(_originalState[i]).length) {
        allTheSame = false
        continue // and we don't need to check each field
      }

      allTheSame = allTheSame && Object.keys(this.state[list][i]).every((field) => {
        // don't check non-FormField values
        if (field === "id" || field === "_id" || field === "_destroy") { return true }

        // treat this as two states that haven't yet undergone changes
        if (this.state[list][i][field].value === '' && (_originalState[i][field] === null ||
                                                        _originalState[i][field] === undefined)) {
          return true
        }

        return this.state[list][i][field].value === _originalState[i][field]
      })
    }

    return !allTheSame
  }

  addToList = (list, data) => {
    this.setState((prevState) => ({
      ...prevState,

      [list]: prevState[list].concat(data)
    }))
  }

  removeFromList = (list, id) => {
    this.setState((prevState) => {
      let index = prevState[list].findIndex((form) => ( form.id === id || form._id === id ))
      if (index === -1) { return prevState }

      return {
        ...prevState,

        [list]: [
          ...prevState[list].slice(0, index),
          ...prevState[list].slice(index + 1)
        ]
      }
    })
  }

  markToDestroyInList = (list, id, callback = () => {}) => {
    this.setState((prevState) => {
      let index = prevState[list].findIndex((form) => ( form.id === id || form._id === id ))
      if (index === -1) { return prevState }

      return {
        ...prevState,

        [list]: [
          ...prevState[list].slice(0, index),
          { ...prevState[list][index], _destroy: true },
          ...prevState[list].slice(index + 1)
        ]
      }
    }, callback)
  }

  // depends on state that looks something like:
  //
  //   { person: { firstName: new FormField(...), lastName: new FormField(...) } }
  //
  // usage: this.handleFieldChange("person", 'firstName')
  //
  handleFieldChange = (form, field) => (value) => {
    let partialStateCopy = {}

    partialStateCopy[form] = { ...this.state[form] }
    partialStateCopy[form][field].value = value

    let errorMessage = partialStateCopy[form][field].validators.reduce((message, validator) => (
      message || validator(partialStateCopy[form][field].value, partialStateCopy[form])
    ), null)

    partialStateCopy[form][field].errorMessage = errorMessage

    this.setState(partialStateCopy)
  }

  validateFields = function(formKey) {
    let allValid = true

    // sticking with the example above, this should get us a copy of the { person: { ... } }
    // object so we can call setState with it later
    let formCopy = { [formKey]: { ...this.state[formKey] } }

    this.setState({ validating: this })

    // formKey == "person", so at this point we'll go through each field in the person form, run
    // it's validators, and set any errors
    Object.keys(formCopy[formKey]).forEach((fieldKey) => {
      let errorMessage = formCopy[formKey][fieldKey].validators.reduce((message, validator) => (
        message || validator(formCopy[formKey][fieldKey].value, formCopy[formKey])
      ), null)

      allValid = allValid && !errorMessage

      formCopy[formKey][fieldKey].errorMessage = errorMessage
    })

    if (allValid) { this.setState({ validating: null }) }
    this.setState(formCopy)

    return allValid
  }

  anyChangesMade = (form, originalState) => {
    let _originalState = originalState || {}

    return !Object.keys(this.state[form]).every((key) => {

      // treat this as two states that haven't yet undergone changes
      if (this.state[form][key].value === '' && (_originalState[key] === null ||
                                                 _originalState[key] === undefined)) {
        return true
      }

      return _.isEqual(this.state[form][key].value, _originalState[key])
    })
  }

  scrollToAndFocusFirstError = function() {
    let erroredForm = ReactDOM.findDOMNode(this.state.validating)
    let errors = erroredForm.getElementsByClassName('has-error')

    if (errors.length > 0) {
      window.scrollBy(0, errors[0].parentElement.getBoundingClientRect().top)

      Array.from(errors[0].parentElement.childNodes).forEach((node) => {
        if (node.nodeName === 'INPUT' || node.nodeName === 'SELECT') {
          node.focus();
        }
      })
    }
  }

  componentDidUpdate() {
    if (this.state.validating) {
      this.scrollToAndFocusFirstError();
      this.setState({ validating: null }); // don't do it again
    }
  }
}
