import { convertToDate } from 'shared/utils/rails_datetime_formatters.js'

const notInFutureValidator = (message = "Date can't be in the future") => function(value, state) {
  if (value && convertToDate(value) > new Date()) {
    return message;
  }

  return null;
};

export default notInFutureValidator;
