import React from 'react'
import { Provider, connect } from 'react-redux'
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from 'react-router-dom'

import store from './store.js'
import { loadMetaData, apiEndpoint } from './ducks/rails.js'
import { loadEventAttendee } from './ducks/event_attendee.js'
import events, { loadEvents } from './ducks/events.js'
import { setSuccessFlash, openMobileMenu, closeMobileMenu } from './ducks/wizard.js'

import AttendeeInformation from './attendee_information/attendee_information_form.jsx'


class AppHeader extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-8">
          <h2 className="panel-title">
            { this.props.eventAttendeeStatus === 'in_progress' ? `Edit ${this.props.attendeeName}` : 'New Event Attendee' }
          </h2>
        </div>

        <div className="col-xs-12 col-sm-4 text-right">
          <a href={`/event_attendees/${this.props.attendeeId}/cancel`}
             onClick={ (eventAttendee) => {
               if (!confirm("Are you sure you want to move away? Any unsaved changes " +
                            "made to this section will be lost.")) {
                 eventAttendee.preventDefault()
               }
             }}>

            <button className="cancel-btn btn btn-link">Cancel</button>
          </a>

          <button className="mobile-claims-menu-btn btn btn-primary" onClick={ this.props.toggleMobileMenu }>
            <i className="fa fa-ellipsis-v"></i>
          </button>
        </div>
      </div>
    )
  }
}
AppHeader = connect(
  (state, props) => ({
    attendeeName: `${state.eventAttendee.firstName} ${state.eventAttendee.lastName}`, 
    attendeeId: state.eventAttendee.id,
    eventAttendeeStatus: state.eventAttendee.firstName !== null ? 'in_progress' : 'new',
    mobileMenuOpen: state.wizard.mobileMenuOpen
  }),
  null,
  ({ mobileMenuOpen, ...otherProps }, { dispatch }, props) => ({
    ...otherProps,
    ...props,

    toggleMobileMenu: () => { dispatch(mobileMenuOpen ? closeMobileMenu() : openMobileMenu()) }
  })
)(AppHeader)

class App extends React.Component {
  constructor(props) {
    super(props);
    const {
      event_attendee,
      events
    } = props

    store.dispatch({
      type: 'INITIAL_LOAD_OF_DATA_FROM_RAILS',
      actions: [
        loadMetaData(props.meta),
        loadEventAttendee(event_attendee),
        loadEvents(events)
      ]
    })
  }

  render() {
    // figure out what the basename should be based on where rails told us we can save the form to
    const { path, method } = apiEndpoint(this.props.meta, "event_attendees")
    const basename = `${path}/${method === "POST" ? "new" : "edit"}`

    return (
      <Provider store={ store }>
        <Router basename={ basename }>
          <div className="panel panel-default panel-event-attendee-form">
            <div className="panel-heading">

              <AppHeader />

            </div>

            {/* routing */}
            <Route exact path="/" render={ () => <Redirect to="/attendee_information" /> } />

            <Route path="/attendee_information"    component={ AttendeeInformation } />
            
            {/* end routing */}
          </div>
        </Router>
      </Provider>
    )
  }
}

export default App
