import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import OneAsyncSelectToRuleThemAll from 'shared/components/one_async_select_to_rule_them_all.jsx'

class SelectMemberLocation extends React.Component {
  constructor(props) {
    super(props)
  }

  getLocations = () => {
    const { memberNumber, memberLocationsPath } = this.props
    const query = `${memberLocationsPath}?member_number=${memberNumber}`;

    return fetch(query, { credentials: 'same-origin' }).
      then((response) => { return response.json() }).
      then((json) => {
        this.setState({ optionsMostRecentlyReturned: json })

        // if there's only one, select it straightaway
        if (!this.props.value && Object.keys(json).length === 1) {
          this.props.onChange(json[Object.keys(json)[0]].value, json[Object.keys(json)[0]].label)
        }

        return { options: json, complete: true }
      })
  }

  handleSelectChange = (valueFromSelect) => {
    const { value, label } = this.state.optionsMostRecentlyReturned.find((option) => {
      return option.value == valueFromSelect;
    }) || {};

    this.props.onChange(value, label)
  }

  render() {
    const {
      memberNumber,
      memberLocationsPath,
      label,
      value,
      errorMessage,
      onChange,
      required,
      tabIndex
    } = this.props

    return (
      <div className={ `form-group ${errorMessage ? 'has-error' : ''}` }>
        <OneAsyncSelectToRuleThemAll.Base
          labelText={ label }
          requiredInput={ required }
          tabIndex={ tabIndex }
          value={ value }
          onChange={ this.handleSelectChange }
          loadOptions={ this.getLocations }
        />

        { errorMessage && <span className="field-level-error">{ errorMessage }</span> }
      </div>
    )
  }
}

SelectMemberLocation.defaultProps = {
  required: false,
}

SelectMemberLocation.propTypes = {
  required:            PropTypes.bool.isRequired,
  label:               PropTypes.string.isRequired,
  memberNumber:        PropTypes.string.isRequired,
  memberLocationsPath: PropTypes.string.isRequired,
  onChange:            PropTypes.func.isRequired,

  errorMessage:        PropTypes.string,
  value:               PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

SelectMemberLocation = connect(
  (state, props) => ({
    memberNumber:         state.rails.memberNumber,
    memberLocationsPath:  state.rails.asyncHelpers.memberLocations
  })
)(SelectMemberLocation);

export default SelectMemberLocation
