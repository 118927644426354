import React from 'react'
import PropTypes from 'prop-types';

import { removeSectionQuestionFromRails } from '../../ducks/survey.js'
import ActionWithConfirmation from '../action_with_confirmation.jsx'

const DeleteSectionQuestionContainer = ({ sectionQuestion, handleHide }) => (
  <ActionWithConfirmation
    record={ sectionQuestion }
    title="Confirm Question Delete"
    body="Are you sure you want to remove this question from the survey?"
    confirmText='Delete Question'
    apiResource="sectionQuestions"
    apiMode="destroy"
    onConfirm={ removeSectionQuestionFromRails }
    handleHide={ handleHide }
  />
)

DeleteSectionQuestionContainer.propTypes = {
  handleHide: PropTypes.func.isRequired,
  sectionQuestion:  PropTypes.object.isRequired
}

export default DeleteSectionQuestionContainer
