import React from 'react'
import { Provider } from 'react-redux'

import { default as Survey } from './components/survey.jsx'

import { default as store, loadResponses } from './store.js'

import { load as loadSurvey }      from 'apps/rmg/shared/ducks/survey.js'
import { loadWithReviewAndSubmit } from 'apps/rmg/shared/ducks/coverage_types.js'
import { load as loadLookup }      from 'apps/rmg/shared/ducks/lookups.js'


class RmgSurveyAdminPreview extends React.Component {
  constructor(props) {
    super(props)

    store.dispatch(loadSurvey(this.props))
    store.dispatch(loadWithReviewAndSubmit(this.props))
    store.dispatch(loadResponses(this.props.responses))

    store.dispatch(loadLookup('tier', this.props.meta.tier))
    store.dispatch(loadLookup('renderAsPreview', true))
  }

  render() {
    return (
      <Provider store={ store }>
        <Survey />
      </Provider>
    )
  }
}

export default RmgSurveyAdminPreview
