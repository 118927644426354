import React from 'react'
import { Provider } from 'react-redux'

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      status: "configuring", // or ["configuration_failed", "loading_dashboard", "dashboard_loaded"]
      error:  '',
      dashboard_source: ''
    }

    this.isDashboardLoading = this.isDashboardLoading.bind(this)
  }

  componentDidMount() {
    console.log("fetching")
    fetch(this.props.configuration_path, {
        credentials: 'same-origin',
        headers: { "Accept": "application/json; charset=UTF-8" }
      }).
      then((response) => {
        if (response.ok) {
          return response.json().then((json) => {
            this.setState({ status: "loading_dashboard", dashboard_source: json.dashboard_source })
          })
        }
        else {
          response.json().then((json) => {
            this.setState({ status: "configuration_failed", error: json.error })
          })
        }
      })
  }

  isDashboardLoading() {
    return this.state.status === "loading_dashboard"
  }

  render() {
    if (this.state.status === "configuring") {
      return <div className="loading-spinner"></div>
    }

    if (this.state.status === "configuration_failed") {
      return <div className="flash alert alert-danger">{ this.state.error }</div>
    }

    return (
      <div>
        <div className="loading-spinner"
             style={{ display: this.isDashboardLoading() ? "block" : "none" }}>
        </div>

        <iframe style="border: none; padding-top: 50px; min-height: 800px;"
                style={{
                  border: "none",
                  width: "100%",
                  minHeight: "1000px",
                  display: this.isDashboardLoading() ? "none" : "block"
                }}
                onLoad={ () => { this.setState({ status: "dashboard_loaded" }) } }
                src={ this.state.dashboard_source }>
        </iframe>
      </div>
    )
  }
}

export default App
