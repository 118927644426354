import { requiredValidator } from 'shared/utils/validators'

export default class SubmissionValidator {
  constructor(state) {
    this.incidentReport = { ...state.incidentReport }
    this.persons        = { ...state.persons }
    this.vehicles       = { ...state.vehicles }
    this.properties     = { ...state.properties }
    this.witnesses      = { ...state.witnesses }
    this.attachments    = { ...state.attachments }

    // {
    //   incidentReport: {
    //     [field]: { path: "", message: "" }
    //   },
    //   persons: {
    //     [id]: {
    //       path: ""
    //       displayName: 'Gray Kemmey'
    //       [field]: "social_security_number can't be blank"
    //     }
    //   }
    // }
    //
    this.errors = {
      incidentReport: {},
      persons: {},
      vehicles: {},
      properties: {},
      witnesses: {},
      attachments: {}
    }
  }

  isValid = () => {
    this.checkForErrors()

    return [
      'incidentReport',
      'persons',
      'vehicles',
      'properties',
      'witnesses',
      'attachments'
    ].every((key) => {
      return Object.keys(this.errors[key]).length === 0
    })
  }

  incidentReportErrors = () => {
    return Object.values(this.errors.incidentReport)
  }

  personsErrors = () => {
    return Object.values(this.errors.persons).map((personWithErrors) => {
      const { path, displayName, ...fields } = personWithErrors

      return {
        path,
        displayName,
        errors: Object.values(fields) // flatten this into an array of error messages
      }
    })
  }

  // -------- not a part of the 'public' interface --------

  checkForErrors = () => {
    this.checkIncidentReport()
    this.checkPersons()
    this.checkVehicles()
    this.checkProperties()
    this.checkWitnesses()
    this.checkAttachments()
  }

  // this is like a bizaro-twice-configurable-function for checking fields. since we store
  // incident_report field errors and sub-record errors slightly differently we do some weird
  // checks. here's usage examples:
  //
  //   let _check = checkField(this.incidentReport, this.errors.incidentReport)("/overview")
  //   _check('descriptionOfHowIncidentOccurred', 'Description can\'t be blank')
  //
  //   let _check = checkField(this.persons, this.errors.persons)(`/persons/${id}/edit`, id, name)
  //   _check('lastName')
  //
  checkField = (values, errors) => (path, id, displayName) => (field, message) => {
    let record = null
    if (id) {
      record = Object.values(values).find((v) => ( v.id && v.id.toString() === id.toString() ))
    }

    // shouldn't happen, but wanna call out that it'd be a problem
    if (id && !record) { throw new Error("Couldn't find child record when validating") }

    let errorMessage = requiredValidator(message || `${field.humanize()} can't be blank`)(
      id ? record[field] : values[field]
    )

    if (errorMessage && id) {
      errors[id]             = errors[id] || {}
      errors[id].displayName = displayName
      errors[id].path        = path
      errors[id][field]      = errorMessage
    }

    if (errorMessage && !id) {
      errors[field] = { message: errorMessage, path: path }
    }
  }

  checkIncidentReport = () => {
    let _check = this.checkField(this.incidentReport, this.errors.incidentReport)("/overview")

    _check('descriptionOfHowIncidentOccurred', 'Description can\'t be blank')
    _check('incidentHappenedAt', 'Incident occurrence date can\'t be blank')
    _check('memberLocationCode')
    _check('department')
    _check('incidentStreetAddress')
    _check('incidentCity')
    _check('incidentState')
    _check('incidentZip')
    _check('firstReportedToName')
    _check('firstReportedOn', 'Date reported can\'t be blank')

    if (!this.incidentReport.firstReportedToEmail && !this.incidentReport.firstReportedToPrimaryPhone) {
      _check('firstReportedToEmail',        'First reported to email or phone required')
      _check('firstReportedToPrimaryPhone', 'First reported to email or phone required')
    }

    _check = this.checkField(this.incidentReport, this.errors.incidentReport)("/contact_details")

    _check('claimContactName')
    _check('claimContactPhone')
    _check('claimContactJobTitle')
    _check('claimContactEmail')
  }

  checkPersons = () => {
    Object.entries(this.persons).map(([_, person]) => {
      let name = `${person.firstName || ''} ${person.lastName || ''}`;
      name = (name.trim() === '') ? 'Unknown Name' : name;

      let id = person.id
      let _check = this.checkField(this.persons, this.errors.persons)(`/persons/${id}/edit`, id, name)

      let isWorkersCompClaim = person.type === "InjuredPerson::Employee" && person.workersCompClaim
      let isWorkersCompClaimNotYetReportedThroughNT24 = isWorkersCompClaim && !person.alreadyReportedThroughNurseTriageTwentyFour

      if (isWorkersCompClaim) {
        _check('primaryPhone')
        _check('dateOfBirth')
        _check('gender')
        _check('streetAddress')
        _check('city')
        _check('state')
        _check('zip')
      }

      if (isWorkersCompClaimNotYetReportedThroughNT24) {
        _check('socialSecurityNumber')
        _check('employmentType')
      }

      _check('additionalInjuryInformation')
    })
  }

  checkVehicles = () => {
  }

  checkProperties = () => {
  }

  checkWitnesses = () => {
  }

  checkAttachments = () => {
  }
}
