const LOAD = "cyber/shared/ducks/coverage_types/LOAD";

export default (state = [], action) => {
    switch (action.type) {
        case LOAD: {
            return [
                { id: null, name: 'Introduction' },

                ...action.coverageTypes.sort((a, b) => {
                    let _a = a.alias || a.name
                    let _b = b.alias || b.name

                    if (_a < _b) { return -1 }
                    if (_a > _b) { return 1 }
                    return 0
                }),

                ...(action.coveragesToAppend || [])
            ]
        }

        default: return state
    }
}

export const load = (props) => {
    // this accepts all the props passed to app.jsx, and pulls out the coverageTypes
    return {
        type: LOAD,
        coverageTypes: [
            ...props.coverageTypes
        ]
    }
}

export const loadWithReviewAndSubmit = (props) => {
    return {
        type: LOAD,
        coverageTypes: [
            ...props.coverageTypes,
        ],
        coveragesToAppend: [
            { id: 'review', name: 'Review and Print' }
        ]
    }
}

export const activeCoverage = (state, tabIndex) => {
    return state[tabIndex]
}
