import React from 'react'
import { normalizePhoneNumber, formatPhoneNumber } from 'shared/utils/phone_numbers.js'
import TextField from './text_field.jsx'

export default class PhoneNumberField extends React.Component {
  onChange = (value) => {
    this.props.onChange(normalizePhoneNumber(value))
  }

  render() {
    const { onChange, value, ...props } = this.props

    return <TextField { ...props } type="text" value={ formatPhoneNumber(value) } onChange={ this.onChange } />
  }
}
