import React from 'react'

let PropertiesHeader = () => (
  <div className="row">
    <div className="col-xs-12">
      <h4>Property and Loss Report</h4>

      <p>
        This section records damage to buildings, machinery, and
        other <strong>non-auto</strong> type of property. Please use the Involved Vehicles section
        to report auto property damage.
      </p>
    </div>
  </div>
)

export default PropertiesHeader
