import React from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'

import { getVehiclesCount, asyncDeleteVehicle } from '../ducks/vehicles.js'
import { apiEndpoint } from '../ducks/rails.js'
import { resetWizardState } from '../ducks/wizard.js'
import { Panel, PanelSidebar, PanelRightColumn, PanelBody, PanelControls } from '../panel'
import BootstrapModal from 'shared/components/bootstrap_modal.jsx'

import VehiclesCard from './vehicles_card.jsx'

class VehiclesIndex extends React.Component {
  constructor(props) {
    super(props)

    this.state = { confirmingDelete: false, deletingId: null }
  }

  componentWillUnmount() {
    this.props.resetWizardState()
  }

  handleDeleteClicked = (vehicleId) => (event) => {
    this.setState({ confirmingDelete: true, deletingId: vehicleId })
  }

  handleDelete = () => {
    return this.props.asyncDeleteVehicle(this.state.deletingId, this.props.vehiclesCount)
  }

  handleModalClosed = () => {
    this.setState({ confirmingDelete: false, deletingId: null })
  }

  render() {
    // we have a problem where, when/if the last vehicle card is deleted we'll redirect before
    // the confirmation modal hides. that unmounts this and that before we have a chance to
    // cleanup state or the dom. this is a sucky pattern, but the alternatives are to move the
    // bootstrap modal up in the heiarchy, or track whether this and the modal are mounted or not.
    //
    if (this.props.vehiclesCount === 0 && !this.state.confirmingDelete) {
      return <Redirect to="/vehicles/new" />
    }

    return (
      <Panel>
        <PanelSidebar />

        <PanelRightColumn>
          <PanelBody>
            <div className="row">
              <div className="col-xs-12">
                <h4>Involved Vehicles Report</h4>

                <p>
                  Below, you can add, edit, or delete vehicle records from this report or add new ones.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <p>
                  <Link to="/vehicles/new">
                    <button type="button" className="btn btn-success btn-with-icon plus">
                      Add New
                    </button>
                  </Link>
                </p>
              </div>
            </div>

            <div className="row">
              {
                Object.entries(this.props.vehicles).map(([_, vehicle]) => (
                  <div key={ vehicle.id } className="col-xs-12">
                    <VehiclesCard id={ vehicle.id }
                                  handleDeleteClicked={ this.handleDeleteClicked(vehicle.id) }
                                  vehicle={ vehicle } />
                  </div>
                ))
              }
            </div>

            {
              this.state.confirmingDelete &&
                <BootstrapModal title="Confirm Deletion"
                                handleHide={ this.handleModalClosed }
                                buttons={[
                                  { className: "btn btn-link", dismiss: true, name: "Cancel" },
                                  {
                                    className: "btn btn-default btn-danger",
                                    onClick: this.handleDelete,
                                    loading: this.props.deletingVehicle,
                                    name: "Delete"
                                  }
                                ]}>

                  <p>{ 'Are you sure you want to delete this vehicle?' }</p>
                </BootstrapModal>
            }
          </PanelBody>

          <PanelControls>
            <Link to="/persons">
              <button type="button"
                      className="btn btn-link">
                Back
              </button>
            </Link>


            <Link to="/properties">
              <button type="button"
                      className="btn btn-success">

                Save & Continue
              </button>
            </Link>
          </PanelControls>
        </PanelRightColumn>
      </Panel>
    )
  }
}

VehiclesIndex = connect(
  (state, props) => ({
    vehiclesCount:   getVehiclesCount(state.vehicles),
    vehicles:        state.vehicles,
    deletingVehicle: state.wizard.updating,
    apiEndpoint:     apiEndpoint(state.rails, "incident_report")
  }),
  null,
  ({ apiEndpoint, ...stateProps }, { dispatch }, props) => ({
    ...stateProps,
    ...props,

    asyncDeleteVehicle: (id, vehiclesCount) => {
      return asyncDeleteVehicle(apiEndpoint.path, id, vehiclesCount)(dispatch)
    },

    resetWizardState: () => { dispatch(resetWizardState()) }
  })
)(VehiclesIndex);

export default VehiclesIndex
