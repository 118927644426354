import React from 'react'
import { connect } from 'react-redux'
import { submit, isSubmitting } from 'redux-form'

import { QueryStore } from '../store.js'

let FormButtonGroup = ({
  currentlyInSuccessStatus,
  currentlyInFailStatus,
  currentlySubmitting,
  submitForm
}) => {

  if (currentlyInFailStatus) {
    return (
      <div>
        <div className="pull-left">
          {
            (function() {
              if (currentlySubmitting) {
                return (
                  <em>
                    <i className="fa fa-spinner fa-spin fa-fw text-primary" aria-hidden="true">
                    </i> Submitting...
                  </em>
                )
              }
              else {
                return (
                  <em>
                    <i className="fa fa-times-circle text-danger" aria-hidden="true">
                    </i> Problem uploading file
                  </em>
                )
              }
            }())
          }
        </div>

        <div className="pull-right">
          <button
            type="button"
            className="btn btn-link"
            data-dismiss="modal">

            Cancel
          </button>

          <button
            type="button"
            className="btn btn-default btn-success"
            onClick={ submitForm }>

            Retry
          </button>
        </div>
      </div>
    )
  }
  else if (currentlyInSuccessStatus) {
    return (
      <div>
        <div className="pull-left">
          {
            (function() {
              if (currentlySubmitting) {
                return (
                  <em>
                    <i className="fa fa-spinner fa-spin fa-fw text-primary" aria-hidden="true">
                    </i> Submitting...
                  </em>
                )
              }
              else {
                return (
                  <em>
                    <i className="fa fa-check-circle text-success" aria-hidden="true">
                    </i> File uploaded successfully
                  </em>
                )
              }
            }())
          }
        </div>

        <div className="pull-right">
          <button
            type="button"
            className="btn btn-link"
            data-dismiss="modal">

            Close
          </button>
        </div>
      </div>
    )
  }
  else {
    return (
      <div>
        {
          currentlySubmitting &&
            <div className="pull-left">
              <em>
                <i className="fa fa-spinner fa-spin fa-fw text-primary" aria-hidden="true">
                </i> Submitting...
              </em>
            </div>
        }
        <div className="pull-right">
          <button
            type="button"
            className="btn btn-link"
            data-dismiss="modal">

            Cancel
          </button>

          <button
            type="button"
            className="btn btn-default btn-success"
            onClick={ submitForm }>

            Upload
          </button>
        </div>
      </div>
    )
  }
}

FormButtonGroup = connect(
  (state, props) => ({
    currentlyInSuccessStatus: QueryStore.currentlyInSuccessStatus(state),
    currentlyInFailStatus:    QueryStore.currentlyInFailStatus(state),
    currentlySubmitting:      isSubmitting("document")(state)
  }),

  (dispatch, props) => ({
    submitForm: () => { dispatch(submit("document")) }
  })
)(FormButtonGroup);

export default FormButtonGroup
