import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import ConfirmationModal from 'shared/components/confirmation_modal.jsx'

import { removeSectionFromRails } from '../../ducks/survey.js'
import { apiEndpoint } from '../../ducks/rails.js'

class DeleteSurveySectionContainer extends React.Component {
  constructor(props) {
    super(props)

    this.deleteSurveySection = this.deleteSurveySection.bind(this)
  }

  deleteSurveySection() {
    let { apiEndpoint: { path, method }, sectionId, dispatch, handleHide } = this.props
    path = path.replace(/:id/g, sectionId)

    // we don't need to hide, because if this works, we're going to unmount the section card 💪
    removeSectionFromRails(sectionId, path, method, dispatch)
  }

  render() {
    return (
      <ConfirmationModal title="Confirm Section Deletion"
        body={ <p>{ 'Are you sure you want to delete this survey section and all its questions?' }</p> }
        handleConfirm={ this.deleteSurveySection }
        handleHide={ this.props.handleHide }
      />
    )
  }
}

DeleteSurveySectionContainer.propTypes = {
  handleHide: PropTypes.func.isRequired,
  sectionId:  PropTypes.number.isRequired
}

DeleteSurveySectionContainer = connect(
  (state, props) => {
    return {
      apiEndpoint: apiEndpoint(state.rails, { resource: "surveySections", mode: "destroy" }),
    }
  }
)(DeleteSurveySectionContainer)

export default DeleteSurveySectionContainer
