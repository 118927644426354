import React from 'react'
import { connect } from 'react-redux'

import { getWitness } from '../ducks/witnesses.js'

import WitnessesForm from './witnesses_form.jsx'

let WitnessesEdit = ({ witness }) => (
  <WitnessesForm witness={ witness } />
)

WitnessesEdit = connect(
  (state, props) => ({
    witness: getWitness(state.witnesses, props.match.params.id)
  })
)(WitnessesEdit)

export default WitnessesEdit
