import React from 'react'
import PropTypes from 'prop-types';

import ConfirmationModal from 'shared/components/confirmation_modal.jsx'

class ConfirmAndSaveModalFooter extends React.Component {
  state = { confirmingSave: false }

  handleSave = () => {
    if(this.props.confirmBeforeSave) {
      this.setState({ confirmingSave: true })
    } else {
      this.handleConfirm()
    }
  }

  handleConfirm = () => {
    this.setState({ confirmingSave: false }, this.props.handleSave)
  }

  render() {
    const confirming = (
      <div>
        <div className='confirm-text'>
          <i className="fa fa-exclamation-circle"></i>
          { this.props.confirmText }
        </div>

        <button
          type="button"
          className="btn btn-link"
          onClick={ () => { this.setState({ confirmingSave: false }) } }>

          Cancel
        </button>

        <button
          type="button"
          className="btn btn-danger"
          onClick={ this.handleConfirm }>

          Confirm
          </button>
      </div>
    )

    const cancelOrSave = (
      <div>
        { this.props.errorMessageFromSaveAttempt &&
            <div className="pull-left">
              <em>
                <i className="fa fa-times-circle text-danger" aria-hidden="true">
                </i> { this.props.errorMessageFromSaveAttempt }
              </em>
            </div>
        }

        <div className="pull-right">
          <button
            type="button"
            className="btn btn-link"
            data-dismiss="modal">

            Cancel
          </button>

          <button
            type="button"
            className="btn btn-default btn-success"
            onClick={ this.handleSave }>

            Save
          </button>
        </div>
      </div>
    )

    return (
      <div>
        { this.state.confirmingSave ?
            confirming
          :
            cancelOrSave
        }
      </div>
    )
  }
}

ConfirmAndSaveModalFooter.propTypes = {
  handleSave: PropTypes.func.isRequired,
  confirmBeforeSave: PropTypes.bool.isRequired,
  confirmText: PropTypes.string.isRequired
}

export default ConfirmAndSaveModalFooter
