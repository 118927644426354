// example state:
//   {
//     updateSucceeded: null, // null == untried, false == failed, true == succeeded
//     updating: false, // are we currently in the process of submitting back to rails
//     flash: {
//       type: "success",
//       message: "",
//       details: [] // display as a bulleted list
//     }
//   }
//

export default function wizardFactory(namespace, defaultState = {}) {
  const SET_FLASH         = `${namespace}/wizard/SET_FLASH`
  const RESET             = `${namespace}/wizard/RESET`
  const UPDATE_SUCCEEDED  = `${namespace}/wizard/UPDATE_SUCCEEDED`
  const UPDATE_FAILED     = `${namespace}/wizard/UPDATE_FAILED`
  const UPDATE_STARTED    = `${namespace}/wizard/UPDATE_STARTED`
  const OPEN_MOBILE_MENU  = `${namespace}/wizard/OPEN_MOBILE_MENU`
  const CLOSE_MOBILE_MENU = `${namespace}/wizard/CLOSE_MOBILE_MENU`

  const setFlash = (flash_type, message, keep, details) => {
    return { type: SET_FLASH, flash_type: flash_type, message: message, keep: keep, details: details }
  }

  return {
    default: (state = defaultState, action) => {
      switch (action.type) {
        case SET_FLASH: {
          return {
            ...state,

            flash: {
              type:    action.flash_type,
              message: action.message,
              details: action.details,
              keep:    action.keep || false
            }
          }
        }

        case RESET: {
          return {
            updateSucceeded: null,
            updating:        false,

            // setting a keep should allow the success messages to persist past a redirect
            flash: (state.flash && state.flash.keep) ? { ...state.flash, keep: false } : null
          }
        }

        case UPDATE_STARTED: {
          return { ...state, updating: true }
        }

        case UPDATE_SUCCEEDED: {
          return { ...state, updateSucceeded: true, updating: false }
        }

        case UPDATE_FAILED: {
          return { ...state, updateSucceeded: false, updating: false }
        }

        case OPEN_MOBILE_MENU: {
          return { ...state, mobileMenuOpen: true }
        }

        case CLOSE_MOBILE_MENU: {
          return { ...state, mobileMenuOpen: false }
        }

        default: {
          return state
        }
      }
    },

    setErrorFlash: (message, options = {}) => {
      const { keep, details } = options
      return setFlash("danger", message, keep, details)
    },
    setSuccessFlash: (message, options = {}) => {
      const { keep, details } = options
      return setFlash("success", message, keep, details)
    },
    updateSucceeded: () => {
      return { type: UPDATE_SUCCEEDED }
    },
    updateFailed: () => {
      return { type: UPDATE_FAILED }
    },
    updateStarted: () => {
      return { type: UPDATE_STARTED }
    },
    resetWizardState: () => {
      return { type: RESET }
    },
    openMobileMenu: () => {
      return { type: OPEN_MOBILE_MENU }
    },
    closeMobileMenu: () => {
      return { type: CLOSE_MOBILE_MENU }
    }
  }
}

