import React from 'react'
import { Link } from 'react-router-dom'

import { formatPhoneNumber } from 'shared/utils/phone_numbers.js'

const PersonsCard = ({ handleDeleteClicked, person }) => {
  const { firstName, lastName } = person
  let name = `${firstName || ''} ${lastName || ''}`;
  name = (name.trim() === '') ? 'Unknown Name' : name;

  let primaryInjury = null;

  if (person.injuredAreaOfTheBodyDescription && person.natureOfInjuryDescription) {
    primaryInjury = `${person.injuredAreaOfTheBodyDescription} (${person.natureOfInjuryDescription})`;
  }
  else if (person.injuredAreaOfTheBodyDescription) {
    primaryInjury = `${person.injuredAreaOfTheBodyDescription}`
  }

  let phone = formatPhoneNumber(person.primaryPhone);

  return (
    <div className="card">
      <header className="card-header">
        <h5 className="card-title">{ name }</h5>

        <div className="card-header-controls">
          <Link to={ `/persons/${person.id}/edit` }>
            <span className="glyphicon glyphicon-pencil"></span>
          </Link>

          <a onClick={ handleDeleteClicked }>
            <span className="glyphicon glyphicon-trash"></span>
          </a>
        </div>
      </header>

      <div className="card-body">
        <p>
          { person.type === 'InjuredPerson::Employee' ? 'Employee' : 'Third Party' }<br />
          { primaryInjury }{ primaryInjury && <br />}
          { phone }{ phone && <br />}
        </p>
        <p>Treatment: { person.initialTreatment ? person.initialTreatment.humanize() : "Unknown" }</p>
      </div>

      {
        person.potentialDuplicates.length > 0 &&
          <div>
            <div className='card-body bg-danger'>
              <h4><i className='fa fa-exclamation-triangle'></i> Possible Duplicate</h4>
              <p>We found { person.potentialDuplicates.length == 1 ? 'an existing claimant' : 'existing claimants' } with the same last name and date of loss.</p>
              <p>If this is a duplicate, please remove the claimant (person) or delete the duplicate incident report.</p>
            </div>

            <table className='table table-condensed'>
              <thead>
                <tr>
                  <th>Claim #</th>
                  <th>Name</th>
                  <th>Loss description</th>
                </tr>
              </thead>
              <tbody>
                {
                  person.potentialDuplicates.map((duplicate) => (
                    <tr key={ duplicate.id }>
                      <td>{ duplicate.claimNumber }</td>
                      <td>{ duplicate.claimantName }</td>
                      <td>{ duplicate.lossDescription }</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
      }

      <footer className="card-footer"></footer>
    </div>
  )
}

export default PersonsCard
