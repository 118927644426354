const LOAD = 'cyber/shared/rails/LOAD'

export default (state = {}, action) => {
    switch (action.type) {
        case LOAD: {
            return { ...action.fromRails }
        }

        default: return state;
    }
}

export const load = (props) => {
    // this accepts all the props passed to app.jsx, and pulls out the rails-y metadata
    return {
        type: LOAD,
        fromRails: {
            apiEndpoints: { ...props.apiEndpoints },
            ...props.meta
        }
    }
}

export const apiEndpoint = (state, options = {}) => {
    const { resource, mode } = options

    const {
        [resource]: {
            [mode]: { path, method }
        }
    } = state.apiEndpoints

    return { path: path, method: method }
}
