import React from 'react'
import { Provider } from 'react-redux'
import { default as TouchBackend } from 'react-dnd-touch-backend';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import { default as Survey } from './components/editable_survey.jsx'

import store from './store.js'

import { load as loadSurvey }        from 'apps/cyber/shared/ducks/survey.js'
import { load as loadCoverageTypes } from 'apps/cyber/shared/ducks/coverage_types.js'
import { load as loadInfoFromRails } from './ducks/rails.js'
import { load as loadLookup }        from 'apps/cyber/shared/ducks/lookups.js'

class CyberSurveyAdmin extends React.Component {
    constructor(props) {
        super(props)

        store.dispatch(loadInfoFromRails(this.props))
        store.dispatch(loadSurvey(this.props))
        store.dispatch(loadCoverageTypes(this.props))

        // Store some lookup data referenced by the questions
        store.dispatch(loadLookup('entityTypes',   this.props.entityTypes))
        store.dispatch(loadLookup('entityTiers',   this.props.entityTiers))
        store.dispatch(loadLookup('programs',      this.props.programs))
        store.dispatch(loadLookup('linkToPreview', this.props.linkToPreview))
    }

    render() {
        return (
            <Provider store={ store }>
                <Survey />
            </Provider>
        )
    }
}

CyberSurveyAdmin = DragDropContext(TouchBackend({ enableMouseEvents: true }))(CyberSurveyAdmin);
// RmgSurveyAdmin = DragDropContext(HTML5Backend)(RmgSurveyAdmin);

export default CyberSurveyAdmin
