import React from 'react'
import { Link } from 'react-router-dom'

import { Panel, PanelSidebar, PanelRightColumn, PanelBody, PanelControls } from '../panel'

import ContactDetailsForm from './contact_details_form.jsx'

let ContactDetailsRouter = () => ( // this doesn't have any nested routes to worry about, so we punt
  <ContactDetailsForm />
)

export default ContactDetailsRouter
