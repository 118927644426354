import React from 'react'
import { connect } from 'react-redux'
import { Link, Prompt, Redirect } from 'react-router-dom'

import { convertToDate } from 'shared/utils/rails_datetime_formatters.js'

import BaseForm from 'shared/components/base_form.jsx'
import SelectWithAsyncOptionsFromRails from 'shared/components/select_with_async_options_from_rails'
import SameAsCheckbox from 'shared/components/same_as_checkbox.jsx'

import {
  FormField,
  TextField,
  RadioField,
  PhoneNumberField,
  DateAndTimeField,
  SelectField,
  AddressField,
  SsnField,
  DollarValueField,
  ToggleSwitchField
} from 'shared/components/fields'

import {
  dollarValueValidator,
  emailValidator,
  lengthValidator,
  notInFutureValidator,
  phoneNumberValidator,
  requiredValidator,
  ssnValidator
} from 'shared/utils/validators'

import { asyncUpdatePersons } from '../ducks/persons.js'
import { apiEndpoint } from '../ducks/rails.js'
import { resetWizardState } from '../ducks/wizard.js'
import { resetErrorsForPerson } from '../ducks/submission_errors.js'
import { Panel, PanelSidebar, PanelRightColumn, PanelBody, PanelControls } from '../panel'

import PersonsPrompt from './persons_prompt.jsx'
import PersonsHeader from './persons_header.jsx'

const requiredIfAnEmployee = (value, state) => {
  if (state.type.value === "InjuredPerson::Employee") {
    return requiredValidator()(value)
  }
}

const requiredIfWorkersComp = (value, state) => {
  if (state.type.value === "InjuredPerson::Employee" && state.workersCompClaim.value) {
    return requiredValidator()(value)
  }
}

const requiredIfWorkersCompAndNotReportedThroughNT24 = (value, state) => {
  if (!state.alreadyReportedThroughNurseTriageTwentyFour.value) {
    return requiredIfWorkersComp(value, state) // we can punt to here
  }
}

const requiredIfEmployeeReturnedToWork = (value, state) => {
  if (state.returnType.value && state.returnType.value !== 'not_yet_returned') {
    return requiredValidator()(value)
  }
}

const requiredIfEmployeeHasNotReturnedToWork = (value, state) => {
  if (state.returnType.value === 'not_yet_returned') {
    return requiredValidator()(value)
  }
}

const requireAnEmployeeToBeAtLeastThirteen = (value, state) => {
  if (value) {
    if (moment().diff(convertToDate(value), 'years', false) < 13) {
      return "An employee should be at least 13"
    }
  }

  return null
}

const natureOfInjuryCodeValidator = (value, state) => {
  if (value) {
    let isWorkersCompClaim = state.type.value === "InjuredPerson::Employee" &&
                             state.workersCompClaim.value

    if (isWorkersCompClaim && value.includes("L")) {
      return  "Looks like you selected a liability code for a workers comp " +
              "claim. Please select another or click the \"X\" to clear your " +
              "old selection."
    }
    else if(!isWorkersCompClaim && !value.includes("L")) {
      return "Looks like you selected a workers comp code for a liability " +
             "claim. Please select another or click the \"X\" to clear your " +
             "old selection."
    }
  }

  return null
}

const holdThePhoneIfAlreadyReportedThroughNT24 = (value, state) => {
  if (value) {
    return "Looks like you've indicated this has already been reported through VRSA's nurse triage and reporting service. " +
           "The employee’s injury should not be included or submitted in this incident report " +
           "since it was already reported. At this point, we'd ask that you click \"Cancel\" " +
           "below, and delete this person if need be."
  }
}

class PersonsForm extends BaseForm {
  constructor(props) {
    super(props)

    const person = { ...(props.person || {}) }
    const errors = { ...(props.errors || {}) }

    this.state = {
      person: {
        type: new FormField(person.type, requiredValidator()),
        workersCompClaim: new FormField(person.workersCompClaim, requiredIfAnEmployee),

        alreadyReportedThroughNurseTriageTwentyFour: new FormField(
          person.alreadyReportedThroughNurseTriageTwentyFour,
          this.props.memberUsesNT24 ? [requiredIfWorkersComp, holdThePhoneIfAlreadyReportedThroughNT24] : []
        ),

        firstName:      new FormField(person.firstName, requiredValidator()),
        middleInitial:  new FormField(person.middleInitial),
        lastName:       new FormField(person.lastName, requiredValidator()),
        nickname:       new FormField(person.nickname),

        primaryPhone:   new FormField(person.primaryPhone,   phoneNumberValidator()),
        emailAddress:   new FormField(person.emailAddress,   emailValidator()),
        alternatePhone: new FormField(person.alternatePhone, phoneNumberValidator()),

        // ---- employee-only fields ----
        dateOfBirth:   new FormField(person.dateOfBirth,   requireAnEmployeeToBeAtLeastThirteen),
        gender:        new FormField(person.gender),
        maritalStatus: new FormField(person.maritalStatus),

        streetAddress: new FormField(person.streetAddress),
        optionalLine:  new FormField(person.optionalLine),
        city:          new FormField(person.city),
        state:         new FormField(person.state),
        zip:           new FormField(person.zip),

        // ---- workers-comp only fields ----
        jobClassificationCode:        new FormField(person.jobClassificationCode),
        jobClassificationDescription: new FormField(person.jobClassificationDescription),

        jobTitle: new FormField(person.jobTitle, requiredIfWorkersCompAndNotReportedThroughNT24),
        socialSecurityNumber: new FormField(person.socialSecurityNumber, ssnValidator()),
        employmentType: new FormField(person.employmentType),

        hiredDate: new FormField(person.hiredDate),

        totalEarningsInPeriod: new FormField(person.totalEarningsInPeriod, dollarValueValidator()),
        earningPeriod:         new FormField(person.earningPeriod),
        hoursWorkedPerDay:     new FormField(person.hoursWorkedPerDay),
        daysWorkedPerWeek:     new FormField(person.daysWorkedPerWeek),

        supervisorName: new FormField(person.supervisorName, requiredIfWorkersCompAndNotReportedThroughNT24),
        supervisorPhone: new FormField(person.supervisorPhone, [phoneNumberValidator(), requiredIfWorkersCompAndNotReportedThroughNT24]),
        supervisorEmail: new FormField(person.supervisorEmail, emailValidator()),

        dateDisabilityBegan:     new FormField(person.dateDisabilityBegan),
        wagesPaidOnDateOfInjury: new FormField(person.wagesPaidOnDateOfInjury || true),
        returnType:              new FormField(person.returnType, requiredIfWorkersCompAndNotReportedThroughNT24),
        returnTypeDetails:       new FormField(person.returnTypeDetails),
        returnToWorkDate:        new FormField(person.returnToWorkDate, requiredIfEmployeeReturnedToWork),
        modifiedDutyAvailable:   new FormField(person.modifiedDutyAvailable, requiredIfEmployeeHasNotReturnedToWork),

        safetyEquipmentProvided:        new FormField(person.safetyEquipmentProvided),
        safetyEquipmentProvidedDetails: new FormField(person.safetyEquipmentProvidedDetails),
        safetyEquipmentUtilized:        new FormField(person.safetyEquipmentUtilized),
        safetyEquipmentUtilizedDetails: new FormField(person.safetyEquipmentUtilizedDetails),

        beganWorkAt: new FormField(person.beganWorkAt),
        descriptionOfWhereInjuryOccurred: new FormField(person.descriptionOfWhereInjuryOccurred),
        descriptionOfActivityAtTimeOfIncident: new FormField(person.descriptionOfActivityAtTimeOfIncident),
        substanceOrObjectCausingInjury: new FormField(person.substanceOrObjectCausingInjury),
        // ---- end workers-comp only fields ----
        // ---- end employee-only fields ----

        injuredAreaOfTheBodyCode:        new FormField(person.injuredAreaOfTheBodyCode),
        injuredAreaOfTheBodyDescription: new FormField(person.injuredAreaOfTheBodyDescription),
        natureOfInjuryCode:              new FormField(person.natureOfInjuryCode, natureOfInjuryCodeValidator),
        natureOfInjuryDescription:       new FormField(person.natureOfInjuryDescription),

        additionalInjuryInformation:     new FormField(person.additionalInjuryInformation, lengthValidator(255)),
        personType:                      new FormField(person.personType),

        panelOfPhysiciansProvided:    new FormField(person.panelOfPhysiciansProvided),
        initialTreatment:             new FormField(person.initialTreatment, requiredIfWorkersComp),
        doctorFirstName:              new FormField(person.doctorFirstName),
        doctorLastName:               new FormField(person.doctorLastName),
        nameOfMedicalFacility:        new FormField(person.nameOfMedicalFacility),
        medicalFacilityStreetAddress: new FormField(person.medicalFacilityStreetAddress),
        medicalFacilityOptionalLine:  new FormField(person.medicalFacilityOptionalLine),
        medicalFacilityCity:          new FormField(person.medicalFacilityCity),
        medicalFacilityState:         new FormField(person.medicalFacilityState),
        medicalFacilityZip:           new FormField(person.medicalFacilityZip),
        medicalFacilityPhone:         new FormField(person.medicalFacilityPhone, phoneNumberValidator()),
        insuranceProviderName:        new FormField(person.insuranceProviderName),

        sufferedEmploymentDiscrimination: new FormField(person.sufferedEmploymentDiscrimination || false),
        sufferedACivilRightsViolation:    new FormField(person.sufferedACivilRightsViolation || false),
        involvedAStudentIssue:            new FormField(person.involvedAStudentIssue || false),

        additionalComments: new FormField(person.additionalComments, lengthValidator(255))
      }
    }

    // add any errors we found when attempting to submit
    Object.keys(this.state.person).map((key) => { this.state.person[key].errorMessage = errors[key] })
  }

  handleChangeFor = (name) => ( this.handleFieldChange("person", name) )

  componentWillUnmount() {
    this.props.resetWizardState()

    if (this.props.person && this.props.person.id) {
      this.props.resetSubmissionErrors(this.props.person.id)
    }
  }

  prepareData = () => {
    let key = Date.now().toString()

    // the structure of what we wanna send back to rails for updating
    let data = {
      incidentReport: {
        ...(this.props.incidentReport || {}),
        injuredPersonsAttributes: { [key]: {} } // new object to copy person values into
      }
    }

    // if this is person has an id (i.e. rails already knows about them), add it in. new persons
    // don't need an id and will be given one by rails
    if ((this.props.person || {}).id) {
      data.incidentReport.injuredPersonsAttributes[key].id = (this.props.person || {}).id
    }

    // collect our form values
    Object.keys(this.state.person).map((field) => {
      data.incidentReport.injuredPersonsAttributes[key][field] = this.state.person[field].value
    })

    return data
  }

  handleSave = (event) => {
    event.preventDefault()

    if (this.validateFields("person")) {
      this.props.asyncUpdatePersons(this.prepareData())
    }
  }

  isAnEmployee = () => {
    return this.state.person.type.value === "InjuredPerson::Employee"
  }

  isWorkersCompClaim = () => {
    return !!(this.isAnEmployee() && this.state.person.workersCompClaim.value)
  }

  wasAlreadyReported = () => {
    return this.isWorkersCompClaim() && this.state.person.alreadyReportedThroughNurseTriageTwentyFour.value
  }

  showWorkersCompQuestions = () => {
    return this.isWorkersCompClaim() && !this.state.person.alreadyReportedThroughNurseTriageTwentyFour.value
  }

  renderControlGroup() {
    if (this.props.withPrompt) {
      return (
        <div>
          <button type="button"
                  className="btn btn-link"
                  onClick={ () => {
                    if (confirm("Are you sure you want to move away? Any unsaved changes " +
                                "made to this section will be lost.")) {
                      this.props.onPromptChange(false) // toggle the prompt back to "No"
                    }
                  }}>
            Cancel
          </button>

          <button type="button"
                  onClick={ this.wasAlreadyReported() ? undefined : this.handleSave }
                  className={ `btn btn-success ${this.props.updating || this.wasAlreadyReported() ? "disabled" : ""}` }>

            Save to Claim
          </button>
        </div>
      )
    }
    else {
      return (
        <div>
          <Link to="/persons">
            <button type="button"
                    className="btn btn-link">

              Back
            </button>
          </Link>

          <button type="button"
                  onClick={ this.wasAlreadyReported() ? undefined : this.handleSave }
                  className={ `btn btn-success ${this.props.updating || this.wasAlreadyReported() ? "disabled" : ""}` }>

            Save to Claim
          </button>
        </div>
      )
    }
  }

  render() {
    if (this.props.updateSucceeded) {
      return <Redirect to="/persons" />
    }

    return (
      <Panel>
        <PanelSidebar />

        <PanelRightColumn>
          <PanelBody>
            <PersonsHeader />

            { this.props.withPrompt && <hr /> }
            {
              this.props.withPrompt &&
                <div className="row">
                  <div className="col-xs-12">
                    <PersonsPrompt onChange={ this.props.onPromptChange }
                                   value={ this.props.promptValue } />
                  </div>
                </div>
            }

            <hr />

            <div className="row">
              <div className="col-xs-12">
                <RadioField name="type"
                            onChange={ (value) => {
                              this.handleChangeFor("type")(value)

                              // it can't be workers comp claim if it's not an employee
                              if (value === 'InjuredPerson::ThirdParty') {
                                this.handleChangeFor("workersCompClaim")(false)
                              }
                              // force them to answer it if this person changes to being an employee
                              else {
                                this.handleChangeFor("workersCompClaim")(null)
                              }
                            }}
                            label="Is this individual an employee?"
                            value={ this.state.person.type.value }
                            errorMessage={ this.state.person.type.errorMessage }
                            required={ true }
                            options={[
                              { displayText: 'Yes', value: 'InjuredPerson::Employee' },
                              { displayText: 'No', value: 'InjuredPerson::ThirdParty' }
                            ]} />
              </div>

              {
                this.isAnEmployee() &&
                  <div className="col-xs-12">
                    <RadioField name="workersCompClaim"
                                onChange={ this.handleChangeFor("workersCompClaim") }
                                label="Is this a Workers' Comp. Claim?"
                                value={ this.state.person.workersCompClaim.value }
                                errorMessage={ this.state.person.workersCompClaim.errorMessage }
                                required={ true }
                                options={[
                                  { displayText: 'Yes', value: true },
                                  { displayText: 'No', value: false }
                                ]} />
                  </div>
              }

              {
                this.isWorkersCompClaim() && this.props.memberUsesNT24 &&
                  <div className="col-xs-12">
                    <RadioField name="alreadyReportedThroughNurseTriageTwentyFour"
                                onChange={ this.handleChangeFor("alreadyReportedThroughNurseTriageTwentyFour") }
                                label="Has this claim already been reported through VRSA's nurse triage and reporting service?"
                                value={ this.state.person.alreadyReportedThroughNurseTriageTwentyFour.value }
                                errorMessage={ this.state.person.alreadyReportedThroughNurseTriageTwentyFour.errorMessage }
                                required={ this.isWorkersCompClaim() && this.props.memberUsesNT24 }
                                options={[
                                  { displayText: 'Yes', value: true },
                                  { displayText: 'No', value: false }
                                ]} />
                  </div>
              }
            </div>

            { /* don't show anything until they've answerd the type question */
              this.state.person.type.value &&
              /* drop everything if they say this person has already been reported 🚫 */
              !this.wasAlreadyReported() &&

                <div>
                  <div className="row">
                    <div className="col-xs-12">
                      <h4>Personal Information</h4>

                      <p>{ 'Who was this person, and how can we contact them?' }</p>

                      <div className="form-group form-inline full-name-group">
                        <TextField name="firstName"
                                   onChange={ this.handleChangeFor("firstName") }
                                   label="First Name"
                                   bootstrapWrapperClassName={ ["pad-right"] }
                                   value={ this.state.person.firstName.value }
                                   errorMessage={ this.state.person.firstName.errorMessage }
                                   required={ true }
                                   tabIndex="1" />

                        <TextField name="middleInitial"
                                   onChange={ this.handleChangeFor("middleInitial") }
                                   label="MI"
                                   bootstrapWrapperClassName={ ["pad-right"] }
                                   value={ this.state.person.middleInitial.value }
                                   errorMessage={ this.state.person.middleInitial.errorMessage }
                                   tabIndex="1" />

                        <TextField name="lastName"
                                   onChange={ this.handleChangeFor("lastName") }
                                   label="Last Name"
                                   bootstrapWrapperClassName={ ["pad-right"] }
                                   value={ this.state.person.lastName.value }
                                   errorMessage={ this.state.person.lastName.errorMessage }
                                   required={ true }
                                   tabIndex="1" />
                      </div>

                      <div className="form-group form-inline full-name-group">
                        {
                          this.isAnEmployee() &&
                            <TextField name="nickname"
                                       onChange={ this.handleChangeFor("nickname") }
                                       label="Nickname"
                                       value={ this.state.person.nickname.value }
                                       errorMessage={ this.state.person.nickname.errorMessage }
                                       tabIndex="1" />
                        }
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-6">
                      <PhoneNumberField name="primaryPhone"
                                        onChange={ this.handleChangeFor("primaryPhone") }
                                        label="Primary Phone"
                                        value={ this.state.person.primaryPhone.value }
                                        errorMessage={ this.state.person.primaryPhone.errorMessage }
                                        required={ !this.state.person.primaryPhone.value && this.isWorkersCompClaim() ? { warn: true } : false }
                                        tabIndex="2" />

                      <TextField type="text"
                                 name="emailAddress"
                                 onChange={ this.handleChangeFor("emailAddress") }
                                 label="Email Address"
                                 value={ this.state.person.emailAddress.value }
                                 errorMessage={ this.state.person.emailAddress.errorMessage }
                                 tabIndex="4" />
                    </div>
                    <div className="col-xs-6">
                      <PhoneNumberField name="alternatePhone"
                                        onChange={ this.handleChangeFor("alternatePhone") }
                                        label="Alternate Phone"
                                        value={ this.state.person.alternatePhone.value }
                                        errorMessage={ this.state.person.alternatePhone.errorMessage }
                                        tabIndex="3" />
                    </div>
                  </div>

                  {
                    this.isAnEmployee() &&
                      <div>
                        <div className="row">
                          <div className="col-xs-12">
                            <h4>Employee Information</h4>

                            <p>
                              Please provide the following additional information about this employee.
                            </p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-12">
                            <DateAndTimeField name="dateOfBirth"
                                              includeTime={ false }
                                              onChange={ this.handleChangeFor("dateOfBirth") }
                                              label={{ date: "Date of Birth" }}
                                              value={ this.state.person.dateOfBirth.value }
                                              errorMessage={ this.state.person.dateOfBirth.errorMessage }
                                              required={ !this.state.person.dateOfBirth.value && this.isWorkersCompClaim() ? { warn: true } : false }
                                              tabIndex="5" />
                          </div>

                          <div className="col-xs-6">
                            <SelectField name="gender"
                                         className="form-control-state"
                                         onChange={ this.handleChangeFor("gender") }
                                         label="Gender"
                                         placeholder="Select..."
                                         value={ this.state.person.gender.value }
                                         errorMessage={ this.state.person.gender.errorMessage }
                                         required={ !this.state.person.gender.value && this.isWorkersCompClaim() ? { warn: true } : false }
                                         tabIndex="6"
                                         options={[
                                           { displayText: "Male", value: "male" },
                                           { displayText: "Female", value: "female" },
                                           { displayText: "Unknown", value: "unknown" }
                                         ]} />
                          </div>

                          <div className="col-xs-6">
                            <SelectField name="maritalStatus"
                                         onChange={ this.handleChangeFor("maritalStatus") }
                                         label="Marital Status"
                                         placeholder="Select..."
                                         value={ this.state.person.maritalStatus.value }
                                         errorMessage={ this.state.person.maritalStatus.errorMessage }
                                         tabIndex="7"
                                         options={ this.props.maritalStatusOptions } />
                          </div>

                          <div className="col-xs-12">
                            <AddressField required={
                                            !(this.state.person.streetAddress.value &&
                                              this.state.person.city.value &&
                                              this.state.person.state.value &&
                                              this.state.person.zip.value) &&
                                                this.isWorkersCompClaim() ? { warn: true } : false
                                          }
                                          tabIndex="8"
                                          fields={{
                                            streetAddress: {
                                              name: "streetAddress",
                                              value: this.state.person.streetAddress.value,
                                              errorMessage: this.state.person.streetAddress.errorMessage,
                                              onChange: this.handleChangeFor("streetAddress")
                                            },
                                            optionalLine: {
                                              name: "optionalLine",
                                              value: this.state.person.optionalLine.value,
                                              errorMessage: this.state.person.optionalLine.errorMessage,
                                              onChange: this.handleChangeFor("optionalLine")
                                            },
                                            city: {
                                              name: "city",
                                              value: this.state.person.city.value,
                                              errorMessage: this.state.person.city.errorMessage,
                                              onChange: this.handleChangeFor("city")
                                            },
                                            state: {
                                              name: "state",
                                              value: this.state.person.state.value,
                                              errorMessage: this.state.person.state.errorMessage,
                                              onChange: this.handleChangeFor("state")
                                            },
                                            zip: {
                                              name: "zip",
                                              value: this.state.person.zip.value,
                                              errorMessage: this.state.person.zip.errorMessage,
                                              onChange: this.handleChangeFor("zip")
                                            }
                                          }} />
                          </div>
                        </div>

                        {
                          this.showWorkersCompQuestions() &&
                            <div>
                              <hr />

                              <div className="row">
                                <div className="col-xs-12">
                                  <h4>Employment Info</h4>
                                </div>

                                <div className="col-xs-6">
                                  <SelectWithAsyncOptionsFromRails loadPath={ this.props.jobClassificationOptionsPath }
                                                                   label="Job Classification..."
                                                                   value={ this.state.person.jobClassificationCode.value }
                                                                   errorMessage={ this.state.person.jobClassificationCode.errorMessage }
                                                                   onChange={ (value, label) => {
                                                                     this.handleChangeFor("jobClassificationCode")(value)
                                                                     this.handleChangeFor("jobClassificationDescription")(label)
                                                                   }}
                                                                   tabIndex="9" />
                                </div>

                                <div className="col-xs-6">
                                  <TextField name="jobTitle"
                                             onChange={ this.handleChangeFor("jobTitle") }
                                             label="Job Title"
                                             value={ this.state.person.jobTitle.value }
                                             errorMessage={ this.state.person.jobTitle.errorMessage }
                                             required={ this.isWorkersCompClaim() }
                                             tabIndex="10" />
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-xs-6">
                                  <p>Employee SSN</p>

                                  <SsnField name="socialSecurityNumber"
                                            onChange={ this.handleChangeFor("socialSecurityNumber") }
                                            label="*****1234"
                                            value={ this.state.person.socialSecurityNumber.value }
                                            errorMessage={ this.state.person.socialSecurityNumber.errorMessage }
                                            required={ !this.state.person.socialSecurityNumber.value && this.isWorkersCompClaim() ? { warn: true } : false }
                                            tabIndex="11" />
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-xs-6">
                                  <SelectField name="employmentType"
                                               onChange={ this.handleChangeFor("employmentType") }
                                               label="Employment Type"
                                               placeholder="Select..."
                                               value={ this.state.person.employmentType.value }
                                               errorMessage={ this.state.person.employmentType.errorMessage }
                                               required={ !this.state.person.employmentType.value && this.isWorkersCompClaim() ? { warn: true } : false }
                                               tabIndex="12"
                                               options={ this.props.employmentTypeOptions } />
                                </div>

                                <div className="col-xs-6">
                                  <DateAndTimeField name="hiredDate"
                                                    includeTime={ false }
                                                    onChange={ this.handleChangeFor("hiredDate") }
                                                    label={{ date: "Date Hired" }}
                                                    value={ this.state.person.hiredDate.value }
                                                    errorMessage={ this.state.person.hiredDate.errorMessage }
                                                    tabIndex="13" />
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-xs-6">
                                  <DollarValueField name="totalEarningsInPeriod"
                                                    onChange={ this.handleChangeFor("totalEarningsInPeriod") }
                                                    label="Gross Earnings (Optional)"
                                                    value={ this.state.person.totalEarningsInPeriod.value }
                                                    errorMessage={ this.state.person.totalEarningsInPeriod.errorMessage }
                                                    tabIndex="14" />
                                </div>

                                <div className="col-xs-6">
                                  <SelectField name="earningPeriod"
                                               onChange={ this.handleChangeFor("earningPeriod") }
                                               label="Earning Period"
                                               placeholder="Select..."
                                               value={ this.state.person.earningPeriod.value }
                                               errorMessage={ this.state.person.earningPeriod.errorMessage }
                                               tabIndex="15"
                                               options={ this.props.earningPeriodOptions } />
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-xs-6">
                                  <SelectField name="hoursWorkedPerDay"
                                               onChange={ this.handleChangeFor("hoursWorkedPerDay") }
                                               label="Hours worked per day"
                                               placeholder="Select..."
                                               value={ this.state.person.hoursWorkedPerDay.value }
                                               errorMessage={ this.state.person.hoursWorkedPerDay.errorMessage }
                                               tabIndex="15"
                                               options={
                                                 [...Array(24).keys()].map((n) => ({ displayText: (n + 1).toString(), value: n + 1 }))
                                               } />
                                </div>

                                <div className="col-xs-6">
                                  <SelectField name="daysWorkedPerWeek"
                                               onChange={ this.handleChangeFor("daysWorkedPerWeek") }
                                               label="Days worked per week"
                                               placeholder="Select..."
                                               value={ this.state.person.daysWorkedPerWeek.value }
                                               errorMessage={ this.state.person.daysWorkedPerWeek.errorMessage }
                                               tabIndex="15"
                                               options={
                                                 [...Array(7).keys()].map((n) => ({ displayText: (n + 1).toString(), value: n + 1 }))
                                               } />
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-xs-12">
                                  <p>{ 'Who supervises this individual? '}</p>
                                </div>

                                <SameAsCheckbox fields={[
                                                  {
                                                    source: this.props.firstReportedTo.name,
                                                    target: this.state.person.supervisorName.value,
                                                    handleChange: this.handleChangeFor("supervisorName")
                                                  },
                                                  {
                                                    source: this.props.firstReportedTo.phone,
                                                    target: this.state.person.supervisorPhone.value,
                                                    handleChange: this.handleChangeFor("supervisorPhone")
                                                  },
                                                  {
                                                    source: this.props.firstReportedTo.email,
                                                    target: this.state.person.supervisorEmail.value,
                                                    handleChange: this.handleChangeFor("supervisorEmail")
                                                  }
                                                ]}
                                                label="Same as reported to?"
                                                wrapperClass="col-xs-12" />

                                <div className="col-xs-6">
                                  <TextField name="supervisorName"
                                             onChange={ this.handleChangeFor("supervisorName") }
                                             label="Supervisor's Name"
                                             value={ this.state.person.supervisorName.value }
                                             errorMessage={ this.state.person.supervisorName.errorMessage }
                                             required={ this.isWorkersCompClaim() }
                                             shouldFloatLabelOnUpdate={ true }
                                             tabIndex="16" />
                                </div>

                                <div className="col-xs-6">
                                  <PhoneNumberField name="supervisorPhone"
                                                    onChange={ this.handleChangeFor("supervisorPhone") }
                                                    label="Supervisor's Phone"
                                                    value={ this.state.person.supervisorPhone.value }
                                                    errorMessage={ this.state.person.supervisorPhone.errorMessage }
                                                    required={ this.isWorkersCompClaim() }
                                                    shouldFloatLabelOnUpdate={ true }
                                                    tabIndex="17" />
                                </div>

                                <div className="col-xs-6">
                                  <TextField name="supervisorEmail"
                                             onChange={ this.handleChangeFor("supervisorEmail") }
                                             label="Supervisor's Email"
                                             value={ this.state.person.supervisorEmail.value }
                                             errorMessage={ this.state.person.supervisorEmail.errorMessage }
                                             shouldFloatLabelOnUpdate={ true }
                                             tabIndex="17" />
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-xs-12">
                                  <p>Date Disability Began:</p>

                                  <DateAndTimeField name="dateDisabilityBegan"
                                                    includeTime={ false }
                                                    onChange={ this.handleChangeFor("dateDisabilityBegan") }
                                                    label={{ date: "Date Disability Began" }}
                                                    value={ this.state.person.dateDisabilityBegan.value }
                                                    errorMessage={ this.state.person.dateDisabilityBegan.errorMessage }
                                                    tabIndex="18" />

                                  <RadioField name="wagesPaidOnDateOfInjury"
                                              onChange={ this.handleChangeFor("wagesPaidOnDateOfInjury") }
                                              label="Were wages paid on the date of injury?"
                                              value={ this.state.person.wagesPaidOnDateOfInjury.value }
                                              errorMessage={ this.state.person.wagesPaidOnDateOfInjury.errorMessage }
                                              required={ true }
                                              options={[
                                                { displayText: 'Yes', value: true },
                                                { displayText: 'No', value: false }
                                              ]} />

                                  <p>{ 'Has the individual returned to work?' }</p>

                                  <SelectField name="returnType"
                                               onChange={ this.handleChangeFor("returnType") }
                                               label="Return Type"
                                               placeholder="Select..."
                                               value={ this.state.person.returnType.value }
                                               errorMessage={ this.state.person.returnType.errorMessage }
                                               required={ this.isWorkersCompClaim() }
                                               tabIndex="20"
                                               options={ this.props.returnTypeOptions } />

                                  {
                                    this.state.person.returnType.value && (
                                      this.state.person.returnType.value === 'returned_as_full_time_with_restrictions' ||
                                      this.state.person.returnType.value === 'returned_as_part_time_with_restrictions') &&
                                        <div>
                                          <p>{ 'Please describe the restrictions.' }</p>

                                          <TextField name="returnTypeDetails"
                                                     type="textarea"
                                                     onChange={ this.handleChangeFor("returnTypeDetails") }
                                                     label="Type..."
                                                     value={ this.state.person.returnTypeDetails.value }
                                                     errorMessage={ this.state.person.returnTypeDetails.errorMessage }
                                                     tabIndex="20" />
                                        </div>
                                  }

                                  <p>{ 'Actual/Anticipated Return to Work Date' }</p>

                                  <DateAndTimeField name="returnToWorkDate"
                                                    includeTime={ false }
                                                    onChange={ this.handleChangeFor("returnToWorkDate") }
                                                    label={{ date: "Return to Work Date" }}
                                                    value={ this.state.person.returnToWorkDate.value }
                                                    errorMessage={ this.state.person.returnToWorkDate.errorMessage }
                                                    required={ this.state.person.returnType.value !== 'not_yet_returned' }
                                                    tabIndex="21" />
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-xs-12">
                                  <RadioField name="modifiedDutyAvailable"
                                              onChange={ this.handleChangeFor("modifiedDutyAvailable") }
                                              label="Modified duty available?"
                                              value={ this.state.person.modifiedDutyAvailable.value }
                                              errorMessage={ this.state.person.modifiedDutyAvailable.errorMessage }
                                              required={ this.state.person.returnType.value === 'not_yet_returned' }
                                              tabIndex="22"
                                              options={[
                                                { displayText: 'Yes', value: true },
                                                { displayText: 'No', value: false }
                                              ]} />
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-xs-12">
                                  <p>Were Safeguards or Safety Equipment <strong>provided</strong>{'?'}</p>
                                </div>

                                <div className="col-xs-6">
                                  <SelectField name="safetyEquipmentProvided"
                                               className="form-control-state"
                                               onChange={ this.handleChangeFor("safetyEquipmentProvided") }
                                               label="Were Safeguards Provided?"
                                               placeholder="Select..."
                                               value={ this.state.person.safetyEquipmentProvided.value }
                                               errorMessage={ this.state.person.safetyEquipmentProvided.errorMessage }
                                               tabIndex="23"
                                               options={[
                                                 { displayText: 'Yes', value: "safety_equipment_was_provided" },
                                                 { displayText: 'No',  value: "safety_equipment_was_not_provided" },
                                                 { displayText: 'N/A', value: "safety_equipment_provided_not_applicable"}
                                               ]} />
                                </div>

                                {
                                  this.state.person.safetyEquipmentProvided.value === 'safety_equipment_was_not_provided' &&
                                    <div className="col-xs-6">
                                      <TextField type="textarea"
                                                 name="safetyEquipmentProvidedDetails"
                                                 onChange={ this.handleChangeFor("safetyEquipmentProvidedDetails") }
                                                 label="Why not?"
                                                 value={ this.state.person.safetyEquipmentProvidedDetails.value }
                                                 errorMessage={ this.state.person.safetyEquipmentProvidedDetails.errorMessage }
                                                 tabIndex="23" />
                                  </div>
                                }
                              </div>

                              <div className="row">
                                <div className="col-xs-12">
                                  <p>Were Safeguards or Safety Equipment <strong>utilized</strong>{'?'}</p>
                                </div>

                                <div className="col-xs-6">
                                  <SelectField name="safetyEquipmentUtilized"
                                               className="form-control-state"
                                               onChange={ this.handleChangeFor("safetyEquipmentUtilized") }
                                               label="Were Safeguards utilized?"
                                               placeholder="Select..."
                                               value={ this.state.person.safetyEquipmentUtilized.value }
                                               errorMessage={ this.state.person.safetyEquipmentUtilized.errorMessage }
                                               tabIndex="23"
                                               options={[
                                                 { displayText: 'Yes', value: "safety_equipment_was_utilized" },
                                                 { displayText: 'No',  value: "safety_equipment_was_not_utilized" },
                                                 { displayText: 'N/A', value: "safety_equipment_utilized_not_applicable"}
                                               ]} />
                                </div>

                                {
                                  this.state.person.safetyEquipmentUtilized.value === 'safety_equipment_was_not_utilized' &&
                                    <div className="col-xs-6">
                                      <TextField type="textarea"
                                                 name="safetyEquipmentUtilizedDetails"
                                                 onChange={ this.handleChangeFor("safetyEquipmentUtilizedDetails") }
                                                 label="Why not?"
                                                 value={ this.state.person.safetyEquipmentUtilizedDetails.value }
                                                 errorMessage={ this.state.person.safetyEquipmentUtilizedDetails.errorMessage }
                                                 tabIndex="23" />
                                  </div>
                                }
                              </div>
                            </div>
                        }
                      </div>
                  }

                  <div className="row">
                    <div className="col-xs-12">
                      <h4>Primary Injury</h4>

                      <p>
                        Add the Primary Injury sustained by this individual during this incident. If the
                        individual has suffered multiple or catastrophic injuries, select these options and
                        provide more details in the text area below.
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-12">
                      <p>Select the part of the body in which the injury was sustained by the individual.</p>

                      <SelectWithAsyncOptionsFromRails loadPath={ this.props.injuriesOptionsPath }
                                                       label="Part of the body..."
                                                       value={ this.state.person.injuredAreaOfTheBodyCode.value }
                                                       errorMessage={ this.state.person.injuredAreaOfTheBodyCode.errorMessage }
                                                       onChange={ (value, label) => {
                                                         this.handleChangeFor("injuredAreaOfTheBodyCode")(value)
                                                         this.handleChangeFor("injuredAreaOfTheBodyDescription")(label)
                                                       }}
                                                       tabIndex="24" />

                      <p>Select the type of injury the individual sustained as their Primary Injury.</p>

                      <SelectWithAsyncOptionsFromRails loadPath={
                                                         (() => {
                                                           if (this.isWorkersCompClaim()) {
                                                             return this.props.naturesOfInjuryWorkersCompOptionsPath
                                                           }
                                                           return this.props.naturesOfInjuryLiabilityBodilyInjuryOptionsPath
                                                         })()
                                                       }
                                                       label="Type of injury..."
                                                       value={ this.state.person.natureOfInjuryCode.value }
                                                       errorMessage={ this.state.person.natureOfInjuryCode.errorMessage }
                                                       onChange={ (value, label) => {
                                                         this.handleChangeFor("natureOfInjuryCode")(value)
                                                         this.handleChangeFor("natureOfInjuryDescription")(label)
                                                       }}
                                                       shouldAggressivelyReload={ true }
                                                       tabIndex="25" />

                      <p>Provide a detailed description of the injury, illness, or other harm, including all body parts affected.</p>

                      <TextField name="additionalInjuryInformation"
                                 type="textarea"
                                 onChange={ this.handleChangeFor("additionalInjuryInformation") }
                                 label="Type..."
                                 value={ this.state.person.additionalInjuryInformation.value }
                                 errorMessage={ this.state.person.additionalInjuryInformation.errorMessage }
                                 required={ !this.state.person.additionalInjuryInformation.value ? { warn: true } : false }
                                 tabIndex="26"
                                 warn={ (value) => ( value && `Character limit: ${value.length}/255` ) } />

                      {
                        this.state.person.type.value === "InjuredPerson::ThirdParty" &&
                          <div>
                            <p>What's this person's relationship to the incident?</p>

                            <SelectField name="personType"
                                         onChange={ this.handleChangeFor("personType") }
                                         label="Relationship to the incident"
                                         placeholder="Select..."
                                         value={ this.state.person.personType.value }
                                         errorMessage={ this.state.person.personType.errorMessage }
                                         tabIndex="26"
                                         options={ this.props.personTypeOptions } />
                          </div>
                      }
                    </div>
                  </div>

                  {
                    this.showWorkersCompQuestions() &&
                      <div>
                        <div className="row">
                          <div className="col-xs-12">
                            <hr />
                            <h4>Activity Details</h4>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-12">
                            <p>{ 'When did the employee start work on the day of injury?' }</p>

                            <DateAndTimeField name="beganWorkAt"
                                              includeDate={ false }
                                              onChange={ this.handleChangeFor("beganWorkAt") }
                                              label={{ time: "Start Time on Day of Injury" }}
                                              value={ this.state.person.beganWorkAt.value }
                                              errorMessage={ this.state.person.beganWorkAt.errorMessage }
                                              tabIndex="27" />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-12">
                            <p>{ 'Where did the injury occur? (e.g. Loading dock north end)' }</p>

                            <TextField name= "descriptionOfWhereInjuryOccurred"
                                       onChange={ this.handleChangeFor("descriptionOfWhereInjuryOccurred") }
                                       label="Injury Location description"
                                       value={ this.state.person.descriptionOfWhereInjuryOccurred.value }
                                       errorMessage={ this.state.person.descriptionOfWhereInjuryOccurred.errorMessage }
                                       tabIndex="28" />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-12">
                            <p>{ 'What was this individual doing prior to the injury?' }</p>

                            <TextField name="descriptionOfActivityAtTimeOfIncident"
                                       type="textarea"
                                       onChange={ this.handleChangeFor("descriptionOfActivityAtTimeOfIncident") }
                                       label="Type..."
                                       value={ this.state.person.descriptionOfActivityAtTimeOfIncident.value }
                                       errorMessage={ this.state.person.descriptionOfActivityAtTimeOfIncident.errorMessage }
                                       tabIndex="29" />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-12">
                            <p>{ 'What substance or object caused this injury or illness?' }</p>

                            <TextField name="substanceOrObjectCausingInjury"
                                       onChange={ this.handleChangeFor("substanceOrObjectCausingInjury") }
                                       label="Substance or Object"
                                       value={ this.state.person.substanceOrObjectCausingInjury.value }
                                       errorMessage={ this.state.person.substanceOrObjectCausingInjury.errorMessage }
                                       tabIndex="30" />
                          </div>
                        </div>
                      </div>
                  }

                  <div className="row">
                    <div className="col-xs-12">
                      <hr />
                      <h4>Treatment</h4>
                    </div>

                    {
                      this.isWorkersCompClaim() &&
                        <div className="col-xs-12">
                          <RadioField name="panelOfPhysiciansProvided"
                                      onChange={ this.handleChangeFor("panelOfPhysiciansProvided") }
                                      label="Was a Panel of Physicians provided?"
                                      value={ this.state.person.panelOfPhysiciansProvided.value }
                                      errorMessage={ this.state.person.panelOfPhysiciansProvided.errorMessage }
                                      tabIndex="31"
                                      options={[
                                        { displayText: 'Yes', value: true },
                                        { displayText: 'No', value: false }
                                      ]} />
                        </div>
                    }

                    <div className="col-xs-6">
                      <p>{ 'Initial Treatment?' }</p>

                      <SelectField name="initialTreatment"
                                   onChange={ this.handleChangeFor("initialTreatment") }
                                   label="Initial Treatment"
                                   placeholder="Initial Treatment"
                                   value={ this.state.person.initialTreatment.value }
                                   errorMessage={ this.state.person.initialTreatment.errorMessage }
                                   required={ this.isWorkersCompClaim() }
                                   tabIndex="32"
                                   options={ this.props.initialTreatmentOptions } />
                    </div>
                  </div>

                  {
                    this.state.person.initialTreatment.value &&
                    this.state.person.initialTreatment.value !== "no_treatment" &&
                    this.state.person.initialTreatment.value !== "minor_first_aid" &&
                      <div>
                        <div className="row">
                          <div className="col-xs-12">
                            <p>Please indicate who provided treatment and at what facility, if known.</p>
                          </div>

                          <div className="col-xs-6">
                            <TextField name="doctorFirstName"
                                       onChange={ this.handleChangeFor("doctorFirstName") }
                                       label="Doctor First Name"
                                       value={ this.state.person.doctorFirstName.value }
                                       errorMessage={ this.state.person.doctorFirstName.errorMessage }
                                       tabIndex="33" />
                          </div>

                          <div className="col-xs-6">
                            <TextField name="doctorLastName"
                                       onChange={ this.handleChangeFor("doctorLastName") }
                                       label="Doctor Last Name"
                                       value={ this.state.person.doctorLastName.value }
                                       errorMessage={ this.state.person.doctorLastName.errorMessage }
                                       tabIndex="34" />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-12">
                            <TextField name="nameOfMedicalFacility"
                                       onChange={ this.handleChangeFor("nameOfMedicalFacility") }
                                       label="Medical Facility"
                                       value={ this.state.person.nameOfMedicalFacility.value }
                                       errorMessage={ this.state.person.nameOfMedicalFacility.errorMessage }
                                       tabIndex="35" />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-12">
                            <AddressField tabIndex="36"
                                          fields={{
                                            streetAddress: {
                                              name: "medicalFacilityStreetAddress",
                                              value: this.state.person.medicalFacilityStreetAddress.value,
                                              errorMessage: this.state.person.medicalFacilityStreetAddress.errorMessage,
                                              onChange: this.handleChangeFor("medicalFacilityStreetAddress")
                                            },
                                            optionalLine: {
                                              name: "medicalFacilityOptionalLine",
                                              value: this.state.person.medicalFacilityOptionalLine.value,
                                              errorMessage: this.state.person.medicalFacilityOptionalLine.errorMessage,
                                              onChange: this.handleChangeFor("medicalFacilityOptionalLine")
                                            },
                                            city: {
                                              name: "medicalFacilityCity",
                                              value: this.state.person.medicalFacilityCity.value,
                                              errorMessage: this.state.person.medicalFacilityCity.errorMessage,
                                              onChange: this.handleChangeFor("medicalFacilityCity")
                                            },
                                            state: {
                                              name: "medicalFacilityState",
                                              value: this.state.person.medicalFacilityState.value,
                                              errorMessage: this.state.person.medicalFacilityState.errorMessage,
                                              onChange: this.handleChangeFor("medicalFacilityState")
                                            },
                                            zip: {
                                              name: "medicalFacilityZip",
                                              value: this.state.person.medicalFacilityZip.value,
                                              errorMessage: this.state.person.medicalFacilityZip.errorMessage,
                                              onChange: this.handleChangeFor("medicalFacilityZip")
                                            }
                                          }} />
                          </div>

                          <div className="col-xs-6">
                            <PhoneNumberField name="medicalFacilityPhone"
                                              onChange={ this.handleChangeFor("medicalFacilityPhone") }
                                              label="Medical Facility's Phone"
                                              value={ this.state.person.medicalFacilityPhone.value }
                                              errorMessage={ this.state.person.medicalFacilityPhone.errorMessage }
                                              tabIndex="37" />
                          </div>
                        </div>
                      </div>
                  }

                  <div className="row">
                    <div className="col-xs-12">
                      <p>{ 'Who is this individual\'s health insurer?' }</p>

                      <TextField name="insuranceProviderName"
                                 onChange={ this.handleChangeFor("insuranceProviderName") }
                                 label="Health Insurer"
                                 value={ this.state.person.insuranceProviderName.value }
                                 errorMessage={ this.state.person.insuranceProviderName.errorMessage }
                                 tabIndex="38" />
                    </div>
                  </div>

                  {
                    /* we want to render these fields if this person is not an employee OR it's an employee, but
                       not a worksers comp claim. these fields are necessary for helping identify the line of
                       business */
                    (!(this.isAnEmployee() && this.isWorkersCompClaim())) &&
                      <div>
                        <div className="row">
                          <div className="col-xs-12">
                            <p>{ 'Does this involve an employment discrimination issue?' }</p>

                            <ToggleSwitchField name="sufferedEmploymentDiscrimination"
                                               onChange={ this.handleChangeFor("sufferedEmploymentDiscrimination") }
                                               value={ this.state.person.sufferedEmploymentDiscrimination.value }
                                               errorMessage={ this.state.person.sufferedEmploymentDiscrimination.errorMessage } />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-12">
                            <p>{ 'Does this involve a civil rights violation?' }</p>

                            <ToggleSwitchField name="sufferedACivilRightsViolation"
                                               onChange={ this.handleChangeFor("sufferedACivilRightsViolation") }
                                               value={ this.state.person.sufferedACivilRightsViolation.value }
                                               errorMessage={ this.state.person.sufferedACivilRightsViolation.errorMessage } />
                          </div>
                        </div>

                        {
                          /* we only need to ask this of non-employees */
                          !this.isAnEmployee() &&
                            <div className="row">
                              <div className="col-xs-12">
                                <p>{ 'Does this involve a student issue other than physical injury?' }</p>

                                <ToggleSwitchField name="involvedAStudentIssue"
                                                   onChange={ this.handleChangeFor("involvedAStudentIssue") }
                                                   value={ this.state.person.involvedAStudentIssue.value }
                                                   errorMessage={ this.state.person.involvedAStudentIssue.errorMessage } />
                              </div>
                            </div>
                        }
                      </div>
                  }

                  <div className="row">
                    <div className="col-xs-12">
                      <p>{ 'Any additional comments to report at this time?' }</p>

                      <TextField name="additionalComments"
                                 type="textarea"
                                 onChange={ this.handleChangeFor("additionalComments") }
                                 label="Type..."
                                 value={ this.state.person.additionalComments.value }
                                 errorMessage={ this.state.person.additionalComments.errorMessage }
                                 tabIndex="39"
                                 warn={ (value) => ( value && `Character limit: ${value.length}/255` ) } />
                    </div>
                  </div>
                </div>
            }
          </PanelBody>

          <PanelControls>
            <Prompt when={ this.anyChangesMade("person", this.props.person) }
                    message="Are you sure you want to move away? Any unsaved changes
                             made to this section will be lost." />

            { this.renderControlGroup() }
          </PanelControls>
        </PanelRightColumn>
      </Panel>
    )
  }
}

PersonsForm = connect(
  (state, props) => ({
    firstReportedTo: {
      name:  state.incidentReport.firstReportedToName,
      phone: state.incidentReport.firstReportedToPrimaryPhone,
      email: state.incidentReport.firstReportedToEmail
    },

    updateSucceeded: state.wizard.updateSucceeded,
    updating:        state.wizard.updating,

    maritalStatusOptions:    state.rails.enumOptionsForSelect["InjuredPerson::Base"].maritalStatus,
    employmentTypeOptions:   state.rails.enumOptionsForSelect["InjuredPerson::Employee"].employmentType,
    earningPeriodOptions:    state.rails.enumOptionsForSelect["InjuredPerson::Employee"].earningPeriod,
    returnTypeOptions:       state.rails.enumOptionsForSelect["InjuredPerson::Employee"].returnType,
    initialTreatmentOptions: state.rails.enumOptionsForSelect["InjuredPerson::Base"].initialTreatment,
    personTypeOptions:       state.rails.enumOptionsForSelect["InjuredPerson::ThirdParty"].personType,

    jobClassificationOptionsPath:                    state.rails.asyncHelpers.jobClassificationOptions,
    injuriesOptionsPath:                             state.rails.asyncHelpers.injuriesOptions,
    naturesOfInjuryWorkersCompOptionsPath:           state.rails.asyncHelpers.naturesOfInjuryWorkersCompOptions,
    naturesOfInjuryLiabilityBodilyInjuryOptionsPath: state.rails.asyncHelpers.naturesOfInjuryLiabilityBodilyInjuryOptions,

    memberUsesNT24: state.rails.memberUsesNT24,
    apiEndpoint:    apiEndpoint(state.rails, "incident_report")
  }),
  null,
  ({ apiEndpoint, ...stateProps }, { dispatch }, props) => ({
    ...stateProps,
    ...props,

    asyncUpdatePersons: (data) => {
      return asyncUpdatePersons(apiEndpoint.path, data)(dispatch)
    },

    resetWizardState: () => { dispatch(resetWizardState()) },
    resetSubmissionErrors: (id) => { dispatch(resetErrorsForPerson(id)) }
  })
)(PersonsForm);

export default PersonsForm
