import React from "react";
import { BrowserRouter as Router, Route, Prompt } from "react-router-dom";

import RailsApi from "shared/utils/rails_api.js";
import BaseForm from "shared/components/base_form.jsx";
import {
  FormField,
  TextField,
  DateAndTimeField,
  DollarValueField,
} from "shared/components/fields";

import {
  requiredValidator,
  lengthValidator,
  dollarValueValidator,
} from "shared/utils/validators";

import { apiEndpoint } from "./ducks/rails.js";
import { Panel, PanelRightColumn, PanelBody, PanelControls } from "./panel";
import MemberInfo from "./member_info.jsx";

class App extends BaseForm {
  constructor(props) {
    super(props);

    this.state = {
      suntrustPayment: {
        invoiceReferenceNumber: new FormField("", [
          requiredValidator(),
          lengthValidator(50),
        ]),
        dueDate: new FormField("", [requiredValidator()]),

        // suntrust does validate the size of the number: their documentation just says max size: 16,2
        // in absence of knowing exactly what that means, i think we can just assume no one owes
        // a billion dollars
        amount: new FormField("", [
          requiredValidator(),
          dollarValueValidator({ max: 1000000000 }),
        ]),
      },

      updating: false,
      flash: null,
    };
  }

  handleChangeFor = (name) => this.handleFieldChange("suntrustPayment", name);

  prepareData = () => {
    let copy = { suntrustPayment: { ...this.state.suntrustPayment } };

    // turn FormField objects in to just the raw value
    Object.keys(copy.suntrustPayment).map((key) => {
      copy.suntrustPayment[key] = copy.suntrustPayment[key].value;
    });

    return copy;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    event.target.blur();

    if ("track" in woopra) {
      woopra.track("rails-link", {
        "link-url": event.target.href,
        "link-title": event.target.innerText,
      });
    }

    if ('track' in woopra) {
      woopra.track("rails-link", {"link-url": event.target.href, "link-title": event.target.innerText});
    }
    console.log('woopra.track', event.target.href, event.target.innerText);

    this.setState({ updating: true }, () => {
      if (this.validateFields("suntrustPayment")) {
        const { path, method } = apiEndpoint(
          this.props.meta,
          "suntrust_payment"
        );
        const data = this.prepareData();

        RailsApi.post(path, { body: data })
          .then((json) => {
            this.manuallyCreateAndSubmitNormalBrowserForm(data, path, method);
          })
          .catch((json) => {
            this.setState({
              flash: { type: "danger", ...json.error },
              updating: false,
            });
          });
      } else {
        this.setState({ updating: false });
      }
    });
  };

  // this is a hack to just let the browser submit a normal form and get html back. this works
  // with our current saml setup of returning an empty layout with a form that gets submitted onload
  manuallyCreateAndSubmitNormalBrowserForm = (data, path, method) => {
    let form = document.createElement("form");
    form.method = method;
    form.action = path;
    form.style.display = "none";

    let invoiceReferenceNumber = document.createElement("input");
    invoiceReferenceNumber.name = "suntrustPayment[invoiceReferenceNumber]";
    invoiceReferenceNumber.value = data.suntrustPayment.invoiceReferenceNumber;
    form.appendChild(invoiceReferenceNumber);

    let dueDate = document.createElement("input");
    dueDate.name = "suntrustPayment[dueDate]";
    dueDate.value = data.suntrustPayment.dueDate;
    form.appendChild(dueDate);

    let amount = document.createElement("input");
    amount.name = "suntrustPayment[amount]";
    amount.value = data.suntrustPayment.amount;
    form.appendChild(amount);

    let authenticity_token = document.createElement("input");
    authenticity_token.name = "authenticity_token";
    authenticity_token.value = document.querySelector(
      "meta[name=csrf-token]"
    ).content;
    form.appendChild(authenticity_token);

    document.body.appendChild(form);
    form.submit();
  };

  render() {
    // figure out what the basename should be based on where rails told us we can save the form to
    const { path, method } = apiEndpoint(this.props.meta, "suntrust_payment");
    const basename = `${path}/${method === "POST" ? "new" : "edit"}`;

    return (
      <Router basename={basename}>
        <div className="panel panel-default panel-incident-form">
          <div className="panel-heading">
            <div className="row">
              <div className="col-xs-12 col-sm-8">
                <h2 className="panel-title">New Payment</h2>
              </div>

              <div className="col-xs-12 col-sm-4 text-right">
                <a
                  href="/"
                  onClick={(event) => {
                    if (
                      !confirm(
                        "Are you sure you want to move away? Any unsaved changes " +
                          "made to this section will be lost."
                      )
                    ) {
                      event.preventDefault();
                    }
                  }}
                >
                  <button className="cancel-btn btn btn-link">Cancel</button>
                </a>
              </div>
            </div>
          </div>

          {/* routing (there's only one) */}
          <Route
            exact
            path="/"
            render={() => (
              <Panel>
                <PanelRightColumn>
                  <PanelBody flash={this.state.flash}>
                    <MemberInfo
                      organizationName={this.props.meta.organization.name}
                      threeCharacterMemberNumber={
                        this.props.meta.organization.threeCharacterMemberNumber
                      }
                    />

                    <hr />

                    <div className="row">
                      <div className="col-xs-12">
                        <h4>Payment Details</h4>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-6">
                        <TextField
                          type="text"
                          name="invoiceReferenceNumber"
                          onChange={this.handleChangeFor(
                            "invoiceReferenceNumber"
                          )}
                          label="Invoice Reference Number"
                          value={
                            this.state.suntrustPayment.invoiceReferenceNumber
                              .value
                          }
                          errorMessage={
                            this.state.suntrustPayment.invoiceReferenceNumber
                              .errorMessage
                          }
                          required={true}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-12 col-sm-6">
                        <DateAndTimeField
                          name="dueDate"
                          includeTime={false}
                          onChange={this.handleChangeFor("dueDate")}
                          label={{ date: "Invoice Date" }}
                          value={this.state.suntrustPayment.dueDate.value}
                          errorMessage={
                            this.state.suntrustPayment.dueDate.errorMessage
                          }
                          required={true}
                        />
                      </div>

                      <div className="col-xs-12 col-sm-6">
                        <DollarValueField
                          name="amount"
                          onChange={this.handleChangeFor("amount")}
                          label="Payment Amount"
                          value={this.state.suntrustPayment.amount.value}
                          errorMessage={
                            this.state.suntrustPayment.amount.errorMessage
                          }
                          required={true}
                        />
                      </div>
                    </div>
                  </PanelBody>

                  <PanelControls>
                    <Prompt
                      when={this.anyChangesMade(
                        "suntrustPayment",
                        this.props.suntrustPayment
                      )}
                      message="Are you sure you want to move away? Any payment details will be lost."
                    />

                    <button
                      type="button"
                      onClick={this.handleSubmit}
                      className={`btn btn-success ${
                        this.state.updating ? "disabled" : ""
                      }`}
                    >
                      Submit
                    </button>
                  </PanelControls>
                </PanelRightColumn>
              </Panel>
            )}
          />
          {/* end routing */}
        </div>
      </Router>
    );
  }
}

export default App;
