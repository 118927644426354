import React from "react"

function decorateInput(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.floatLabel = this.floatLabel.bind(this);
    }

    floatLabel() {
      let label_floater = new App.LabelFloater({
        $label: $(this.label),
        $input: $(this.wrapped.inputElement()) }
      )

      label_floater.initialize();
    }

    componentDidUpdate() {
      // opt-in to floating the label on update so we only have to call this code on inputs
      // that are populated by dispatching a change action. otherwise, it'll happen on every
      // keypress, blur, click, etc. on every input 😳
      if (this.props.shouldFloatLabelOnUpdate) {
        this.floatLabel();
      }
    }

    componentDidMount() {
      this.floatLabel();
    }

    inputElement() {
      return this.wrapped.inputElement()
    }

    render() {
      const { label, name, isRequired, ...otherProps } = this.props

      return (
        <div>
          <label ref={ (_label) => { this.label = _label } } htmlFor={ name }>
            { label }{ isRequired && <span></span> }
          </label>

          <WrappedComponent ref={ (wrapped) => { this.wrapped = wrapped } }
            { ...otherProps } id={ name } name={ name }
          />
        </div>
      )
    }
  }
}

class Input extends React.Component {
  inputElement() {
    return this.input;
  }

  render() {
    return (
      <input ref={ (input) => { this.input = input } } { ...this.props } />
    )
  }
}
Input = decorateInput(Input);

class TextArea extends React.Component {
  inputElement() {
    return this.input;
  }

  render() {
    return (
      <textarea ref={ (input) => { this.input = input } } { ...this.props } />
    )
  }
}
TextArea = decorateInput(TextArea);

class Select extends React.Component {
  inputElement() {
    return this.input;
  }

  render() {
    const { children, ...otherProps } = this.props;

    return (
      <select ref={ (input) => { this.input = input } } { ...otherProps }>
        { children }
      </select>
    )
  }
}
Select = decorateInput(Select);

const FloatingLabelInputs = {
  Input: Input,
  TextArea: TextArea,
  Select: Select
}

export default FloatingLabelInputs
