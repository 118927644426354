import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import BootstrapModal from 'shared/components/move_off_of_eventually_bootstrap_modal.jsx'
import { surveyId } from 'apps/cyber/shared/ducks/survey.js'

import SurveySectionForm from './survey_section_form.jsx'
import ConfirmAndSaveModalFooter from 'shared/components/confirm_and_save_modal_footer.jsx'
import { submitSectionToRails } from '../../ducks/survey.js'
import { apiEndpoint } from '../../ducks/rails.js'

class SurveySectionFormContainer extends React.Component {
    constructor(props) {
        super(props)

        const { updatedAt, createdAt, ...sectionMinusTimestamps } = this.props.section
        this.state = {
            cyberSurveySection: {
                surveyId: this.props.surveyId,
                ...sectionMinusTimestamps
            },
            errorMessageFromSaveAttempt: ''
        }

        this.handleFormFieldChange = this.handleFormFieldChange.bind(this)
        this.handleSave = this.handleSave.bind(this)
    }

    handleFormFieldChange(event) {
        let name  = event.target.name
        let value = event.target.value

        this.setState((prevState) => {
            return {
                cyberSurveySection: { ...prevState.cyberSurveySection, [name]: value }
            }
        })
    }

    handleSave() {
        let { apiEndpoint: { path, method }, mode, dispatch, handleHide } = this.props
        path = path.replace(/:id/g, this.state.cyberSurveySection.id)
        submitSectionToRails(this.state.cyberSurveySection, path, method, mode, dispatch).
        then(() => { handleHide() }). // TODO - make this smoother, modal unmounts and hides, kinda backwards
            catch((json) => {
                this.setState({
                    errorMessageFromSaveAttempt: json.error || "Unable to save survey section"
                })
            })
    }

    render() {
        const title = this.props.mode == "create" ? "New Section" : `Edit ${this.props.section.header}`

        return (
            <BootstrapModal title={ title }
                            body={
                                <SurveySectionForm handleFormFieldChange={ this.handleFormFieldChange }
                                                   section={ this.state.cyberSurveySection } />
                            }
                            footer={
                                <ConfirmAndSaveModalFooter
                                    handleSave={ this.handleSave }
                                    errorMessageFromSaveAttempt={ this.state.errorMessageFromSaveAttempt }
                                    confirmBeforeSave={ this.props.surveyStatus === 'active' }
                                    confirmText="This will change the active cybersecurity assessment. Are you sure?"
                                />
                            }
                            handleHide={ this.props.handleHide }
            />
        )
    }
}

SurveySectionFormContainer.propTypes = {
    handleHide:  PropTypes.func.isRequired,
    section:     PropTypes.object.isRequired,

    surveyId:    PropTypes.number.isRequired,
    mode:        PropTypes.string.isRequired,
    apiEndpoint: PropTypes.object.isRequired
}

SurveySectionFormContainer = connect(
    (state, props) => {
        const mode = props.section.id ? "update" : "create"

        return {
            surveyStatus: state.survey.status,
            surveyId:     surveyId(state.rails),
            apiEndpoint:  apiEndpoint(state.rails, { resource: "surveySections", mode: mode }),
            mode:         mode
        }
    }
)(SurveySectionFormContainer)

export default SurveySectionFormContainer
