import React from 'react'
import AddOrImportQuestionPopup from './add_or_import_question_popup.jsx'

class AddOrImportQuestionButton extends React.Component {
  constructor(props) {
    super(props)

    this.handleAddOrImportClick = this.handleAddOrImportClick.bind(this)
    this.handleModalHide        = this.handleModalHide.bind(this)

    this.state = {
      addingOrImporting: false
    }
  }

  handleAddOrImportClick() {
    this.setState({ addingOrImporting: true })
  }

  handleModalHide() {
    this.setState({ addingOrImporting: false })
  }

  render() {
    return (
      <div>
        <a className="btn btn-muted-blue btn-lg btn-normalcase btn-align-left btn-block btn-with-icon plus-circle icon-right"
           onClick={ this.handleAddOrImportClick } >
          Add or Import a New Question
        </a>

        { this.state.addingOrImporting &&
           <AddOrImportQuestionPopup
              surveySectionId={ this.props.surveySectionId }
              coverageTypeId={ this.props.coverageTypeId }
              handleHide={ this.handleModalHide } />
        }
      </div>
    )
  }
}

export default AddOrImportQuestionButton
