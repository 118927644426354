import React from 'react'
import { connect } from 'react-redux'

import { getVehicle } from '../ducks/vehicles.js'

import VehiclesForm from './vehicles_form.jsx'

let VehiclesEdit = ({ vehicle }) => (
  <VehiclesForm vehicle={ vehicle } />
)

VehiclesEdit = connect(
  (state, props) => ({
    vehicle: getVehicle(state.vehicles, props.match.params.id)
  })
)(VehiclesEdit)
export default VehiclesEdit
