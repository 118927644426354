import React from 'react'
import PropTypes from 'prop-types';

import Card from 'shared/components/card.jsx'
import ControlButton from 'shared/components/control_button.jsx'
import SurveySectionFormContainer from './survey_section_form_container.jsx'

class AddSurveySectionButton extends React.Component {
  constructor(props) {
    super(props)

    this.state = { addingNewSection: false }

    this.handleAddNewSection = this.handleAddNewSection.bind(this)
    this.handleHide          = this.handleHide.bind(this)
  }

  handleAddNewSection(event) {
    this.setState({ addingNewSection: true })
  }

  handleHide() {
    this.setState({ addingNewSection: false })
  }

  render() {
    return (
      <div>
        <div style={ { cursor: "pointer" } } onClick={ this.handleAddNewSection }>
          <Card fill={ false }
                controls={ [ <ControlButton iconClass="glyphicon glyphicon-plus-sign" /> ] }
                title="Add New Form Section"
          />
        </div>

        {
          this.state.addingNewSection &&
            <SurveySectionFormContainer handleHide={ this.handleHide }
                                        section={ { coverageTypeId: this.props.coverageToAddSectionToId } }
            />
        }
      </div>
    )
  }
}

AddSurveySectionButton.propTypes = {
  // this'll be either the id of the FromVmlip::CoverageType record we want to add a section to,
  // or `null` if we're adding a section to the "General Guidelines" tab. mildly, confusing 😖
  coverageToAddSectionToId: PropTypes.number
}

export default AddSurveySectionButton
