import RailsApi from 'shared/utils/rails_api.js'

import { refreshMetaData } from './rails.js'
import { setErrorFlash, setSuccessFlash, updateSucceeded, updateFailed, updateStarted } from './wizard.js'

const LOAD   = "event_attendee_form/event_attendee/LOAD"
const UPDATE = "event_attendee_form/event_attendee/UPDATE"

export default function eventAttendee(state = {}, action) {
  switch (action.type) {
    case LOAD: {
      return { ...action.eventAttendee }
    }

    case UPDATE: {
      return { ...state, ...action.attributes }
    }

    default: {
      return state
    }
  }
}

export const loadEventAttendee = (eventAttendee) => {
  return { type: LOAD, eventAttendee: eventAttendee }
}

export const updateEventAttendee = (eventAttendee) => {
  const { ...attributes } = eventAttendee

  return { type: UPDATE, attributes: attributes }
}

export const asyncUpdateEventAttendee = (url, data) => (dispatch) => {
  dispatch(updateStarted())

  RailsApi.patch(url, { body: data }).
    then((json) => {
      dispatch({
        type: 'RAILS_UPDATE_SUCCEEDED',
        actions: [
          refreshMetaData({ ...json.meta }),
          updateEventAttendee(json.eventAttendee),
          setSuccessFlash('Attendee Updated'),
          updateSucceeded()
        ]
      })
    }).
    catch((json) => {
      dispatch({
        type: 'RAILS_UPDATE_FAILED',
        actions: [
          updateFailed(),
          setErrorFlash(json.error.message, { details: json.error.details })
        ]
      })
    })
}
