import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import { activeCoverage } from 'apps/cyber/shared/ducks/coverage_types.js'

import ImportQuestionTable from './import_question_table.jsx'

let AddOrImportQuestion = ({ onAddQuestionClick, coverageTypeId, coverageName, surveySectionId }) => (
    <div>
        <h4>Add a New Question</h4>
        <p>Create a new question to use in this coverage line.</p>
        <div className='text-right'>
            <button
                className="btn btn-sm btn-muted-blue"
                onClick={ onAddQuestionClick }
            >
                Add New Question
            </button>
        </div>
        <hr />
        <h4>Import a Question from { coverageName }</h4>
        <p>Select an existing question to import into this form.</p>

        <ImportQuestionTable coverageTypeId={ coverageTypeId } surveySectionId={ surveySectionId } />
    </div>
)

AddOrImportQuestion.propTypes = {
    onAddQuestionClick: PropTypes.func.isRequired,
    surveySectionId: PropTypes.number.isRequired,

    coverageTypeId: PropTypes.number
}

AddOrImportQuestion = connect(
    (state, props) => {
        const _activeCoverage = activeCoverage(state.coverageTypes, state.tab)

        return { coverageName: _activeCoverage.alias || _activeCoverage.name }
    }
)(AddOrImportQuestion)

export default AddOrImportQuestion
