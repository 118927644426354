import React from 'react'
import PropTypes from 'prop-types';

import {
  extractDateFromRailsDateTime, extractTimeFromRailsDateTime, constructRailsDateTime
} from 'shared/utils/rails_datetime_formatters.js'

class DateAndTimeField extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      // if we're not including a date with this picker component, and we don't have a value
      // to use, we can assume they're only interested in the time and just use today's date
      // as the date value peice
      dateValue: (!props.includeDate && !this.props.value) ?
                   extractDateFromRailsDateTime(new Date().toISOString())
                 :
                   (this.props.value && extractDateFromRailsDateTime(this.props.value)) || '',

      timeValue: (this.props.value && extractTimeFromRailsDateTime(this.props.value)) ||
                   this.props.defaultTimeTo ||
                   ''
    }
  }

  floatLabels = () => {
    [[this.dateLabel, this.dateInput], [this.timeLabel, this.timeInput]].map(([label, input]) => {
      if ( label && input ) {
        new App.LabelFloater({
          $label: $(label),
          $input: $(input)
        }).initialize();
      }
    })
  }

  componentDidMount() {
    // TODO - we're currently using two different datepicker libraries...that kinda sucks.
    $(this.dateInput).on('changeDate', (event) => {
      this.handleDateChange(event)
      this.floatLabels();
    })

    if (this.props.includeTime) {
      $(this.timeInput).on('dp.change', ({ date }) => {
        this.handleTimeChange({ target: { value: (date && date.format('hh:mm A')) || '' } })
        this.floatLabels();
      })
    }

    this.floatLabels();
  }

  handleChange = () => {
    let value = this.props.includeTime ?
                  constructRailsDateTime(this.state.dateValue, this.state.timeValue) :
                  constructRailsDateTime(this.state.dateValue, null);

    this.props.onChange(value)
  }

  handleDateChange = (event) => {
    let formatted = (event.target.value || "").replace(/[\D]/g, '').slice(0, 8);

    if (!formatted) {
      formatted = "";
    }
    else if (formatted.length <= 2) {
      formatted = formatted;
    }
    else if (formatted.length <= 4) {
      formatted = `${formatted.slice(0, 2)}/${formatted.slice(2)}`
    }
    else {
      formatted = `${formatted.slice(0, 2)}/${formatted.slice(2, 4)}/${formatted.slice(4, 8)}`
    }

    this.setState({ dateValue: formatted }, this.handleChange)
  }

  handleTimeChange = (event) => {
    let formatted = (event.target.value || "").replace(/(\s+|[^\dPpAaMm])/g, '').slice(0, 6);

    if (!formatted) {
      formatted = ""
    }
    else if (formatted.length <= 2) {
      formatted = formatted;
    }
    else if (formatted.length <= 4) {
      formatted = `${formatted.slice(0, 2)}:${formatted.slice(2)}`
    }
    else {
      formatted = `${formatted.slice(0, 2)}:${formatted.slice(2, 4)} ${formatted.slice(4).toUpperCase()}`
    }

    this.setState({ timeValue: formatted }, this.handleChange)
  }

  render() {
    const {
      name,
      required,
      includeDate,
      includeTime,
      errorMessage,
      label,
      value,
      instructions,
      tabIndex,
      style
    } = this.props

    let wrapperClass = ["form-group"].
      concat(includeTime && includeDate ? "pad-right" : "").
      concat(errorMessage ? "has-error" : "").
      concat(required && required.warn ? "is-required-but-warn-about-it" : "").
      concat(required && (required.warn === null || required.warn === undefined) ? "is-required" : "").
      filter((n) => n).
      join(' ')

    let inputClass = "form-control"

    return (
      <div className="form-inline datetime-group" style={style}>
        {
          includeDate &&
            <div className={ wrapperClass }>
              <div className="input-group">
                <span className="input-group-addon" data-behavior="datepicker-icon">
                  <i className="fa fa-calendar"></i>
                </span>

                <label ref={ (label) => { this.dateLabel = label } } htmlFor={ `${name}_date` }>
                  { label.date }
                  { required ? <span></span> : "" }
                </label>

                <input
                  type="text"
                  id={ `${name}_date` }
                  className={ inputClass }
                  placeholder="00/00/0000"
                  data-behavior="datepicker"
                  ref={ (input) => { this.dateInput = input; } }
                  value={ this.state.dateValue }
                  tabIndex={ tabIndex }
                  onChange={ this.handleDateChange }
                />

                { errorMessage && <span className="field-level-error">{ errorMessage }</span> }
              </div>

              {
                instructions &&
                  (typeof instructions == 'string') ? <p>{ instructions }</p> : instructions
              }
            </div>
        }

        {
          includeTime &&
            <div className={ wrapperClass }>
              <div className="input-group">
                <span className="input-group-addon" data-behavior="timepicker-icon">
                  <i className="fa fa-clock-o"></i>
                </span>

                <label ref={ (label) => { this.timeLabel = label } } htmlFor={ `${name}_time` }>
                  { label.time }
                  { required ? <span></span> : "" }
                </label>

                <input
                  type="text"
                  id={ `${name}_time` }
                  className={ inputClass }
                  placeholder="01:00 PM"
                  data-behavior="timepicker"
                  ref={ (input) => { this.timeInput = input; } }
                  value={ this.state.timeValue }
                  tabIndex={ tabIndex }
                  onChange={ this.handleTimeChange}
                />

                { errorMessage && <span className="field-level-error">{ errorMessage }</span> }
              </div>

              {
                instructions &&
                  (typeof instructions == 'string') ? <p>{ instructions }</p> : instructions
              }
          </div>
        }
      </div>
    )
  }
}

DateAndTimeField.defaultProps = {
  required:    false,
  includeDate: true,
  includeTime: true,
  shouldFloatLabelOnUpdate: true
}

DateAndTimeField.propTypes = {
  name:         PropTypes.string.isRequired,
  required:     PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  includeTime:  PropTypes.bool.isRequired,
  onChange:     PropTypes.func.isRequired,
  instructions: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),


  label: (props) => {
    if (props.includeDate && typeof props.label.date !== "string" ||
       (props.includeTime && typeof props.label.time !== "string")) {

      return new Error('DateAndTimeField label should be an object with a date and/or time key.');
    }
  },

  errorMessage:  PropTypes.string,
  value:         PropTypes.string,
  tabIndex:      PropTypes.string,
  defaultTimeTo: PropTypes.string,
  style:         PropTypes.object
}

export default DateAndTimeField
