import React from 'react'

class PanelSidebar extends React.Component {
  render() {
    return (
      <ul className={ `panel-body-left nav nav-stacked nav-sidebar ${this.props.mobileMenuOpen ? "-is-open" : ""}` }>
        <li><a href="/osha/locations" className="active">Locations / Departments</a></li>
      </ul>
    )
  }
}

export default PanelSidebar
