import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import BootstrapModal from 'shared/components/bootstrap_modal.jsx'

import AttachmentRow from './attachment_row.jsx'

class AttachmentsTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      confirmingDelete: false,
      deletingAttachment: null
    }
  }

  handleDeleteClicked = (attachment) => (event) => {
    this.setState({ confirmingDelete: true, deletingAttachment: attachment })
  }

  handleDelete = () => {
    this.props.handleDelete(this.state.deletingAttachment)
  }

  handleModalClosed = () => {
    this.setState({ confirmingDelete: false, deletingAttachment: null })
  }

  render() {
    const {
      attachments,
      handleCommentChange,
      handleRelatedToChange
    } = this.props

    return (
      <div>
        <table id="attachment-table" className="table table-striped">
          <thead>
            <tr>
              <th className="col-xs-4">File Preview</th>
              <th>File Comment</th>
              <th>Related To</th>
              <th className="col-xs-1"></th>
            </tr>
          </thead>

          <tbody>
            { Object.values(attachments).map((attachment, ix) => {
              return (
                <AttachmentRow
                  key={ attachment.id || attachment._tempId }
                  attachment={ attachment }
                  handleCommentChange={ handleCommentChange }
                  handleRelatedToChange={ handleRelatedToChange }
                  handleDeleteClicked={ this.handleDeleteClicked(attachment) } />
              )
            }) }

            { Object.values(attachments).length == 0 &&
                <tr>
                  <td colSpan='4'>
                    <em>No supporting documentation uploaded yet.</em>
                  </td>
                </tr>
            }
          </tbody>
        </table>

        { this.state.confirmingDelete &&
            <BootstrapModal title="Confirm Deletion"
              handleHide={ this.handleModalClosed }
              buttons={[
                { className: "btn btn-link", dismiss: true, name: "Cancel" },
                {
                  className: "btn btn-default btn-danger",
                  onClick: this.handleDelete,
                  name: "Delete"
                }
              ]}>

              <p>{ 'Are you sure you want to delete this supporting documentation?' }</p>
            </BootstrapModal>
        }
      </div>
    )
  }
}

AttachmentsTable.propTypes = {
  attachments: PropTypes.array,
  handleCommentChange: PropTypes.func,
  handleRelatedToChange: PropTypes.func,
  handleDelete: PropTypes.func
}

AttachmentsTable = connect(
  (state, props) => {
    return {
      attachments: props.attachments
    }
  }
)(AttachmentsTable)

export default AttachmentsTable
