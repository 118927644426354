import React from 'react'
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux'
import { DragSource, DropTarget } from 'react-dnd';

import Card from 'shared/components/card.jsx'
import ControlButton from 'shared/components/control_button.jsx'
import { associatedEntities } from 'apps/cyber/shared/ducks/lookups.js'

import EditQuestionContainer from './section_question/edit_question_container.jsx'
import DeleteSectionQuestionContainer from './section_question/delete_section_question_container.jsx'
import LockSectionQuestionButton from './section_question/lock_section_question_button.jsx'
import HideSectionQuestionButton from './section_question/hide_section_question_button.jsx'
import ActionWithConfirmation from './action_with_confirmation.jsx'

import {
    canDestroyQuestions,
    canEditQuestions
} from '../ducks/survey.js'

class EditableSectionQuestion extends React.Component {
    constructor(props) {
        super(props)

        this.state = { editing: false, confirmingDelete: false }

        this.handleEdit                    = this.updateState.bind(this, 'editing', true)
        this.handleHideEditQuestionModal   = this.updateState.bind(this, 'editing', false)
        this.handleDelete                  = this.updateState.bind(this, 'confirmingDelete', true)
        this.handleHideDeleteQuestionModal = this.handleHideDeleteQuestionModal.bind(this)
    }

    // See editable_survey_section.jsx
    componentDidMount() { this._isMounted = true; }
    componentWillUnmount() { this._isMounted = false; }

    updateState(attribute, value) {
        this.setState({ [attribute]: value })
    }

    handleHideDeleteQuestionModal() {
        if (this._isMounted) { this.setState({ confirmingDelete: false }) }
    }

    render() {
        let question = this.props.question

        // Build allowed controls array
        const controls = []

        if (this.props.canEditQuestions) {
            controls.push(<ControlButton handler={ this.handleEdit } iconClass="glyphicon glyphicon-pencil" />)
        }

        controls.push( <LockSectionQuestionButton cyberSectionQuestion={ this.props } /> )
        controls.push( <HideSectionQuestionButton cyberSectionQuestion={ this.props } /> )
        if (this.props.canDestroyQuestions) {

            controls.push(<ControlButton handler={ this.handleDelete } iconClass='glyphicon glyphicon-trash' />)
        }

        if(this.props.canEditQuestions) {
            if (this.props.connectDragSource) {
                controls.push(
                    this.props.connectDragSource(
                        <div><ControlButton iconClass="glyphicon glyphicon-menu-hamburger" /></div>
                    )
                )
            }
            else {
                controls.push(<ControlButton iconClass="glyphicon glyphicon-menu-hamburger" />)
            }
        }

        return (
            <div ref={ this.props.forwardedRef }>
                <Card
                    controls={ controls }
                    title={ `Question: ${question.id}` }
                    body={
                        <div>
                            <div className="row">
                                <div className="col-xs-6">
                                    <div className="question-property">
                                        <h5>Question Text</h5>
                                        <p dangerouslySetInnerHTML={{ __html: question.questionContent }} />
                                    </div>

                                    <div className="question-property">
                                        <h5>Tip Text</h5>
                                        <p dangerouslySetInnerHTML={{ __html: question.helpContent }} />
                                    </div>
                                </div>

                                <div className="col-xs-6">
                                    <div className="question-property">
                                        <h5>Associated Entities</h5>
                                        <ul>
                                            {
                                                this.props.associatedEntities.map((entity) => (
                                                    <li key={ entity.id }>{ entity.name }</li>
                                                ))
                                            }
                                        </ul>
                                    </div>

                                    <div className="row">
                                        <div className="col-xs-6">
                                            <h5>Date Required?</h5>
                                            <p>{ question.requireDateWhenAnsweringYes ? "Yes" : "No" }</p>
                                        </div>

                                        <div className="col-xs-6">
                                            <h5>Resets Anually?</h5>
                                            <p>{ question.resetAnswerAnnually ? "Yes" : "No" }</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                />

                { this.state.editing &&
                    <EditQuestionContainer
                        cyberSectionQuestion={ this.props }
                        surveySectionId={ this.props.surveySectionId }
                        handleHide={ this.handleHideEditQuestionModal } />
                }

                { this.state.confirmingDelete &&
                    <DeleteSectionQuestionContainer
                        handleHide={ this.handleHideDeleteQuestionModal }
                        cyberSectionQuestion={ this.props } />
                }
            </div>
        )
    }
}

EditableSectionQuestion = connect(
    (state, props) => ({
        canDestroyQuestions:   canDestroyQuestions(state.survey),
        canEditQuestions:      canEditQuestions(state.survey),
        associatedEntities:    associatedEntities(state.lookups, props.question.entityTypeIds)
    })
)(EditableSectionQuestion)

export default React.forwardRef((props, ref) => {
    return <EditableSectionQuestion { ...props } forwardedRef={ ref } />
});
