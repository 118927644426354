import React from 'react'
import { connect } from 'react-redux'
import { recordWithId } from 'shared/utils/rails_params.js'
import ProgressBar from 'shared/components/progress_bar.jsx'

import { TextField } from 'shared/components/fields'

import DeleteAttachmentButton from './delete_attachment_button.jsx'
import PreviewContainer from './preview_container.jsx'
import RelatedToSelect from './related_to_select.jsx'

const AttachmentError = ({ attachment }) => {
  const error = attachment.uploadStatus && attachment.uploadStatus.message
  return error ? (
    <div className="alert alert-danger alert-condensed" role="alert">
      <strong>Error!</strong><br />
      { attachment.uploadStatus &&
        attachment.uploadStatus.details &&
        attachment.uploadStatus.details['attachments.document'] &&
        `Document ${ attachment.uploadStatus.details['attachments.document'][0] }`
      }
    </div>
  ) : null
}

class AttachmentRow extends React.Component {
  render() {
    const {
      attachment,
      handleCommentChange,
      handleRelatedToChange,
      handleDeleteClicked
    } = this.props

    return (
      <tr>
        <td className='preview'>
          <PreviewContainer
            file={ attachment.document && attachment.document.value != "" ? attachment.document.value : null }
            attachmentUrl={ attachment.attachmentUrl }
            documentContentType={ attachment.documentContentType }
          />

          <div className='filename'>
            { attachment.unsavedChanges && <small>*</small> }
            { attachment.documentFileName }
          </div>

          { attachment.documentFileSize &&
              <small>{ Math.round(attachment.documentFileSize / 1000) }kb</small>
          }

          <ProgressBar
            percentage={ attachment.uploadStatus ? attachment.uploadStatus.percentage : null }
            hideWhenComplete={ !!attachment.id }
            hideBeforeStart={ !!attachment.id } />
        </td>
        <td>
          <AttachmentError attachment={ attachment } />

          <TextField name="comment"
            onChange={ handleCommentChange(attachment) }
            type='textarea'
            label="Type comment..."
            value={
              (() => {
                // old attachments created before our large refactoring could have had null
                // values for comments saved to the database
                if (attachment.comment === null) { return '' }

                if (typeof(attachment.comment) == 'string') {
                  return attachment.comment
                }
                else { // it's a FormField object, so we gotta call value on it
                  return attachment.comment.value
                }
              })()
            }
            errorMessage={ attachment.comment && attachment.comment.errorMessage }
            bootstrapWrapperClassName='no-margin-bottom'
            rows={ 3 } />
        </td>
        <td>
          <RelatedToSelect
            attachment={ attachment }
            onChange={ handleRelatedToChange(attachment) }
            bootstrapWrapperClassName='no-margin-bottom' />
        </td>
        <td>
          <DeleteAttachmentButton handleDeleteClicked={ handleDeleteClicked } />
        </td>
      </tr>
    )
  }
}

AttachmentRow = connect(
  (state, props) => {
    const attachableType = props.attachment.attachableType
    const attachableId = props.attachment.attachableId

    let relatedTo = "The Report";

    switch (attachableType) {
      case "InjuredPerson::Base": {
        const record = recordWithId(attachableId, state.persons);

        if (record) { relatedTo = `${record.firstName} ${record.lastName}` }
        break;
      }

      case "DamagedProperty": {
        const record = recordWithId(attachableId, state.properties);

        if (record) { relatedTo = `Damaged Property${record.id}` }
        break;
      }

      case "InvolvedVehicle": {
        const record = recordWithId(attachableId, state.vehicles);

        if (record) {
          relatedTo = `${record.year} ${record.makeAndModel} (${record.vehicleIdentificationNumber})`
        }
        break;
      }
    }

    return {
      relatedTo: relatedTo
    }
  }
)(AttachmentRow)

export default AttachmentRow
