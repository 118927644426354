import { convertToDate } from 'shared/utils/rails_datetime_formatters.js'
import { extractDateFromRailsDateTime  } from 'shared/utils';

const notBeforeValidator = (date) => (value, _state) => {
  if (value && convertToDate(value) < convertToDate(date)) {
    return `Date can't be before ${extractDateFromRailsDateTime(date)}`
  }

  return null;
};

export default notBeforeValidator;
