import React from 'react'

import Legend from 'shared/components/legend';

let PersonsHeader = () => (
  <div className="row">
    <div className="col-xs-12">
      <h4>Injury or Illness Details</h4>

      <p>
        This section is to provide details related to workers’ compensation claims, liability
        claims for bodily injury, or other personal claims including but not limited to EEOC and
        land use issues. <strong>This report should not be used for work related injuries already
        reported through VRSA's nurse triage and reporting service.</strong>
      </p>

      <Legend />
    </div>
  </div>
)

export default PersonsHeader
