import RailsApi from 'shared/utils/rails_api.js'

// we extend this reducer by just calling it in the default action handler. basically, if we
// define an action here use it, otherwise punt to the baseCoverageTypesReducer
import { default as baseCoverageTypesReducer } from 'apps/cyber/shared/ducks/coverage_types.js'

const UPDATE_COVERAGE_TYPE = 'cyber/admin/ducks/coverage_types/UPDATE_COVERAGE_TYPE'

export default (state = [], action) => {
    switch (action.type) {
        case UPDATE_COVERAGE_TYPE: {
            let index = state.indexOf((coverage) => { return coverage.id === action.coverage.id })

            if (index === -1) { return state }

            return {
                ...state.slice(0, index),
                ...[action.coverage],
                ...state.slice(index + 1)
            }
        }

        default: return baseCoverageTypesReducer(state, action)
    }
}

const updateCoverageType = (coverage) => {
    return { type: UPDATE_COVERAGE_TYPE, coverage: { ...coverage } }
}

export const submitCoverageTypeToRails = (coverage, url, method, dispatch) => {
    return RailsApi.patch(url, { body: { coverage_type: { ...coverage } } }).
    then((json) => {
        dispatch(updateCoverageType(json.coverage_type))
    })
}

export const canUpdateCoverageType = (coverage) => {
    return coverage.id !== null && coverage.id !== undefined && coverage.id !== 'review'
}
