import React from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'

import { getWitnessesCount, asyncDeleteWitness } from '../ducks/witnesses.js'
import { apiEndpoint } from '../ducks/rails.js'
import { resetWizardState } from '../ducks/wizard.js'
import { Panel, PanelSidebar, PanelRightColumn, PanelBody, PanelControls } from '../panel'
import BootstrapModal from 'shared/components/bootstrap_modal.jsx'

import WitnessesCard from './witnesses_card.jsx'

class WitnessesIndex extends React.Component {
  constructor(props) {
    super(props)

    this.state = { confirmingDelete: false, deletingId: null }
  }

  componentWillUnmount() {
    this.props.resetWizardState()
  }

  handleDeleteClicked = (witnessId) => (event) => {
    this.setState({ confirmingDelete: true, deletingId: witnessId })
  }

  handleDelete = () => {
    return this.props.asyncDeleteWitness(this.state.deletingId, this.props.witnessesCount)
  }

  handleModalClosed = () => {
    this.setState({ confirmingDelete: false, deletingId: null })
  }

  render() {
    // we have a problem where, when/if the last witness card is deleted we'll redirect before
    // the confirmation modal hides. that unmounts this and that before we have a chance to
    // cleanup state or the dom. this is a sucky pattern, but the alternatives are to move the
    // bootstrap modal up in the heiarchy, or track whether this and the modal are mounted or not.
    //
    if (this.props.witnessesCount === 0 && !this.state.confirmingDelete) {
      return <Redirect to="/witnesses/new" />
    }

    return (
      <Panel>
        <PanelSidebar />

        <PanelRightColumn>
          <PanelBody>
            <div className="row">
              <div className="col-xs-12">
                <h4>Witnesses</h4>

                <p>
                  Below, you can add, edit, or delete any witnesses related to this report.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <p>
                  <Link to="/witnesses/new">
                    <button type="button" className="btn btn-success btn-with-icon plus">
                      Add New
                    </button>
                  </Link>
                </p>
              </div>
            </div>

            <div className="row">
              {
                Object.entries(this.props.witnesses).map(([_, witness]) => (
                  <div key={ witness.id } className="col-xs-12">
                    <WitnessesCard id={ witness.id }
                                   handleDeleteClicked={ this.handleDeleteClicked(witness.id) }
                                   witness={ witness } />
                  </div>
                ))
              }
            </div>

            {
              this.state.confirmingDelete &&
                <BootstrapModal title="Confirm Deletion"
                                handleHide={ this.handleModalClosed }
                                buttons={[
                                  { className: "btn btn-link", dismiss: true, name: "Cancel" },
                                  {
                                    className: "btn btn-default btn-danger",
                                    onClick: this.handleDelete,
                                    loading: this.props.deletingWitness,
                                    name: "Delete"
                                  }
                                ]}>

                  <p>{ 'Are you sure you want to delete this witness?' }</p>
                </BootstrapModal>
            }
          </PanelBody>

          <PanelControls>
            <Link to="/properties">
              <button type="button"
                      className="btn btn-link">
                Back
              </button>
            </Link>


            <Link to="/attachments">
              <button type="button"
                      className="btn btn-success">

                Save & Continue
              </button>
            </Link>
          </PanelControls>
        </PanelRightColumn>
      </Panel>
    )
  }
}

WitnessesIndex = connect(
  (state, props) => ({
    witnessesCount:   getWitnessesCount(state.witnesses),
    witnesses:        state.witnesses,
    deletingWitness:  state.wizard.updating,
    apiEndpoint:      apiEndpoint(state.rails, "incident_report")
  }),
  null,
  ({ apiEndpoint, ...stateProps }, { dispatch }, props) => ({
    ...stateProps,
    ...props,

    asyncDeleteWitness: (id, witnessesCount) => {
      return asyncDeleteWitness(apiEndpoint.path, id, witnessesCount)(dispatch)
    },

    resetWizardState: () => { dispatch(resetWizardState()) }
  })
)(WitnessesIndex);

export default WitnessesIndex
