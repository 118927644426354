import React from 'react'

export const Legend = () => (
  <div>
    <p>
      <em>Fields <span className="danger-text" >required before you can save or move to the next section</span> are
      marked with a red asterisk (<span className="danger-text" >*</span>).</em>
    </p>

    <p>
      <em>Fields <span className="warning-text" >required before you can submit</span> are marked
      with a yellow warning icon (<span className="warning-text" ><i className="fa fa-exclamation-triangle"
                                                                 aria-hidden="true"
                                                                 style={{ color: '#8a6d3b' }}></i></span>).</em>
    </p>
  </div>
)

export default Legend
