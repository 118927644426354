import React from 'react'
import { connect } from 'react-redux'

import FloatingLabelInputs from 'shared/components/floating_label_inputs.jsx'

const ClientTierSelect = ({ onChange, entityTiers, value }) => (
  <div className="form-group">
    <FloatingLabelInputs.Select
      label="Client Tier"
      name="entityTierId" 
      className="form-control"
      value={ value || '' }
      onChange={ onChange }
    >
      <option></option>
      { 
        entityTiers.map( (program) => (
          <option key={ program.id } value={ program.id }>
            Tier { program.tier }
          </option>
        )) 
      }
    </FloatingLabelInputs.Select>
  </div>
)

export default connect(
  (state, props) => ({
    entityTiers: Object.values(state.lookups.entityTiers)
  })
)(ClientTierSelect)