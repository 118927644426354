import React from 'react'
import Label from './label.jsx'
import Response from './response.jsx'

const BasicColumn = ({ label, value, missingText = "Unspecified" }) => (
  <div className={ !value ? "unanswered" : "" }>
    <Label>{ label }</Label>
    <Response>
        { value !== undefined && value !== null && value !== "" ?
            String(value) :  missingText }

    </Response>
  </div>
)

export default BasicColumn;
