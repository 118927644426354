import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import BaseForm from "shared/components/base_form.jsx";

import EmailEditor from "./email_editor";

import {
  FormField,
  TextField,
  ToggleSwitchField,
  DateAndTimeField,
} from "shared/components/fields";

import {
  requiredValidator,
  notInPastValidator,
  notAfterValidator,
  commaSeparatedEmailsValidator,
} from "shared/utils/validators";

import { asyncUpdateEventEmail } from "../ducks/event_email";
import { apiEndpoint } from "../ducks/rails";
import {
  resetWizardState,
  updateStarted,
  updateSucceeded,
  updateFailed,
  setSuccessFlash,
  setErrorFlash,
} from "../ducks/wizard";
import { resetErrorsForEvent } from "../ducks/submission_errors";
import {
  Panel,
  PanelSidebar,
  PanelRightColumn,
  PanelBody,
  PanelControls,
} from "../panel";

class EmailNotifications extends BaseForm {
  constructor(props) {
    super(props);

    const eventEmail = { ...(this.props.eventEmail || {}) };
    const errors = { ...(this.props.errors || {}) };

    const withOrganizerAndEventsAtAsDefault = (value) => {
      if (value === null || value === undefined) {
        return ["events@vrsa.us", this.props.event.organizerEmails].join(", ");
      }
      return value;
    };

    this.state = {
      eventEmail: {
        confirmationSend: new FormField(
          eventEmail.confirmationSend,
          requiredValidator()
        ),
        confirmationCc: new FormField(
          withOrganizerAndEventsAtAsDefault(eventEmail.confirmationCc),
          commaSeparatedEmailsValidator()
        ),
        confirmationSubject: new FormField(eventEmail.confirmationSubject),
        confirmationMessage: new FormField(eventEmail.confirmationMessage),

        waitlistedSend: new FormField(
          eventEmail.waitlistedSend,
          requiredValidator()
        ),
        waitlistedCc: new FormField(
          withOrganizerAndEventsAtAsDefault(eventEmail.waitlistedCc),
          commaSeparatedEmailsValidator()
        ),
        waitlistedSubject: new FormField(eventEmail.waitlistedSubject),
        waitlistedMessage: new FormField(eventEmail.waitlistedMessage),

        cancellationSend: new FormField(
          eventEmail.cancellationSend,
          requiredValidator()
        ),
        cancellationCc: new FormField(
          withOrganizerAndEventsAtAsDefault(eventEmail.cancellationCc),
          commaSeparatedEmailsValidator()
        ),
        cancellationSubject: new FormField(eventEmail.cancellationSubject),
        cancellationMessage: new FormField(eventEmail.cancellationMessage),

        reminderSend: new FormField(
          eventEmail.reminderSend,
          requiredValidator()
        ),
        remindAt: new FormField(eventEmail.remindAt, [
          notInPastValidator(),
          notAfterValidator(props.event.date),
        ]),
        reminderCc: new FormField(
          withOrganizerAndEventsAtAsDefault(eventEmail.reminderCc),
          commaSeparatedEmailsValidator()
        ),
        reminderSubject: new FormField(eventEmail.reminderSubject),
        reminderMessage: new FormField(eventEmail.reminderMessage),
      },
    };

    // add any errors we found when attempting to submit
    Object.keys(this.state.eventEmail).map((key) => {
      this.state.eventEmail[key].errorMessage = (errors[key] || {}).message;
    });
  }

  handleChangeFor = (name) => {
    return this.handleFieldChange("eventEmail", name);
  };

  componentWillUnmount() {
    this.props.resetWizardState();
    this.props.resetSubmissionErrors();
  }

  prepareData = () => {
    let copy = { eventEmail: { ...this.state.eventEmail } };

    // turn FormField objects in to just the raw value
    Object.keys(copy.eventEmail).map((key) => {
      copy.eventEmail[key] = copy.eventEmail[key].value;
    });

    return { event: copy };
  };

  handleSave = (event) => {
    event.preventDefault();
    if (!this.validateFields("event")) {
      return null;
    }

    event.preventDefault();
    if (event.target && event.target.href) {
      // looks like we clicked a link in the sidebar
      // since we hijack clicking on the sidebar and attempt to save this form, forcing you
      // to at least deal with this first step before bouncing to anywhere in the form,
      // we need to remember what you clicked so we can send you on your way if everything checked
      // out
      this.setState({
        redirect: event.target.href.split("/edit").slice(1).join("/edit"),
      });
    } else {
      this.setState({ redirect: "/events" });
    }

    if (this.anyChangesMade("eventEmail", this.props.eventEmail)) {
      this.props.asyncUpdateEventEmail(this.prepareData());
    }
  };

  renderConfirmationDetails = () => (
    <div>
      <TextField
        type="text"
        name="confirmationCc"
        onChange={this.handleChangeFor("confirmationCc")}
        label="CC"
        value={this.state.eventEmail.confirmationCc.value}
        errorMessage={this.state.eventEmail.confirmationCc.errorMessage}
        tabIndex="2"
        autoFocus={false}
      />

      <TextField
        type="text"
        name="confirmationSubject"
        onChange={this.handleChangeFor("confirmationSubject")}
        label="Subject"
        value={this.state.eventEmail.confirmationSubject.value}
        errorMessage={this.state.eventEmail.confirmationSubject.errorMessage}
        tabIndex="3"
        required={true}
        autoFocus={false}
      />

      <p>Confirmation Message</p>
      <EmailEditor
        defaultMessage={this.state.eventEmail.confirmationMessage.value}
        tabIndex={4}
        onChange={(value) => this.handleChangeFor("confirmationMessage")(value)}
      />
    </div>
  );

  renderWaitlistedDetails = () => (
    <div>
      <TextField
        type="text"
        name="waitlistedCc"
        onChange={this.handleChangeFor("waitlistedCc")}
        label="CC"
        value={this.state.eventEmail.waitlistedCc.value}
        errorMessage={this.state.eventEmail.waitlistedCc.errorMessage}
        tabIndex="10"
        autoFocus={false}
      />

      <TextField
        type="text"
        name="waitlistedSubject"
        onChange={this.handleChangeFor("waitlistedSubject")}
        label="Subject"
        value={this.state.eventEmail.waitlistedSubject.value}
        errorMessage={this.state.eventEmail.waitlistedSubject.errorMessage}
        tabIndex="11"
        required={true}
        autoFocus={false}
      />

      <p>Waitlisted Message</p>
      <EmailEditor
        defaultMessage={this.state.eventEmail.waitlistedMessage.value}
        tabIndex={12}
        onChange={(value) => this.handleChangeFor("waitlistedMessage")(value)}
      />
    </div>
  );

  renderCancellationDetails = () => (
    <div>
      <TextField
        type="text"
        name="cancellationCc"
        onChange={this.handleChangeFor("cancellationCc")}
        label="CC"
        value={this.state.eventEmail.cancellationCc.value}
        errorMessage={this.state.eventEmail.cancellationCc.errorMessage}
        tabIndex="14"
        autoFocus={false}
      />

      <TextField
        type="text"
        name="cancellationSubject"
        onChange={this.handleChangeFor("cancellationSubject")}
        label="Subject"
        value={this.state.eventEmail.cancellationSubject.value}
        errorMessage={this.state.eventEmail.cancellationSubject.errorMessage}
        tabIndex="15"
        required={true}
        autoFocus={false}
      />

      <p>Cancellation Message</p>
      <EmailEditor
        defaultMessage={this.state.eventEmail.cancellationMessage.value}
        tabIndex={16}
        onChange={(value) => this.handleChangeFor("cancellationMessage")(value)}
      />
    </div>
  );

  renderReminderDetails = () => (
    <div>
      <div className="full-width-date-and-time-inputs">
        <DateAndTimeField
          name="remindAt"
          onChange={this.handleChangeFor("remindAt")}
          label={{ date: "Remind On", time: "Remind At" }}
          defaultTimeTo="08:00 AM"
          value={this.state.eventEmail.remindAt.value}
          errorMessage={this.state.eventEmail.remindAt.errorMessage}
          required={true}
          tabIndex="18"
        />
      </div>

      <TextField
        type="text"
        name="reminderCC"
        onChange={this.handleChangeFor("reminderCc")}
        label="CC"
        value={this.state.eventEmail.reminderCc.value}
        errorMessage={this.state.eventEmail.reminderCc.errorMessage}
        tabIndex="19"
        autoFocus={false}
      />

      <TextField
        type="text"
        name="reminderSubject"
        onChange={this.handleChangeFor("reminderSubject")}
        label="Subject"
        value={this.state.eventEmail.reminderSubject.value}
        errorMessage={this.state.eventEmail.reminderSubject.errorMessage}
        tabIndex="20"
        required={true}
        autoFocus={false}
      />

      <p>Reminder Message</p>
      <EmailEditor
        defaultMessage={this.state.eventEmail.reminderMessage.value}
        tabIndex={21}
        onChange={(value) => this.handleChangeFor("reminderMessage")(value)}
      />
    </div>
  );

  render() {
    if (!this.props.updating) {
      if (this.state.redirect === "/events") {
        window.location = `${window.location.origin}/events`;
      } else if (
        this.state.redirect &&
        this.state.redirect !== this.props.match.path
      ) {
        return <Redirect to={this.state.redirect} />;
      }
    }

    return (
      <Panel>
        <PanelSidebar onLinkClick={this.handleSave} />

        <PanelRightColumn>
          <PanelBody>
            <div className="row">
              <div className="col-xs-12">
                <h3>Registration Confirmation Email</h3>
                <p>{"Send Confirmation?"}</p>
                <ToggleSwitchField
                  name="confirmationSend"
                  onChange={this.handleChangeFor("confirmationSend")}
                  value={this.state.eventEmail.confirmationSend.value}
                  errorMessage={
                    this.state.eventEmail.confirmationSend.errorMessage
                  }
                  tabIndex="1"
                />
                {this.state.eventEmail.confirmationSend.value &&
                  this.renderConfirmationDetails()}
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-xs-12">
                <h3>Attendee Waitlisted Email</h3>
                <p>{"Send Notification"}</p>
                <ToggleSwitchField
                  name="waitlistedSend"
                  onChange={this.handleChangeFor("waitlistedSend")}
                  value={this.state.eventEmail.waitlistedSend.value}
                  errorMessage={
                    this.state.eventEmail.waitlistedSend.errorMessage
                  }
                  tabIndex="9"
                />
                {this.state.eventEmail.waitlistedSend.value &&
                  this.renderWaitlistedDetails()}
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-xs-12">
                <h3>Registration Cancellation Email</h3>
                <p>{"Send Cancellation?"}</p>
                <ToggleSwitchField
                  name="cancellationSend"
                  onChange={this.handleChangeFor("cancellationSend")}
                  value={this.state.eventEmail.cancellationSend.value}
                  errorMessage={
                    this.state.eventEmail.cancellationSend.errorMessage
                  }
                  tabIndex="13"
                />
                {this.state.eventEmail.cancellationSend.value &&
                  this.renderCancellationDetails()}
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-xs-12">
                <h3>Event Reminder Email</h3>
                <p>{"Send Reminder"}</p>
                <ToggleSwitchField
                  name="reminderSend"
                  onChange={this.handleChangeFor("reminderSend")}
                  value={this.state.eventEmail.reminderSend.value}
                  errorMessage={this.state.eventEmail.reminderSend.errorMessage}
                  tabIndex="17"
                />
                {this.state.eventEmail.reminderSend.value &&
                  this.renderReminderDetails()}
              </div>
            </div>
          </PanelBody>

          <PanelControls>
            <button
              type="button"
              onClick={this.handleSave}
              className={`btn btn-success ${
                this.props.updating ? "disabled" : ""
              }`}
            >
              Save &amp; Exit
            </button>
          </PanelControls>
        </PanelRightColumn>
      </Panel>
    );
  }
}

EmailNotifications = connect(
  (state, props) => ({
    event: state.event,
    eventEmail: state.eventEmail,
    errors: state.submissionErrors.event,
    updateSucceeded: state.wizard.updateSucceeded,
    updating: state.wizard.updating,
    apiEndpoint: apiEndpoint(state.rails, "eventEmail"),
  }),
  null,
  ({ apiEndpoint, ...stateProps }, { dispatch }, props) => ({
    ...stateProps,
    ...props,

    asyncUpdateEventEmail: (data, updated) => {
      return asyncUpdateEventEmail(apiEndpoint.path, data, updated)(dispatch);
    },

    resetWizardState: () => {
      dispatch(resetWizardState());
    },
    resetSubmissionErrors: () => {
      dispatch(resetErrorsForEvent());
    },
  })
)(EmailNotifications);

export default EmailNotifications;
