import RailsApi from 'shared/utils/rails_api.js'

export const asyncUpdateAttachment = (attachment, url, options) => {
  const {
    onSuccess  = () => {},
    onProgress = () => {},
    onError    = () => {}
  } = options

  RailsApi.uploadWithXhr(url, 'PATCH', {
    body: { business_continuity_document: attachment },

    onSuccess: onSuccess,

    onError: (error) => {
      console.log("error", error)
      onError(error)
    },

    onProgress: onProgress
  })
}
