import React from 'react'

let MemberInfo = ({ organizationName, threeCharacterMemberNumber }) => (
  <div>

      <div className="row">
        <div className="col-xs-12">
          <h4>Member Information</h4>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-6">
          <b>Member Name</b>
          <p>{ organizationName }</p>
        </div>

        <div className="col-xs-6">
          <b>Member Number</b>
          <p>#{ threeCharacterMemberNumber }</p>
        </div>
      </div>

    </div>
)

export default MemberInfo;
