import RailsApi from 'shared/utils/rails_api.js'
import { setErrorFlash } from './wizard.js'
const LOADING   = "event_attendee_form/eventQuestionSet/LOADING"

export default function eventQuestionSet(state = {}, action) {
  switch (action.type) {
    case LOADING: {
      return {
        loading: true
      }
    }
    case 'EVENT_QUESTION_SET_FETCH_SUCCEEDED': {
      return { ...action.payload, loading: false }
    }
    case 'EVENT_QUESTION_SET_FETCH_FAILED': {
      return { ...state, loading: false }
    }
    default: {
      return state
    }
  }
}

export const setLoadingState = (data) => {
  return { type: LOADING };
}

export const loadEventQuestionSet = (data) => ({
  type: 'EVENT_QUESTION_SET_FETCH_SUCCEEDED', 
  payload: data
});

export const asyncLoadEventQuestionSet = (eventId) => (dispatch) => {
  dispatch(setLoadingState());
  RailsApi.get(`/event_question_sets/${eventId}`).
    then((json) => {
      dispatch(loadEventQuestionSet(json.data))
    }).
    catch((json) => {
      dispatch({
        type: 'EVENT_QUESTION_SET_FETCH_FAILED'
      })
    })
}
