import RailsApi from 'shared/utils/rails_api.js'

import { refreshMetaData } from './rails.js'
import { setErrorFlash, setSuccessFlash, updateSucceeded, updateFailed, updateStarted } from './wizard.js'
import { updateIncidentReport } from './incident_report.js'

const LOAD_VEHICLES   = "incident_report_form/vehicles/LOAD_VEHICLES"
const UPDATE_VEHICLES = "incident_report_form/vehicles/UPDATE_VEHICLES"

export default function vehicles(state = {}, action) {
  switch (action.type) {
    case LOAD_VEHICLES: {
      return { ...action.vehicles }
    }

    case UPDATE_VEHICLES: {
      return { ...action.vehicles }
    }

    default: {
      return state
    }
  }
}

export const loadVehicles = (vehicles) => {
  return { type: LOAD_VEHICLES, vehicles: vehicles }
}

export const updateVehicles = (vehicles) => {
  return { type: UPDATE_VEHICLES, vehicles: vehicles }
}

export const getVehiclesCount = (state) => ( Object.keys(state).length )

export const getVehicle = (state, id) => {
  return Object.values(state).find((vehicle) => (
    vehicle.id && vehicle.id.toString() === id.toString()
  ))
}

export const asyncUpdateVehicles = (url, data) => (dispatch) => {
  dispatch(updateStarted())
  
  return RailsApi.patch(url, { body: data }).
    then((json) => {
      dispatch({
        type: 'RAILS_UPDATE_SUCCEEDED',
        actions: [
          refreshMetaData({ ...json.meta }),
          updateIncidentReport(json.incidentReport),
          updateVehicles(json.incidentReport.involvedVehiclesAttributes),
          updateSucceeded(),
          setSuccessFlash("Vehicles updated", { keep: true })
        ]
      })
    }).
    catch((json) => {
      console.log(json)

      dispatch({
        type: 'RAILS_UPDATE_FAILED',
        actions: [
          updateFailed(),
          setErrorFlash(json.error.message, { details: json.error.details })
        ]
      })
    })
}

export const asyncDeleteVehicle = (url, id, vehiclesCount) => (dispatch) => {
  let data = {
    incidentReport: {
      involvedVehiclesAttributes: {
        [id]: { id: id, "_destroy": true }
      }
    }
  }

  if (vehiclesCount === 1) { // we're about to delete the last vehicle 😱
    data.incidentReport.wereAnyVehiclesInvolved = false
  }

  dispatch(updateStarted())

  return RailsApi.patch(url, { body: data }).
    then((json) => {
      dispatch({
        type: 'RAILS_UPDATE_SUCCEEDED',
        actions: [
          refreshMetaData({ ...json.meta }),
          updateIncidentReport(json.incidentReport),
          updateVehicles(json.incidentReport.involvedVehiclesAttributes),
          updateSucceeded(),
          setSuccessFlash("Vehicle deleted", { keep: vehiclesCount === 1 }) // we redirect when we delete the last vehicle
        ]
      })
    }).
    catch((json) => {
      console.log(json)

      dispatch({
        type: 'RAILS_UPDATE_FAILED',
        actions: [
          updateFailed(),
          setErrorFlash(json.error.message, { details: json.error.details })
        ]
      })
    })
}
