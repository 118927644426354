import React from 'react'
import { connect } from 'react-redux'
import { Link, Prompt, Redirect } from 'react-router-dom'

import BaseForm from 'shared/components/base_form.jsx'
import SameAsCheckbox from 'shared/components/same_as_checkbox.jsx'

import {
  FormField,
  TextField,
  RadioField,
  SelectField,
  AddressField,
  PhoneNumberField,
  DollarValueField
} from 'shared/components/fields'

import {
  requiredValidator,
  phoneNumberValidator,
  emailValidator,
  lengthValidator,
  dollarValueValidator
} from 'shared/utils/validators'

import { asyncUpdateProperties } from '../ducks/properties.js'
import { apiEndpoint } from '../ducks/rails.js'
import { resetWizardState } from '../ducks/wizard.js'
import { Panel, PanelSidebar, PanelRightColumn, PanelBody, PanelControls } from '../panel'

import PropertiesPrompt from './properties_prompt.jsx'
import PropertiesHeader from './properties_header.jsx'
import SearchInsuredBuildingsField from './search_insured_buildings_field.jsx'
import SearchInsuredEquipmentField from './search_insured_equipment_field.jsx'

const requiredIfMemberOwned = (value, state) => {
  if (state.memberOwned.value) {
    return requiredValidator()(value)
  }
}

const requiredIfMemberOwnedBuilding = (value, state) => {
  if (state.memberOwned.value && state.propertyType.value === 'building_on_site') {
    return requiredValidator()(value)
  }
}

class PropertiesForm extends BaseForm {
  constructor(props) {
    super(props)

    const property = { ...(props.property || {}) }

    this.state = {
      property: {
        memberOwned: new FormField(property.memberOwned, requiredValidator()),
        claimBeingMade: new FormField(property.claimBeingMade, requiredIfMemberOwned),
        propertyType: new FormField(property.propertyType, requiredIfMemberOwned),
        ogmScheduleId: new FormField(property.ogmScheduleId),

        propertyDescription: new FormField(property.propertyDescription, requiredValidator()),
        streetAddress:       new FormField(property.streetAddress),
        optionalLine:        new FormField(property.optionalLine),
        city:                new FormField(property.city),
        state:               new FormField(property.state),
        zip:                 new FormField(property.zip),

        ownerFirstName:      new FormField(property.ownerFirstName),
        ownerLastName:       new FormField(property.ownerLastName),
        ownerPrimaryPhone:   new FormField(property.ownerPrimaryPhone, phoneNumberValidator()),
        ownerEmail:          new FormField(property.ownerEmail, emailValidator()),
        ownerAlternatePhone: new FormField(property.ownerAlternatePhone, phoneNumberValidator()),
        ownerStreetAddress:  new FormField(property.ownerStreetAddress),
        ownerOptionalLine:   new FormField(property.ownerOptionalLine),
        ownerCity:           new FormField(property.ownerCity),
        ownerState:          new FormField(property.ownerState),
        ownerZip:            new FormField(property.ownerZip),

        insuranceProviderStreetAddress: new FormField(property.insuranceProviderStreetAddress),
        insuranceProviderOptionalLine:  new FormField(property.insuranceProviderOptionalLine),
        insuranceProviderCity:          new FormField(property.insuranceProviderCity),
        insuranceProviderState:         new FormField(property.insuranceProviderState),
        insuranceProviderZip:           new FormField(property.insuranceProviderZip),
        insuranceProviderName:          new FormField(property.insuranceProviderName),
        policyNumber:                   new FormField(property.policyNumber),
        insuranceProviderPhone:         new FormField(property.insuranceProviderPhone, phoneNumberValidator()),

        descriptionOfDamage:  new FormField(property.descriptionOfDamage, [requiredValidator(), lengthValidator(255)]),
        estimatedTotalDamage: new FormField(property.estimatedTotalDamage, dollarValueValidator()),

        contentDamage:                new FormField(property.contentDamage, requiredIfMemberOwnedBuilding),
        estimatedCostOfContentDamage: new FormField(property.estimatedCostOfContentDamage, dollarValueValidator()),

        additionalComments: new FormField(property.additionalComments, lengthValidator(255))
      }
    }
  }

  handleChangeFor = (name) => ( this.handleFieldChange("property", name) )

  componentWillUnmount() {
    this.props.resetWizardState()
  }

  prepareData = () => {
    let key = Date.now().toString()

    // the structure of what we wanna send back to rails for updating
    let data = {
      incidentReport: {
        ...(this.props.incidentReport || {}),
        damagedPropertiesAttributes: { [key]: {} } // new object to copy property values into
      }
    }

    // if this property has an id (i.e. rails already knows about them), add it in. new properties
    // don't need an id and will be given one by rails
    if ((this.props.property || {}).id) {
      data.incidentReport.damagedPropertiesAttributes[key].id = (this.props.property || {}).id
    }

    // collect our form values
    Object.keys(this.state.property).map((field) => {
      data.incidentReport.damagedPropertiesAttributes[key][field] = this.state.property[field].value
    })

    return data
  }

  handleSave = (event) => {
    event.preventDefault()

    if (this.validateFields("property")) {
      this.props.asyncUpdateProperties(this.prepareData())
    }
  }

  renderControlGroup() {
    if (this.props.withPrompt) {
      return (
        <div>
          <button type="button"
                  className="btn btn-link"
                  onClick={ () => {
                    if (confirm("Are you sure you want to move away? Any unsaved changes " +
                                "made to this section will be lost.")) {
                      this.props.onPromptChange(false) // toggle the prompt back to "No"
                    }
                  }}>
            Cancel
          </button>

          <button type="button"
                  onClick={ this.handleSave }
                  className={ `btn btn-success ${this.props.updating ? "disabled" : ""}` }>

            Save to Claim
          </button>
        </div>
      )
    }
    else {
      return (
        <div>
          <Link to="/properties">
            <button type="button"
                    className="btn btn-link">

              Back
            </button>
          </Link>

          <button type="button"
                  onClick={ this.handleSave }
                  className={ `btn btn-success ${this.props.updating ? "disabled" : ""}` }>

            Save to Claim
          </button>
        </div>
      )
    }
  }

  render() {
    if (this.props.updateSucceeded) {
      return <Redirect to="/properties" />
    }

    return (
      <Panel>
        <PanelSidebar />

        <PanelRightColumn>
          <PanelBody>
            <PropertiesHeader />

            {
              this.props.withPrompt &&
                <div className="row">
                  <div className="col-xs-12">
                    <PropertiesPrompt onChange={ this.props.onPromptChange }
                                      value={ this.props.promptValue } />
                  </div>
                </div>
            }

            <hr />

            <div className="row">
              <div className="col-xs-12">
                <RadioField name="memberOwned"
                            onChange={ (value) => {
                              this.handleChangeFor("memberOwned")(value)

                              // if you're not memberOwned, we assume yes
                              !value && this.handleChangeFor("claimBeingMade")(true)
                            }}
                            label="Is this property member-owned?"
                            value={ this.state.property.memberOwned.value }
                            errorMessage={ this.state.property.memberOwned.errorMessage }
                            required={ true }
                            options={[
                              { displayText: 'Yes', value: true },
                              { displayText: 'No', value: false }
                            ]} />
              </div>
            </div>

            { /* don't show the other fields until they've answered this question */
              (this.state.property.memberOwned.value === false || this.state.property.memberOwned.value === true) &&
                <div>
                  {
                    this.state.property.memberOwned.value &&
                      <div>
                        <div className="row">
                          <div className="col-xs-12">
                            <RadioField name="claimBeingMade"
                                        onChange={ this.handleChangeFor("claimBeingMade") }
                                        label="Is a claim being made for damage to this property?"
                                        value={ this.state.property.claimBeingMade.value }
                                        errorMessage={ this.state.property.claimBeingMade.errorMessage }
                                        required={ true }
                                        options={[
                                          { displayText: 'Yes', value: true },
                                          { displayText: 'No', value: false }
                                        ]} />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-12">
                            <SelectField name="propertyType"
                                         onChange={ (propertyType) => {
                                          this.handleChangeFor("propertyType")(propertyType) 
                                          this.handleChangeFor("ogmScheduleId")("")
                                         }}
                                         label="Property Type"
                                         placeholder="Select..."
                                         value={ this.state.property.propertyType.value }
                                         errorMessage={ this.state.property.propertyType.errorMessage }
                                         required={ true }
                                         tabIndex="2"
                                         options={ this.props.propertyTypeOptions } />
                          </div>
                        </div>

                        {
                          this.state.property.propertyType.value === "building_on_site" &&
                            <div className="row">
                              <div className="col-xs-12">
                                <p>Search for a building</p>

                                <div className="form-group">
                                  <SearchInsuredBuildingsField
                                    onChange={ (option) => {
                                      if (option) {
                                        this.handleChangeFor("propertyDescription")(option.description || '')
                                        this.handleChangeFor("streetAddress")      (option.street_address || '')
                                        this.handleChangeFor("optionalLine")       (option.optional_line || '')
                                        this.handleChangeFor("city")               (option.city || '')
                                        this.handleChangeFor("state")              (option.state || '')
                                        this.handleChangeFor("zip")                (option.zip || '')
                                        this.handleChangeFor("ogmScheduleId")          (option.building_id || '')
                                      }
                                      else {
                                        this.handleChangeFor("propertyDescription")("")
                                        this.handleChangeFor("streetAddress")("")
                                        this.handleChangeFor("optionalLine")("")
                                        this.handleChangeFor("city")("")
                                        this.handleChangeFor("state")("")
                                        this.handleChangeFor("zip")("")
                                        this.handleChangeFor("ogmScheduleId")("")
                                      }
                                    }}
                                    tabIndex="3"
                                  />
                                </div>
                              </div>
                            </div>
                        }

                        {
                          this.state.property.propertyType.value === "equipment_or_other_scheduled_items" &&
                            <div className="row">
                              <div className="col-xs-12">
                                <p>Search for a piece of Equipment</p>

                                <div className="form-group">
                                  <SearchInsuredEquipmentField
                                    onChange={ (option) => {
                                      if (option) {
                                        this.handleChangeFor("propertyDescription")(option.description || '')
                                        this.handleChangeFor("ogmScheduleId")(option.equipment_id || 'id')
                                      }
                                      else {
                                        this.handleChangeFor("propertyDescription")("")
                                        this.handleChangeFor("ogmScheduleId")("")
                                      }
                                    }}
                                    tabIndex="3"
                                  />
                                </div>
                              </div>
                            </div>
                        }
                      </div>
                  }

                  <div className="row">
                    <div className="col-xs-12">
                      <p>Briefly describe this property or equipment.</p>

                      <TextField type="textarea"
                                 name="propertyDescription"
                                 onChange={ this.handleChangeFor("propertyDescription") }
                                 label="Type..."
                                 value={ this.state.property.propertyDescription.value }
                                 errorMessage={ this.state.property.propertyDescription.errorMessage }
                                 shouldFloatLabelOnUpdate={ this.state.property.memberOwned.value }
                                 required={ true }
                                 tabIndex="4" />
                    </div>
                  </div>

                  <div className="row">
                    {/* New read only fields to display property ID or Equipment ID */}
                    {(this.state.property.memberOwned.value === true &&
                      (this.state.property.propertyType.value === "building_on_site" || this.state.property.propertyType.value === "equipment_or_other_scheduled_items" ))
                      &&
                      <div className="col-xs-12" style={{display: 'none'}}>
                        <TextField name="ogmScheduleId"
                                            onChange={ this.handleChangeFor("ogmScheduleId") }
                                            label="OGM Schedule ID"
                                            readOnly
                                            value={ this.state.property.ogmScheduleId.value }
                                            errorMessage={ this.state.property.ogmScheduleId.errorMessage }
                                            shouldFloatLabelOnUpdate={ true }
                                          />
                      </div>}
                    <div className="col-xs-12">
                      <p>{ 'Where is the property located?' }</p>
                    </div>

                    <div className="col-xs-12">
                      <AddressField tabIndex="5"
                                    shouldFloatLabelsOnUpdate={ this.state.property.memberOwned.value }
                                    fields={{
                                      streetAddress: {
                                        name: "streetAddress",
                                        value: this.state.property.streetAddress.value,
                                        errorMessage: this.state.property.streetAddress.errorMessage,
                                        onChange: this.handleChangeFor("streetAddress")
                                      },
                                      optionalLine: {
                                        name: "optionalLine",
                                        value: this.state.property.optionalLine.value,
                                        errorMessage: this.state.property.optionalLine.errorMessage,
                                        onChange: this.handleChangeFor("optionalLine")
                                      },
                                      city: {
                                        name: "city",
                                        value: this.state.property.city.value,
                                        errorMessage: this.state.property.city.errorMessage,
                                        onChange: this.handleChangeFor("city")
                                      },
                                      state: {
                                        name: "state",
                                        value: this.state.property.state.value,
                                        errorMessage: this.state.property.state.errorMessage,
                                        onChange: this.handleChangeFor("state")
                                      },
                                      zip: {
                                        name: "zip",
                                        value: this.state.property.zip.value,
                                        errorMessage: this.state.property.zip.errorMessage,
                                        onChange: this.handleChangeFor("zip")
                                      }
                                    }} />
                    </div>
                  </div>

                  {
                    !this.state.property.memberOwned.value &&
                      <div>
                        <div className="row">
                          <div className="col-xs-12">
                            <p>{ 'Who owns this property and how can we contact them? ' }</p>
                          </div>

                          <div className="col-xs-6">
                            <TextField name="ownerFirstName"
                                       onChange={ this.handleChangeFor("ownerFirstName") }
                                       label="First Name"
                                       value={ this.state.property.ownerFirstName.value }
                                       errorMessage={ this.state.property.ownerFirstName.errorMessage }
                                       tabIndex="6" />
                          </div>

                          <div className="col-xs-6">
                            <TextField name="ownerLastName"
                                       onChange={ this.handleChangeFor("ownerLastName") }
                                       label="Last Name"
                                       value={ this.state.property.ownerLastName.value }
                                       errorMessage={ this.state.property.ownerLastName.errorMessage }
                                       tabIndex="7" />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-6">
                            <PhoneNumberField name="ownerPrimaryPhone"
                                              onChange={ this.handleChangeFor("ownerPrimaryPhone") }
                                              label="Primary Phone"
                                              value={ this.state.property.ownerPrimaryPhone.value }
                                              errorMessage={ this.state.property.ownerPrimaryPhone.errorMessage }
                                              tabIndex="8" />

                            <TextField name="ownerEmail"
                                       onChange={ this.handleChangeFor("ownerEmail") }
                                       label="Email Address"
                                       value={ this.state.property.ownerEmail.value }
                                       errorMessage={ this.state.property.ownerEmail.errorMessage }
                                       tabIndex="10" />
                          </div>

                          <div className="col-xs-6">
                            <PhoneNumberField name="ownerAlternatePhone"
                                              onChange={ this.handleChangeFor("ownerAlternatePhone") }
                                              label="Alternate Phone"
                                              value={ this.state.property.ownerAlternatePhone.value }
                                              errorMessage={ this.state.property.ownerAlternatePhone.errorMessage }
                                              tabIndex="9" />
                          </div>
                        </div>

                        <div className="row">
                          <SameAsCheckbox fields={[
                                            {
                                              source: this.state.property.streetAddress.value,
                                              target: this.state.property.ownerStreetAddress.value,
                                              handleChange: this.handleChangeFor("ownerStreetAddress")
                                            },
                                            {
                                              source: this.state.property.optionalLine.value,
                                              target: this.state.property.ownerOptionalLine.value,
                                              handleChange: this.handleChangeFor("ownerOptionalLine")
                                            },
                                            {
                                              source: this.state.property.city.value,
                                              target: this.state.property.ownerCity.value,
                                              handleChange: this.handleChangeFor("ownerCity")
                                            },
                                            {
                                              source: this.state.property.state.value,
                                              target: this.state.property.ownerState.value,
                                              handleChange: this.handleChangeFor("ownerState")
                                            },
                                            {
                                              source: this.state.property.zip.value,
                                              target: this.state.property.ownerZip.value,
                                              handleChange: this.handleChangeFor("ownerZip")
                                            }
                                          ]}
                                          label="Same as property location?"
                                          wrapperClass="col-xs-12" />

                          <div className="col-xs-12">
                            <AddressField tabIndex="11"
                                          fields={{
                                            streetAddress: {
                                              name: "ownerStreetAddress",
                                              value: this.state.property.ownerStreetAddress.value,
                                              errorMessage: this.state.property.ownerStreetAddress.errorMessage,
                                              onChange: this.handleChangeFor("ownerStreetAddress")
                                            },
                                            optionalLine: {
                                              name: "ownerOptionalLine",
                                              value: this.state.property.ownerOptionalLine.value,
                                              errorMessage: this.state.property.ownerOptionalLine.errorMessage,
                                              onChange: this.handleChangeFor("ownerOptionalLine")
                                            },
                                            city: {
                                              name: "ownerCity",
                                              value: this.state.property.ownerCity.value,
                                              errorMessage: this.state.property.ownerCity.errorMessage,
                                              onChange: this.handleChangeFor("ownerCity")
                                            },
                                            state: {
                                              name: "ownerState",
                                              value: this.state.property.ownerState.value,
                                              errorMessage: this.state.property.ownerState.errorMessage,
                                              onChange: this.handleChangeFor("ownerState")
                                            },
                                            zip: {
                                              name: "ownerZip",
                                              value: this.state.property.ownerZip.value,
                                              errorMessage: this.state.property.ownerZip.errorMessage,
                                              onChange: this.handleChangeFor("ownerZip")
                                            }
                                          }}
                                          shouldFloatLabelsOnUpdate={ true } />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-12">
                            <p>{ 'Third-party insurance information to report?' }</p>
                          </div>

                          <div className="col-xs-12">
                            <TextField name="insuranceProviderName"
                                       onChange={ this.handleChangeFor("insuranceProviderName") }
                                       label="Insurance Provider Name"
                                       value={ this.state.property.insuranceProviderName.value }
                                       errorMessage={ this.state.property.insuranceProviderName.errorMessage }
                                       tabIndex="10" />
                          </div>

                          <div className="col-xs-6">
                            <TextField name="policyNumber"
                                       onChange={ this.handleChangeFor("policyNumber") }
                                       label="Policy Number"
                                       value={ this.state.property.policyNumber.value }
                                       errorMessage={ this.state.property.policyNumber.errorMessage }
                                       tabIndex="10" />
                          </div>

                          <div className="col-xs-6">
                            <PhoneNumberField name="insuranceProviderPhone"
                                              onChange={ this.handleChangeFor("insuranceProviderPhone") }
                                              label="Phone Number"
                                              value={ this.state.property.insuranceProviderPhone.value }
                                              errorMessage={ this.state.property.insuranceProviderPhone.errorMessage }
                                              tabIndex="10" />
                          </div>

                          <div className="col-xs-12">
                            <AddressField tabIndex="10"
                                          fields={{
                                            streetAddress: {
                                              name: "insuranceProviderStreetAddress",
                                              value: this.state.property.insuranceProviderStreetAddress.value,
                                              errorMessage: this.state.property.insuranceProviderStreetAddress.errorMessage,
                                              onChange: this.handleChangeFor("insuranceProviderStreetAddress")
                                            },
                                            optionalLine: {
                                              name: "insuranceProviderOptionalLine",
                                              value: this.state.property.insuranceProviderOptionalLine.value,
                                              errorMessage: this.state.property.insuranceProviderOptionalLine.errorMessage,
                                              onChange: this.handleChangeFor("insuranceProviderOptionalLine")
                                            },
                                            city: {
                                              name: "insuranceProviderCity",
                                              value: this.state.property.insuranceProviderCity.value,
                                              errorMessage: this.state.property.insuranceProviderCity.errorMessage,
                                              onChange: this.handleChangeFor("insuranceProviderCity")
                                            },
                                            state: {
                                              name: "insuranceProviderState",
                                              value: this.state.property.insuranceProviderState.value,
                                              errorMessage: this.state.property.insuranceProviderState.errorMessage,
                                              onChange: this.handleChangeFor("insuranceProviderState")
                                            },
                                            zip: {
                                              name: "insuranceProviderZip",
                                              value: this.state.property.insuranceProviderZip.value,
                                              errorMessage: this.state.property.insuranceProviderZip.errorMessage,
                                              onChange: this.handleChangeFor("insuranceProviderZip")
                                            }
                                          }} />
                          </div>
                        </div>
                      </div>
                  }

                  <hr />

                  <div className="row">
                    <div className="col-xs-12">
                      <h4>Damage Sustained</h4>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-12">
                      <p>Describe what was damaged and how.</p>

                      <TextField type="textarea"
                                 name="descriptionOfDamage"
                                 onChange={ this.handleChangeFor("descriptionOfDamage") }
                                 label="Type..."
                                 value={ this.state.property.descriptionOfDamage.value }
                                 errorMessage={ this.state.property.descriptionOfDamage.errorMessage }
                                 required={ true }
                                 tabIndex="12"
                                 warn={ (value) => ( value && `Character limit: ${value.length}/255` ) } />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-12">
                      <p>{ 'What is the estimated loss value for this property?' }</p>

                      <DollarValueField name="estimatedTotalDamage"
                                        onChange={ this.handleChangeFor("estimatedTotalDamage") }
                                        label="Estimated Loss Value"
                                        value={ this.state.property.estimatedTotalDamage.value }
                                        errorMessage={ this.state.property.estimatedTotalDamage.errorMessage }
                                        tabIndex="13" />
                    </div>
                  </div>

                  {
                    (this.state.property.memberOwned.value && this.state.property.propertyType.value === 'building_on_site') &&
                      <div>
                        <div className="row">
                          <div className="col-xs-12">
                            <div className="is-required">
                              <label>{ 'Was there content damage in this building?' }<span></span></label>
                            </div>

                            <RadioField name="contentDamage"
                                        onChange={ this.handleChangeFor("contentDamage") }
                                        value={ this.state.property.contentDamage.value }
                                        errorMessage={ this.state.property.contentDamage.errorMessage }
                                        required={ true }
                                        options={[
                                          { displayText: 'Yes',     value: 'content_was_damaged' },
                                          { displayText: 'No',      value: 'content_was_not_damaged' },
                                          { displayText: 'Unknown', value: 'unknown_if_content_was_damaged' }
                                        ]} />
                          </div>
                        </div>
                        {
                          this.state.property.contentDamage.value === 'content_was_damaged' &&
                            <div className="row">
                              <div className="col-xs-12">
                                <p>{ 'What is the estimated cost to replace the contents damaged?' }</p>

                                <DollarValueField name="estimatedCostOfContentDamage"
                                                  onChange={ this.handleChangeFor("estimatedCostOfContentDamage") }
                                                  label="Estimated Loss Value for Content Damage"
                                                  value={ this.state.property.estimatedCostOfContentDamage.value }
                                                  errorMessage={ this.state.property.estimatedCostOfContentDamage.errorMessage }
                                                  tabIndex="13" />
                              </div>
                            </div>
                        }
                      </div>
                  }

                  <hr />

                  <div className="row">
                    <div className="col-xs-12">
                      <h4>Miscellaneous</h4>

                      <p>{ 'Any additional comments to report at this time?' }</p>

                      <TextField name="additionalComments"
                                 type="textarea"
                                 onChange={ this.handleChangeFor("additionalComments") }
                                 label="Type..."
                                 value={ this.state.property.additionalComments.value }
                                 errorMessage={ this.state.property.additionalComments.errorMessage }
                                 tabIndex="14"
                                 warn={ (value) => ( value && `Character limit: ${value.length}/255` ) } />
                    </div>
                  </div>
                </div>
            }
          </PanelBody>

          <PanelControls>
            <Prompt when={ this.anyChangesMade("property", this.props.property) }
                    message="Are you sure you want to move away? Any unsaved changes
                             made to this section will be lost." />

            { this.renderControlGroup() }
          </PanelControls>
        </PanelRightColumn>
      </Panel>
    )
  }
}

PropertiesForm = connect(
  (state, props) => ({
    updateSucceeded: state.wizard.updateSucceeded,
    updating:        state.wizard.updating,

    propertyTypeOptions: state.rails.enumOptionsForSelect["DamagedProperty"].propertyType,

    apiEndpoint:     apiEndpoint(state.rails, "incident_report")
  }),
  null,
  ({ apiEndpoint, ...stateProps }, { dispatch }, props) => ({
    ...stateProps,
    ...props,

    asyncUpdateProperties: (data) => {
      return asyncUpdateProperties(apiEndpoint.path, data)(dispatch)
    },

    resetWizardState: () => { dispatch(resetWizardState()) }
  })
)(PropertiesForm);

export default PropertiesForm
