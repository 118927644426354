import React from "react"
import { connect } from 'react-redux'

import SurveyStatusMessage from './survey_status_message.jsx'
import EditableSurveyHeader from './editable_survey_header.jsx'
import EditableSurveyTabList from './editable_survey_tab_list.jsx'
import EditableSurveyTab from './editable_survey_tab.jsx'

let EditableSurvey = ({ status }) => (
    <div>
        <SurveyStatusMessage status={ status } />

        <div className="panel panel-default panel-incident-form">
            <div className="panel-heading">
                <EditableSurveyHeader />
            </div>

            <div className="panel-body">
                <div className="panel-columns">
                    <EditableSurveyTabList />
                    <EditableSurveyTab />
                </div>
            </div>
        </div>
    </div>
)

export default connect(
    (state, props) => ({
        status: state.survey.status
    })
)(EditableSurvey)
