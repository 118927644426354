import React from 'react'
import { connect } from 'react-redux'

import { changeTab } from 'apps/cyber/shared/ducks/tabs.js'
import { activeCoverage } from 'apps/cyber/shared/ducks/coverage_types.js'

let SurveyTabLink = ({ active, switchActiveCoverage, coverage }) => (
    <a onClick={ switchActiveCoverage }
       className={ active ? 'active' : '' }>

        { coverage.alias || coverage.name }
    </a>
)

SurveyTabLink = connect(
    (state, props) => ({
        active: activeCoverage(state.coverageTypes, state.tab).id == props.coverage.id
    }),

    null,

    (stateProps, dispatchProps, ownProps) => ({
        ...stateProps,
        ...dispatchProps,
        ...ownProps,

        switchActiveCoverage: () => {
            dispatchProps.dispatch(changeTab(ownProps.index));
        }
    })
)(SurveyTabLink)

export default SurveyTabLink
