import React from 'react'
import { Switch, Route, Link, Redirect } from 'react-router-dom'

import VehiclesIndex from './vehicles_index.jsx'
import VehiclesNew from './vehicles_new.jsx'
import VehiclesEdit from './vehicles_edit.jsx'

let VehiclesRouter = () => (
  <Switch>
    <Route exact path="/vehicles"          component={ VehiclesIndex } />
    <Route exact path="/vehicles/new"      component={ VehiclesNew } />
    <Route       path="/vehicles/:id/edit" component={ VehiclesEdit } />
  </Switch>
)

export default VehiclesRouter
