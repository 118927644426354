import React from 'react'
import PropTypes from 'prop-types';

export default class ToggleSwitchField extends React.Component {
  onChange = (event) => {
    this.props.onChange(event.target.checked)
  }

  render() {
    const {
      name,
      errorMessage,
      labels,
      value,
      tabIndex,
      disabled,
      onChange
    } = this.props

    return (
      <div className="form-group toggle-switch-group">
        <div className={ `toggle-switch-container ${errorMessage ? 'has-error' : ''}` }>
          <span>{ labels.no }</span>

          <label className={`toggle-switch ${disabled ? '-is-disabled' : ''}`}>
            <input type="checkbox"
                   id={ name }
                   name={ name }
                   value={ value }
                   checked={ value }
                   tabIndex={ tabIndex }
                   disabled={ disabled }
                   onChange={ this.onChange } />

            <div className="toggle-slider -round"></div>
          </label>

          <span>{ labels.yes }</span>
          { errorMessage && <span className="field-level-error">{ errorMessage }</span> }
        </div>
      </div>
    )
  }
}

ToggleSwitchField.defaultProps = {
  labels: { yes: "Yes", no: "No" }
}

ToggleSwitchField.propTypes = {
  name:         PropTypes.string.isRequired,
  labels:       PropTypes.object,

  errorMessage: PropTypes.string,
  value:        PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  tabIndex:     PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled:     PropTypes.bool,

  onChange: (props) => {
    if (!props.disabled) {
      if (typeof props.onChange !== 'function') {
        return new Error('If ToggleSwitchField is not ReadOnly, then onChange must be defined');
      }
    }
  }
}
