const requiredValidator = (message = "Required") => function(value, state) {
  // be explicit here because we may require a whose value resolves to true or false. using
  // `!value` won't catch those
  if ((value === null || value === undefined || value === '') ||
      (typeof value === "string" && value.trim() === '') ||
      (Array.isArray(value) && value.length === 0)) {

    return message;
  }

  return null;
};

export default requiredValidator;
