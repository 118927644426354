import React from 'react'
import { connect } from 'react-redux'

import { dispatchCustomEvent } from 'shared/utils/events.js'

import { asyncUpdateAttachment } from '../utils/api.js'

import EditDocumentModal from './edit_document_modal.jsx'

class EditDocumentContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = { document: null }
  }

  componentDidMount() {
    // Catch clicks on links that should open this modal
    document.addEventListener('click', (event) => {
      if(event.target.classList.contains("bc-edit-title")) {
        event.stopPropagation()

        // grab the doc record JSON from the data-document attribute
        let document = JSON.parse(event.target.getAttribute('data-document'))

        this.setState({ document: document })

        return false
      }
    })
  }

  handleTitleChange = (newTitle) => {
    this.setState((prevState) => ({
      document: {
        ...prevState.document,
        title: newTitle
      }
    }))
  }

  stopEditing = () => {
    this.setState({ document: null })
  }

  saveDocument = () => {
    this.setState({ saving: true })

    asyncUpdateAttachment(this.state.document, this.state.document.download_url, {
      onSuccess: () => {
        this.setState({ saving: false }, () => {
          this.notifyOnEditSuccess()
          this.stopEditing()
        })
      }
    })
  }

  notifyOnEditSuccess = (row) => {
    dispatchCustomEvent(this, 'business_continuity:edited', row)
  }

  render() {
    return !this.state.document ? null : (
      <EditDocumentModal
        document={ this.state.document }
        handleCloseModal={ this.stopEditing }
        handleSaveDocument={ this.saveDocument }
        handleTitleChange={ this.handleTitleChange }
        saving={ this.state.saving }
        />
    )
  }
}

export default EditDocumentContainer
