import { FormField } from 'shared/components/fields'
import { fileSizeValidator, mimeTypeValidator } from 'shared/utils/validators'

export const formFieldsFromFileList = (files, allowableMimeTypes) => {
  return convertFileListToFormFields(
           files,
           allowableMimeTypes,
           (formItem, file) => {
             formItem.title = new FormField('')
             return formItem
           })
}

/**
 * Takes in a FileList from an `input[type=file]`, converts each File
 * to an object of FormFields and returns them as an array.
 *
 * - formItemCallback: Allows the caller to add custom fields to each object. It's called with 2 arguments: the new formItem object and the native File object.
 */
const convertFileListToFormFields = (files, allowableMimeTypes, formItemCallback = () => {}) => {
  let formItems = []

  for (let i = 0; i < files.length; i++) {
    console.log("File " + i)
    let formItem = {
      _id:      Date.now() + i,
      document: new FormField(files[i]),

      contentType: new FormField('', mimeTypeValidator(allowableMimeTypes)),
      filename:    new FormField(files[i].name),
      url:         new FormField(''),
      size:        new FormField(files[i].size, fileSizeValidator(52428800, "File is too large (> 50 MB)")),

      updating:        new FormField(false),
      reading:         new FormField(false),
      changed:         new FormField(true),
      percentComplete: new FormField(0),
      error:           new FormField('')
    }

    formItem = formItemCallback(formItem, files[i])

    formItems.push(formItem)
  }

  return formItems
}
