import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import OneAsyncSelectToRuleThemAll from "shared/components/one_async_select_to_rule_them_all.jsx";

class SearchInsuredVehiclesField extends React.Component {
  constructor(props) {
    super(props);

    this.state = { value: "" };
  }

  getVehicles = (input) => {
    return fetch(this.props.loadPath, { credentials: "same-origin" })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        return { options: json, complete: true };
      });
  };

  onChange = (option) => {
    this.setState({ value: option && option.vin }, () => {
      this.props.onChange(option);
    });
  };

  render() {
    return (
      <OneAsyncSelectToRuleThemAll.Base
        labelText="Search by VIN, make, or model"
        tabIndex={this.props.tabIndex}
        value={this.state.value}
        onChange={this.onChange}
        loadOptions={this.getVehicles}
        valueKey="vin"
        labelKey="label"
        simpleValue={false}
      />
    );
  }
}

SearchInsuredVehiclesField.propTypes = {
  loadPath: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,

  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SearchInsuredVehiclesField = connect((state, props) => ({
  loadPath: `${state.rails.asyncHelpers.searchInsuredVehicles}?member_number=${state.rails.memberNumber}`,
}))(SearchInsuredVehiclesField);

export default SearchInsuredVehiclesField;
