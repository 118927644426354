const LOAD_SUBMISSION_ERRORS  = "grant_request_form/submissionErrors/LOAD_SUBMISSION_ERRORS"
const RESET_SUBMISSION_ERRORS = "grant_request_form/submissionErrors/RESET_SUBMISSION_ERRORS"

// example state:
// {
//   grantRequest: {
//     [field]: { path: "", message: "" }
//   },
//
//   attachments: { path: "", message: "has text if there's a problem" },
//   lineItems:   { path: "", message: "it doesn't make sense to validate these per--top level errors only"}
// }
//
const initialState = {
  grantRequest: {},
  attachments: {},
  lineItems: {}
}

export default function submissionErrors(state = initialState, action) {
  switch (action.type) {
    case LOAD_SUBMISSION_ERRORS: {
      return { ...initialState, ...action.errors }
    }

    case RESET_SUBMISSION_ERRORS: {
      return { ...initialState }
    }

    default: {
      return state
    }
  }
}

export const loadSubmissionErrors = (errors) => {
  return { type: LOAD_SUBMISSION_ERRORS, errors: errors }
}

export const resetSubmissionErrors = () => {
  return { type: RESET_SUBMISSION_ERRORS }
}
