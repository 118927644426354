import React from 'react'
import { Field } from 'redux-form'

import {
  BootstrapInputField,
  BootstrapSelect,
  BootstrapTextArea,
  ErrorDivAndSpanWrapper
} from 'shared/components/redux_form/bootstrap_inputs.jsx'
import FileInput from './file_input.jsx'

const Fields = () => (
  <div>
    <div className="row">
      <div className="col-xs-12">
        <p>
          Select a local document to be uploaded. The <em>Document Name</em> may be overwritten
          with a ‘friendly’ name that is more descriptive. Additional details or instructions
          may be added in the <em>Notes</em> field.
        </p>
      </div>
    </div>

    <div className="row">
      <div className="col-xs-12">
        <Field name="file" component={ ErrorDivAndSpanWrapper }>
          <FileInput form={ "document" } name="file" fieldToPopulateWithFilename="title" />
        </Field>
      </div>
    </div>

    <div className="row">
      <div className="col-xs-12 col-sm-6">
        <Field name="title"
          component={ BootstrapInputField } type="text"
          labelText="Document Name"
          requiredInput={ true }
          shouldFloatLabelOnUpdate={ true }
        />
      </div>

      <div className="col-xs-12 col-sm-6">
        <Field name="documentType"
               component={ BootstrapSelect }
               labelText="DocumentType"
               inputClass="form-control form-control-state disabled"
               disabled={ true }>

          <option value="General to VRSA">General to VRSA</option>
        </Field>
      </div>
    </div>

    <div className="row">
      <div className="col-xs-12">

        <Field component={ BootstrapTextArea }
          name="notes"
          labelText="Notes"
        />

      </div>
    </div>
  </div>
)

export default Fields
