import RailsApi from 'shared/utils/rails_api.js'

import { refreshMetaData } from './rails.js'
import { setErrorFlash, setSuccessFlash, updateSucceeded, updateFailed, updateStarted } from './wizard.js'

const LOAD   = "event_form/eventEmail/LOAD"
const UPDATE = "event_form/eventEmail/UPDATE"

export default function eventEmail(state = {}, action) {
  switch (action.type) {
    case LOAD: {
      return { ...action.eventEmail }
    }

    case UPDATE: {
      return { ...state, ...action.attributes }
    }

    default: {
      return state
    }
  }
}

export const loadEventEmail = (eventEmail) => {
  return { type: LOAD, eventEmail: eventEmail }
}

export const updateEventEmail = (eventEmail) => {
  const { ...attributes } = eventEmail

  return { type: UPDATE, attributes: attributes }
}

export const asyncUpdateEventEmail = (url, data, updated) => (dispatch) => {
  dispatch(updateStarted())

  RailsApi.patch(url, { body: data }).
    then((json) => {
      dispatch({
        type: 'RAILS_UPDATE_SUCCEEDED',
        actions: [
          refreshMetaData({ ...json.meta }),
          updateEventEmail(json.eventEmail),
          updateSucceeded(),
          setSuccessFlash("Event Updated", { keep: updated })
        ]
      })
    }).
    catch((json) => {
      dispatch({
        type: 'RAILS_UPDATE_FAILED',
        actions: [
          updateFailed(),
          setErrorFlash(json.error.message, { details: json.error.details })
        ]
      })
    })
}
