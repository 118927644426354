import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import ActionWithConfirmation from '../action_with_confirmation.jsx'
import ControlButton from 'shared/components/control_button.jsx'

import { submitQuestionToRails, canLockOrHideQuestion } from '../../ducks/survey.js'

class HideSectionQuestionButton extends React.Component {
  state = { confirmingHide: false }

  handleHideClick = () => { this.setState({ confirmingHide: true }) }
  handleModalHide = () => { this.setState({ confirmingHide: false }) }

  hideQuestion = (record, ...args) => {
    submitQuestionToRails({ ...record, status: 'disabled_and_hidden' }, ...args)
  }

  showQuestion = (record, ...args) => {
    submitQuestionToRails({ ...record, status: 'enabled' }, ...args)
  }

  render() {
    const confirmModalArgs = {
      record: this.props.sectionQuestion,
      apiResource: "sectionQuestions",
      apiMode: "update",
      handleHide: this.handleModalHide
    }

    const hideQuestion = (
      <ActionWithConfirmation
        title="Confirm Question Hide"
        body="Are you sure you want to hide this question? It will be hidden from the survey, and no longer scored."
        confirmText="Hide Question"
        onConfirm={ this.hideQuestion }
        { ...confirmModalArgs }
      />
    )

    const showQuestion = (
      <ActionWithConfirmation
        title="Confirm Question Show"
        body="Are you sure you want to show this question? It will become visible and editable on the survey."
        confirmText="Show Question"
        onConfirm={ this.showQuestion }
        { ...confirmModalArgs }
      />
    )

    const iconClass = this.props.sectionQuestion.status == 'disabled_and_hidden' ?
                        'fa fa-eye text-brand-yellow' :
                        'fa fa-eye-slash'

    return !this.props.canLockOrHideQuestion ? ( null) : (
      <div>
        <ControlButton
          handler={ this.handleHideClick }
          iconClass={ iconClass }
        />

        { this.state.confirmingHide &&
          ( this.props.sectionQuestion.status == 'disabled_and_hidden' ?
              showQuestion :
              hideQuestion )
        }
      </div>
    )
  }
}

HideSectionQuestionButton.propTypes = {
  sectionQuestion:  PropTypes.object.isRequired
}

HideSectionQuestionButton = connect(
  (state, props) => ({
    canLockOrHideQuestion: canLockOrHideQuestion(state.survey)
  })
)(HideSectionQuestionButton)

export default HideSectionQuestionButton
