import { convertToDate } from 'shared/utils/rails_datetime_formatters.js'
import { extractDateFromRailsDateTime  } from 'shared/utils';

const notAfterValidator = (date) => (value, _state) => {
  if (value && convertToDate(value) > convertToDate(date)) {
    return `Date can't be after ${extractDateFromRailsDateTime(date)}`
  }

  return null;
};

export default notAfterValidator;
