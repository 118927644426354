import React from 'react'
import PropTypes from 'prop-types';

export default class FileField extends React.Component {
  onChange = (event) => {
    // In IE11, the onchange event fires when target.value gets reset to ''.
    // We don't want this change event to fire, because we're counting on this
    // event to be a useful indicator of files being selected :P.
    if(event.target.value === '' && event.target.files.length === 0) {
      return
    } else {
      let files = this.props.multiple ? event.target.files : event.target.files[0]
      this.props.onChange(files)

      // Clear the file field after firing the event with the new files
      event.target.value = ''
    }
  }

  render() {
    const {
      name,
      required,
      withoutBootstrapMarkup,
      bootstrapWrapperClassName,
      className,
      errorMessage,
      label,
      placeholder,
      readOnly,
      type,
      instructions,
      tabIndex,
      autoFocus,
      multiple
    } = this.props

    let wrapperClass = [withoutBootstrapMarkup ? "" : "form-group"].
      concat(bootstrapWrapperClassName || []).
      concat(errorMessage ? "has-error" : "").
      concat(required && required.warn ? "is-required-but-warn-about-it" : "").
      concat(required && (required.warn === null || required.warn === undefined) ? "is-required" : "").
      filter((n) => n).
      join(' ')

    let inputClass = [withoutBootstrapMarkup ? "" : "form-control"].
      concat(className || []).
      filter((n) => n).
      join(' ')

    return (
      <div className={ wrapperClass }>
        {
          label &&
            <label ref={ (label) => { this.label = label } } htmlFor={ name }>
              { label }{ required ? <span></span> : "" }
            </label>
        }

        <input
          ref={ (input) => { this.input = input } }
          className={ inputClass }
          id={ name }
          name={ name }
          placeholder= { placeholder }
          readOnly={ readOnly }
          type={ type }
          tabIndex={ tabIndex }
          autoFocus={ autoFocus }
          onChange={ this.onChange }
          multiple={ multiple }
        />

        {
          errorMessage && <span className="field-level-error">{ errorMessage }</span>
        }

        { instructions && <p>{ instructions }</p> }
      </div>
    )
  }
}

FileField.defaultProps = {
  required: false,
  type:     'file',
  withoutBootstrapMarkup: false,
  shouldFloatLabelOnUpdate: false
};

FileField.propTypes = {
  name:                      PropTypes.string.isRequired,
  required:                  PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  withoutBootstrapMarkup:    PropTypes.bool.isRequired,

  bootstrapWrapperClassName: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  className:                 PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),

  errorMessage:              PropTypes.string,
  label:                     PropTypes.string,
  placeholder:               PropTypes.string,
  readOnly:                  PropTypes.bool,
  type:                      PropTypes.string,
  instructions:              PropTypes.string,
  tabIndex:                  PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoFocus:                 PropTypes.bool,
  shouldFloatLabelOnUpdate:  PropTypes.bool,

  onChange: (props) => {
    if (!props.readOnly) {
      if (typeof props.onChange !== 'function') {
        return new Error('If FileField is not ReadOnly, then onChange must be defined');
      }
    }
  }
};
