import React from "react";
import { connect } from "react-redux";

import { activeCoverage } from "apps/cyber/shared/ducks/coverage_types.js";
import { filterSectionsToCoverage } from "apps/cyber/shared/ducks/survey.js";

import { default as SurveySection } from "./survey_section.jsx";
import { default as SurveyReview } from "./survey_review.jsx";

let SurveyTab = ({
                     activeCoverage,
                     sections,
                     renderAsPreview,
                     renderAsReadOnly,
                     saveAndExitLink,
                 }) => (
    <div className="panel-body-right">
        <div className="panel-body-right-inner-padding">
            <div>
                <h2 className="coverage-title">
                    {activeCoverage.alias || activeCoverage.name}
                    {activeCoverage.id === "review" && (
                        <a
                            onClick={() => {
                                let element = document.querySelector(
                                    '[data-behavior="printed-at-timestamp"]'
                                );
                                element.innerHTML = `Printed at: ${moment().format(
                                    "hh:mm A MM/DD/YYYY"
                                )}`;
                            }}
                            className="btn btn-success pull-right"
                            href="javascript:window.print()"
                        >
                            Print
                        </a>
                    )}
                </h2>
            </div>

            {renderAsPreview && (
                <div className="alert alert-warning">
                    <div className="alert-warning">
                        This is a preview of the Cybersecurity Assessment as members would
                        see it. Your responses will not be saved.
                    </div>
                </div>
            )}

            {activeCoverage.id !== "review" &&
                sections.map((section) => {
                    return (
                        Object.values(section.cyberSectionQuestionsAttributes).length > 0 && (
                            <SurveySection
                                key={section.id}
                                id={section.id}
                                header={section.header}
                                content={section.content}
                                questions={section.cyberSectionQuestionsAttributes}
                            />
                        )
                    );
                })}
            {activeCoverage.id === "review" && <SurveyReview />}
        </div>

        {!renderAsReadOnly && (
            <div className="panel-controls">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="pull-right">
                            <a href={saveAndExitLink.link} className="btn btn-success">
                                Save and Exit
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </div>
);

SurveyTab = connect((state, props) => {
    const _activeCoverage = activeCoverage(state.coverageTypes, state.tab);
    console.log('ExitLink:', state.saveAndExitLink)
    return {
        activeCoverage: _activeCoverage,
        sections: filterSectionsToCoverage(state.survey, _activeCoverage),

        renderAsPreview: state.lookups.renderAsPreview,
        renderAsReadOnly: state.lookups.renderAsReadOnly,
        saveAndExitLink: state.saveAndExitLink,
    };
})(SurveyTab);

export default SurveyTab;
