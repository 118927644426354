import React from 'react'

let Panel = ({ children }) => (
  <div className="panel-body">
    <div className="panel-columns">
      { children }
    </div>
  </div>
)

export default Panel
