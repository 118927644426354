import React from 'react';
import { extractDateFromRailsDateTime } from 'shared/utils/rails_datetime_formatters.js'

class EarliestDatePicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = { dateValue: (this.props.value && extractDateFromRailsDateTime(this.props.value)) };

        this.floatLabels      = this.floatLabels.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    floatLabels() {
        let label_floater = new App.LabelFloater({
            $label: $(this.dateLabel),
            $input: $(this.dateInput) }
        )

        label_floater.initialize();
    }

    handleDateChange(event) {
        let formatted = (event.target.value || "").replace(/[\D]/g, '').slice(0, 8);

        if (!formatted) {
            formatted = "";
        }
        else if (formatted.length <= 2) {
            formatted = formatted;
        }
        else if (formatted.length <= 4) {
            formatted = `${formatted.slice(0, 2)}/${formatted.slice(2)}`
        }
        else {
            formatted = `${formatted.slice(0, 2)}/${formatted.slice(2, 4)}/${formatted.slice(4, 8)}`
        }

        this.setState({ dateValue: formatted });

        // call the handleDateChange property we passed in so that we whoever lives above us knows
        // this date has changed
        event.target.value = formatted
        this.props.handleDateChange(event);
    }

    componentDidMount() {
        $(this.dateInput).on('changeDate', this.handleDateChange);

        this.floatLabels();
    }

    componentDidUpdate() {
        this.floatLabels();
    }

    render() {
        return (
            <div className="form-inline datetime-group">
                <div className="form-group input-group">
          <span className="input-group-addon" data-behavior="datepicker-icon">
            <i className="fa fa-calendar"></i>
          </span>

                    <label ref={ (label) => { this.dateLabel = label } }>
                        Date Limitation
                    </label>

                    <input
                        type="text"
                        className="form-control"
                        data-behavior="datepicker"
                        ref={ (input) => { this.dateInput = input; } }
                        name={ this.props.name }
                        value={ this.state.dateValue }
                        onChange={ this.handleDateChange }
                    />
                </div>

                <small className="help-block">
                    Select the furthest date (month/year) in the past an Entity may choose as an answer to this question.
                </small>
            </div>
        )
    }
}

export default EarliestDatePicker;
