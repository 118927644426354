const LOAD_SUBMISSION_ERRORS  = "event_form/submissionErrors/LOAD_SUBMISSION_ERRORS"
const RESET_SUBMISSION_ERRORS = "event_form/submissionErrors/RESET_SUBMISSION_ERRORS"
const RESET_ERRORS_FOR_EVENT = "event_form/submissionErrors/RESET_ERRORS_FOR_EVENT"

const initialState = {
  event: {},
}

export default function submissionErrors(state = initialState, action) {
  switch (action.type) {
    case LOAD_SUBMISSION_ERRORS: {
      return { ...initialState, ...action.errors }
    }

    case RESET_SUBMISSION_ERRORS: {
      return { ...initialState }
    }

    case RESET_ERRORS_FOR_EVENT: {
      return {
        ...state,
        event: {}
      }
    }

    default: {
      return state
    }
  }
}

export const loadSubmissionErrors = (errors) => {
  return { type: LOAD_SUBMISSION_ERRORS, errors: errors }
}

export const resetSubmissionErrors = () => {
  return { type: RESET_SUBMISSION_ERRORS }
}

export const resetErrorsForEvent = () => {
  return { type: RESET_ERRORS_FOR_EVENT }
}
