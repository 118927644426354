import React from 'react'
import { connect } from 'react-redux'
import { Link, Redirect, Prompt } from 'react-router-dom'

import BaseForm from 'shared/components/base_form.jsx'

import {
  FormField,
  TextField,
  PhoneNumberField,
  SelectField
} from 'shared/components/fields'

import {
  requiredValidator,
  emailValidator,
  phoneNumberValidator
} from 'shared/utils/validators'

import { asyncUpdateIncidentReport } from '../ducks/incident_report.js'
import { apiEndpoint } from '../ducks/rails.js'
import { resetWizardState } from '../ducks/wizard.js'
import { resetErrorsForIncidentReport } from '../ducks/submission_errors.js'
import { Panel, PanelSidebar, PanelRightColumn, PanelBody, PanelControls } from '../panel'

class ContactDetailsForm extends BaseForm {
  constructor(props) {
    super(props)

    const incidentReport = { ...(this.props.incidentReport || {}) }
    const errors = { ...(this.props.errors || {}) }

    this.state = {
      incidentReport: {
        claimContactName: new FormField(
          incidentReport.claimContactName || props.prefilledClaimContact.name,
          requiredValidator()
        ),

        claimContactPhone: new FormField(
          incidentReport.claimContactPhone || props.prefilledClaimContact.phoneNumber,
          [requiredValidator(), phoneNumberValidator()]
        ),

        claimContactJobTitle: new FormField(
          incidentReport.claimContactJobTitle || props.prefilledClaimContact.jobTitle,
          requiredValidator()
        ),

        claimContactEmail: new FormField(
          incidentReport.claimContactEmail || props.prefilledClaimContact.email,
          [requiredValidator(), emailValidator()]
        ),

        claimContactBestMethod: new FormField(incidentReport.claimContactBestMethod),
        claimContactBestTime:   new FormField(incidentReport.claimContactBestTime)
      }
    }

    // add any errors we found when attempting to submit
    Object.keys(this.state.incidentReport).map((key) => {
      this.state.incidentReport[key].errorMessage = (errors[key] || {}).message
    })
  }

  handleChangeFor = (name) => ( this.handleFieldChange("incidentReport", name) )

  componentWillUnmount() {
    this.props.resetWizardState()
    this.props.resetSubmissionErrors()
  }

  prepareData = () => {
    let copy = { incidentReport: { ...this.state.incidentReport } }

    // turn FormField objects in to just the raw value
    Object.keys(copy.incidentReport).map((key) => {
      copy.incidentReport[key] = copy.incidentReport[key].value
    })

    return copy
  }

  handleSave = (event) => {
    event.preventDefault()

    if (this.validateFields("incidentReport")) {
      this.props.asyncUpdateIncidentReport(this.prepareData())
    }
  }

  render() {
    if (this.props.updateSucceeded) {
      return <Redirect to="/persons" />
    }

    return (
      <Panel>
        <PanelSidebar />

        <PanelRightColumn>
          <PanelBody>
            <div className="row">
              <div className="col-xs-12">
                <h3>Contact Details</h3>

                <p>{ `Who is the main point of contact at ${this.props.organizationName} for this occurrence report?` }</p>
              </div>

              <div className="col-xs-6">
                <TextField name="claimContactName"
                           onChange={ this.handleChangeFor("claimContactName") }
                           label="Contact Name"
                           value={ this.state.incidentReport.claimContactName.value }
                           errorMessage={ this.state.incidentReport.claimContactName.errorMessage }
                           required={ true }
                           tabIndex="1" />

                <PhoneNumberField name="claimContactPhone"
                                  onChange={ this.handleChangeFor("claimContactPhone") }
                                  label="Contact Phone"
                                  value={ this.state.incidentReport.claimContactPhone.value }
                                  errorMessage={ this.state.incidentReport.claimContactPhone.errorMessage }
                                  required={ true }
                                  tabIndex="3" />
              </div>

              <div className="col-xs-6">
                <TextField name="claimContactJobTitle"
                           onChange={ this.handleChangeFor("claimContactJobTitle") }
                           label="Contact Job Title"
                           value={ this.state.incidentReport.claimContactJobTitle.value }
                           errorMessage={ this.state.incidentReport.claimContactJobTitle.errorMessage }
                           required={ true }
                           tabIndex="2" />

                <TextField name="claimContactEmail"
                           onChange={ this.handleChangeFor("claimContactEmail") }
                           label="Contact Email Address"
                           value={ this.state.incidentReport.claimContactEmail.value }
                           errorMessage={ this.state.incidentReport.claimContactEmail.errorMessage }
                           required={ true }
                           tabIndex="4" />
              </div>

              <div className="col-xs-12">
                <p><i>We've prefilled this information as best we can, so please verify it's correct.</i></p>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <hr />
                <h4>Contact Preferences</h4>
              </div>

              <div className="col-xs-12">
                <SelectField name="claimContactBestMethod"
                             onChange={ this.handleChangeFor("claimContactBestMethod") }
                             label="What is your preferred contact method?"
                             placeholder="Select..."
                             value={ this.state.incidentReport.claimContactBestMethod.value }
                             errorMessage={ this.state.incidentReport.claimContactBestMethod.errorMessage }
                             tabIndex="5"
                             options={ this.props.claimContactBestMethodOptions } />
              </div>

              <div className="col-xs-12">
                <SelectField name="claimContactBestTime"
                             onChange={ this.handleChangeFor("claimContactBestTime") }
                             label="What is the best time to contact you about this report?"
                             placeholder="Select..."
                             value={ this.state.incidentReport.claimContactBestTime.value }
                             errorMessage={ this.state.incidentReport.claimContactBestTime.errorMessage }
                             tabIndex="6"
                             options={ this.props.claimContactBestTimeOptions } />
              </div>
            </div>
          </PanelBody>

          <PanelControls>
            <Prompt when={ this.anyChangesMade("incidentReport", this.props.incidentReport) }
                    message="Are you sure you want to move away? Any unsaved changes
                             made to this section will be lost." />

            <Link to="/overview">
              <button type="button"
                      className="btn btn-link">
                Back
              </button>
            </Link>

            <Link to="/persons" onClick={ this.handleSave }>
              <button type="button"
                      className={ `btn btn-success ${this.props.updating ? "disabled" : ""}` }>

                Save & Continue
              </button>
            </Link>
          </PanelControls>
        </PanelRightColumn>
      </Panel>
    )
  }
}

ContactDetailsForm = connect(
  (state, props) => ({
    incidentReport:         {
      claimContactName:       state.incidentReport.claimContactName,
      claimContactPhone:      state.incidentReport.claimContactPhone,
      claimContactJobTitle:   state.incidentReport.claimContactJobTitle,
      claimContactEmail:      state.incidentReport.claimContactEmail,
      claimContactBestMethod: state.incidentReport.claimContactBestMethod,
      claimContactBestTime:   state.incidentReport.claimContactBestTime
    },

    errors: state.submissionErrors.incidentReport,

    claimContactBestMethodOptions: state.rails.enumOptionsForSelect["IncidentReport::Base"].claimContactBestMethod,
    claimContactBestTimeOptions:   state.rails.enumOptionsForSelect["IncidentReport::Base"].claimContactBestTime,

    organizationName: state.rails.organizationName,

    updateSucceeded:        state.wizard.updateSucceeded,
    updating:               state.wizard.updating,
    apiEndpoint:            apiEndpoint(state.rails, "incident_report"),

    prefilledClaimContact: { ...state.rails.claimContact }
  }),
  null,
  ({ apiEndpoint, ...stateProps }, { dispatch }, props) => ({
    ...stateProps,
    ...props,

    asyncUpdateIncidentReport: (data) => {
      return asyncUpdateIncidentReport(apiEndpoint.path, data)(dispatch)
    },

    resetWizardState: () => { dispatch(resetWizardState()) },
    resetSubmissionErrors: () => { dispatch(resetErrorsForIncidentReport()) }
  })
)(ContactDetailsForm);

export default ContactDetailsForm
