import React from 'react'
import Label from './label.jsx'
import Response from './response.jsx'

import { formatZip } from 'shared/utils/addresses.js'

const Address = ({
  label,
  streetAddress,
  optionalLine,
  city,
  state,
  zip,
  missingText = "Unspecified"
}) => (
  <div className={ !streetAddress ? "unanswered" : "" }>
    <Label>{ label }</Label>
    {
      streetAddress ?
        <Response className='address'>
          <div>{ streetAddress }</div>
          {
            optionalLine &&
            <div>{ optionalLine }</div>
          }
          <div>{ `${city}, ${state} ${formatZip(zip)}` }</div>
        </Response>
      :
        <Response>{ missingText }</Response>
    }
  </div>
)

export default Address;
