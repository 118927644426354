const validateEmail = (value) => {
  const REGEX = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i
  return (REGEX.test(value)) ? true : false;
}
const commaSeparatedEmailsValidator = (message = "Invalid email(s)") => function(value, state) {
  if (!value) { return null; }

  const emails = value.replace(/,\s/g, ",").split(/,|;/);

    for(let index in emails) {
        if(!validateEmail(emails[index])) {
            return message;
        }
    }

    return null;
};

export default commaSeparatedEmailsValidator;
