import React from 'react'
import { Link } from 'react-router-dom'

const VehiclesCard = ({ handleDeleteClicked, vehicle }) => {
  let description = `${vehicle.year || ''} ${vehicle.makeAndModel || ''}`.trim();

  return (
    <div className="card">
      <header className="card-header">
        <h5 className="card-title">Damaged Vehicle</h5>

        <div className="card-header-controls">
          <Link to={ `/vehicles/${vehicle.id}/edit` }>
            <span className="glyphicon glyphicon-pencil"></span>
          </Link>

          <a onClick={ handleDeleteClicked }>
            <span className="glyphicon glyphicon-trash"></span>
          </a>
        </div>
      </header>

      <div className="card-body">
        <p>
          { description }{ description && <br /> }
          { vehicle.vehicleIdentificationNumber && `VIN: ${vehicle.vehicleIdentificationNumber}` }
        </p>
        {
          vehicle.claimBeingMade ?
            <p>A claim is being made for damages to this vehicle.</p>
          :
            <p>A claim is not being made for damages to this vehicle.</p>
        }
      </div>

      <footer className="card-footer"></footer>
    </div>
  )
}

export default VehiclesCard
