import React from 'react'
import { connect } from 'react-redux'

import Legend from 'shared/components/legend'

let MemberInfo = ({ organizationName, threeCharacterMemberNumber }) => (
  <div>
    <div className="row">
      <div className="col-xs-12">
        <h3>Report Overview</h3>

        <p>
          To begin, we need some basic information regarding this claim. Complete the form below,
          save, and continue with the rest of the claim.
        </p>

        <Legend />
      </div>
    </div>

    <hr />

    <div className="row">
      <div className="col-xs-12">
        <h4>Membership Information</h4>
      </div>
    </div>

    <div className="row">
      <div className="col-xs-6">
        <b>Member Name</b>
        <p>{ organizationName }</p>
      </div>

      <div className="col-xs-6">
        <b>Member Number</b>
        <p>#{ threeCharacterMemberNumber }</p>
      </div>
    </div>

  </div>
)

MemberInfo = connect(
  (state, props) => {
    return {
      organizationName: state.rails.organizationName,
      threeCharacterMemberNumber: state.rails.threeCharacterMemberNumber
    }
  }
)(MemberInfo)

export default MemberInfo;
