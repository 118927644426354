import React from "react";
import { Provider, connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
} from "react-router-dom";

import store from "./store.js";
import { loadMetaData, apiEndpoint } from "./ducks/rails.js";
import { loadGrantRequest } from "./ducks/grant_request.js";
import { loadLineItems } from "./ducks/line_items.js";
import { loadAttachments } from "./ducks/attachments.js";
import { loadEmailsToNotify } from "./ducks/emails_to_notify.js";
import {
  setSuccessFlash,
  openMobileMenu,
  closeMobileMenu,
} from "./ducks/wizard.js";
import railsFactory from "shared/ducks/rails_factory.js";

import MemberInformationRouter from "./member_information/member_information_router.jsx";
import RequestDetailsRouter from "./request_details/request_details_router.jsx";
import AttachmentsRouter from "./attachments/attachments_router.jsx";
import FinalizeRouter from "./finalize/finalize_router.jsx";
import ReviewRouter from "./review/review_router.jsx";

class AppHeader extends React.Component {
  displayGrantRequestAcceptanceMethod = () => {
    if (!this.props.meta.currentlyAcceptingGrantRequests) {
      return (
        <div className="col-xs-12 col-sm-4" style={{ color: "#e1e8ec" }}>
          {
            this.props.meta
              .grantRequestsMessageToDisplayWhenNotCurrentlyAccepting
          }
        </div>
      );
    }
    return "";
  };

  panelTitleDisplayStyle = () => {
    if (this.props.meta.currentlyAcceptingGrantRequests) {
      return (
        <div className="col-xs-12 col-sm-8">
          <h2 className="panel-title">
            {this.props.grantRequestStatus === "in_progress" ? "Edit" : "New"}{" "}
            Grant Request
          </h2>
        </div>
      );
    }
    return (
      <div className="col-xs-12 col-sm-4">
        <h2 className="panel-title">
          {this.props.grantRequestStatus === "in_progress" ? "Edit" : "New"}{" "}
          Grant Request
        </h2>
      </div>
    );
  };

  //making the two preceding methods into the following two components solves the following error
  //react-dom.development.js:91 Warning: Functions are not valid as a React child.
  //This may happen if you return a Component instead of <Component /> from render.
  //Or maybe you meant to call this function rather than return it.
  PanelTitle = this.panelTitleDisplayStyle;
  DisplayGrantRequestAcceptance = this.displayGrantRequestAcceptanceMethod;

  render() {
    return (
      <div className="row">
        <this.PanelTitle />
        <this.DisplayGrantRequestAcceptance />
        <div className="col-xs-12 col-sm-4 text-right">
          <a
            href="/grant_requests"
            onClick={(event) => {
              if (
                !confirm(
                  "Are you sure you want to move away? Any unsaved changes " +
                    "made to this section will be lost."
                )
              ) {
                event.preventDefault();
              }
            }}
          >
            <button
              className="cancel-btn btn btn-link"
              onClick={(event) => {
                if ("track" in woopra) {
                  woopra.track("rails-link", {
                    "link-url": window.location.href,
                    "link-title": event.target.innerText,
                  });
                }
              }}
            >
              {this.props.grantRequestStatus === "in_progress"
                ? "Finish Later"
                : "Cancel"}
            </button>
          </a>

          <button
            className="mobile-claims-menu-btn btn btn-primary"
            onClick={this.props.toggleMobileMenu}
          >
            <i className="fa fa-ellipsis-v"></i>
          </button>
        </div>
      </div>
    );
  }
}
AppHeader = connect(
  (state, props) => ({
    grantRequestStatus: state.grantRequest.status,
    mobileMenuOpen: state.wizard.mobileMenuOpen,
    meta: state.rails,
  }),
  null,
  ({ mobileMenuOpen, ...otherProps }, { dispatch }, props) => ({
    ...otherProps,
    ...props,

    toggleMobileMenu: () => {
      dispatch(mobileMenuOpen ? closeMobileMenu() : openMobileMenu());
    },
  })
)(AppHeader);

class App extends React.Component {
  constructor(props) {
    super(props);

    const {
      // test: accepting_grant_request,
      lineItemsAttributes: lineItems,
      emailsToNotifyAttributes: emailsToNotify,
      attachmentsAttributes: attachments,
      ...grantRequest
    } = props.grantRequest;

    store.dispatch({
      type: "INITIAL_LOAD_OF_DATA_FROM_RAILS",
      actions: [
        loadMetaData(props.meta),
        loadGrantRequest(grantRequest),
        loadLineItems(lineItems),
        loadAttachments(attachments),
        loadEmailsToNotify(emailsToNotify),
      ],
    });
  }
  render() {
    // figure out what the basename should be based on where rails told us we can save the form to
    const { path, method } = apiEndpoint(this.props.meta, "grant_request");
    const basename = `${path}/${method === "POST" ? "new" : "edit"}`;

    return (
      <Provider store={store}>
        <Router basename={basename}>
          <div className="panel panel-default panel-incident-form">
            <div className="panel-heading">
              <AppHeader />
            </div>

            {/* routing */}
            <Route
              exact
              path="/"
              render={() => <Redirect to="/member_information" />}
            />

            <Route
              path="/member_information"
              component={MemberInformationRouter}
            />
            <Route path="/request_details" component={RequestDetailsRouter} />
            <Route path="/attachments" component={AttachmentsRouter} />
            <Route path="/finalize" component={FinalizeRouter} />
            <Route path="/review" component={ReviewRouter} />
            {/* end routing */}
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
export { AppHeader }; // for testing
