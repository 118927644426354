const LOAD = "rmg/shared/ducks/lookups/LOAD"

export default (state = {}, action) => {
  switch (action.type) {
    case LOAD: {
      return {
        ...state,
        ...action.data
      }
    }

    default: return state;
  }
}

// Store some data, IDed by key
// Example: load(props, 'entityTypes')
export const load = (key, data) => {
  return {
    type: LOAD,
    key: key,
    data: {
      [key]: data
    }
  }
}

export const associatedEntities = (state, entityTypeIds) => {
  if (!entityTypeIds || entityTypeIds.length === 0) { return [{ id: -1, name: "All Types" }] }
  
  return Object.values(state.entityTypes).
    filter( (e) => ( entityTypeIds.includes(e.id) )).
    sort((a, b) => {
      if (a.name < b.name) { return -1 }
      if (a.name > b.name) { return 1 }
      return 0
    })
}
