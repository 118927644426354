import React from 'react'

let WitnessesHeader = () => (
  <div className="row">
    <div className="col-xs-12">
      <h4>Witnesses</h4>

      <p>
        Any witnesses to this occurrence should be recorded here.
      </p>
    </div>
  </div>
)

export default WitnessesHeader
