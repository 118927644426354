import React from "react";
import { connect } from "react-redux";
import { Link, Redirect, Prompt } from "react-router-dom";

import BaseForm from "shared/components/base_form.jsx";

import {
  FormField,
  TextField,
  ToggleSwitchField,
} from "shared/components/fields";

import { requiredValidator } from "shared/utils/validators";

import {
  extractDateFromRailsDateTime,
  extractTimeFromRailsDateTime,
} from "shared/utils";

import { asyncUpdateEventQuestionSet } from "../ducks/event_question_set";
import { apiEndpoint } from "../ducks/rails";
import { resetWizardState } from "../ducks/wizard";
import { resetErrorsForEvent } from "../ducks/submission_errors";
import {
  Panel,
  PanelSidebar,
  PanelRightColumn,
  PanelBody,
  PanelControls,
} from "../panel";

class EventQuestionsForm extends BaseForm {
  constructor(props) {
    super(props);

    const eventQuestionSet = { ...(this.props.eventQuestionSet || {}) };
    const errors = { ...(this.props.errors || {}) };

    this.state = {
      eventQuestionSet: {
        enablePrefix: new FormField(eventQuestionSet.enablePrefix),
        requirePrefix: new FormField(eventQuestionSet.requirePrefix),

        enableFirstName: new FormField(eventQuestionSet.enableFirstName),
        enableLastName: new FormField(eventQuestionSet.enableLastName),

        enableEmail: new FormField(eventQuestionSet.enableEmail),
        enableOrganizationName: new FormField(
          eventQuestionSet.enableOrganizationName
        ),

        enableOrganizationNumber: new FormField(
          eventQuestionSet.enableOrganizationNumber === null ||
          eventQuestionSet.enableOrganizationNumber === undefined
            ? true
            : eventQuestionSet.enableOrganizationNumber
        ),
        requireOrganizationNumber: new FormField(
          eventQuestionSet.requireOrganizationNumber === null ||
          eventQuestionSet.requireOrganizationNumber === undefined
            ? true
            : eventQuestionSet.requireOrganizationNumber
        ),

        enableJobTitle: new FormField(
          eventQuestionSet.enableJobTitle === null ||
          eventQuestionSet.enableJobTitle === undefined
            ? true
            : eventQuestionSet.enableJobTitle
        ),
        requireJobTitle: new FormField(
          eventQuestionSet.requireJobTitle === null ||
          eventQuestionSet.requireJobTitle === undefined
            ? true
            : eventQuestionSet.requireJobTitle
        ),

        enablePhoneNumber: new FormField(eventQuestionSet.enablePhoneNumber),
        requirePhoneNumber: new FormField(eventQuestionSet.requirePhoneNumber),

        enableBadgeName: new FormField(eventQuestionSet.enableBadgeName),
        requireBadgeName: new FormField(eventQuestionSet.requireBadgeName),

        enableDietaryRestrictions: new FormField(
          eventQuestionSet.enableDietaryRestrictions
        ),
        requireDietaryRestrictions: new FormField(
          eventQuestionSet.requireDietaryRestrictions
        ),

        enableAdditionalAttendees: new FormField(
          this.props.event.eventType === "physical"
            ? false
            : eventQuestionSet.enableAdditionalAttendees
        ),
        requireAdditionalAttendees: new FormField(
          this.props.event.eventType === "physical"
            ? false
            : eventQuestionSet.requireAdditionalAttendees
        ),

        enableCustomQuestionOne: new FormField(
          eventQuestionSet.enableCustomQuestionOne
        ),
        requireCustomQuestionOne: new FormField(
          eventQuestionSet.requireCustomQuestionOne
        ),
        customQuestionOne: new FormField(eventQuestionSet.customQuestionOne),

        enableCustomQuestionTwo: new FormField(
          eventQuestionSet.enableCustomQuestionTwo
        ),
        requireCustomQuestionTwo: new FormField(
          eventQuestionSet.requireCustomQuestionTwo
        ),
        customQuestionTwo: new FormField(eventQuestionSet.customQuestionTwo),

        enableCustomQuestionThree: new FormField(
          eventQuestionSet.enableCustomQuestionThree
        ),
        requireCustomQuestionThree: new FormField(
          eventQuestionSet.requireCustomQuestionThree
        ),
        customQuestionThree: new FormField(
          eventQuestionSet.customQuestionThree
        ),

        enableCustomQuestionFour: new FormField(
          eventQuestionSet.enableCustomQuestionFour
        ),
        requireCustomQuestionFour: new FormField(
          eventQuestionSet.requireCustomQuestionFour
        ),
        customQuestionFour: new FormField(eventQuestionSet.customQuestionFour),
      },
    };

    // add any errors we found when attempting to submit
    Object.keys(this.state.eventQuestionSet).map((key) => {
      this.state.eventQuestionSet[key].errorMessage = (
        errors[key] || {}
      ).message;
    });
  }

  handleChangeFor = (name) => {
    return this.handleFieldChange("eventQuestionSet", name);
  };

  componentWillUnmount() {
    this.props.resetWizardState();
    this.props.resetSubmissionErrors();
  }

  componentDidMount() {
    console.log("PROPS", this.props);
    console.log("STATE", this.state);
  }

  prepareData = () => {
    let copy = { eventQuestionSet: { ...this.state.eventQuestionSet } };

    // turn FormField objects in to just the raw value
    Object.keys(copy.eventQuestionSet).map((key) => {
      copy.eventQuestionSet[key] = copy.eventQuestionSet[key].value;
    });

    return { event: copy };
  };

  handleSave = (event) => {
    event.preventDefault();
    if (!this.validateFields("eventQuestionSet")) {
      return null;
    }

    if (event.target && event.target.href) {
      // looks like we clicked a link in the sidebar
      // since we hijack clicking on the sidebar and attempt to save this form, forcing you
      // to at least deal with this first step before bouncing to anywhere in the form,
      // we need to remember what you clicked so we can send you on your way if everything checked
      // out
      this.setState({
        redirect: event.target.href.split("/edit").slice(1).join("/edit"),
      });
    } else {
      this.setState({ redirect: "/events" });
    }

    if (this.anyChangesMade("eventQuestionSet", this.props.eventQuestionSet)) {
      this.props.asyncUpdateEventQuestionSet(this.prepareData());
    }
  };

  render() {
    if (!this.props.updating) {
      if (this.state.redirect === "/events") {
        window.location = `${window.location.origin}/events`;
      } else if (
        this.state.redirect &&
        this.state.redirect !== this.props.match.path
      ) {
        return <Redirect to={this.state.redirect} />;
      }
    }

    return (
      <Panel>
        <PanelSidebar onLinkClick={this.handleSave} />

        <PanelRightColumn>
          <PanelBody>
            <div className="row">
              <h3>Event Questions</h3>
              <div className="col-xs-12">
                <div className="row questions-section-divider">
                  <h4>Attendee Name</h4>
                  <div className="col-xs-12">
                    <div className="row">
                      <div className="col-xs-6">
                        <p>
                          Enable <strong>Prefix</strong>
                        </p>
                        <ToggleSwitchField
                          name="enablePrefix"
                          onChange={this.handleChangeFor("enablePrefix")}
                          value={this.state.eventQuestionSet.enablePrefix.value}
                          errorMessage={
                            this.state.eventQuestionSet.enablePrefix
                              .errorMessage
                          }
                          tabIndex="11"
                        />
                      </div>
                      {this.state.eventQuestionSet.enablePrefix.value && (
                        <div className="col-xs-6">
                          <p>Require?</p>
                          <ToggleSwitchField
                            name="requirePrefix"
                            onChange={this.handleChangeFor("requirePrefix")}
                            value={
                              this.state.eventQuestionSet.requirePrefix.value
                            }
                            errorMessage={
                              this.state.eventQuestionSet.requirePrefix
                                .errorMessage
                            }
                            tabIndex="12"
                          />
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-xs-12">
                        <p>Require Participant First Name</p>
                        <ToggleSwitchField
                          name="enableFirstName"
                          onChange={this.handleChangeFor("enableFirstName")}
                          value={
                            this.state.eventQuestionSet.enableFirstName.value
                          }
                          errorMessage={
                            this.state.eventQuestionSet.enableFirstName
                              .errorMessage
                          }
                          disabled
                          tabIndex="1"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12">
                        <p>Require Participant Last Name</p>
                        <ToggleSwitchField
                          name="enableLastName"
                          onChange={this.handleChangeFor("enableLastName")}
                          value={
                            this.state.eventQuestionSet.enableLastName.value
                          }
                          errorMessage={
                            this.state.eventQuestionSet.enableLastName
                              .errorMessage
                          }
                          disabled
                          tabIndex="2"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row questions-section-divider">
                  <h4>Attendee Role</h4>
                  <div className="col-xs-12">
                    <div className="row">
                      <div className="col-xs-6">
                        <p>
                          Enable <strong>Job Title</strong>
                        </p>
                        <ToggleSwitchField
                          name="enableJobTitle"
                          onChange={this.handleChangeFor("enableJobTitle")}
                          value={
                            this.state.eventQuestionSet.enableJobTitle.value
                          }
                          errorMessage={
                            this.state.eventQuestionSet.enableJobTitle
                              .errorMessage
                          }
                          tabIndex="7"
                        />
                      </div>
                      {this.state.eventQuestionSet.enableJobTitle.value && (
                        <div className="col-xs-6">
                          <p>Require?</p>
                          <ToggleSwitchField
                            name="requireJobTitle"
                            onChange={this.handleChangeFor("requireJobTitle")}
                            value={
                              this.state.eventQuestionSet.requireJobTitle.value
                            }
                            errorMessage={
                              this.state.eventQuestionSet.requireJobTitle
                                .errorMessage
                            }
                            tabIndex="8"
                          />
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-xs-12">
                        <p>Require Member Entity Name</p>
                        <ToggleSwitchField
                          name="enableOrganizationName"
                          onChange={this.handleChangeFor(
                            "enableOrganizationName"
                          )}
                          value={
                            this.state.eventQuestionSet.enableOrganizationName
                              .value
                          }
                          errorMessage={
                            this.state.eventQuestionSet.enableOrganizationName
                              .errorMessage
                          }
                          disabled
                          tabIndex="4"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-6">
                        <p>
                          Enable <strong>Member Number</strong>
                        </p>
                        <ToggleSwitchField
                          name="enableOrganizationNumber"
                          onChange={this.handleChangeFor(
                            "enableOrganizationNumber"
                          )}
                          value={
                            this.state.eventQuestionSet.enableOrganizationNumber
                              .value
                          }
                          errorMessage={
                            this.state.eventQuestionSet.enableOrganizationNumber
                              .errorMessage
                          }
                          tabIndex="5"
                        />
                      </div>
                      {this.state.eventQuestionSet.enableOrganizationNumber
                        .value && (
                        <div className="col-xs-6">
                          <p>Require?</p>
                          <ToggleSwitchField
                            name="requireOrganizationNumber"
                            onChange={this.handleChangeFor(
                              "requireOrganizationNumber"
                            )}
                            value={
                              this.state.eventQuestionSet
                                .requireOrganizationNumber.value
                            }
                            errorMessage={
                              this.state.eventQuestionSet
                                .requireOrganizationNumber.errorMessage
                            }
                            tabIndex="6"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row questions-section-divider">
                  <h4>Attendee Contact Info</h4>
                  <div className="col-xs-12">
                    <div className="row">
                      <div className="col-xs-12">
                        <p>Require Email Address</p>
                        <ToggleSwitchField
                          name="enableEmail"
                          onChange={this.handleChangeFor("enableEmail")}
                          value={this.state.eventQuestionSet.enableEmail.value}
                          errorMessage={
                            this.state.eventQuestionSet.enableEmail.errorMessage
                          }
                          disabled
                          tabIndex="3"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-6">
                        <p>
                          Enable <strong>Phone Number</strong>
                        </p>
                        <ToggleSwitchField
                          name="enablePhoneNumber"
                          onChange={this.handleChangeFor("enablePhoneNumber")}
                          value={
                            this.state.eventQuestionSet.enablePhoneNumber.value
                          }
                          errorMessage={
                            this.state.eventQuestionSet.enablePhoneNumber
                              .errorMessage
                          }
                          tabIndex="9"
                        />
                      </div>
                      {this.state.eventQuestionSet.enablePhoneNumber.value && (
                        <div className="col-xs-6">
                          <p>Require?</p>
                          <ToggleSwitchField
                            name="requirePhoneNumber"
                            onChange={this.handleChangeFor(
                              "requirePhoneNumber"
                            )}
                            value={
                              this.state.eventQuestionSet.requirePhoneNumber
                                .value
                            }
                            errorMessage={
                              this.state.eventQuestionSet.requirePhoneNumber
                                .errorMessage
                            }
                            tabIndex="10"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row questions-section-divider">
                  <h4>Attendee Preferences</h4>
                  <div className="col-xs-12">
                    <div className="row">
                      <div className="col-xs-6">
                        <p>
                          Enable <strong>Nickname for Name Badge</strong>
                        </p>
                        <ToggleSwitchField
                          name="enableBadgeName"
                          onChange={this.handleChangeFor("enableBadgeName")}
                          value={
                            this.state.eventQuestionSet.enableBadgeName.value
                          }
                          errorMessage={
                            this.state.eventQuestionSet.enableBadgeName
                              .errorMessage
                          }
                          tabIndex="11"
                        />
                      </div>
                      {this.state.eventQuestionSet.enableBadgeName.value && (
                        <div className="col-xs-6">
                          <p>Require?</p>
                          <ToggleSwitchField
                            name="requireBadgeName"
                            onChange={this.handleChangeFor("requireBadgeName")}
                            value={
                              this.state.eventQuestionSet.requireBadgeName.value
                            }
                            errorMessage={
                              this.state.eventQuestionSet.requireBadgeName
                                .errorMessage
                            }
                            tabIndex="12"
                          />
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-xs-6">
                        <p>
                          Enable <strong>Dietary Restrictions</strong>
                        </p>
                        <ToggleSwitchField
                          name="enableDietaryRestrictions"
                          onChange={this.handleChangeFor(
                            "enableDietaryRestrictions"
                          )}
                          value={
                            this.state.eventQuestionSet
                              .enableDietaryRestrictions.value
                          }
                          errorMessage={
                            this.state.eventQuestionSet
                              .enableDietaryRestrictions.errorMessage
                          }
                          tabIndex="11"
                        />
                      </div>
                      {this.state.eventQuestionSet.enableDietaryRestrictions
                        .value && (
                        <div className="col-xs-6">
                          <p>Require?</p>
                          <ToggleSwitchField
                            name="requireDietaryRestrictions"
                            onChange={this.handleChangeFor(
                              "requireDietaryRestrictions"
                            )}
                            value={
                              this.state.eventQuestionSet
                                .requireDietaryRestrictions.value
                            }
                            errorMessage={
                              this.state.eventQuestionSet
                                .requireDietaryRestrictions.errorMessage
                            }
                            tabIndex="12"
                          />
                        </div>
                      )}
                    </div>
                    {this.props.event.locationType !== "physical" && (
                      <div className="row">
                        <div className="col-xs-6">
                          <p>
                            Enable <strong>Additional Attendees</strong>
                          </p>
                          <ToggleSwitchField
                            name="enableAdditionalAttendees"
                            onChange={this.handleChangeFor(
                              "enableAdditionalAttendees"
                            )}
                            value={
                              this.state.eventQuestionSet
                                .enableAdditionalAttendees.value
                            }
                            errorMessage={
                              this.state.eventQuestionSet
                                .enableAdditionalAttendees.errorMessage
                            }
                            tabIndex="11"
                          />
                        </div>
                        {this.state.eventQuestionSet.enableAdditionalAttendees
                          .value && (
                          <div className="col-xs-6">
                            <p>Require?</p>
                            <ToggleSwitchField
                              name="requireAdditionalAttendees"
                              onChange={this.handleChangeFor(
                                "requireAdditionalAttendees"
                              )}
                              value={
                                this.state.eventQuestionSet
                                  .requireAdditionalAttendees.value
                              }
                              errorMessage={
                                this.state.eventQuestionSet
                                  .requireAdditionalAttendees.errorMessage
                              }
                              tabIndex="12"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <h4>Custom Questions</h4>
                  <div className="col-xs-12">
                    <div className="row">
                      <div className="col-xs-6">
                        <p>
                          Enable <strong>Custom Question 1</strong>
                        </p>
                        <ToggleSwitchField
                          name="enableCustomQuestionOne"
                          onChange={this.handleChangeFor(
                            "enableCustomQuestionOne"
                          )}
                          value={
                            this.state.eventQuestionSet.enableCustomQuestionOne
                              .value
                          }
                          errorMessage={
                            this.state.eventQuestionSet.enableCustomQuestionOne
                              .errorMessage
                          }
                          tabIndex="13"
                        />
                      </div>
                      {this.state.eventQuestionSet.enableCustomQuestionOne
                        .value && (
                        <div className="col-xs-6">
                          <p>Require?</p>
                          <ToggleSwitchField
                            name="requireCustomQuestionOne"
                            onChange={this.handleChangeFor(
                              "requireCustomQuestionOne"
                            )}
                            value={
                              this.state.eventQuestionSet
                                .requireCustomQuestionOne.value
                            }
                            errorMessage={
                              this.state.eventQuestionSet
                                .requireCustomQuestionOne.errorMessage
                            }
                            tabIndex="14"
                          />
                        </div>
                      )}
                    </div>
                    {this.state.eventQuestionSet.enableCustomQuestionOne
                      .value && (
                      <div className="row">
                        <div className="col-xs-12">
                          <TextField
                            type="textarea"
                            name="customQuestionOne"
                            onChange={this.handleChangeFor("customQuestionOne")}
                            label="Custom Question 1"
                            value={
                              this.state.eventQuestionSet.customQuestionOne
                                .value
                            }
                            errorMessage={
                              this.state.eventQuestionSet.customQuestionOne
                                .errorMessage
                            }
                            tabIndex="15"
                            autoFocus={false}
                          />
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-xs-6">
                        <p>
                          Enable <strong>Custom Question 2</strong>
                        </p>
                        <ToggleSwitchField
                          name="enableCustomQuestionTwo"
                          onChange={this.handleChangeFor(
                            "enableCustomQuestionTwo"
                          )}
                          value={
                            this.state.eventQuestionSet.enableCustomQuestionTwo
                              .value
                          }
                          errorMessage={
                            this.state.eventQuestionSet.enableCustomQuestionTwo
                              .errorMessage
                          }
                          tabIndex="13"
                        />
                      </div>
                      {this.state.eventQuestionSet.enableCustomQuestionTwo
                        .value && (
                        <div className="col-xs-6">
                          <p>Require?</p>
                          <ToggleSwitchField
                            name="requireCustomQuestionTwo"
                            onChange={this.handleChangeFor(
                              "requireCustomQuestionTwo"
                            )}
                            value={
                              this.state.eventQuestionSet
                                .requireCustomQuestionTwo.value
                            }
                            errorMessage={
                              this.state.eventQuestionSet
                                .requireCustomQuestionTwo.errorMessage
                            }
                            tabIndex="14"
                          />
                        </div>
                      )}
                    </div>
                    {this.state.eventQuestionSet.enableCustomQuestionTwo
                      .value && (
                      <div className="row">
                        <div className="col-xs-12">
                          <TextField
                            type="textarea"
                            name="customQuestionTwo"
                            onChange={this.handleChangeFor("customQuestionTwo")}
                            label="Custom Question 2"
                            value={
                              this.state.eventQuestionSet.customQuestionTwo
                                .value
                            }
                            errorMessage={
                              this.state.eventQuestionSet.customQuestionTwo
                                .errorMessage
                            }
                            tabIndex="15"
                            autoFocus={false}
                          />
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-xs-6">
                        <p>
                          Enable <strong>Custom Question 3</strong>
                        </p>
                        <ToggleSwitchField
                          name="enableCustomQuestionThree"
                          onChange={this.handleChangeFor(
                            "enableCustomQuestionThree"
                          )}
                          value={
                            this.state.eventQuestionSet
                              .enableCustomQuestionThree.value
                          }
                          errorMessage={
                            this.state.eventQuestionSet
                              .enableCustomQuestionThree.errorMessage
                          }
                          tabIndex="13"
                        />
                      </div>
                      {this.state.eventQuestionSet.enableCustomQuestionThree
                        .value && (
                        <div className="col-xs-6">
                          <p>Require?</p>
                          <ToggleSwitchField
                            name="requireCustomQuestionThree"
                            onChange={this.handleChangeFor(
                              "requireCustomQuestionThree"
                            )}
                            value={
                              this.state.eventQuestionSet
                                .requireCustomQuestionThree.value
                            }
                            errorMessage={
                              this.state.eventQuestionSet
                                .requireCustomQuestionThree.errorMessage
                            }
                            tabIndex="14"
                          />
                        </div>
                      )}
                    </div>
                    {this.state.eventQuestionSet.enableCustomQuestionThree
                      .value && (
                      <div className="row">
                        <div className="col-xs-12">
                          <TextField
                            type="textarea"
                            name="customQuestionThree"
                            onChange={this.handleChangeFor(
                              "customQuestionThree"
                            )}
                            label="Custom Question 3"
                            value={
                              this.state.eventQuestionSet.customQuestionThree
                                .value
                            }
                            errorMessage={
                              this.state.eventQuestionSet.customQuestionThree
                                .errorMessage
                            }
                            tabIndex="15"
                            autoFocus={false}
                          />
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-xs-6">
                        <p>
                          Enable <strong>Custom Question 4</strong>
                        </p>
                        <ToggleSwitchField
                          name="enableCustomQuestionFour"
                          onChange={this.handleChangeFor(
                            "enableCustomQuestionFour"
                          )}
                          value={
                            this.state.eventQuestionSet.enableCustomQuestionFour
                              .value
                          }
                          errorMessage={
                            this.state.eventQuestionSet.enableCustomQuestionFour
                              .errorMessage
                          }
                          tabIndex="13"
                        />
                      </div>
                      {this.state.eventQuestionSet.enableCustomQuestionFour
                        .value && (
                        <div className="col-xs-6">
                          <p>Require?</p>
                          <ToggleSwitchField
                            name="requireCustomQuestionFour"
                            onChange={this.handleChangeFor(
                              "requireCustomQuestionFour"
                            )}
                            value={
                              this.state.eventQuestionSet
                                .requireCustomQuestionFour.value
                            }
                            errorMessage={
                              this.state.eventQuestionSet
                                .requireCustomQuestionFour.errorMessage
                            }
                            tabIndex="14"
                          />
                        </div>
                      )}
                    </div>
                    {this.state.eventQuestionSet.enableCustomQuestionFour
                      .value && (
                      <div className="row">
                        <div className="col-xs-12">
                          <TextField
                            type="textarea"
                            name="customQuestionFour"
                            onChange={this.handleChangeFor(
                              "customQuestionFour"
                            )}
                            label="Custom Question 4"
                            value={
                              this.state.eventQuestionSet.customQuestionFour
                                .value
                            }
                            errorMessage={
                              this.state.eventQuestionSet.customQuestionFour
                                .errorMessage
                            }
                            tabIndex="15"
                            autoFocus={false}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </PanelBody>

          <PanelControls>
            <button
              type="button"
              onClick={this.handleSave}
              className={`btn btn-success ${
                this.props.updating ? "disabled" : ""
              }`}
            >
              Save &amp; Exit
            </button>
          </PanelControls>
        </PanelRightColumn>
      </Panel>
    );
  }
}

EventQuestionsForm = connect(
  (state, props) => ({
    event: state.event,
    eventQuestionSet: state.eventQuestionSet,
    errors: state.submissionErrors.event,
    updateSucceeded: state.wizard.updateSucceeded,
    updating: state.wizard.updating,
    apiEndpoint: apiEndpoint(state.rails, "eventQuestionSet"),
  }),
  null,
  ({ apiEndpoint, ...stateProps }, { dispatch }, props) => ({
    ...stateProps,
    ...props,

    asyncUpdateEventQuestionSet: (data, updated) => {
      return asyncUpdateEventQuestionSet(
        apiEndpoint.path,
        data,
        updated
      )(dispatch);
    },

    resetWizardState: () => {
      dispatch(resetWizardState());
    },
    resetSubmissionErrors: () => {
      dispatch(resetErrorsForEvent());
    },
  })
)(EventQuestionsForm);

export default EventQuestionsForm;
